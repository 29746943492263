import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetCurrentTargets,
  getWidgetKpiAggregateValues,
  getWidgetUtilityAggregateValues,
} from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";
import moment from "moment";
import {
  getBillingPeriodForReportingPeriod,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
  processStringForDashboardFilters,
  niceFormatData,
  parseBaseLinePeriod,
  getWhenFilterForBillingPeriod,
  convertFilterToLastSecond,
  getWhenFilterForBaselinePeriod,
} from "../../../common/helpers";
import { tokens } from "theme";
import { useSearchParams } from "react-router-dom";

import { BarChart, BarPlot } from "@mui/x-charts/BarChart";
import {
  ChartsAxisHighlight,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  PiePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";

import { PieChart } from "@mui/x-charts/PieChart";

import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const WidgetPieChart = ({
  name,
  icon,
  top,
  left,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
  useFilters,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const lineColour = "black";

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  //console.log("Passed config: ", config);
  const title = config?.title;
  const description = config?.description;

  const serviceType = config?.serviceType;
  const currencyField = config?.currencyField;
  const valueField = config?.valueField;
  const groupBy = config?.groupBy;
  const sortBy = config?.sortBy;
  const fieldFormatter = config?.fieldFormatter;
  const groupMode = config?.groupMode;
  const showLegend = config?.showLegend;

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const renderTitle = processStringForDashboardFilters(title, renderState);
  const renderDescription = processStringForDashboardFilters(
    description,
    renderState
  );

  const columnsForRendering = useSelector(
    (state) =>
      state.dashboards.columnsForRendering?.find(
        (widgetData) =>
          widgetData.dashboard === dashboard && widgetData.widget === widget
      )?.columns
  );

  //calculate the when range based on the selected dimension

  const period = parsePeriod(config?.period, searchParams);

  const billingPeriod = getBillingPeriodForReportingPeriod(
    config?.billingPeriod
  );

  const dimension = parseDimension(config?.dimension, searchParams);

  const filter = getWhenFilterForReportingPeriod(period);
  const lastDayOfPeriod = convertFilterToLastSecond(filter);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard && widgetData.widget === widget
    )
  );

  let dataHasNegitives = false;

  const [itemData, setItemData] = React.useState();

  //console.log("Widget data ", widgetData)

  console.log(
    "render pie chart: ",
    widget,
    " refresh time of ",
    refreshPoint,
    " ",
    renderTitle
  );

  //Get the widget Data along with the Columns this Widget Provides
  useEffect(() => {
    //debugger

    if (config) {
      //need to augment the filter, we have the filter from the periods now add what is set in the renderstate

      let localFilter = filter;

      //debugger;

      if (useFilters) {
        localFilter = localFilter + "|" + renderState.filter;
      }

      //process the fileds list looking for negitives and remove them
      let fields = valueField.replace(/(^|,)-/g, "$1");

      let descriptors = "";

      if (currencyField) {
        descriptors = currencyField;
      }

      let search = {
        dashboard: dashboard,
        widget: widget,

        function: config.function,
        id: config.implementorId,
        fields: fields,
        dimension: dimension,

        //descriptors: currencyField,
        billingPeriod: billingPeriod,
        groupBy: groupBy,
        descriptors: descriptors,
      };

      // Split the string on '|'
      const parts = localFilter.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");

      //debugger;

      switch (serviceType) {
        case "Kpi":
          console.log("Dispatching getWidgetKpiAggregateValues:", search);
          dispatch(getWidgetKpiAggregateValues({ search }));

          break;

        case "Utility":
          console.log("Dispatching getWidgetUtilityAggregateValues:", search);
          dispatch(getWidgetUtilityAggregateValues({ search }));

          break;
      }

      search = {
        dashboard: dashboard,
        widget: widget,
        id: config.implementorId,
        serviceType: serviceType,
      };

      console.log("Dispatching getWidgetColumnValues:", search);
      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dashboard, widget, config, refreshPoint]);

  if (!widgetData?.rows || !config) {
    return <Box>Loading...</Box>;
  }

  let sortedData = [...widgetData?.rows]
    .map((row) => {
      const newRow = { ...row }; // Create a copy of the original row
      return newRow;
    })
    .sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

  let groupedData = [];
  let data = [];

  const groupBys = groupBy?.split(",");
  const fields = valueField?.split(",");

  //debugger;

  if (groupBys?.length > 1) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        sx={{ border: "1px solid", borderColor: theme.palette.grey[300] }}
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            To many Group By fields
          </Typography>
        </Box>
      </Box>
    );
  }

  if (groupBys?.length > 1 && fields?.length > 0) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        sx={{ border: "1px solid", borderColor: theme.palette.grey[300] }}
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            You cant have multi group by fields as well as multi value fileds,
            pick one
          </Typography>
        </Box>
      </Box>
    );
  }

  //check if we have an array of value fields

  if (fields.length > 1) {
    //process the fields into the data series
    console.log("Data befor field group:", sortedData);

    groupedData = sortedData.map((obj) => {
      // Destructure the object and create a new one without the specified key
      const { ["descriptor_" + currencyField]: removedKey, ...rest } = obj;

      // Round numeric values to 2 decimal places
      const roundedValues = Object.keys(rest)?.reduce((acc, key) => {
        let roundedValue =
          typeof rest[key] === "number"
            ? parseFloat(rest[key].toFixed(2))
            : rest[key];

        //modify the field name if this is a nonAggregateField
        if (key.startsWith("descriptor_")) {
          key = key.replace(/^descriptor_/, "");
        }

        acc[key] = roundedValue;

        // Check if the key is in valueFields and starts with a negitive

        const negKey = "-" + key;

        if (fields.includes(negKey)) {
          acc[key] = -roundedValue; // Negate the rounded value
          dataHasNegitives = true;
        }

        return acc;
      }, {});

      return roundedValues;
    });

    //create series array, this is all fields in the groupd data minus the index by

    console.log("Columns: ", columnsForRendering);
    console.log("Widget: ", widget);

    data = Array.from(
      new Set(
        groupedData?.reduce((fieldNames, entry) => {
          Object.keys(entry).forEach((fieldName) => {
            //debugger


            if (
              fieldName !== "ALL" && //Case rerturned by aggregate function
              !fieldNames.some((obj) => obj.dataKey === fieldName)
            ) {
              //define the item for the series
              let item = {
                dataKey: "series-1",
              };

              //debugger;

              //lookup human name for field
              const name = columnsForRendering.find(
                (col) => col.field === fieldName
              )?.headerName;
              item.label = name;

              // //add formatting if needed
              // if (fieldFormatter) {
              //   item.valueFormatter = (value) =>
              //     niceFormatData(fieldFormatter, value, currency);
              // }

              item.value = entry[fieldName];

              fieldNames.push(item);
            }
          });
          return fieldNames;
        }, [])
      )
    );

    console.log("Data after field group:", groupedData);
  } else {
    //no array of fields
    //force the index by to be the single group by

    //groupedData = [...sortedData];

    console.log("Data befor field group single field:", sortedData);

    groupedData = sortedData.map((item) => {
      let roundedItem = { ...item }; // Create a shallow copy of the item

      //debugger

      // Loop through each property in the item
      for (const key in roundedItem) {
        if (Object.prototype.hasOwnProperty.call(roundedItem, key)) {
          let thisKey = key;
          let roundedValue = roundedItem[key];

          //modify the field name if this is a nonAggregateField
          if (thisKey.startsWith("descriptor_")) {
            thisKey = thisKey.replace(/^descriptor_/, "");
          }

          // Check if the value is a number
          if (typeof roundedItem[thisKey] === "number") {
            // Round numeric values to 2 decimal places

            roundedValue = parseFloat(roundedItem[thisKey].toFixed(2));
            roundedItem[thisKey] = roundedValue;

            // Check if the key is in valueFields and starts with a negitive

            const negKey = "-" + thisKey;

            if (fields.includes(negKey)) {
              roundedItem[thisKey] = -roundedItem[thisKey]; // Negate the rounded value
              dataHasNegitives = true;
            }
          } else {
            roundedItem[thisKey] = roundedValue;
          }
        }
      }

      return roundedItem;
    });

    //debugger;

    console.log("Data after field group:", groupedData);

    const noNegField = valueField.replace(/(^|,)-/g, "$1");

    let item = {
      dataKey: "series-1",
    };

    //lookup human name for field
    const name = columnsForRendering.find(
      (col) => col.field === noNegField
    )?.headerName;
    item.label = name;

    // //add formatting if needed
    // if (fieldFormatter) {
    //   item.valueFormatter = (value) =>
    //     niceFormatData(fieldFormatter, value, currency);
    // }

    data.push(item);
  }

  console.log("Data for Pie series:", data);

  const currency = widgetData?.rows[0]?.["descriptor_" + currencyField];

  const series = [
    {
      type: "pie",
      // arcLabel: (item) => `${item.label} (${item.formattedValue})`,
      // arcLabelMinAngle: 45,
      valueFormatter: fieldFormatter ? (value) =>
        niceFormatData(fieldFormatter, value.value, currency) : null,
      innerRadius: 60,
      outerRadius: 100,
      data: data,
    },
  ];

  //debugger;

  console.log("Completed series for Pie Chart:", series);

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      sx={{ border: "1px solid", borderColor: theme.palette.grey[300] }}
      borderRadius="0.55rem"
    >
      <Box display="flex" flexDirection="column">
        <FlexBetween>
          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            {renderTitle}
          </Typography>
          {icon}
        </FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main }}>
          {renderDescription}
        </Typography>
      </Box>

      {data.length > 0 && series.length > 0 && (


          <PieChart
          series={series}
            // width={400}
            // height={300}
            slotProps={{
              legend: { hidden: !showLegend },
            }}
            onItemClick={(event, d) => setItemData(d)}
          />
      )}
    </Box>
  );
};

export default WidgetPieChart;
