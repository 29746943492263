import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getOrchestrator } from "../../slices/orchestrator";

const NiceOrchestrator = (props) => {
 
  const dispatch = useDispatch();

  const orchestratorId = props.children

  const orchestrator = useSelector((state) =>
    state.orchestrators.data.list?.find((org) => org.id === orchestratorId)
  );


  useEffect(() => {
    //debugger;

    if (!orchestrator  && orchestratorId !== "ALL") {
      
      //need to get the orchestrator to display
      let id = props.children;

      dispatch(getOrchestrator({ id }));
    }
  }, [orchestrator]);

  return (
  <>
    {orchestratorId === "ALL" ? (
      <Box>ALL</Box>
    ) : (
      orchestrator && (
        <Box display="flex" justifyContent="start" alignItems="baseline">
          {orchestrator.name}
        </Box>
      )
    )}
  </>
);

};

export default NiceOrchestrator;
