import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import PreferenceService from "../services/preference.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};






export const listPreference = createAsyncThunk(
  "Preference/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await PreferenceService.listPreference(search);
      return { Preference: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPreference = createAsyncThunk(
  "Preference/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreference(id);
      return { Preference: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getPreferenceFilterValues = createAsyncThunk(
  "Preference/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreferenceFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getPreferenceUi = createAsyncThunk(
  "Preference/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreferenceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updatePreference = createAsyncThunk(
  "Preference/update",
  async ({ preference }, thunkAPI) => {
    try {
      const data = await PreferenceService.updatePreference(preference);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  loading: false,
  filter_values: [],
  Ui: [],
}

const PreferenceSlice = createSlice({
  name: "Preference",
  initialState,

  extraReducers: {

    [updatePreference.fulfilled]: (state, action) => {

      const updatedPreference = state.data.list.map((Preference) => {
        if (Preference.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Preference;
        }
      });
      state.data.list = updatedPreference;
    },

    

    [getPreferenceUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreferenceUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getPreferenceUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getPreferenceFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreferenceFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getPreferenceFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },





    [listPreference.pending]: (state, action) => {
      state.loading = true;
    },
    [listPreference.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.Preference;
      state.loading = false;
    },
    [listPreference.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [getPreference.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreference.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.Preference;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getPreference.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});

const { reducer } = PreferenceSlice;
export default reducer;
