import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  validateYupSchema,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Stepper,
  Step,
  StepLabel,
  Grid,
  InputAdornment,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import { createOpportunity, updateOpportunity } from "slices/opportunity";
import { getServiceDimensionValues } from "slices/dimensions";
import {
  getCloudOrgStructure,
  getCloudOrgStructureByType,
  getCloudOrgStructureElement,
} from "slices/cloudOrgStructure";
import { getCustomerDefaults } from "slices/customer_defaults";

const separateAndTitleCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

const steps = ["Define Opportunity", "Resource", "Review your opportunity"];

// function _renderStepContent(step) {
//   switch (step) {
//     case 0:
//       return <AddressForm formField={formField} />;
//     case 1:
//       return <PaymentForm formField={formField} />;
//     case 2:
//       return <ReviewOrder />;
//     default:
//       return <div>Not Found</div>;
//   }
// }

const validationSchema = Yup.object().shape({
  //name: Yup.string().required("This field is required"),
  savingsType: Yup.string().required("Select the type of savings"),

  // subscription: Yup.string().when("cloud", {
  //   is: "AZURE",
  //   then: Yup.string().required("Select Subscription"),
  // }),
});

const OpportunityCreate = ({ closerFunc, opportunity }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  const formRef = useRef();

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const [selectedCloud, setSelectedCloud] = useState("AZURE");
  const [selectedSubscription, setSelectedSubscription] = useState("");

  const [selectedSavingsModel, setSelectedSavingsModel] = useState("");

  const customerDefaultBillingCurrency = useSelector(
    (state) => state.customerDefaults?.defaults?.defaultCurrency
  );

  const selectedBillingCurrency = useSelector(
    (state) =>
      state.cloudOrgStructure.data.list?.find(
        (element) => element.id === selectedSubscription
      )?.billingCurrency
  );

  const handleBack = () => {
    if (selectedCloud === "ALL" && activeStep == 2) {
      //this is one step higher than the cloud stop so skpi ove rit
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handelNext = () => {
    if (selectedCloud === "ALL" && activeStep == 0) {
      setActiveStep(activeStep + 2); //skip the cloud phase
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  //console.log("Selected sub: ", selectedSubscription  , " billing currency: " , selectedBillingCurrency )

  const initialValues = {};

  initialValues.id = "";
  initialValues.ref = "";
  initialValues.description = "";
  initialValues.annualSavingInBillingCurrency = null;
  initialValues.dailySavingInBillingCurrency = null;
  initialValues.subscription = "";
  initialValues.cloud = "AZURE";
  initialValues.resourceGroupName = "";
  initialValues.location = "";
  initialValues.billingCurrency = selectedBillingCurrency || "";
  initialValues.savingsType = "";
  initialValues.saving = "";
  initialValues.complexity = "Medium";
  initialValues.project = "";
  initialValues.byApplication = "";

  const availableSavingType = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SAVING_TYPE" &&
          dimension.field === "id"
      )?.values
  );

  const availableAzureSavingsModels = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SAVING_MODEL" &&
          dimension.field === "id"
      )?.values
  );

  const resolvedAzureSavingsModel = useSelector(
    (state) =>
      state.dimensions?.dimensions
        ?.find(
          (dimension) =>
            dimension.type === "stateSet" &&
            dimension.implementor === "SAVING_MODEL" &&
            dimension.field === "id"
        )
        ?.values.find((value) => value.value === selectedSavingsModel)?.label
  );

  const availableApplications = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "application" &&
          dimension.field === "id"
      )?.values
  );

  const usedClouds = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "cloud" &&
          dimension.field === "cloud"
      )?.values
  );

  const availableSubscriptions = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "subscription" &&
          dimension.field === "id"
      )?.values
  );

  const availableResourceGroups = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "resourceGroupName" &&
          dimension.field === "id"
      )?.values
  );

  const availableLocations = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "location" &&
          dimension.field === "id"
      )?.values
  );

  const availableResources = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "cloudOrgStructure" &&
          dimension.implementor === "resource" &&
          dimension.field === "id"
      )?.values
  );

  useEffect(() => {
    //on mount get some defaults

    if (!usedClouds) {
      let search = {
        type: "cloudOrgStructure",
        implementor: "cloud",
        field: "cloud",
        //lookUpLabel: lookUpLabel,
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    //debugger

    if (!customerDefaultBillingCurrency) {
      dispatch(getCustomerDefaults());
    }

    if (!availableSavingType) {
      let search = {
        type: "stateSet",
        implementor: "SAVING_TYPE",
        field: "id",
        //lookUpLabel: lookUpLabel,
      };

      dispatch(getServiceDimensionValues({ search }));
    }
  }, []);

  useEffect(() => {
    if (!availableSubscriptions && selectedCloud === "AZURE") {
      let search = {
        type: "cloudOrgStructure",
        implementor: "subscription",
        field: "id",
        filter: "[resourceType]=Subscription",
        lookUpLabel: "<customerName>",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (!availableApplications && selectedCloud !== "ALL") {
      let search = {
        type: "cloudOrgStructure",
        implementor: "application",
        field: "id",
        filter: "[resourceType]=Application",
        lookUpLabel: "<customerName>",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (!availableLocations && selectedCloud === "AZURE") {
      let search = {
        type: "cloudOrgStructure",
        implementor: "location",
        field: "id",
        filter: "[resourceType]=Location",
        lookUpLabel: "<customerName>",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (!availableAzureSavingsModels && selectedCloud === "AZURE") {
      let search = {
        type: "stateSet",
        implementor: "SAVING_MODEL",
        field: "id",
        lookUpLabel: "<id> (<savingType>)",
      };

      dispatch(getServiceDimensionValues({ search }));
    }
  }, [selectedCloud]);

  useEffect(() => {
    //get the savings type

    //debugger

    if (resolvedAzureSavingsModel) {
      const regex = /\((.*?)\)/; // Match text between parentheses
      const match = resolvedAzureSavingsModel.match(regex);

      if (match) {
        formRef.current.values.savingsType = match[1];
      }
    }
  }, [resolvedAzureSavingsModel]);

  console.log("selected savings:", selectedSavingsModel);

  useEffect(() => {
    if (!availableResourceGroups && selectedSubscription !== "") {
      //debugger
      let search = {
        type: "cloudOrgStructure",
        implementor: "resourceGroupName",
        field: "id",
        filter:
          "[subscription]=" +
          selectedSubscription +
          "|[resourceType]=Resource Group",
        lookUpLabel: "<customerName>",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (selectedSubscription !== "") {
      if (!selectedBillingCurrency) {
        // const search = {
        //   resourceType: "Subscription",
        //   wantedResource: selectedSubscription,
        // };

        // dispatch(getCloudOrgStructureByType({ search }));

        const id = selectedSubscription;
        dispatch(getCloudOrgStructureElement({ id }));
      } else {
        //debugger;
        formRef.current.values.billingCurrency = selectedBillingCurrency;
      }
    }
  }, [selectedSubscription, selectedBillingCurrency]);

  useEffect(() => {
    if (!availableResources && selectedSubscription !== "") {
      debugger;

      let search = {
        type: "cloudOrgStructure",
        implementor: "resource",
        field: "id",
        filter:
          "[subscription]=" + selectedSubscription + "|[resourceType]=Resource",
        lookUpLabel: "<customerName> (<subResourceType>)",
      };

      dispatch(getServiceDimensionValues({ search }));
    }
  }, [selectedSubscription]);

  const clouds = [
    // {
    //   value: "ALL",
    //   label: "All - Cross Cloud",
    // },
    {
      value: "AZURE",
      label: "Microsoft Azure",
    },
    {
      value: "AWS",
      label: "Amazon Web Services",
    },
    {
      value: "GCP",
      label: "Google Cloud Platform",
    },
  ];

  const complexity = [
    {
      value: "Low",
      label: "Low",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "High",
      label: "High",
    },
    {
      value: "Very High",
      label: "Very High",
    },
  ];

  const availableClouds = clouds.filter((cloud) => {
    // Check if the value of the current cloud is not present in usedClouds
    return usedClouds?.some((usedCloud) => usedCloud.value === cloud.value);
  });

  const [savingsMode, setSavingsMode] = React.useState("daily");
  const [lookupMode, setLookupMode] = React.useState("resource");

  const handleSavingsModeChange = (event) => {
    setSavingsMode(event.target.value);
  };

  const handlLookupModeChange = (event) => {
    setLookupMode(event.target.value);
  };

  return (
    <Box m="20px">
      <Header
        title={"New Opportunity"}
        subtitle={
          selectedCloud === "ALL"
            ? "At Hub Level"
            : "For the cloud " + selectedCloud
        }
      />

      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box height={"1rem"} />

      <Formik
        innerRef={formRef}
        onSubmit={(values, actions) => {
          const opportunity = { ...values };

          dispatch(clearMessage());

          // debugger;

          if (savingsMode === "daily") {
            opportunity.annualSavingInBillingCurrency = null;
          } else {
            opportunity.dailySavingInBillingCurrency = null;
          }

          opportunity.billingCurrency =
            selectedCloud === "ALL"
              ? customerDefaultBillingCurrency
              : selectedBillingCurrency;

          //correct what we send based on the mode

          switch (selectedCloud) {
            case "ALL":
              opportunity.subscription = "";
              opportunity.resourceGroupName = "";
              opportunity.location = "";
              opportunity.leadObject = "Cloud";
              opportunity.saving = "";
              break;

            case "AZURE":
              switch (lookupMode) {
                case "subscription":
                  opportunity.resourceGroupName = "";
                  opportunity.location = "";
                  opportunity.leadObject = opportunity.subscription;
                  break;

                case "resourceGroup":
                  opportunity.location = "";
                  opportunity.leadObject = opportunity.resourceGroupName;
                  break;

                case "resource":
                  opportunity.leadObject = opportunity.resource;
                  break;

                case "application":
                  opportunity.subscription = "";
                  opportunity.resourceGroupName = "";
                  opportunity.location = "";
                  opportunity.leadObject = opportunity.byApplication;

                  break;

              }


              
          }

          dispatch(createOpportunity({ opportunity }))
            .unwrap()
            .then(() => {
              //debugger;

              dispatch(setMessage("Opportunity Added", "success"));
              closerFunc();
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Define opportunity */}

              {activeStep === 0 && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      type="string"
                      label="Cloud"
                      onBlur={handleBlur}
                      //onChange={handleChange}
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedCloud(e.target.value);
                      }}
                      value={values.cloud}
                      name="cloud"
                      error={!!touched.cloud && !!errors.cloud}
                      helperText={touched.cloud && errors.cloud}
                      sx={{ gridColumn: "span 1" }}
                      inputProps={{ min: 1, max: 16 }}
                    >
                      {availableClouds?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Ext Ref"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ref}
                      name="ref"
                      error={!!touched.ref && !!errors.ref}
                      helperText={touched.ref && errors.ref}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows="5"
                      variant="outlined"
                      type="text"
                      label="Description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      error={!!touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Billing Currency"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingCurrency}
                      name="billingCurrency"
                      error={
                        !!touched.billingCurrency && !!errors.billingCurrency
                      }
                      helperText={
                        touched.billingCurrency && errors.billingCurrency
                      }
                      sx={{ gridColumn: "span 1" }}
                      disabled={true}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      type="string"
                      label="Saving"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedSavingsModel(e.target.value);
                      }}
                      value={values.saving}
                      name="saving"
                      error={!!touched.saving && !!errors.saving}
                      helperText={touched.saving && errors.saving}
                      sx={{ gridColumn: "span 1" }}
                      disabled={selectedCloud === "ALL"}
                    >
                      {availableAzureSavingsModels?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      type="string"
                      label="Savings Type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.savingsType}
                      name="savingsType"
                      error={!!touched.savingsType && !!errors.savingsType}
                      helperText={touched.savingsType && errors.savingsType}
                      sx={{ gridColumn: "span 1" }}
                      inputProps={{ min: 1, max: 16 }}
                      disabled={selectedCloud !== "ALL"}
                    >
                      {availableSavingType?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      type="string"
                      label="Complexity"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.complexity}
                      name="complexity"
                      error={!!touched.complexity && !!errors.complexity}
                      helperText={touched.complexity && errors.complexity}
                      sx={{ gridColumn: "span 1" }}
                      inputProps={{ min: 1, max: 16 }}
                    >
                      {complexity?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Project"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.project}
                      name="project"
                      error={!!touched.project && !!errors.project}
                      helperText={touched.project && errors.project}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Grid>
                </>
              )}

              {/* Cloud  */}

              {activeStep === 1 && (
                <>
                  <Grid item xs={12} sm={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="lookupMode"
                        value={lookupMode}
                        onChange={handlLookupModeChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="cloudOrgStructure"
                          control={<Radio />}
                          label="Select from the Cloud Org Structure"
                        />
                        <FormControlLabel
                          value="application"
                          control={<Radio />}
                          label="Select Application"
                        />
                        <FormControlLabel
                          value="subscription"
                          control={<Radio />}
                          label="At Subscription Level"
                        />
                        <FormControlLabel
                          value="resourceGroup"
                          control={<Radio />}
                          label="At Resource Group Level"
                        />
                        <FormControlLabel
                          value="resource"
                          control={<Radio />}
                          label="At Resource Level"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {lookupMode == "application" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          type="string"
                          label="Application"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedSubscription(e.target.value);
                          }}
                          value={values.byApplication}
                          name="byApplication"
                          error={
                            !!touched.byApplication && !!errors.byApplication
                          }
                          helperText={
                            touched.byApplication && errors.byApplication
                          }
                          sx={{ gridColumn: "span 1" }}
                          inputProps={{ min: 1, max: 16 }}
                        >
                          {availableApplications.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label} ({option.value})
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}

                  {lookupMode !== "cloudOrgStructure" &&
                    lookupMode !== "application" && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            type="string"
                            label="Subscription"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedSubscription(e.target.value);
                            }}
                            value={values.subscription}
                            name="subscription"
                            error={
                              !!touched.subscription && !!errors.subscription
                            }
                            helperText={
                              touched.subscription && errors.subscription
                            }
                            sx={{ gridColumn: "span 1" }}
                            inputProps={{ min: 1, max: 16 }}
                          >
                            {availableSubscriptions?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label} ({option.value})
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            type="string"
                            label="Resource Group"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.resourceGroupName}
                            name="resourceGroupName"
                            error={
                              !!touched.resourceGroupName &&
                              !!errors.resourceGroupName
                            }
                            helperText={
                              touched.resourceGroupName &&
                              errors.resourceGroupName
                            }
                            sx={{ gridColumn: "span 1" }}
                            inputProps={{ min: 1, max: 16 }}
                            disabled={
                              values.subscription === "" ||
                              lookupMode === "subscription"
                            }
                          >
                            {availableResourceGroups?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label} ({option.value})
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            type="string"
                            label="Location"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.location}
                            name="location"
                            error={!!touched.location && !!errors.location}
                            helperText={touched.location && errors.location}
                            sx={{ gridColumn: "span 1" }}
                            inputProps={{ min: 1, max: 16 }}
                            disabled={
                              values.subscription === "" ||
                              values.resourceGroup === "" ||
                              lookupMode !== "resource"
                            }
                          >
                            {availableLocations?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label} ({option.value})
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            type="string"
                            label="Resource"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.resource}
                            name="resource"
                            error={!!touched.resource && !!errors.resource}
                            helperText={touched.resource && errors.resource}
                            sx={{ gridColumn: "span 1" }}
                            inputProps={{ min: 1, max: 16 }}
                            disabled={
                              lookupMode !== "resource" ||
                              values.subscription === "" ||
                              values.resourceGroup === "" ||
                              values.location === ""
                            }
                          >
                            {availableResources?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </>
                    )}
                </>
              )}

              {/* Summary  */}

              {isLastStep && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="savingsMode"
                        value={savingsMode}
                        onChange={handleSavingsModeChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="annual"
                          control={<Radio />}
                          label="Annual Saving"
                        />
                        <FormControlLabel
                          value="daily"
                          control={<Radio />}
                          label="Daily Saving"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {savingsMode === "annual" ? (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        label="Annual Saving"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.annualSavingInBillingCurrency}
                        name="annualSavingInBillingCurrency"
                        error={
                          !!touched.annualSavingInBillingCurrency &&
                          !!errors.annualSavingInBillingCurrency
                        }
                        helperText={
                          touched.annualSavingInBillingCurrency &&
                          errors.annualSavingInBillingCurrency
                        }
                        sx={{ gridColumn: "span 1" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {selectedCloud === "ALL"
                                ? customerDefaultBillingCurrency
                                : selectedBillingCurrency}
                            </InputAdornment>
                          ),
                          step: "0.01", // Control the step for increment/decrement
                          min: "0", // Minimum value
                          pattern: "\\d*\\.?\\d{0,2}", // Allow up to two decimal places
                        }}
                      />
                    </Grid>
                  ) : null}
                  {savingsMode === "daily" ? (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Daily Saving"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.dailySavingInBillingCurrency}
                        name="dailySavingInBillingCurrency"
                        error={
                          !!touched.dailySavingInBillingCurrency &&
                          !!errors.dailySavingInBillingCurrency
                        }
                        helperText={
                          touched.dailySavingInBillingCurrency &&
                          errors.dailySavingInBillingCurrency
                        }
                        sx={{ gridColumn: "span 1" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {selectedCloud === "ALL"
                                ? customerDefaultBillingCurrency
                                : selectedBillingCurrency}
                            </InputAdornment>
                          ),
                          step: "0.01", // Control the step for increment/decrement
                          min: "0", // Minimum value
                          pattern: "\\d*\\.?\\d{0,2}", // Allow up to two decimal places
                        }}
                      />
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>

            <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={activeStep === 0 ? closerFunc : handleBack}
                >
                  {activeStep === 0 ? "Cancel" : "Back"}
                </Button>

                {!isLastStep && (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handelNext}
                  >
                    Next
                  </Button>
                )}

                {isLastStep && (
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Add Opportunity
                  </Button>
                )}
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default OpportunityCreate;
