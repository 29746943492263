import React, { useEffect } from "react";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  MenuOpenOutlined,
  CommitOutlined,
  DashboardOutlined,
  VerifiedUserOutlined,
  CollectionsOutlined,
  PersonSearchOutlined,
  SpatialTrackingOutlined,
  WidgetsOutlined,
  FlagOutlined,
  TimerOutlined,
  MonetizationOn,
  DirectionsRunOutlined,
  PlaylistAddCheck,
  AccountTreeOutlined,
  PlaylistAddCheckOutlined,
  BentoOutlined,
  SettingsSuggestOutlined,
  InsightsOutlined,
  MenuOutlined,
  LanOutlined,
  RouteOutlined,
  AppRegistrationOutlined,
  MenuBookOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PersonOutlined,
  AttachMoneyOutlined,
  CalendarMonthSharp,
  CalendarMonthOutlined,
  CalculateOutlined,
  CloudOutlined,
  HelpOutlineOutlined,
  RadarOutlined,
  PolicyOutlined,
  InfoOutlined,
  PsychologyOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogues } from "slices/catalogue";


export const services = [
  {
    serviceType: "copilot",
    icon: <PsychologyOutlined/>,
    name: "Copilot",
    description: "OpsBlox.io copilot",
  },
  {
    serviceType: "opportunity",
    icon: <CalculateOutlined/>,
    name: "Opportunities",
    description: "Savings opportunities",
  },
  {
    serviceType: "cloudOrgStructure",
    icon: <CloudOutlined/>,
    name: "Cloud Org Structure",
    description: "Resources in the cloud",
  },

  {
    serviceType: "cataloguePage",
    icon: <MenuBookOutlined/>,
    name: "Catalogues",
    description: "Info for you",
  },

  {
    serviceType: "catalogue",
    icon: <MenuBookOutlined/>,
    name: "Catalogues",
    description: "Info for you",
  }

  


  


]
