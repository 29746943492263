import api from "./api";


const updateWorkflowActive = (data) => {
  return api.patch("/workflow/active",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const updateWorkflow = (workflow) => {
  return api.patch("/workflow",
  {
    ...workflow
  })
  .then((response) => {
    return response.data;
  });
};

const stopWorkflow = (id) => {
  return api.get("/workflow/stop/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflows = (search) => {

  return api.get("/workflow",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getWorkflow = (id) => {
  return api.get("/workflow/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflowLog = (id) => {
  return api.get("/workflow/log/"+id)
  .then((response) => {
    return response.data;
  });
};


const WorkflowSevice = {
  getWorkflows,
  updateWorkflow,
  getWorkflow,
  getWorkflowLog,
  updateWorkflowActive,
};

export default WorkflowSevice;