import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { clearUtilityUi } from "slices/utility";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getUtility,
  updateUtility,
  getUtilityUi,
  getUtilityData,
  runUtility,
  updateUtilityActive,
} from "slices/utility";

import { getJobs } from "slices/job";
import { setMessage, clearMessage } from "slices/message";

import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import KeyVal from "components/global/KeyVal";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import {
  CheckOutlined,
  CloseOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceActive from "components/global/NiceActive";
import TabPanel from "components/global/TabPanel";
import NiceLink from "components/global/NiceLink";
import NiceUser from "components/global/NiceUser";
import NiceObjectCell from "components/global/NiceObjectCell";
import NiceSubscription from "components/global/NiceSubscription";
import { renderColumn } from "common/renderColumn";
import StateSetAsConfigProvider from "components/state/StateSetAsConfigProvider";
import NiceBoolean from "components/global/NiceBoolean";
import { getCurrentBillingPeriod } from "common/billingPeriod";
import TabsVertical from "components/global/TabsVerticle";
import TabVertical from "components/global/TabVertical";
import ObjectDetails from "components/global/ObjectDetails";
import WhereUsedService from "components/where_used/WhereUsedService";

const Utility = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const Jobs = useSelector((state) => state.job.data);

  const Utility = useSelector((state) =>
    state.utility.data.list?.find((org) => org.id === id)
  );

  const Utility_data = useSelector((state) => state.utility.utility_data);

  const Utility_UI = useSelector((state) => state.utility.utilityUi);

  const [tabValue, setTabValue] = React.useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    //need to get the Utility UI form the API
    dispatch(getUtilityUi({ id }));

    if (!Utility) {
      //need to get the Utility form the API
      dispatch(getUtility({ id }));
    }
  }, [Utility, id]);

  useEffect(() => {
    if (isFirstLoad) {
      //set the fact that we have an update on the UI block to use
      setIsFirstLoad(false);
      dispatch(clearUtilityUi());

      if (searchParams.get("tab")) {
        setTabValue(parseInt(searchParams.get("tab"), 10));
      } else {
        setTabValueAndUpdateQuery(tabValue);
      }
    }
  }, [id]);

  useEffect(() => {
    dispatch(getUtility({ id }));
  }, [refreshPoint]);

  const handleRun = () => {
    dispatch(clearMessage());
    dispatch(runUtility({ id }));
  };

  const handleActivate = () => {
    dispatch(clearMessage());
    const utility = { id: id, active: true };
    dispatch(updateUtilityActive({ utility }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const utility = { id: id, active: false };
    dispatch(updateUtilityActive({ utility }));
  };

  const jobColumnsVisability = {
    organization: false,
    orchestrator: false,
    modifiedAt: false,
  };

  const jobColumns = [
    {
      field: "id",
      headerName: "Job Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <NiceLink label={id} link={`/job/${id}`} tip="Open Job" makeBlue />
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "jobOrigin",
      headerName: "Origin",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: ({ row: { createdAt } }) => {
        return <NiceDate>{createdAt}</NiceDate>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Update",
      flex: 1,
      type: "date",
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
    {
      field: "startedAt",
      headerName: "Started",
      flex: 1,
      type: "date",
      renderCell: ({ row: { startedAt } }) => {
        return <NiceDate>{startedAt}</NiceDate>;
      },
    },
    {
      field: "stoppedAt",
      headerName: "Stopped",
      flex: 1,
      type: "date",
      renderCell: ({ row: { stoppedAt } }) => {
        return <NiceDate>{stoppedAt}</NiceDate>;
      },
    },
    {
      field: "duration",
      headerName: "Run Time",
      flex: 1,
      type: "number",
      renderCell: ({ row: { duration } }) => {
        return <NiceDuration>{duration}</NiceDuration>;
      },
    },
    {
      field: "itemsProcessed",
      headerName: "Items Processed",
      flex: 1,
      type: "number",
    },
  ];

  let columns = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;

    inputString = inputString.replace(/_id/g, "id");

    const processedString = inputString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (Utility_UI?.columns) {
    columns = Utility_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Utility?.name} subtitle={Utility?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          {!Utility?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckOutlined />}
              onClick={handleActivate}
            >
              Activate
            </Button>
          )}
          {Utility?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloseOutlined />}
              onClick={handleDectivate}
            >
              Deactivate
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayArrowOutlined />}
            onClick={handleRun}
          >
            Run
          </Button>
        </FlexBetween>
      </FlexBetween>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box
          gap="1rem"
          sx={{
            //borderBottom: 1,
            borderTop: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <TabsVertical
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Opportunity Tabs"
          >
            <TabVertical
              label={"Details"}
              {...a11yProps(0)}
              sx={{ textTransform: "none", textAlign: "left" }}
            />
            <TabVertical
              label={`Resources (${Utility_data?.total})`}
              {...a11yProps(1)}
              sx={{ textTransform: "none", textAlign: "left" }}
            />
            <TabVertical
              label={`Job History (${Jobs?.total})`}
              {...a11yProps(2)}
              sx={{ textTransform: "none", textAlign: "left" }}
            />
            <TabVertical
              label="Configguration"
              {...a11yProps(3)}
              sx={{ textTransform: "none", textAlign: "left" }}
              disabled={!Utility?.requiresConfiguration}
            />

            <TabVertical
              label="Related To"
              {...a11yProps(4)}
              sx={{ textTransform: "none", textAlign: "left" }}
            />
          </TabsVertical>

          <TabPanel value={tabValue} index={0}>
            {id != undefined ? (
              <ObjectDetails
                serviceType={"utility"}
                id={id}
                rowId={null}
                refreshPoint={refreshPoint}
                titleSetter={null}
                hideId={false}
              />
            ) : (
              <Typography>Nothing to load...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {Utility_data && !isFirstLoad && Utility_UI && Utility ? (
              <TablePage
                masterId={id}
                dataRows={Utility_data}
                dataColumns={columns}
                dataGetter={getUtilityData}
                defaultColumnsVisability={Utility_UI.columnsVisability}
                defaultSort={Utility_UI.sort}
                refreshPoint={refreshPoint}
                serviceType={"utility"}
                billingRelevant={Utility.billingRelevant}
                initialBillingPeriod={getCurrentBillingPeriod()}
              />
            ) : (
              <Typography>Loading</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {Jobs ? (
              <TablePage
                masterId={id}
                masterIdField="implementor"
                dataRows={Jobs}
                dataColumns={jobColumns}
                dataGetter={getJobs}
                defaultColumnsVisability={jobColumnsVisability}
                defaultSort={"-startedAt"}
                refreshPoint={refreshPoint}
                serviceType={"jobs"}
              />
            ) : (
              <Typography>Empty Log...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <StateSetAsConfigProvider id={id} showHidden={false} />
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            <WhereUsedService
              serviceType={"utility"}
              id={id}
              refreshPoint={refreshPoint}
            />
          </TabPanel>
        </Box>
      </Box>
    </PageBlock>
  );
};

export default Utility;
