import api from "./api";

const updateCopilotConversation = (copilotConversation) => {
  return api
    .patch("/copilot", {
      ...copilotConversation,
    })
    .then((response) => {
      return response.data;
    });
};


const getCopilotConversations = (search) => {
  return api
    .get("/copilot", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getCopilotPrompts = (search) => {
  return api
    .get("/copilot/prompt", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};



const getCopilotConversation = (id) => {
  return api.get("/copilot/" + id).then((response) => {
    return response.data;
  });
};

const getCopilotPrompt = (id) => {
  return api.get("/copilot/prompt/" + id).then((response) => {
    return response.data;
  });
};

const createCopilotConversation = (step) => {
  return api.post("/copilot", {
    ...step,
  });
};

const createCopilotPrompt = (step) => {
  return api.post("/copilot/prompt", {
    ...step,
  });
};

const getCopilotConversationFilterValues = (search) => {

  return api.get("/copilot/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getCopilotConversationUi = () => {
  return api.get("/copilot/ui")
  .then((response) => {
    return response.data;
  });
};

const getCopilotPromptFilterValues = (search) => {

  return api.get("/copilot/prompt/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getCopilotPromptUi = () => {
  return api.get("/copilot/prompt/ui")
  .then((response) => {
    return response.data;
  });
};

const updateCopilotFeedback = (prompt) => {
  return api
    .patch("/copilot/prompt", {
      ...prompt,
    })
    .then((response) => {
      return response.data;
    });
};

const CopilotService = {
  createCopilotConversation,
  updateCopilotConversation,
  getCopilotConversations,
  getCopilotPrompts,
  getCopilotConversation,
  getCopilotPrompt,
  createCopilotPrompt,
  getCopilotConversationFilterValues,
  getCopilotPromptFilterValues,
  getCopilotConversationUi,
  getCopilotPromptUi,
  updateCopilotFeedback,

};

export default CopilotService;
