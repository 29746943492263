import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Tooltip,
  Popover,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";

import {
  WidgetsOutlined,
  PlayCircleOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  RefreshOutlined,
  ArrowDropDown,
  DeleteOutline,
  PlayArrowOutlined,
  CheckOutlined,
  CompressOutlined,
  CloseOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOpportunity,
  getOpportunities,
  getOpportunityUi,
} from "slices/opportunity";
import { setMessage, clearMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";

import { renderColumn } from "common/renderColumn";
import { processString } from "common/helpers";
import OpportunityCreate from "components/opportunity/OpportunityCreate";

const Opportunities = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.opportunity.data);
  const ui = useSelector((state) => state.opportunity.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getOpportunityUi({}));
  }, [rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [createEditMode, setCreateEditMode] = useState("NEW");

  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    //debugger;

    setCreateEditMode("NEW");

    setAnchorEl(configRef.current);
  };

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleOpen = () => {
    navigate("/opportunity/" + selectionModel[0]);
  };

  const handleDelete = async () => {
    dispatch(clearMessage());

    // Create an array of promises for each delete action
    const deletePromises = selectionModel.map((item) => {
      const selectedOpp = selectionModel[0];
      return dispatch(deleteOpportunity(selectedOpp));
    });

    // Wait for all delete actions to complete
    try {
      await Promise.all(deletePromises);
      // All delete actions are completed, proceed with refresh
      handelRefresh();
    } catch (error) {
      // Handle any errors if necessary
      console.error("Error deleting rows:", error);
    }
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: handleDelete,
    },
  ];

  let columns = [];

  if (ui?.columns) {
    columns = ui.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  return (
    <>
      <Dialog open={open} maxWidth={"lg"}>
        <DialogContent>
          <OpportunityCreate
            closerFunc={handelCloseAddEdit}
            //opportunity = {opportunity}
          />
        </DialogContent>
      </Dialog>

      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Opportunities" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button
              variant="outlined"
              color="primary"
              onClick={handelRefresh}
            >
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handelOpenAddEdit}
            >
              New Savings Opportunity
            </Button>
          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (
          <TablePage
            dataRows={rows}
            dataColumns={columns}
            dataGetter={getOpportunities}
            defaultColumnsVisability={ui.columnsVisability}
            defaultSort={ui.sort}
            refreshPoint={refreshPoint}
            selectionModel={selectionModel}
            selectionSetter={setSelectionModel}
            serviceType={"opportunity"}
          />
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>
    </>
  );
};

export default Opportunities;
