import api from "./api";



const listSequence = (search) => {
  return api
    .get("/sequence", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};


const getSequence = (id) => {
  return api.get("/sequence/" + id).then((response) => {
    return response.data;
  });
};

const getNextSequence = (id) => {
  return api.get("/sequence/next/" + id).then((response) => {
    return response.data;
  });
};


const getSequenceFilterValues = (search) => {

  return api.get("/sequence/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getSequenceUi = () => {
  return api.get("/sequence/ui")
  .then((response) => {
    return response.data;
  });
};


const updateSequence = (sequence) => {
  return api
    .patch("/sequence", {
      ...sequence,
    })
    .then((response) => {
      return response.data;
    });
};



const SequenceService = {
  listSequence,
  getSequence,
  getSequenceFilterValues,
  getSequenceUi,
  updateSequence,
  getNextSequence

};

export default SequenceService;
