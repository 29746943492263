import { Box, Typography, useTheme ,Stack,Button,IconButton} from "@mui/material";
import { GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes} from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/global/Header";
import React, { useState, useEffect,useMemo } from 'react';
import NiceDate from "../../../components/global/NiceDateOnly";
import { useDispatch, useSelector } from "react-redux";
import { getUsers , updateUser} from "../../../slices/user";
import { setMessage , clearMessage} from "../../../slices/message"
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import NiceOrganization from "../../../components/global/NiceOrganization";
import TablePage from "components/global/TablePage";
import TableActionsButton from "components/global/TableActionsButton";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { CheckOutlined, CloseOutlined, DeleteOutlined, OpenInNewOutlined, RefreshOutlined } from "@mui/icons-material";
import { now } from "moment";
import PageBlock from "components/global/PageBlock";


const UserList = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const columnsVisability = {
    organization: false,
  };


  const handleActivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const role = { id: item, active: true };

      dispatch(updateUser({ role }));
    });

  }

  const handleDectivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const role = { id: item, active: false };

      dispatch(updateUser({ role }));
    });

  }


  const handleOpen = () => {

    navigate("/user/" + selectionModel[0])
  }

  const actionMenuItems = [
    
    {
      text: "Details",
      icon:<OpenInNewOutlined/>,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon:<CheckOutlined/>,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon:<CloseOutlined/>,
      callBack: handleDectivate,
    },


    {
      text: "",
      icon:null,
      callBack: "",
      isDivider:true,
    },

    {
      text: "Delete",
      icon: <DeleteOutlined />,
      callBack: "",
    },

  ];


  const columns = [
    { 
      field: "organization", 
      headerName: "Organization" ,
      flex: 1,
      renderCell: ({row: {organization}}) => {

        return (
          <NiceOrganization>{organization}</NiceOrganization>
        )
      }
    },
    { 
      field: "id", 
      headerName: "User" ,
      flex: 1
    },
    { 
      field: "name", 
      headerName: "Full Name" ,
      flex: 1,
      renderCell: ({row: {firstName,lastName}}) => {

        return (
          <Typography>
            {firstName} {lastName}
          </Typography>
        )
      }
    },
    { 
      field: "Email", 
      headerName: "Email" ,
      flex: 1,
    },
    {
      field: "Active",
      headerName: "Active",
      type:"boolean",
      editable: true ,
      renderCell: ({row: {Active}}) => {

        return (
          <Box>
          {Active &&
            <CheckIcon />
          }
          </Box>
        )
      }

    },
    {
      field: "createdAt",
      headerName: "Created",
      type: "date",
      flex: 1,  
      renderCell: ({row: {createdAt}}) => {

        return (
          <NiceDate>{createdAt}</NiceDate>
        )
      }
    },
    

  ];

  //const getRowId = (row) => row.username;

  return (

    <PageBlock>
      <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="Users" subtitle="" />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
          
          <TableActionsButton selectedRows={selectionModel} menuItems={actionMenuItems}/>

          <Button variant="contained" color="primary">
            New User
          </Button>
        </FlexBetween>
      </FlexBetween>

      <TablePage
        dataRows={users?.data}
        dataColumns={columns}
        dataGetter={getUsers}
        defaultColumnsVisability={columnsVisability}
        defaultSort={"-_id"}
        refreshPoint={refreshPoint}
        selectionModel={selectionModel}
        selectionSetter={setSelectionModel}
      />
    </PageBlock>

  );
};

export default UserList;
