import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckOutlined, ClearOutlined, EditOutlined } from "@mui/icons-material";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = "Click To Edit";

const EditableFieldBool = ({
  fieldType,
  field,
  initialValue,
  tip,
  displayValue,
  onChange = () => {},
  ...props
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue ? "TRUE" : "FALSE");


  console.log("Editable Bool: " , field , " vlaue: " , initialValue)

  const handleChange = (e) => {
    setValue(e.target.value);}

  const handelEditMode = () => {
    setEditing(true);
  };

  const handelDisplayMode = () => {
    setEditing(false);
  };

  const handelSave = (e) => {
    onChange(value);
    handelDisplayMode();
  };

  const handelReset = (e) => {
    setValue(initialValue ? "TRUE" : "FALSE");
    handelDisplayMode();
  };

  useEffect(() => {
    setValue(initialValue ? "TRUE" : "FALSE");
  }, [initialValue]);

  const boolOptions = [
    {
      value: "TRUE",
      label: "True",
    },
    {
      value: "FALSE",
      label: "False",
    },
  ];

  if (isEditing) {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap=".4rem"
        border={0}
        p={".2rem"}
      >
        <TextField
        select
          size="small"
          variant="standard"
          value={value}
          onChange={handleChange}
          onBlur={() => {
            if (!buttonClicked) {
              handelReset();
            }
          }}
          autoFocus
          sx={{ flex: 1 }} 
        >
          {boolOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Stack gap=".2rem" direction="row">
          <IconButton
            size="small"
            onClick={handelSave}
            onMouseDown={() => setButtonClicked(true)}
            onMouseUp={() => setButtonClicked(false)}
          >
            <CheckOutlined fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={handelReset}
            onMouseDown={() => setButtonClicked(true)}
            onMouseUp={() => setButtonClicked(false)}
          >
            <ClearOutlined fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
    );
  }

  return (
    <Tooltip title={tip} placement="bottom-start">
      <Stack gap=".2rem" direction="row" alignItems={"flex-start"}
        p={"2px"}
        onClick={handelEditMode}
        // sx={{
        //   borderRadius: 1,
        //   cursor: "pointer",
        //   border: "1px solid transparent", // Initially transparent border
        //   "&:hover": {
        //     border: "1px solid #000", // Change border color on hover
        //   },
        // }}

        sx={{
          cursor: "pointer",
        }}


      >
        {displayValue}

        <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <EditOutlined fontSize="small" />
            </IconButton>

      </Stack>
    </Tooltip>
  );
};

export default EditableFieldBool;
