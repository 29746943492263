import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import userReducer from "./slices/user";
import organizationsReducer from "./slices/organization"
import OrchestratorReducer from "slices/orchestrator"
import BloxReducer from "slices/blox"
import UtilityReducer from "slices/utility"
import JobsReducer from "slices/job"
import SchedulesReducer from "slices/schedule"
import WorkflowContributionsReducer from "slices/worklow_contribution"
import workflowsReducer from "slices/workflow"
import WorkflowStepsReducer from "slices/worklow_step"
import workflowUiReducer from "slices/workflowUI"
import StateSetReducer from "slices/state"
import CustomerNetworkReducer from "slices/customer_network"
import CustomerDefaultsReducer from "slices/customer_defaults"
import tablePageReducer from "slices/tablePage"
import KpiReducer from "slices/kpi"
import ServiceReducer from 'slices/services';
import DashboardReducer from 'slices/dashboard';
import DimensionReducer from 'slices/dimensions';
import OpportunityReducer from 'slices/opportunity';
import CloudOrgStructureReducer from 'slices/cloudOrgStructure'
import AuditLogReducer from 'slices/auditLog';
import SequenceReducer from 'slices/sequence';
import CopilotReducer from 'slices/copilot';
import CatalogueReducer from 'slices/catalogue';
import SearchReducer from 'slices/search';
import ConfigReducer from 'slices/config';
import BackupReducer from 'slices/backup';

//import PreferenceReducer from 'slices/preference';

const reducer = {
  auth: authReducer,
  message: messageReducer,
  users: userReducer,
  organizations: organizationsReducer,
  orchestrators: OrchestratorReducer,
  blox: BloxReducer,
  utility: UtilityReducer,
  job: JobsReducer,
  schedules: SchedulesReducer,
  workflowContributions: WorkflowContributionsReducer,
  workflows : workflowsReducer,
  workflowSteps: WorkflowStepsReducer,
  workflowUi: workflowUiReducer,
  stateSet: StateSetReducer,
  customerNetwork: CustomerNetworkReducer,
  customerDefaults: CustomerDefaultsReducer,
  tablePage: tablePageReducer,
  kpi: KpiReducer,
  services : ServiceReducer,
  dashboards : DashboardReducer,
  dimensions : DimensionReducer,
  opportunity: OpportunityReducer,
  cloudOrgStructure: CloudOrgStructureReducer,
  auditLog: AuditLogReducer,
  sequence: SequenceReducer,
  copilot: CopilotReducer,
  catalogue : CatalogueReducer,
  search: SearchReducer,
  config: ConfigReducer,
  backup: BackupReducer
  //preference: PreferenceReducer,
}


const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;