import {
  AutoAwesomeMotionOutlined,
  FlagOutlined,
  InsightsOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlaylistAddCheckOutlined,
  VerticalAlignBottomOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import { getBlox } from "slices/blox";
import { getUtilities, getUtility } from "slices/utility";
import { getWorkflowContribution } from "slices/worklow_contribution";
import CloudOrgBaseNode from "components/cloud_org_graph/CloudOrgBaseNode";
import { getStateSet } from "slices/state";
import { getKpi } from "slices/kpi";

const CloudOrgNode = memo(({ data }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("Loading");
  const [icon, setIcon] = useState(null);

  let howUsed = "This Service"

  if (data.nodeType === "child"){

     howUsed = data.relationship == "Config"
      ? "Provides Configuration for " + data.rootName
      : data.relationship === "Reads"
      ? "provides Data to "+ data.rootName
      : data.relationship === "Writes"
      ? "Is Updated by " + data.rootName
      : data.relationship === "Manages"
      ? "Is Managed by " + data.rootName
      : data.relationship;
    
  }

  if (data.nodeType === "parent"){

    howUsed = data.relationship == "Config"
      ? "Is Configgured by " + data.rootName
      : data.relationship === "Reads"
      ? "Is read by "+ data.rootName
      : data.relationship === "Writes"
      ? "Updates " + data.rootName 
      : data.relationship === "Manages"
      ? "Manages " + data.rootName  
      : data.relationship;
  }


  

  return (
    <CloudOrgBaseNode
      nodeType={data.nodeType}
      id={data.id}
      width={data.width}
      height={data.height}
      relationshipCounts={data.relationshipCounts}
      relationship={data.relationship}
      nodeSelector={data.nodeSelector}
    >
      <Box display="flex" flexDirection="column" alignItems={"flex-start"}>
        <Box display={"flex"} gap={".4rem"} alignItems={"center"} mb={".7rem"}>
          {icon}
          <Typography variant="h3">{data.customerName}</Typography>
        </Box>

        <Typography variant="h5">{data.resourceType}</Typography>
        <Typography>{data.subResourceType}</Typography>
      </Box>
    </CloudOrgBaseNode>
  );
});

export default CloudOrgNode;
