import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment-timezone";
import { CheckOutlined, ClearOutlined, EditOutlined } from "@mui/icons-material";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = "Click To Edit";

const EditableFieldDate = ({
  fieldType,
  field,
  initialValue,
  tip,
  displayValue,
  lookupCollection,
  lookupIdField,
  lookupLabel,

  onChange = () => {},
  ...props
}) => {

  //console.log
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [value, setValue] = useState(() => {
    //console.log("Forming Moment on mount" , initialValue)
    const myMoment = moment(initialValue);
    //const myMoment = moment.tz(initialValue, "UTC");
    if (myMoment.isValid()) {
      return myMoment;
    } else {
      return moment.unix(moment.now() / 1000);
    }
  });

  useEffect(() => {
    setValue(() => {
      //console.log("Forming Moment on useEffect" , initialValue)

      const myMoment = moment(initialValue);
      //const myMoment = moment.tz(initialValue, "UTC")
      if (myMoment.isValid()) {
        return myMoment;
      } else {
        return moment.unix(moment.now() / 1000);
      }
    });
  }, [initialValue]);


  const handelEditMode = () => {
    setEditing(true);
  };

  const handelDisplayMode = () => {
    setEditing(false);
  };

  const handelSave = (e) => {
    
    //debugger;

    const utcTime = value.utc();
    const formatedTime =utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]")
    onChange(formatedTime);
    handelDisplayMode();
  };

  const handelReset = (e) => {
    setValue(() => {
      //console.log("Forming Moment" , initialValue)

      const myMoment = moment(initialValue);
      if (myMoment.isValid()) {
        return myMoment;
      } else {
        return moment.unix(moment.now() / 1000);
      }
    });
    handelDisplayMode();
  };

  if (isEditing) {
    if (lookupCollection != undefined && lookupCollection != "") {
      return (
        <Autocomplete
          width="100%"
          size="small"
          id={`autocomplete-${field}`}
          //options={options}
          //getOptionLabel={(option) => option.label}

          includeInputInList
          renderInput={(params) => (
            <TextField
              inputProps={{
                value,
              }}
              {...params}
              fullWidth
              variant="outlined"
              //value={network.find(option => option.value === values.role)?.label || ''}
            />
          )}
        />
      );
    } else {
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          <DateTimePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            //autoFocus
            slotProps={{
              textField: {
                size: "small",
                variant: "standard",
              },
            }}

            sx={{ flex: 1 }} 

          />
          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    }
  }

  return (
    <Tooltip title={tip} placement="bottom-start">
      <Stack gap=".2rem" direction="row" alignItems={"flex-start"}
        p={"2px"}
        onClick={handelEditMode}
        // sx={{
        //   borderRadius: 1,
        //   cursor: "pointer",
        //   border: "1px solid transparent", // Initially transparent border
        //   "&:hover": {
        //     border: "1px solid #000", // Change border color on hover
        //   },
        // }}

        sx={{
          cursor: "pointer",
        }}


      >
        {displayValue}

        <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <EditOutlined fontSize="small" />
            </IconButton>

      </Stack>
    </Tooltip>
  );
};

export default EditableFieldDate;
