import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userHasRole } from "../../slices/auth";
import { useGridSelector } from "@mui/x-data-grid";

//import { history } from '_helpers';

export { PrivateRoute };

function PrivateRoute({ children, role }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const location = useLocation();

  const userRoles = useSelector((state) => state.auth?.user?.roles);

  var isOk = false;

  if (role !== undefined && role !== "") {
    //get a list of roles if there is one
    var roles = role?.split(",");

    //loop over roles to check if they have any of the roles in the list if so stop and say OK

    roles.forEach((check) => {

      if (userRoles?.some(e => e.roleId === check)) {
        isOk = true;
      }

    });
  }

  //debugger;

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  if (role !== undefined && role !== "" && !isOk) {
    // does not have the right roll to use this area of the site
    return <Navigate to="/forbidden" state={{ from: location.pathname }} />;
  }

  // authorized so return child components
  //state={{ from: history.location }}
  return children;
}
