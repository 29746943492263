import { Box, Typography } from "@mui/material";
import { Background } from "reactflow";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  // return (
  //   <div
  //     role="tabpanel"
  //     hidden={value !== index}
  //     id={`simple-tabpanel-${index}`}
  //     aria-labelledby={`simple-tab-${index}`}
  //     {...other}

  //   >
  //     {value === index && (
  //       <Box  sx={{ p: 1, flex: 1, width: "100%"}} >
  //         {children}
  //       </Box>
  //     )}
  //   </div>
  // );

  if (value === index) {
    return <Box sx={{ p: 1, flex: 1, width: "100%" }}>{children}</Box>;
  }
};

export default TabPanel;
