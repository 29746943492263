import { LaunchOutlined } from "@mui/icons-material";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const NiceLink = ({ label, link, tip, showFollowIcon,makeBlue }) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      gap=".4rem"
    >
      <Link
        to={link}
        component={RouterLink}
        underline="hover"
        color={makeBlue ? "blue" : "inherit" }
        fontSize="inherit"
      >
        <Tooltip title={tip}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            gap=".2rem"
          >
            <Typography fontSize="inherit">{label}</Typography>

            {showFollowIcon && (
              <LaunchOutlined
                fontSize="inherit"
                aria-label="follow"
                onClick={() => {
                  navigate(link);
                }}
              ></LaunchOutlined>
            )}
          </Box>
        </Tooltip>
      </Link>
    </Box>
  );
};

export default NiceLink;
