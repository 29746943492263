import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "theme";

const Header = ({ title, subtitle, count , titleUpdater, subtitleUpdater}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  document.title = title

  return (
    <Box mb=".5rem" display="flex" flexDirection="column">
      <Box display="flex" gap=".2rem" alignItems="baseline">
        <Typography
          variant="h3"
          color={theme.palette.grey[900]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>

        {count && (
          <Typography
            variant="h5"
            color={theme.palette.grey[900]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            ({count})
          </Typography>
        )}
      </Box>
      <Typography variant="h5" color={theme.palette.grey[800]}>
        {subtitle}
      </Typography>

      
    </Box>
  );
};

export default Header;
