import api from "./api";



const updateOrganization = (org) => {
  return api.patch("/organization",
  {
    ...org
  })
  .then((response) => {
    return response.data;
  });
};




const getOrganizations = (search) => {

  return api.get("/organization",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getOrganization = (id) => {
  return api.get("/organization/"+id)
  .then((response) => {
    return response.data;
  });
};



const OrganizationSevice = {
  getOrganizations,
  updateOrganization,
  getOrganization,
};

export default OrganizationSevice;