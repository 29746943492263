import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  TextField,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { getServiceTypes } from "slices/services";
import { now } from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment-timezone";
import { createSchedule, getSchedules } from "slices/schedule";

const ScheduleAdd = ({ closerFunc, schedule, page }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  //const schedules = useSelector((state) => state.schedule.schedule.data.list);
  //const pages = useSelector((state) => state.schedule.page.data.list);

  const [selectedServiceType, setSelectedServiceType] = useState("");

  const [selectedImplemenatorId, setSelectedImplemenatorId] = useState("");

  // const serviceTypes = useSelector((state) => {
  //   const types = state.services?.serviceTypes || [];
  //   return types.slice().sort((a, b) => a.name.localeCompare(b.name));
  // });

  const serviceTypes = useSelector((state) => {
    const types = state.services?.serviceTypes || [];

    // Filter the service types based on the conditions
    const filteredServiceTypes = types.filter(type => !type.isKernel && type.isRunable);
    const kernelServices = types.filter(type => type.isKernel && type.isRunable);
    
    
    //debugger
    // Sort the arrays
    const sortedFilteredServiceTypes = filteredServiceTypes.slice().sort((a, b) => a.name.localeCompare(b.name));
    const sortedKernelServices = kernelServices.slice().sort((a, b) => a.name.localeCompare(b.name));
    
    // Add the additional item to the sortedFilteredServiceTypes
    const newItem = {
    serviceType: "kernel",
    collection: "", 
    icon: "", 
    description: "Kernel Functions", 
    name: "Kernel",
    isKernel: false,
    isRunable: true
  };

  const updatedFilteredServiceTypes = sortedFilteredServiceTypes.concat(newItem).sort((a, b) => a.name.localeCompare(b.name));


    return { updatedFilteredServiceTypes, sortedKernelServices };
  });
  
  const implemenators = useSelector((state) => {
    const types = state.services?.serviceTypes || [];
    return types.slice().sort((a, b) => a.name.localeCompare(b.name));
  });


  const avilableImplemenators = selectedServiceType === "kernel" ? serviceTypes.sortedKernelServices : implemenators;



  useEffect(() => {
    //on mount get some defaults
    dispatch(getServiceTypes());
  }, []);

  //get the services of the selected type that a schedule can be created for

  // useEffect(() => {
  //   let search = {
  //     type: "kernel",
  //     implementor: "schedulePage",
  //     field: "id",
  //     lookUpLabel: "<name>",
  //     sort: "sequence",
  //     filter: "[scheduleId]=" + selectedSchedule,
  //   };

  //   //debugger
  //   dispatch(getServiceDimensionValues({ search }));
  // }, [selectedSchedule, dispatch]);

  const initialValues = {};

  initialValues.type = "";
  initialValues.name = "";
  initialValues.validFrom = moment.unix(moment().startOf("day") / 1000);
  initialValues.validTo = moment.unix(
    moment("2099-12-31T23:59:59.999Z") / 1000
  );
  initialValues.cron = "0 0 * * *";
  initialValues.implementorId = "";
  initialValues.active = true;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });

  return (
    <Box m="20px">
      <Header title={"New Schedule"} />

      <Formik
        onSubmit={(values, actions) => {
          const schedule = { ...values };

          const fromUtcTime = schedule.validFrom.utc();
          schedule.validFrom =fromUtcTime.format("YYYY-MM-DDTHH:mm:ss[Z]")

          const toUtcTime = schedule.validTo.utc();
          schedule.validTo =toUtcTime.format("YYYY-MM-DDTHH:mm:ss[Z]")

          //debugger
          dispatch(clearMessage());

          console.log('Dispatching createSchedule with schedule:', schedule); 

          dispatch(createSchedule({ schedule }))
            .unwrap()
            .then(() => {
              //debugger;

              dispatch(setMessage("Schedule Added", "success"));
              closerFunc();
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 1" }}
              />

              {serviceTypes && serviceTypes.updatedFilteredServiceTypes.length > 0 && (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  type="string"
                  label="Type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedServiceType(e.target.value);
                  }}
                  value={values.type}
                  name="type"
                  error={!!touched.type && !!errors.type}
                  helperText={touched.type && errors.type}
                  sx={{ gridColumn: "span 1" }}
                >
                  {serviceTypes.updatedFilteredServiceTypes?.map((option) => (
                    <MenuItem
                      key={option.serviceType}
                      value={option.serviceType}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {implemenators && implemenators.length > 0 && (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  type="string"
                  label="Run"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedImplemenatorId(e.target.value);
                  }}
                  value={values.implementorId}
                  name="implementorId"
                  error={!!touched.implementorId && !!errors.implementorId}
                  helperText={touched.implementorId && errors.implementorId}
                  sx={{ gridColumn: "span 1" }}
                >
                  {avilableImplemenators?.map((option) => (
                    <MenuItem
                      key={option.serviceType}
                      value={option.serviceType}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <DateTimePicker
                label="Valid From"
                value={values.validFrom}
                onChange={(newValue) => setFieldValue("validFrom", newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={handleBlur}
                    error={!!touched.validFrom && !!errors.validFrom}
                    helperText={touched.validFrom && errors.validFrom}
                    sx={{ gridColumn: "span 1" }}
                  />
                )}
                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "standard",
                  },
                }}
                sx={{ flex: 1 }}
              />

              <DateTimePicker
                label="Valid To"
                value={values.validTo}
                onChange={(newValue) => setFieldValue("validTo", newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={handleBlur}
                    error={!!touched.validTo && !!errors.validTo}
                    helperText={touched.validTo && errors.validTo}
                    sx={{ gridColumn: "span 1" }}
                  />
                )}
                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "standard",
                  },
                }}
                sx={{ flex: 1 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Schedule"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cron}
                name="cron"
                error={!!touched.cron && !!errors.cron}
                helperText={touched.cron && errors.cron}
                sx={{ gridColumn: "span 1" }}
              />

<FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.active}
                    name="active"
                  />
                }
                label="Schedule is Active"
              /> 

            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Add Schedule
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ScheduleAdd;
