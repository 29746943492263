import api from "./api";



const updateService = (data) => {
  return api.patch("/service",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};




const getServices = (search) => {

  return api.get("/service",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getService = (id) => {
  return api.get("/service/"+id)
  .then((response) => {
    return response.data;
  });
};


const getServiceType = (serviceType) => {
  return api.get("/service_type/"+serviceType)
  .then((response) => {
    return response.data;
  });
};
const getServiceTypes = () => {
  return api.get("/service_type")
  .then((response) => {
    return response.data;
  });
};

const getServiceRelated = (service) => {
  return api.get("/service/" + service.implementor_type + "/" + service.implementor_id)
  .then((response) => {
    return response.data;
  });
};

const getServiceFilterValues = (search) => {

  return api.get("/service/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getServiceUi = () => {
  return api.get("/service/ui")
  .then((response) => {
    return response.data;
  });
};

const ServiceService = {
  getServices,
  updateService,
  getService,
  getServiceUi,
  getServiceFilterValues,
  getServiceRelated,
  getServiceType,
  getServiceTypes,

};

export default ServiceService;