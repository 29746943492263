import { CheckOutlined } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";

const NiceMultiLine = (props) => {

  let multiLineString = props.children;

  return (
    <Box
    >
      {multiLineString.split("\n").map((line, index) => (
        <Box key={index}>{line}</Box>
      ))}
    </Box>

  );
};

export default NiceMultiLine;
