import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuditLogService from "../services/audit_log.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};



export const getAuditLogLastService = createAsyncThunk(
  "AuditLog_lastservice/get",

  async ({ userName }, thunkAPI) => {
    try {
      const data = await AuditLogService.getAuditLogLastService(userName);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAuditLogLastResource = createAsyncThunk(
  "AuditLog_lastresource/get",

  async ({ userName }, thunkAPI) => {
    try {
      const data = await AuditLogService.getAuditLogLastResource(userName);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const listAuditLog = createAsyncThunk(
  "AuditLog/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AuditLogService.listAuditLog(search);
      return { AuditLog: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAuditLog = createAsyncThunk(
  "AuditLog/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await AuditLogService.getAuditLog(id);
      return { AuditLog: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getAuditLogFilterValues = createAsyncThunk(
  "AuditLog/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AuditLogService.getAuditLogFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getAuditLogUi = createAsyncThunk(
  "AuditLog/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await AuditLogService.getAuditLogUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  lastService: list,
  lastResource: list,
  loading: false,
  filter_values: [],
  Ui: [],
}

const AuditLogSlice = createSlice({
  name: "AuditLog",
  initialState,

  extraReducers: {

    [getAuditLogUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getAuditLogUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getAuditLogUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getAuditLogFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getAuditLogFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getAuditLogFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },



    [getAuditLogLastService.pending]: (state, action) => {
      state.loading = true;
    },
    [getAuditLogLastService.fulfilled]: (state, action) => {

      //debugger

      state.lastService = action.payload.data;
      state.loading = false;
    },
    [getAuditLogLastService.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getAuditLogLastResource.pending]: (state, action) => {
      state.loading = true;
    },
    [getAuditLogLastResource.fulfilled]: (state, action) => {

      //debugger

      state.lastResource = action.payload.data;
      state.loading = false;
    },
    [getAuditLogLastResource.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [listAuditLog.pending]: (state, action) => {
      state.loading = true;
    },
    [listAuditLog.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.AuditLog;
      state.loading = false;
    },
    [listAuditLog.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [getAuditLog.pending]: (state, action) => {
      state.loading = true;
    },
    [getAuditLog.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.AuditLog;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getAuditLog.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});

const { reducer } = AuditLogSlice;
export default reducer;
