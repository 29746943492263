import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import KpiService from "../services/kpi.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false};

export const updateKpi = createAsyncThunk(
  "Kpi/update",
  async ({ org }, thunkAPI) => {
    try {
      const data = await KpiService.updateKpi(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateKpiActive = createAsyncThunk(
  "Kpi/update_active",
  async ({ kpi }, thunkAPI) => {
    try {
      const data = await KpiService.updateKpiActive(kpi);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpis = createAsyncThunk(
  "Kpi/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpis(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiTargets = createAsyncThunk(
  "Kpi/get_list_targets",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiTargets(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiCurrentTargets = createAsyncThunk(
  "Kpi/get_list_current_targets",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiCurrentTargets(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpi = createAsyncThunk(
  "Kpi/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await KpiService.getKpi(id);
      return { kpi: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiTarget = createAsyncThunk(
  "Kpi/getTarget",
  async ({ id }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiTarget(id);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiUi = createAsyncThunk(
  "Kpi/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiTargetUi = createAsyncThunk(
  "Kpi/get_ui_target",
  async ({ id }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiTargetUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getKpiData = createAsyncThunk(
  "Kpi/get_data_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiData(search);
      return { kpi_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiFilterValues = createAsyncThunk(
  "Kpi/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getKpiAggregateValues = createAsyncThunk(
  "Kpi/get_aggregate_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiAggregateValues(search);
      return { aggregate_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const runKpi = createAsyncThunk(
  "Kpi/run",
  async ({ id }, thunkAPI) => {
    try {
      const data = await KpiService.runKpi(id);
      thunkAPI.dispatch(
        setMessage({
          message: `Kpi started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process kpi ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const addKpiTarget = createAsyncThunk(
  "kpi/addTarget",
  async ({ row }, thunkAPI) => {
    try {
      const data = await KpiService.addKpiTarget(row);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearKpiUi = createAction("Kpi/clearKpiUi");

const initialState = {
  data: list,
  targets: list,
  loading: false,
  kpi_data: list,
  kpiUi: [],
  kpiRunJob: null,
  filter_values: [],
  aggregate_values: [],
  targetUi: [],
  current_targets: [],
};

const KpiSlice = createSlice({
  name: "kpis",
  initialState,

  extraReducers: {
    [clearKpiUi]: (state, action) => {
      state.kpiUi = null;
      state.targetUi = null;
      state.loading = false;
    },


    [getKpiUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiUi.fulfilled]: (state, action) => {
      state.kpiUi = action.payload.ui;
      state.loading = false;
    },
    [getKpiUi.rejected]: (state, action) => {
      state.kpiUi = null;
      state.loading = false;
    },

    [getKpiTargetUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiTargetUi.fulfilled]: (state, action) => {
      state.targetUi = action.payload.ui;
      state.loading = false;
    },
    [getKpiTargetUi.rejected]: (state, action) => {
      state.kpiUi = null;
      state.loading = false;
    },


    [runKpi.pending]: (state, action) => {
      state.loading = true;
    },
    [runKpi.fulfilled]: (state, action) => {
      state.kpiRunJob = action.payload.job;
      state.loading = false;

      //update the rows that this job was created for 
      let kpis = state.data.list;
      let thisKpi = kpis.find(
        (kpi) => kpi.id === action.payload.job.implementorId
      );

      thisKpi.jobStatus = action.payload.job.status;
      thisKpi.jobId = action.payload.job.id;

    },
    [runKpi.rejected]: (state, action) => {
      state.kpiRunJob = null;
      state.loading = false;
    },

    [getKpiFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getKpiFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },


    [getKpiAggregateValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiAggregateValues.fulfilled]: (state, action) => {
      //debugger;

      state.aggregate_values = action.payload.aggregate_values;
      state.loading = false;
    },
    [getKpiAggregateValues.rejected]: (state, action) => {
      state.aggregate_values = list;
      state.loading = false;
    },

    [getKpiData.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiData.fulfilled]: (state, action) => {
      //debugger;

      state.kpi_data = action.payload.kpi_data;
      state.loading = false;
    },
    [getKpiData.rejected]: (state, action) => {
      state.kpi_data = list;
      state.loading = false;
    },


    [getKpis.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpis.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    },
    [getKpis.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateKpiActive.fulfilled]: (state, action) => {
      let kpis = state.data.list;
      let thisKpi = kpis.find(
        (kpi) => kpi.id === action.payload.data.id
      );
      thisKpi.active = action.payload.data.active;
    },

    [updateKpi.fulfilled]: (state, action) => {
      debugger;

      let organizations = state.data.list;
      let thisOrg = organizations.find(
        (org) => org.id === action.payload.data.id
      );

      thisOrg.name = action.payload.data.name;
      thisOrg.domain = action.payload.data.domain;
      thisOrg.active = action.payload.data.active;
    },

    [getKpi.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpi.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.kpi;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getKpi.rejected]: (state, action) => {
      state.loading = false;
    },

    //Targets Bits 

    [addKpiTarget.pending]: (state, action) => {
      state.loading = true;
    },
    [addKpiTarget.fulfilled]: (state, action) => {

      const newPayload = action.payload.data;

      const existingIndex = state.targets.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.targets.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.targets.list.push(newPayload);
      }
      state.loading = false;

    },
    [addKpiTarget.rejected]: (state, action) => {
      state.loading = false;
    },


    [getKpiTarget.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiTarget.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.data;

      const existingIndex = state.targets.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.targets.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.targets.list.push(newPayload);
      }
      state.loading = false;
    },
    [getKpiTarget.rejected]: (state, action) => {
      state.loading = false;
    },

    [getKpiTargets.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiTargets.fulfilled]: (state, action) => {
      state.targets = action.payload.data;
      state.loading = false;
    },
    [getKpiTargets.rejected]: (state, action) => {
      state.targets = list;
      state.loading = false;
    },

    [getKpiCurrentTargets.pending]: (state, action) => {
      state.loading = true;
    },
    [getKpiCurrentTargets.fulfilled]: (state, action) => {
      state.current_targets = action.payload.data;
      state.loading = false;
    },
    [getKpiCurrentTargets.rejected]: (state, action) => {
      state.current_targets = list;
      state.loading = false;
    },



  },
});

const { reducer } = KpiSlice;
export default reducer;
