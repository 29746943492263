import { createSlice } from "@reduxjs/toolkit";

const message={
  message : "",
  type: null,
  longText : "",
  helpLink: "",
}

const initialState = {message};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
    setPopupMessage: (state, action) => {
      return { message: action.payload };
    },
    clearPopupMessage: () => {
      return { message: "" };
    },
  },
});



const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage , setPopupMessage, clearPopupMessage} = actions
export default reducer;