
import api from "./api";


const createCatalogueImage = (newImage) => {
  //debugger
  return api.post("/catalogue/image", {
    ...newImage,
  });
};



const updateCatalogue = (catalogue) => {
  return api
    .patch("/catalogue", {
      ...catalogue,
    })
    .then((response) => {
      return response.data;
    });
};

const updateCataloguePageSequenceParent = (page) => {
  return api
    .patch("/catalogue/page_sequence_parent", {
      ...page,
    })
    .then((response) => {
      return response.data;
    });
};

const updateCataloguePage = (page) => {
  return api
    .patch("/catalogue/page", {
      ...page,
    })
    .then((response) => {
      return response.data;
    });
};

const getCatalogues = (search) => {
  return api
    .get("/catalogue", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getCataloguePages = (search) => {
  return api
    .get("/catalogue/page", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};



const getCatalogue = (id) => {
  return api.get("/catalogue/" + id).then((response) => {
    return response.data;
  });
};

const getCataloguePage = (id) => {
  return api.get("/catalogue/page/" + id).then((response) => {
    return response.data;
  });
};

const createCatalogue = (step) => {
  return api.post("/catalogue", {
    ...step,
  });
};

const createCataloguePage = (step) => {
  return api.post("/catalogue/page", {
    ...step,
  });
};

const getCatalogueFilterValues = (search) => {

  return api.get("/catalogue/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getCatalogueUi = () => {
  return api.get("/catalogue/ui")
  .then((response) => {
    return response.data;
  });
};

const getCataloguePageFilterValues = (search) => {

  return api.get("/catalogue/page/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getCataloguePageUi = () => {
  return api.get("/catalogue/page/ui")
  .then((response) => {
    return response.data;
  });
};

const getCataloguePageType = () => {
  return api.get("/catalogue_page_type")
  .then((response) => {
    return response.data;
  });
};

const deleteCataloguePage = (id) => {
  return api.delete("/catalogue/page/" + id).then((response) => {
    return response.data;
  });
};


const CatalogueService = {
  updateCatalogue,
  updateCataloguePage,
  getCatalogues,
  getCataloguePages,
  getCatalogue,
  getCataloguePage,
  createCatalogue,
  createCataloguePage,
  getCatalogueFilterValues,
  getCataloguePageFilterValues,
  getCatalogueUi,
  getCataloguePageUi,

  createCatalogueImage,
  deleteCataloguePage,
  updateCataloguePageSequenceParent,
  getCataloguePageType,

};

export default CatalogueService;
