import api from "./api";




const getConfig = () => {
  return api.get("/config").then((response) => {
    return response.data;
  });
};



const updateConfig = (config) => {
  return api
    .patch("/config", {
      ...config,
    })
    .then((response) => {
      return response.data;
    });
};

const getConfigUi = () => {
  return api.get("/config/ui")
  .then((response) => {
    return response.data;
  });
};


const ConfigService = {
  getConfig,
  getConfigUi,
  updateConfig,

};

export default ConfigService;
