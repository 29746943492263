import {
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  Tab,
  Tabs,
  IconButton,
  useMediaQuery,
  Stack,
  Collapse,
  Dialog,
  DialogContent,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo, useRef } from "react";

import * as Yup from "yup";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getStateSet,
  updateStateSet,
  updateStateSetActive,
  getStateSetValues,
  updateState,
  getStateUi,
  getStateSetData,
  clearStateUi,
  getStateDataId,
  updateStateSetRowValueData,
  deleteStateSetRow,
} from "slices/state";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import KeyVal from "components/global/KeyVal";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  ListOutlined,
  OpenInNewOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceActive from "components/global/NiceActive";
import TabPanel from "components/global/TabPanel";

import { Formik, Form, Field, useFormik } from "formik";
import NiceLink from "components/global/NiceLink";
import TableActionsButton from "components/global/TableActionsButton";
import StateSetDataDisplayEdit from "components/state/StateSetDataDisplayEdit";
import NiceUser from "components/global/NiceUser";
import NiceObjectCell from "components/global/NiceObjectCell";
import NiceSubscription from "components/global/NiceSubscription";
import CollapseTreeBlock from "components/state/RowSchemaDisplay";
import StateSetFieldCreateEdit from "components/state/StateSetFieldCreateEdit";
import RowSchemaDisplay from "components/state/RowSchemaDisplay";
import { renderColumn } from "common/renderColumn";
import StateSetCreateEdit from "components/state/StateSetCreateEdit";
import StateSetAsConfigProvider from "components/state/StateSetAsConfigProvider";
import StateSetAddRow from "components/state/StateSetCreateRow";
import TabsVertical from "components/global/TabsVerticle";
import TabVertical from "components/global/TabVertical";
import { processString } from "common/helpers";
import { listAuditLog } from "slices/auditLog";
import ObjectDetails from "components/global/ObjectDetails";
import UiGroupDisplay from "components/state/UIGroupDisplay";
import WhereUsedService from "components/where_used/WhereUsedService";

const StateSet = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const StateSet = useSelector((state) =>
    state.stateSet.data.list?.find((set) => set.id === id)
  );

  const auditLogRows = useSelector((state) => state.auditLog.data);

  const state_values = useSelector((state) => state.stateSet.values);

  const state_rows = useSelector((state) => state.stateSet.rows);
  const state_UI = useSelector((state) => state.stateSet.stateUi);

  const [tabValue, setTabValue] = React.useState(2);

  const [searchParams, setSearchParams] = useSearchParams();

  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (!StateSet) {
      //need to get the StateSet form the API
      dispatch(getStateSet({ id }));
    }

    dispatch(getStateUi({ id }));

    if (StateSet) {
      if (!StateSet?.tableMode) {
        const search = {
          stateSetId: id,
        };

        //   dispatch(getStateSetData({ search }));
        // } else {
        dispatch(getStateDataId({ id }));
      }
    }
  }, [StateSet, id]);

  useEffect(() => {
    if (isFirstLoad) {
      //set the fact that we have an update on the UI block to use
      setIsFirstLoad(false);
      dispatch(clearStateUi());

      if (searchParams.get("tab")) {
        setTabValue(parseInt(searchParams.get("tab"), 10));
      } else {
        setTabValueAndUpdateQuery(tabValue);
      }
    }
  }, [id]);

  useEffect(() => {
    if (StateSet) {
      if (StateSet?.tableMode) {
        dispatch(getStateSet({ id }));
      } else {
        dispatch(getStateDataId({ id }));
      }
    }
  }, [refreshPoint]);

  const handleActivate = () => {
    dispatch(clearMessage());
    const stateSet = { id: id, active: true };
    dispatch(updateStateSetActive({ stateSet }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const stateSet = { id: id, active: false };
    dispatch(updateStateSetActive({ stateSet }));
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [selectionModel, setSelectionModel] = React.useState([]);
  const navigate = useNavigate();

  const handleOpenOther = () => {
    navigate("/state/" + StateSet.id + "/" + selectionModel[0]);
  };

  const handleEditStateSet = () => {
    dispatch(clearMessage());
    setAnchorEl(configRef.current);
  };

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const configRef = React.useRef(null);

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //Add row

  const handleAddRow = () => {
    dispatch(clearMessage());
    setAnchorElAddRow(addRowRef.current);
  };

  const [anchorElAddRow, setAnchorElAddRow] = useState(null);
  const [areaHeightAdd, setAreaHeightAdd] = useState("18vh");
  const [areaWidthAdd, setAreaWidthAdd] = useState("30vw");

  const addRowRef = React.useRef(null);

  const handelCloseAddRow = () => {
    setAnchorElAddRow(null);
  };

  const openAddRow = Boolean(anchorElAddRow);

  let columns = [];

  const logColumns = [
    {
      field: "when",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "userName",
      headerName: "User",
      flex: 1,
      renderCell: ({ row: { userName } }) => {
        return <NiceUser>{userName}</NiceUser>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "fieldUpdate",
      headerName: "Change",
      flex: 1,
    },
    {
      field: "ipAddress",
      headerName: "From",
      flex: 1,
    },
  ];

  const logColumnsVisability = {};

  let canAddRow = false;
  let canEdit = false;

  if (state_UI?.columns) {
    columns = state_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //You can only add a row if the ID field of the set is user based, else how would it know what the ID is
      if (updatedColumn.field === "id" && updatedColumn.valueMode === "User") {
        canAddRow = true;
      }

      //check if atleast one colum is editable if so allowing editing fo the row

      if (updatedColumn.valueMode === "User") {
        canEdit = true;
      }

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  // const handleDeleteRow = () =>{

  //   dispatch(clearMessage())

  //   selectionModel.forEach((item) => {

  //     const row = {
  //       stateSetId: StateSet.id,
  //       rowId: item
  //     }
  //     dispatch(deleteStateSetRow(row));
  //   });

  //   if (!state_rows.loading)

  //   handelRefresh()

  // }

  const handleDeleteRow = async () => {
    dispatch(clearMessage());

    // Create an array of promises for each delete action
    const deletePromises = selectionModel.map((item) => {
      const row = {
        stateSetId: StateSet.id,
        rowId: item,
      };
      return dispatch(deleteStateSetRow(row));
    });

    // Wait for all delete actions to complete
    try {
      await Promise.all(deletePromises);
      // All delete actions are completed, proceed with refresh
      handelRefresh();
    } catch (error) {
      // Handle any errors if necessary
      console.error("Error deleting rows:", error);
    }
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpenOther,
      singleItemOnly: true,
    },
    {
      text: "Edit Row",
      icon: <EditOutlined />,
      callBack: handleOpenOther,
      singleItemOnly: true,
      disabled: !canEdit,
    },
    {
      text: "Multi Edit",
      icon: <ListOutlined />,
      callBack: null,
      singleItemOnly: false,
      disabled: !canEdit,
      isMassEdit: true,
    },
    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      disabled: !canAddRow,
      callBack: handleDeleteRow,
    },
  ];


  const handelUpdateUIGroups = (group, mode) => {
    if (mode === "NEW") {
      // Add the new group to the array and update the state
      const newGroups = [...StateSet.uiGroups, group];
      const stateSet = {
        id: StateSet.id,
        uiGroups: newGroups
      };
      dispatch(updateStateSet({ stateSet }));
    } else if (mode === "EDIT") {

      //debugger

      // Find the index of the group to be updated
      const index = StateSet.uiGroups.findIndex((item) => item.name === group.name);
  
      if (index !== -1) {
        // Create a copy of the original groups array
        const updatedGroups = [...StateSet.uiGroups];
        // Update the group at the found index
        updatedGroups[index] = group;
  
        // Update the state with the updated groups
        const stateSet = {
          id: StateSet.id,
          uiGroups: updatedGroups
        };
        dispatch(updateStateSet({ stateSet }));
      } else {
        console.error("Group not found for update");
      }
    } else {
      console.error("Invalid mode:", mode);
    }
  };
  

  // const handelRowUpdate = (rowId,field, newVal) => {
  //   debugger;

  //   const stateValue = {
  //     stateSetId: StateSet.id,
  //     data: {
  //       id: rowId,
  //       [field]: newVal,
  //     },
  //   };

  //     dispatch(updateStateSetRowValueData({ stateValue }));

  // };

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs lastOverride={StateSet?.name}/>

        <FlexBetween>
          <Header title={StateSet?.name} subtitle={StateSet?.id}/>
          <FlexBetween gap=".5rem">
            <Button
              variant="outlined"
              color="primary"
              onClick={handelRefresh}
            >
              <RefreshOutlined />
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleEditStateSet}
            >
              <EditOutlined />
            </Button>

            {!StateSet?.active &&
              StateSet?.type !== "System" &&
              StateSet?.type !== "OpsBlox" && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CheckOutlined />}
                  onClick={handleActivate}
                >
                  Activate
                </Button>
              )}
            {StateSet?.active &&
              StateSet?.type !== "System" &&
              StateSet?.type !== "OpsBlox" && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseOutlined />}
                  onClick={handleDectivate}
                >
                  Deactivate
                </Button>
              )}

            

          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        <Box mt="20px" sx={{ width: "100%" }}>
          <Box
            gap="1rem"
            sx={{
              //borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              width: "100%",
              display: "flex",
              flexGrow: 1,
            }}
          >
            <TabsVertical
              value={tabValue}
              onChange={handleTabChange}
              aria-label="State Tabs"
            >
              <TabVertical
                label="Details"
                {...a11yProps(0)}
                // disabled={StateSet?.type !== "User"}
              />
              <TabVertical
                label="State Config"
                {...a11yProps(1)}
                // disabled={StateSet?.type !== "User"}
              />

              <TabVertical
                label={
                  StateSet?.tableMode
                    ? `Current State (${state_rows?.total})`
                    : "Current State"
                }
                {...a11yProps(2)}
              />
              <TabVertical label="Related To" {...a11yProps(3)} />
              <TabVertical label="Change Log" {...a11yProps(4)} />
            </TabsVertical>

            <TabPanel value={tabValue} index={0}>
              {id !== undefined ? (
                <ObjectDetails
                  serviceType={"stateSet"}
                  id={id}
                  rowId={null}
                  refreshPoint={refreshPoint}
                  titleSetter={null}
                  hideId={false}
                />
              ) : (
                <Typography>Nothing to load...</Typography>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Stack gap={".7rem"} direction={"row"}>
                {StateSet && (
                  <RowSchemaDisplay
                    data={StateSet.rowSchema}
                    stateSetId={StateSet.id}
                    configRef={configRef}
                  />
                )}
                {StateSet && (
                  <UiGroupDisplay
                    groups={StateSet.uiGroups}
                    updater={handelUpdateUIGroups}
                    configRef={configRef}
                  />
                )}
              </Stack>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              {tabValue === 2 && StateSet?.tableMode && (
                <Stack
                  gap="1rem"
                  direction={"row"}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <TableActionsButton
                    selectedRows={selectionModel}
                    menuItems={actionMenuItems}
                    columns={columns}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!canAddRow}
                    ref={addRowRef}
                    onClick={handleAddRow}
                  >
                    Add Row
                  </Button>
                </Stack>
              )}

              {StateSet && state_UI && !isFirstLoad ? (
                StateSet?.tableMode === true ? (
                  <TablePage
                    //masterId={}
                    dataRows={state_rows}
                    dataColumns={columns}
                    dataGetter={getStateSetData}
                    defaultColumnsVisability={state_UI.columnsVisability}
                    defaultSort={state_UI.sort}
                    refreshPoint={refreshPoint}
                    selectionModel={selectionModel}
                    selectionSetter={setSelectionModel}
                    serviceType={"stateSet"}
                    stateSetId={id}
                    initialFilter={""}
                  />
                ) : (
                  <Box mt="1rem">
                    {state_values && (
                      <StateSetDataDisplayEdit
                        schema={state_UI}
                        set_values={state_values}
                        stateSet={StateSet}
                      />
                    )}
                  </Box>
                )
              ) : (
                <Box mt="1rem">Loading</Box>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
            <WhereUsedService
                  serviceType={"stateSet"}
                  id={id}
                  refreshPoint={refreshPoint}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <TablePage
                masterId={id}
                masterIdField="objectId"
                dataRows={auditLogRows}
                dataColumns={logColumns}
                dataGetter={listAuditLog}
                defaultColumnsVisability={logColumnsVisability}
                defaultSort={"-when"}
                refreshPoint={refreshPoint}
                serviceType={"auditLog"}
                initialFilter={"[operation]=Create,Update"}
              />
            </TabPanel>
          </Box>
        </Box>
      </PageBlock>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <StateSetCreateEdit
            closerFunc={handelCloseAddEdit}
            stateSet={StateSet}
            mode={"EDIT"}
          />
        </Box>
      </Popover>

      <Popover
        open={openAddRow}
        anchorEl={anchorElAddRow}
        onClose={handelCloseAddRow}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeightAdd} width={areaWidthAdd}>
          <StateSetAddRow
            closerFunc={handelCloseAddRow}
            stateSet={StateSet}
            refreshTableFunc={handelRefresh}
          />
        </Box>
      </Popover>
    </>
  );
};

export default StateSet;
