import React, { useContext, useState, useEffect, useCallback } from "react";
import { ColorModeContext, tokens } from "../../theme";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  NotificationsOutlined,
  PersonOutlined,
  ManageAccountsOutlined,
  LoginOutlined,
  LogoutOutlined,
  PersonAddOutlined,
  PsychologyOutlined,
  ListOutlined,
  HelpOutlineOutlined,
  LocalHospitalOutlined,
  InfoOutlined,
  ForwardToInboxOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "slices/auth";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ProfileAvatar from "components/user/ProfileAvatar";

import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Link,
  Divider,
  ListItemIcon,
  Popover,
  Tooltip,
} from "@mui/material";
import MegaMenu from "./MegaMenu";
import { clearMessage } from "slices/message";
import NavBarPreferences from "./NavBarPreferences";
import { updatePreferenceByUser } from "slices/auth";
import { createSearchPrompt } from "slices/search";
import NavBarSearch from "./NavBarSearch";

const Navbar = ({
  copilotSize,
  setCopilotSize,
  isCopilotOpen,
  setIsCopilotOpen,
  isNavMenuOpen,
  setIsNavMenuOpen,
  menuArea,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleOpenPreferences = () => {
    dispatch(clearMessage());
    setAnchorElPreferences(configRef.current);
  };

  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const isOpenSearch = Boolean(anchorElSearch);
  const handleOpenSearch = () => {
    dispatch(clearMessage());
    setAnchorElSearch(searchRef.current);
  };
  const handleCloseSearch = () => {
    setAnchorElSearch(null);
  };

  const [searchPromptText, setSearchPromptText] = useState("");

  const [anchorElPreferences, setAnchorElPreferences] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const configRef = React.useRef(null);
  const chatRef = React.useRef(null);
  const searchRef = React.useRef(null);

  const [areaHeightSearch, setAreaHeightSearch] = useState("60vh");
  const [areaHeightSearchList, setAreaHeightSearchList] = useState("50vh");
  const [areaWidthSearch, setAreaWidthSearch] = useState("70vw");

  const handelClosePreferences = () => {
    setAnchorElPreferences(null);
  };

  const handleToggleDarkMode = () => {
    //first toggle the mode
    colorMode.toggleColorMode();

    //now update the user preference

    dispatch(clearMessage());

    const preference = {
      preference_value: theme.palette.mode === "light" ? "dark" : "light",
      name: "useDarkMode",
      username: currentUser.user.username,
    };
    dispatch(updatePreferenceByUser({ preference }));
  };

  const openPreferences = Boolean(anchorElPreferences);

  const handelToggleCopilot = () => {
    setIsCopilotOpen(isCopilotOpen ? false : true);
  };

  var imgSrc = `../../assets/img/Logo_long.png`;

  const handleSearchChange = (event) => {
    setSearchPromptText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchPromptText !== "") {
      const searchPrompt = {
        prompt: searchPromptText,
      };
      dispatch(createSearchPrompt({ searchPrompt }));
      setSearchPromptText("");
      handleOpenSearch();
    }
  };

  return (
    <AppBar
      sx={{
        position: "fixed",
        backgroundColor: theme.palette.background.default,
        borderBottomColor: theme.palette.primary.main,
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
        boxShadow: "none",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween gap="1rem">
          <Link component={RouterLink} to="/" display="flex">
            <img alt="Logo" height="35px" src={imgSrc} />
          </Link>

          <MegaMenu />
        </FlexBetween>

        <FlexBetween
          backgroundColor={theme.palette.background.default}
          border="1px solid"
          borderColor={theme.palette.primary.main}
          borderRadius="9px"
          gap=".2rem"
          p="0.1rem 1.5rem"
          width={"30%"}
          ref={searchRef}
        >
          <InputBase
            placeholder="Search..."
            fullWidth
            value={searchPromptText}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton onClick={handleSearch}>
            <Search />
          </IconButton>

          <IconButton onClick={handleOpenSearch}>
            <Tooltip title="Previous Search">
              <ListOutlined />
            </Tooltip>
          </IconButton>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap=".5rem">
          <Button
            onClick={handelToggleCopilot}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textTransform: "none",
              gap: ".3rem",
            }}
            disabled={!isLoggedIn}
          >
            <PsychologyOutlined sx={{ fontSize: "25px" }} />
            <Typography
              fontWeight="bold"
              fontSize="0.85rem"
              sx={{
                color: theme.palette.primary.main,
                lineHeight: "25px",
                gap: "1rem",
              }}
            >
              Copilot
            </Typography>
          </Button>

          <Divider orientation="vertical" flexItem />

          <IconButton onClick={handleToggleDarkMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined
                sx={{ fontSize: "25px", color: theme.palette.primary.main }}
              />
            ) : (
              <LightModeOutlined
                sx={{ fontSize: "25px", color: theme.palette.primary.main }}
              />
            )}
          </IconButton>

          <IconButton disabled={!isLoggedIn}>
            <NotificationsOutlined
              sx={{ fontSize: "25px", color: theme.palette.primary.main }}
            />
          </IconButton>

          <IconButton
            ref={configRef}
            onClick={handleOpenPreferences}
            disabled={!isLoggedIn}
          >
            <SettingsOutlined
              sx={{ fontSize: "25px", color: theme.palette.primary.main }}
            />
          </IconButton>

          <IconButton
            component={RouterLink}
            target={"_blank"}
            to={"catalogue/663a19db1242002d9120d634"}
            sx={{ fontSize: "25px", color: theme.palette.primary.main }}
          >
            <HelpOutlineOutlined />
          </IconButton>

          <FlexBetween>
            <Button
              ref={chatRef}
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              {isLoggedIn ? (
                <>
                  <ProfileAvatar user={currentUser.user} size="25" />

                  <Box textAlign="left">
                    <Typography
                      fontWeight="bold"
                      fontSize="0.85rem"
                      sx={{
                        color: theme.palette.primary.main,
                        lineHeight: "25px",
                        gap: "1rem",
                      }}
                    >
                      {currentUser.user.username}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <PersonOutlined sx={{ color: theme.palette.primary.main }} />
                </>
              )}

              <ArrowDropDownOutlined
                sx={{ color: theme.palette.primary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={isOpen}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {isLoggedIn && (
                <div>
                  <MenuItem component={RouterLink} to={"./profile"}>
                    <ProfileAvatar user={currentUser.user} size="24" />
                    Profile
                  </MenuItem>

                  <MenuItem
                    component={RouterLink}
                    to={"./user/" + currentUser.user.username}
                  >
                    <ListItemIcon>
                      <ManageAccountsOutlined fontSize="small" />
                    </ListItemIcon>
                    My Account
                  </MenuItem>

                  <Divider />
                </div>
              )}

              <MenuItem component={RouterLink} to={"/contact/"}>
                <ListItemIcon>
                  <ForwardToInboxOutlined fontSize="small" />
                </ListItemIcon>
                Contact Us
              </MenuItem>

              <MenuItem component={RouterLink} to={"/status/"}>
                <ListItemIcon>
                  <LocalHospitalOutlined fontSize="small" />
                </ListItemIcon>
                Status of OpsBlox
              </MenuItem>

              <MenuItem component={RouterLink} to={"/about/"}>
                <ListItemIcon>
                  <InfoOutlined fontSize="small" />
                </ListItemIcon>
                About OpsBlox
              </MenuItem>

              <Divider />

              {isLoggedIn ? (
                <MenuItem onClick={logOut}>
                  <ListItemIcon>
                    <LogoutOutlined fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              ) : (
                <div>
                  {/* <MenuItem component={RouterLink} to="./register">
                    <ListItemIcon>
                      <PersonAddOutlined fontSize="small" />
                    </ListItemIcon>
                    New Account
                  </MenuItem> */}

                  <MenuItem component={RouterLink} to="./login">
                    <ListItemIcon>
                      <LoginOutlined fontSize="small" />
                    </ListItemIcon>
                    Login
                  </MenuItem>
                </div>
              )}
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>

      <Popover
        open={openPreferences}
        anchorEl={anchorElPreferences}
        onClose={handelClosePreferences}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <NavBarPreferences
            copilotOpenSetter={setIsCopilotOpen}
            copilotSizeSetter={setCopilotSize}
            copilotSize={copilotSize}
          />
        </Box>
      </Popover>

      <Popover
        open={isOpenSearch}
        anchorEl={anchorElSearch}
        onClose={handleCloseSearch}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box height={areaHeightSearch} width={areaWidthSearch}>
          <NavBarSearch maxHeightList={areaHeightSearchList} closer={handleCloseSearch}/>
        </Box>
      </Popover>
    </AppBar>
  );
};

export default Navbar;
