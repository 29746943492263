import moment from "moment";

//Convert a period in the format YYYYMM into the when range
// The from date will be 1 second past minnight UTC for the 1st of the month
// The to date will be 23:59:59 on the last day of the month
export function getWhenFilterForBillingPeriod(period) {
  const parsedMoment = moment(period, "YYYYMM");

  // Set the date to the last day of the month
  const lastDayOfMonth = moment(period, "YYYYMM").endOf("month");

  const filter =
    "[when]=<>" +
    parsedMoment.format("YYYY-MM-DDTHH:mm:ss[Z]") +
    "," +
    lastDayOfMonth.format("YYYY-MM-DDTHH:mm:ss[Z]");

  return filter;
}


//Convert a period in the format YYYYMM into the when range
// The from date will be 1 second past minnight UTC for the 1st of the month
// The to date will be 23:59:59 on the last day of the month
export function getWhenFilterForBaselinePeriod(period) {
  const parsedMoment = moment(period, "YYYYMM");

  // Set the date to the last day of the month
  const lastDayOfMonth = moment(period, "YYYYMM").endOf("month");

  const filter =
    "[when]=" +
    parsedMoment.format("YYYY-MM-DDTHH:mm:ss[Z]")

  return filter;
}


export function getBaseLineAsFirstDay(period) {
  const parsedMoment = moment(period, "YYYYMM");

  return getNiceDate(parsedMoment);
}

export function convertFilterToLastSecond(filter) {
  const parts = filter.split(",");

  return parts[1];
}

export function getWhenFilterForReportingPeriod(period) {
  var startOfMonth,
    to,
    from,
    filter,
    startOfDay,
    startOfWeek,
    startOfQuarter,
    startOfYear;

  switch (period) {
    case "Today":
      startOfDay = moment().startOf("day");
      to = moment();

      filter =
        "[when]=<>" +
        startOfDay.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "Yesterday":
      startOfDay = moment().startOf("day");
      from = moment(startOfDay).subtract(1, "days");
      to = moment(from).endOf("day");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "CurrentWeek":
      startOfWeek = moment().startOf("week");
      //from = moment(startOfDay).subtract(1, "days");
      to = moment(startOfWeek).endOf("week");

      filter =
        "[when]=<>" +
        startOfWeek.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "LastWeek":
      startOfWeek = moment().startOf("week");
      from = moment(startOfWeek).subtract(1, "weeks");
      to = moment(from).endOf("week");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "CurrentMonth":
      startOfMonth = moment().startOf("month");
      to = moment();
      from = moment(startOfMonth).subtract(0, "months");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "LastMonth":
      startOfMonth = moment().startOf("month");
      from = moment(startOfMonth).subtract(1, "months");
      to = moment(from).endOf("month");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "CurrentQuarter":
      startOfQuarter = moment().startOf("quarter");
      from = moment(startOfQuarter).subtract(0, "quarters");
      to = moment(from).endOf("quarter");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "LastQuarter":
      startOfQuarter = moment().startOf("quarter");
      from = moment(startOfQuarter).subtract(1, "quarters");
      to = moment(from).endOf("quarter");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "Last3Months":
      startOfMonth = moment().startOf("month");
      from = moment(startOfMonth).subtract(3, "months");
      to = moment(startOfMonth).endOf("month");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

      case "Last6Months":
        startOfMonth = moment().startOf("month");
        from = moment(startOfMonth).subtract(6, "months");
        to = moment(startOfMonth).endOf("month");
  
        filter =
          "[when]=<>" +
          from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
          "," +
          to.format("YYYY-MM-DDTHH:mm:ss[Z]");
  
        break;

    case "CurrentYear":
      startOfYear = moment().startOf("year");
      from = moment(startOfYear).subtract(0, "years");
      to = moment(from).endOf("year");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;

    case "LastYear":
      startOfYear = moment().startOf("year");
      from = moment(startOfYear).subtract(1, "years");
      to = moment(from).endOf("year");

      filter =
        "[when]=<>" +
        from.format("YYYY-MM-DDTHH:mm:ss[Z]") +
        "," +
        to.format("YYYY-MM-DDTHH:mm:ss[Z]");

      break;
  }

  return filter;
}

export function getBillingPeriodForReportingPeriod(period) {
  var startOfMonth, to, from, billingPeriod;

  switch (period) {
    case "Today":
      return moment().format("YYYYMM");

    case "Yesterday":
      const yesterday = moment(moment().startOf("day")).subtract(1, "days");

      return yesterday.format("YYYYMM");

    case "CurrentWeek":
      const startOfWeek = moment().startOf("week");

      return startOfWeek.format("YYYYMM");

    case "LastWeek":
      const lastWeek = moment(moment().startOf("week")).subtract(1, "weeks");

      return lastWeek.format("YYYYMM");

    case "CurrentMonth":
      startOfMonth = moment().startOf("month");
      from = moment(startOfMonth).subtract(0, "months");

      return from.format("YYYYMM");

    case "LastMonth":
      startOfMonth = moment().startOf("month");
      from = moment(startOfMonth).subtract(1, "months");

      return from.format("YYYYMM");

    case "CurrentQuarter":
      const startOfQuarter = moment().startOf("quarter");
      return startOfQuarter.format("YYYYMM");

    case "LastQuarter":
      const lastQuarter = moment(moment().startOf("quarter")).subtract(
        1,
        "quarters"
      );

      return lastQuarter.format("YYYYMM");

      case "Last3Months":
      const Last3Months = moment(moment().startOf("month")).subtract(
        3,
        "months"
      );

      return Last3Months.format("YYYYMM");

      case "Last6Months":
      const Last6Months = moment(moment().startOf("month")).subtract(
        6,
        "months"
      );

      return Last6Months.format("YYYYMM");

    case "CurrentYear":
      const startOfYear = moment().startOf("year");

      return startOfYear.format("YYYYMM");

    case "LastYear":
      const lastYear = moment(moment().startOf("year")).subtract(1, "years");

      return lastYear.format("YYYYMM");
  }
}

export function parseDimension(dimensionToCheck, searchParams) {
  if (dimensionToCheck === "<!dimension!>") {
    //we need to get the search param
    return searchParams.get("dimension") !== null
      ? searchParams.get("dimension")
      : "Month";
  }

  return dimensionToCheck;
}

export function parsePeriod(periodToCheck, searchParams) {
  if (periodToCheck === "<!period!>") {
    //we need to get the search param
    return searchParams.get("period") !== null
      ? searchParams.get("period")
      : "CurrentMonth";
  }

  return periodToCheck;
}

export function parseBaseLinePeriod(periodToCheck, searchParams) {
  //debugger

  if (periodToCheck === "<!baseLine!>") {
    //we need to get the search param
    return searchParams.get("baseline") !== null
      ? searchParams.get("baseline")
      : "202001";
  }

  return periodToCheck;
}

export function getReportingPeriodsForAvilableBillingPeriods(billingPeriods) {
  var periods = [];

  //periods.push({ value: "CurrentMonth", label: "Current Month" });

  //debugger

  const today = moment().format("YYYYMM");
  if (billingPeriods.includes(today)) {
    periods.push({ value: "Today", label: "Today" });
  }

  const yesterday = moment(moment().startOf("day").subtract(1, "days")).format(
    "YYYYMM"
  );
  if (billingPeriods.includes(yesterday)) {
    periods.push({ value: "Yesterday", label: "Yesterday" });
  }

  const startOfWeek = moment().startOf("week");
  // const currentweek = startOfWeek.format("YYYYMM");
  // if (billingPeriods.includes(currentweek)) {
  //   periods.push({ value: "CurrentWeek", label: "Current Week" });
  // }

  periods.push({ value: "CurrentWeek", label: "Current Week" });

  const lastweek = moment(startOfWeek).subtract(1, "weeks").format("YYYYMM");
  if (billingPeriods.includes(lastweek)) {
    periods.push({ value: "LastWeek", label: "Last Week" });
  }

  const startOfMonth = moment().startOf("month");
  // const currentPeriod = startOfMonth.format("YYYYMM");
  // if (billingPeriods.includes(currentPeriod)) {
  //   periods.push({ value: "CurrentMonth", label: "Current Month" });
  // }

  periods.push({ value: "CurrentMonth", label: "Current Month" });

  const lastPeriod = moment(startOfMonth)
    .subtract(1, "months")
    .format("YYYYMM");
  if (billingPeriods.includes(lastPeriod)) {
    periods.push({ value: "LastMonth", label: "Last Month" });
  }

  const startOfQuater = moment().startOf("quarter");
  const currentQuarter = startOfQuater.format("YYYYMM");
  if (billingPeriods.includes(currentQuarter)) {
    periods.push({ value: "CurrentQuarter", label: "Current Quarter" });
  }

  const lastQuarter = moment(startOfQuater)
    .subtract(1, "quarters")
    .format("YYYYMM");
  if (billingPeriods.includes(lastQuarter)) {
    periods.push({ value: "LastQuarter", label: "Last Quarter" });
  }

  const last3Months = moment(startOfMonth)
    .subtract(3, "months")
    .format("YYYYMM");
  if (billingPeriods.includes(last3Months)) {
    periods.push({ value: "Last3Months", label: "Last 3 Months" });
  }

  const last6Months = moment(startOfMonth)
    .subtract(6, "months")
    .format("YYYYMM");
  if (billingPeriods.includes(last3Months)) {
    periods.push({ value: "Last6Months", label: "Last 6 Months" });
  }

  const startOfYear = moment().startOf("year");
  const currentYear = startOfYear.format("YYYYMM");
  if (billingPeriods.includes(currentYear)) {
    periods.push({ value: "CurrentYear", label: "Current Year" });
  }

  const lastYear = moment(startOfYear).subtract(1, "years").format("YYYYMM");

  if (billingPeriods.includes(lastYear)) {
    periods.push({ value: "LastYear", label: "Last Year" });
  }

  return periods;
}

export function niceFormatData(formatter, value, currencyCode, row) {
  //check if we have an area in the string and remove if we do

  //debugger

  

  let valuePart = value;
  let area = ""

  if (typeof valuePart === "string") {
    const parts = value.split("<|>");

    if ((parts.length === 2)) {
      area = parts[0]
      valuePart = parts[1];
    }
  }

  //console.log("Nice Formater: " , formatter , "Nice Value: ", value, " value part: " , valuePart);

  var  formatedValue = ""

  switch (formatter) {
    case "getDay":
      formatedValue =  valuePart.substring(8, 10);
      break

    case "getMonth":
      formatedValue =  valuePart.substring(5, 7);
      break

    case "getYear":
      formatedValue =  valuePart.substring(0, 4);
      break

    case "getDate":
      formatedValue =  valuePart.substring(0, 10);
      break

    case "getNiceDate":
      formatedValue =   getNiceDate(valuePart);
      break

    case "getCurrency":
      //debugger
      formatedValue =  getNiceCurrency(valuePart, currencyCode);
      break

    case "getNicePercentage":
      formatedValue =  getNicePercentage(valuePart);
      break

    default:
      formatedValue =  valuePart;
      break
  }

  if (area.length > 0){

    return area + "\n" + formatedValue
  }

  return formatedValue
}

export function getNicePercentage(value) {
  // Get the user's preferred language and region
  const userLanguage = navigator.language || navigator.userLanguage;

  // Extract the language and region from the user's preferences
  const [language, region] = userLanguage.split("-");

  const formatter = new Intl.NumberFormat(`${language}-${region}`, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value);
}

export function getNiceDate(value) {
  // Get the user's preferred language and region
  const userLanguage = navigator.language || navigator.userLanguage;

  // Extract the language and region from the user's preferences
  const [language, region] = userLanguage.split("-");

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const formatter = new Intl.DateTimeFormat(`${language}-${region}`, options);
  return formatter.format(new Date(value));
}

export function getNiceDateTime(value) {
  // Get the user's preferred language and region
  const userLanguage = navigator.language || navigator.userLanguage;

  // Extract the language and region from the user's preferences
  const [language, region] = userLanguage.split("-");

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };

  const formatter = new Intl.DateTimeFormat(`${language}-${region}`, options);
  return formatter.format(new Date(value));
}

export function getNiceCurrency(value, currencyCode) {
  if (!currencyCode) {
    return value;
  }

  // Get the user's preferred language and region
  const userLanguage = navigator.language || navigator.userLanguage;

  // Extract the language and region from the user's preferences
  const [language, region] = userLanguage.split("-");

  // Define the desired number of decimal places dynamically
  const decimalPlaces = 2; // Set this value as needed

  // Use the user's language and region for formatting
  const formatter = new Intl.NumberFormat(`${language}-${region}`, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  // Format the currency value with the symbol

  let newValue = formatter.format(value);

  // if (newValue.substring(0,1) === '-'){
  //   newValue = '(' + newValue.substring(1,newValue.length) + ')'
  // }

  return newValue;
}

export function processStringForDashboardFilters(inputString, renderState) {
  const regexForParamater = /<!([^!]+)!>/g;

  //inputString = inputString.replace(/_id/g, "id");

  //debugger;

  let processedString = inputString.replace(
    regexForParamater,
    (match, placeholder) => {
      //debugger;

      switch (placeholder) {
        case "dimension":
          const selectedDimension = renderState?.dimensions?.find(
            (obj) => obj.value === renderState.dimension
          );

          if (selectedDimension) {
            return selectedDimension.label;
          } else {
            return match;
          }

        case "period":
          const selectedPeriod = renderState?.periods?.find(
            (obj) => obj.value === renderState.period
          );

          if (selectedPeriod) {
            return selectedPeriod.label;
          } else {
            return match;
          }

        
        default:
          //its a custom variable so see if we have it, if not return the string unchnaged

          //debugger

          const selectedVariable = renderState?.variables?.find(
            (obj) => obj.value === placeholder
          );

          if (selectedVariable) {
            return selectedVariable.label;
          } else {
            return match;
          }

      }
    }
  );

  return processedString;
}



export const processString = (inputString, row) => {
  const regex = /<([^>]+)>/g;

  inputString = inputString.replace(/_id/g, "id");

  const processedString = inputString.replace(regex, (match, placeholder) => {
    if (row.hasOwnProperty(placeholder)) {
      return row[placeholder];
    } else {
      return match;
    }
  });

  return processedString;
};