import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import DashboardService from "../services/dashboard.service";
import StateService from "services/state.service";
import KpiService from "../services/kpi.service";
import UtilitySevice from "services/utility.service";
import OpportunityService from "services/opportunity.service";

export const createDashboard = createAsyncThunk(
  "Dashboard/create",
  async ({ dashboard }, thunkAPI) => {
    try {
      const data = await DashboardService.createDashboard(dashboard);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteDashboard = createAsyncThunk(
  "Dashboard/delete",

  async ({ id }, thunkAPI) => {
    debugger;
    try {
      const data = await DashboardService.deleteDashboard(id);
      return { Dashboards: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDashboard = createAsyncThunk(
  "Dashboard/update",
  async ({ dashboard }, thunkAPI) => {
    try {
      const data = await DashboardService.updateDashboard(dashboard);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDashboardActive = createAsyncThunk(
  "Dashboard/update_active",
  async ({ dashboard }, thunkAPI) => {
    try {
      const data = await DashboardService.updateDashboardActive(dashboard);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboards = createAsyncThunk(
  "Dashboard/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboards(search);
      return { Dashboards: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboard = createAsyncThunk(
  "Dashboard/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboard(id);
      return { Dashboard: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboardFilterValues = createAsyncThunk(
  "Dashboard/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboardWidgets = createAsyncThunk(
  "DashboardWidget/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardWidgets(search);
      return { Dashboards: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboardWidget = createAsyncThunk(
  "DashboardWidget/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardWidget(id);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createDashboardWidget = createAsyncThunk(
  "DashboardWidget/create",
  async ({ dashboardWidget }, thunkAPI) => {
    try {
      const data = await DashboardService.createDashboardWidget(
        dashboardWidget
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  "DashboardWidget/delete",

  async ({ id }, thunkAPI) => {
    debugger;
    try {
      const data = await DashboardService.deleteDashboardWidget(id);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDashboardWidget = createAsyncThunk(
  "DashboardWidget/update",
  async ({ dashboardWidget }, thunkAPI) => {
    try {
      const data = await DashboardService.updateDashboardWidget(
        dashboardWidget
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetConfig = createAsyncThunk(
  "DashboardWidget/getConfig",
  async ({ search }, thunkAPI) => {
    try {
      const data = await StateService.getStateData(search);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetConfigForRendering = createAsyncThunk(
  "DashboardWidget/getConfigForRendering",
  async ({ search }, thunkAPI) => {
    try {
      const data = await StateService.getStateData(search);

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDashboardWidgetsForRendering = createAsyncThunk(
  "DashboardWidget/get_listForRendering",
  async ({ search }, thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardWidgets(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetKpiAggregateValues = createAsyncThunk(
  "DashboardWidget/get_aggregate_list_kpi",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiAggregateValues(search);

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetCurrentTargets = createAsyncThunk(
  "DashboardWidget/get_current_targets",
  async ({ search }, thunkAPI) => {
    try {
      const data = await KpiService.getKpiCurrentTargets(search);

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetUtilityAggregateValues = createAsyncThunk(
  "DashboardWidget/get_aggregate_list_utility",
  async ({ search }, thunkAPI) => {
    try {
      const data = await UtilitySevice.getUtilityAggregateValues(search);

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetOpportunityAggregateValues = createAsyncThunk(
  "DashboardWidget/get_aggregate_list_Opportunity",
  async ({ search }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunityAggregateValues(
        search
      );

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWidgetColumnValues = createAsyncThunk(
  "DashboardWidget/get_columns",
  async ({ search }, thunkAPI) => {
    try {
      let data = null;

      if (search.serviceType) {
        switch (search.serviceType) {
          case "Utility":
            data = await UtilitySevice.getUtilityUi(search.id);
            return { data };

          case "Kpi":
            data = await KpiService.getKpiUi(search.id);
            return { data };

          case "Opportunity":
            data = await OpportunityService.getOpportunityUi(search.id);
            return { data };

          default:
            thunkAPI.dispatch(
              setMessage({
                message:
                  "Service type: " +
                  search.serviceType +
                  " is not enabled for dashboard use",
                type: "error",
              })
            );
            return thunkAPI.rejectWithValue();
        }
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

// const widgetData = {
//   dashboard: "",
//   widget: "",
//   rows: [],
//   loading: false,
// };

// const renderState = {
//   dashboard: "",

//   tab: "",
//   dimension: "",
//   period: "",
//   scope: "",
//   baseline: "",
//   owner: "",

//   dimensions: [],
//   periods: [],
//   owners: [],
//   variables: [],
// };

const config = JSON.parse(localStorage.getItem("dashboardRenderState"));

const initialState = {
  data: list,
  widgetsForRendering: [],
  loading: false,
  configForRendering: [],
  columnsForRendering: [],
  widgets: list,
  config: list,
  widgetDataForRendering: [],
  renderState: config ? config : [],
};

const persistRenderState = (config) => {
  localStorage.setItem("dashboardRenderState", JSON.stringify(config));
};

const DashboardSlice = createSlice({
  name: "Dashboards",
  initialState,
  reducers: {
    setDashboardRenderState: (state, action) => {
      //debugger;

      const dashboard = action.payload?.dashboard;

      // Check for null renderState in which case just add this one
      if (state.renderState) {
        // Find the index of the record in renderState array
        const index = state.renderState.findIndex(
          (item) => item.dashboard === dashboard
        );

        if (index !== -1) {
          // If record found, update loading to true
          state.renderState[index] = action.payload;
        } else {
          // If no match found, insert a new record
          state.renderState.push(action.payload);
        }
      } else {
        state.renderState.push(action.payload);
      }

      persistRenderState(state.renderState);
    },
    setDashboardRenderStateVariable: (state, action) => {
      const { dashboard, value, label } = action.payload;

      const renderStateIndex = state.renderState.findIndex(rs => rs.dashboard === dashboard);

      //debugger

      if (renderStateIndex >= 0) {
        const currentRenderState = state.renderState[renderStateIndex];
        const updatedVariables = currentRenderState.variables.map(variable =>
          variable.value === value ? { ...variable, label } : variable
        );

        if (!updatedVariables.some(variable => variable.value === value)) {
          updatedVariables.push({ value:value, label:label });
        }

        const updatedRenderState = {
          ...currentRenderState,
          variables: updatedVariables
        };

        state.renderState[renderStateIndex] = updatedRenderState;
      } else {
        const newRenderState = {
          dashboard,
          built: false,
          variables: [{ value:value, label:label }]
        };

        state.renderState.push(newRenderState);
      }

      persistRenderState(state.renderState);
    }
  
  },

  extraReducers: {
    //Column Reducers

    [getWidgetColumnValues.pending]: (state, action) => {
      //debugger;

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;

      // Find the index of the record in widgetDataForRendering array
      const index = state.columnsForRendering.findIndex(
        (item) => item.dashboard === dashboard && item.widget === widget
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.columnsForRendering[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.columnsForRendering.push({
          dashboard,
          widget,
          columns: [],
          loading: true,
        });
      }
    },
    [getWidgetColumnValues.fulfilled]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const columns = action.payload?.data?.columns;

      // Find the index of the record in columnsForRendering array
      const index = state.columnsForRendering.findIndex(
        (item) => item.dashboard === dashboard && item.widget === widget
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.columnsForRendering[index].columns = columns;
        state.columnsForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.columnsForRendering.push({
          dashboard,
          widget,
          columns: columns,
          loading: false,
        });
      }
    },
    [getWidgetColumnValues.rejected]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;

      // Find the index of the record in columnsForRendering array
      const index = state.columnsForRendering.findIndex(
        (item) => item.dashboard === dashboard && item.widget === widget
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.columnsForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.columnsForRendering.push({
          dashboard,
          widget,
          columns: [],
          loading: false,
        });
      }
    },

    //Data Reduces

    [getWidgetCurrentTargets.pending]: (state, action) => {
      //debugger;

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: true,
        });
      }
    },
    [getWidgetCurrentTargets.fulfilled]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;
      const rows = action.payload?.data?.targets;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].rows = rows;
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: rows,
          loading: false,
        });
      }
    },
    [getWidgetCurrentTargets.rejected]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: false,
        });
      }
    },

    [getWidgetKpiAggregateValues.pending]: (state, action) => {
      //debugger;

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: true,
        });
      }
    },
    [getWidgetKpiAggregateValues.fulfilled]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;
      const rows = action.payload?.data?.rows;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].rows = rows;
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: rows,
          loading: false,
        });
      }
    },
    [getWidgetKpiAggregateValues.rejected]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: false,
        });
      }
    },

    [getWidgetUtilityAggregateValues.pending]: (state, action) => {
      //debugger;

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: true,
        });
      }
    },
    [getWidgetUtilityAggregateValues.fulfilled]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;
      const rows = action.payload?.data?.rows;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].rows = rows;
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: rows,
          loading: false,
        });
      }
    },
    [getWidgetUtilityAggregateValues.rejected]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: false,
        });
      }
    },

    [getWidgetOpportunityAggregateValues.pending]: (state, action) => {
      //debugger;

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: true,
        });
      }
    },
    [getWidgetOpportunityAggregateValues.fulfilled]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;
      const rows = action.payload?.data?.rows;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].rows = rows;
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: rows,
          loading: false,
        });
      }
    },
    [getWidgetOpportunityAggregateValues.rejected]: (state, action) => {
      //debugger

      const dashboard = action.meta.arg.search?.dashboard;
      const widget = action.meta.arg.search?.widget;
      const area = action.meta.arg.search?.area;

      // Find the index of the record in widgetDataForRendering array
      const index = state.widgetDataForRendering.findIndex(
        (item) =>
          item.dashboard === dashboard &&
          item.widget === widget &&
          item.area === area
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.widgetDataForRendering[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.widgetDataForRendering.push({
          dashboard,
          widget,
          area,
          rows: [],
          loading: false,
        });
      }
    },

    //Meta reduces

    [getDashboardFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboardFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getDashboardFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },

    [createDashboard.pending]: (state, action) => {
      state.loading = true;
    },
    [createDashboard.fulfilled]: (state, action) => {
      state.data.list.push(action.payload.data.data);
      state.data.total++;
      state.loading = false;
    },
    [createDashboard.rejected]: (state, action) => {
      state.loading = false;
    },

    [getDashboards.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboards.fulfilled]: (state, action) => {
      state.data = action.payload.Dashboards;
      state.loading = false;
    },
    [getDashboards.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [deleteDashboard.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteDashboard.fulfilled]: (state, action) => {
      state.data = action.payload.Dashboards;
      state.loading = false;
    },
    [deleteDashboard.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateDashboardActive.fulfilled]: (state, action) => {
      const updatedDashboard = state.data.list.map((Dashboard) => {
        if (Dashboard.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Dashboard;
        }
      });
      state.data.list = updatedDashboard;
    },

    [updateDashboard.fulfilled]: (state, action) => {
      const updatedDashboard = state.data.list.map((Dashboard) => {
        if (Dashboard.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Dashboard;
        }
      });
      state.data.list = updatedDashboard;
    },

    [getDashboard.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboard.fulfilled]: (state, action) => {
      //debugger;

      const newPayload = action.payload.Dashboard;

      const existingIndex = state.data.list.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }

      state.loading = false;
    },
    [getDashboard.rejected]: (state, action) => {
      state.loading = false;
    },

    [createDashboardWidget.pending]: (state, action) => {
      state.loading = true;
    },
    [createDashboardWidget.fulfilled]: (state, action) => {
      state.widgets.list.push(action.payload.data.data);
      state.widgets.total++;
      state.loading = false;
    },
    [createDashboardWidget.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateDashboardWidget.fulfilled]: (state, action) => {
      const updatedDashboard = state.widgets.list.map((widget) => {
        if (widget.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return widget;
        }
      });
      state.widgets.list = updatedDashboard;
    },

    [getDashboardWidget.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboardWidget.fulfilled]: (state, action) => {
      //debugger;

      const newPayload = action.payload.data;

      const existingIndex = state.data.list.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.widgets.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.widgets.list.push(newPayload);
      }

      state.loading = false;
    },
    [getDashboardWidget.rejected]: (state, action) => {
      state.loading = false;
    },

    [getDashboardWidgets.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboardWidgets.fulfilled]: (state, action) => {
      state.widgets = action.payload.Dashboards;
      state.loading = false;
    },
    [getDashboardWidgets.rejected]: (state, action) => {
      state.widgets = list;
      state.loading = false;
    },

    [getWidgetConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getWidgetConfig.fulfilled]: (state, action) => {
      debugger;

      state.config = action.payload.data;
      state.loading = false;
    },
    [getWidgetConfig.rejected]: (state, action) => {
      state.config = list;
      state.loading = false;
    },

    //readering

    [getDashboardWidgetsForRendering.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboardWidgetsForRendering.fulfilled]: (state, action) => {
      const newPayloadList = action.payload.data.list;

      newPayloadList.forEach((newItem) => {
        const existingIndex = state.widgetsForRendering.findIndex(
          (existingItem) => existingItem.id === newItem.id
        );

        if (existingIndex >= 0) {
          // The item already exists, update it
          state.widgetsForRendering[existingIndex] = {
            ...state.widgetsForRendering[existingIndex],
            ...newItem,
          };
        } else {
          // The item doesn't exist, add it
          state.widgetsForRendering.push(newItem);
        }
      });

      state.loading = false;
    },
    [getDashboardWidgetsForRendering.rejected]: (state, action) => {
      state.loading = false;
    },

    [getWidgetConfigForRendering.pending]: (state, action) => {
      state.loading = true;
    },
    [getWidgetConfigForRendering.fulfilled]: (state, action) => {
      const newPayloadList = action.payload.data.list;

      newPayloadList.forEach((newItem) => {
        const existingIndex = state.configForRendering.findIndex(
          (existingItem) => existingItem.id === newItem.id
        );

        if (existingIndex >= 0) {
          // The item already exists, update it
          state.configForRendering[existingIndex] = {
            ...state.configForRendering[existingIndex],
            ...newItem,
          };
        } else {
          // The item doesn't exist, add it
          state.configForRendering.push(newItem);
        }
      });

      state.loading = false;
    },
    [getWidgetConfigForRendering.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer, actions } = DashboardSlice;

export const { setDashboardRenderState, setDashboardRenderStateVariable } =
  actions;

export default reducer;
