import {
  AddCircleOutlineOutlined,
  CenterFocusStrong,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { getBezierPath, MarkerType } from "reactflow";

import { useDispatch, useSelector } from "react-redux";
import {
  initUi,
  clearUi,
  setEdges,
  setNodes,
  insertMenu,
  closeMenu,
} from "slices/workflowUI";

const foreignObjectSize = 48;

const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const dispatch = useDispatch();


    

  const openMenu = () => {

    dispatch(insertMenu({ id }));
  };

  const markerEnd = {
    type: MarkerType.ArrowClosed,
  };

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        //markerEnd={markerEnd}
        markerEnd="arrowclosed"
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Tooltip title="Add step here">
          <IconButton
            aria-label="Menu"
            onClick={openMenu}
            p="0px"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <AddCircleOutlineOutlined />
          </IconButton>
        </Tooltip>
      </foreignObject>
    </>
  );
};

export default ButtonEdge;
