import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetColumnValues, getWidgetKpiAggregateValues, getWidgetUtilityAggregateValues } from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";
import { getBillingPeriodForReportingPeriod, getWhenFilterForReportingPeriod, parseDimension, parsePeriod, processStringForDashboardFilters } from "../../../common/helpers";
import { useSearchParams } from "react-router-dom";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
  GaugeValueText,
  gaugeClasses ,
} from '@mui/x-charts/Gauge';

// function GaugePointer() {
//   const { valueAngle, outerRadius, cx, cy } = useGaugeState();
//   const movePointerOrigin = 30

//   if (valueAngle === null) {
//     // No value to display
//     return null;
//   }

//   const target = {
//     x: cx + outerRadius * Math.sin(valueAngle),
//     y: cy - outerRadius * Math.cos(valueAngle),
//   };


//   return (
//     <g>
//       {/* <circle cx={cx} cy={cy} r={5} fill="red" /> */}
//       <path
//         d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
//         stroke="red"
//         strokeWidth={3}
//       />
//     </g>
//   );
// }

function describeArc(cx, cy, radius, startAngle, endAngle) {
  const start = polarToCartesian(cx, cy, radius, endAngle);
  const end = polarToCartesian(cx, cy, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");
}

function polarToCartesian(cx, cy, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: cx + (radius * Math.cos(angleInRadians)),
    y: cy + (radius * Math.sin(angleInRadians))
  };
}

const GaugeCustomArc = ({ cx, cy, radius, startAngle, endAngle, color }) => {
  const arcPath = describeArc(cx, cy, radius, startAngle, endAngle);
  
  return (
    <path
      d={arcPath}
      fill="none"
      stroke={color}
      strokeWidth={5}
    />
  );
};


function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  const movePointerOrigin = 30;

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  // Calculate the target point
  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };

  // Calculate the direction vector
  const direction = {
    x: target.x - cx,
    y: target.y - cy,
  };

  // Calculate the length of the direction vector
  const length = Math.sqrt(direction.x ** 2 + direction.y ** 2);

  // Normalize the direction vector
  const unitDirection = {
    x: direction.x / length,
    y: direction.y / length,
  };

  // Calculate the new starting point
  const start = {
    x: cx + movePointerOrigin * unitDirection.x,
    y: cy + movePointerOrigin * unitDirection.y,
  };

  return (
    <g>
      {/* <circle cx={cx} cy={cy} r={5} fill="red" /> */}
      <path
        d={`M ${start.x} ${start.y} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}


const WidgetGague = ({
  title,
  description,
  icon,
  columns,
  rows,
  top,
  left,
  value,
  valueText,
  min,
  midStart,
  midEnd,
  max,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  //debugger


  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}




      borderRadius="0.55rem"
    >
      <FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main }}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>


    <GaugeContainer
      // width={200}
      // height={200}
      startAngle={-90}
      endAngle={90}
      value={value}
      valueMin={min}
      valueMax={max}
      sx={{ fontSize: '28px' }}

    >
      <GaugeReferenceArc  />
      <GaugeValueArc />
    


      <GaugePointer />
      <GaugeValueText  text={valueText}  />
    </GaugeContainer>

      <FlexBetween gap="1rem">
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default WidgetGague;
