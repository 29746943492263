import api from "./api";



const listPreference = (search) => {
  return api
    .get("/preference", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};


const getPreference = (id) => {
  return api.get("/preference/" + id).then((response) => {
    return response.data;
  });
};


const getPreferenceFilterValues = (search) => {

  return api.get("/preference/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getPreferenceUi = () => {
  return api.get("/preference/ui")
  .then((response) => {
    return response.data;
  });
};


const updatePreference = (preference) => {
  return api
    .patch("/preference", {
      ...preference,
    })
    .then((response) => {
      return response.data;
    });
};

const updatePreferenceByUser = (preference) => {
  return api
    .patch("/preference_by_user", {
      ...preference,
    })
    .then((response) => {
      return response.data;
    });
};

const PreferenceService = {
  listPreference,
  getPreference,
  getPreferenceFilterValues,
  getPreferenceUi,
  updatePreference,
  updatePreferenceByUser,

};

export default PreferenceService;
