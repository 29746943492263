import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
  Autocomplete,
  TextField,
  Switch,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { clearKpiUi, getKpiTargetUi } from "slices/kpi";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getKpi,
  getKpiTargets,
  updateKpi,
  getKpiUi,
  getKpiData,
  runKpi,
  updateKpiActive,
} from "slices/kpi";

import { getJobs } from "slices/job";
import { setMessage, clearMessage } from "slices/message";

import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import KeyVal from "components/global/KeyVal";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import {
  CheckOutlined,
  CloseOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceActive from "components/global/NiceActive";
import TabPanel from "components/global/TabPanel";
import NiceLink from "components/global/NiceLink";
import NiceDateOnly from "components/global/NiceDateOnly";
import NiceUser from "components/global/NiceUser";
import StateSetAsConfigProvider from "components/state/StateSetAsConfigProvider";
import { renderColumn } from "common/renderColumn";
import TableActionsButton from "components/global/TableActionsButton";
import KpiAddTarget from "./KpiAddTarget";
import NiceTarget from "components/global/NiceTarget";

const Kpi = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const handleAddRow = () => {
    dispatch(clearMessage());
    setAnchorElAddRow(addRowRef.current);
  };

  const [anchorElAddRow, setAnchorElAddRow] = useState(null);
  const [areaHeightAdd, setAreaHeightAdd] = useState("65vh");
  const [areaWidthAdd, setAreaWidthAdd] = useState("35vw");

  const addRowRef = React.useRef(null);

  const handelCloseAddRow = () => {
    setAnchorElAddRow(null);
  };

  const openAddRow = Boolean(anchorElAddRow);

  console.log("Open: ", openAddRow);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const Jobs = useSelector((state) => state.job.data);

  const targets = useSelector((state) => state.kpi.targets);

  const Kpi = useSelector((state) =>
    state.kpi.data.list?.find((kpi) => kpi.id === id)
  );

  const Kpi_data = useSelector((state) => state.kpi.kpi_data);

  const Kpi_UI = useSelector((state) => state.kpi.kpiUi);
  const target_UI = useSelector((state) => state.kpi.targetUi);

  const [tabValue, setTabValue] = React.useState(
    searchParams.get("tab") ? parseInt(searchParams.get("tab"), 10) : 0
  );

  const selectedDimension = useSelector(
    (state) => state.tablePage.config.dimension
  );

  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    //need to get the Kpi UI form the API
    dispatch(getKpiUi({ id }));
    dispatch(getKpiTargetUi({ id }));

    if (!Kpi) {
      //need to get the Kpi form the API
      dispatch(getKpi({ id }));
    }
  }, [Kpi, id]);

  useEffect(() => {
    if (isFirstLoad) {
      //set the fact that we have an update on the UI block to use
      setIsFirstLoad(false);
      dispatch(clearKpiUi());

      if (searchParams.get("tab")) {
        setTabValue(parseInt(searchParams.get("tab"), 10));
      } else {
        setTabValueAndUpdateQuery(tabValue);
      }
    }
  }, [id]);

  useEffect(() => {
    dispatch(getKpi({ id }));
  }, [refreshPoint]);

  const handleRun = () => {
    dispatch(clearMessage());
    dispatch(runKpi({ id }));
  };

  const handleActivate = () => {
    dispatch(clearMessage());
    const kpi = { id: id, active: true };
    dispatch(updateKpiActive({ kpi }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const kpi = { id: id, active: false };
    dispatch(updateKpiActive({ kpi }));
  };

  const jobColumnsVisability = {
    organization: false,
    orchestrator: false,
    modifiedAt: false,
  };

  const jobColumns = [
    {
      field: "id",
      headerName: "Job Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <NiceLink label={id} link={`/job/${id}`} tip="Open Job" makeBlue />
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "jobOrigin",
      headerName: "Origin",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: ({ row: { createdAt } }) => {
        return <NiceDate>{createdAt}</NiceDate>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Update",
      flex: 1,
      type: "date",
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
    {
      field: "startedAt",
      headerName: "Started",
      flex: 1,
      type: "date",
      renderCell: ({ row: { startedAt } }) => {
        return <NiceDate>{startedAt}</NiceDate>;
      },
    },
    {
      field: "stoppedAt",
      headerName: "Stopped",
      flex: 1,
      type: "date",
      renderCell: ({ row: { stoppedAt } }) => {
        return <NiceDate>{stoppedAt}</NiceDate>;
      },
    },
    {
      field: "duration",
      headerName: "Run Time",
      flex: 1,
      type: "number",
      renderCell: ({ row: { duration } }) => {
        return <NiceDuration>{duration}</NiceDuration>;
      },
    },
    {
      field: "itemsProcessed",
      headerName: "Items Processed",
      flex: 1,
      type: "number",
    },
  ];

  const [showOld, setShowOld] = React.useState(false);

  const toggleOld = (event) => {
    setShowOld(event.target.checked);
  };

  let columns = [];
  let measures = [];
  let targetColumns = [];
  let dimensions = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;
    const regexForParamater = /<!([^!]+)!>/g;

    inputString = inputString.replace(/_id/g, "id");

    let processedString = inputString.replace(
      regexForParamater,
      (match, placeholder) => {
        if (placeholder == "dimension") {
          return selectedDimension;
        } else {
          return match;
        }
      }
    );

    processedString = processedString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (Kpi_UI?.columns) {
    columns = Kpi_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });

    measures = measures = Kpi_UI.columns
      .filter((column) => column.purpose === "measure")
      .map((column) => ({
        value: column.field,
        label: column.headerName,
      }));

      dimensions = dimensions = Kpi_UI.columns
      .filter((column) => column.purpose === "dimension")
      .map((column) => ({
        ...column
      }));

  }

  if (target_UI?.columns) {
    targetColumns = target_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row, Kpi_UI?.columns);
        }
      }

      return updatedColumn;
    });
  }

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Kpi?.name} subtitle={Kpi?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          {!Kpi?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckOutlined />}
              onClick={handleActivate}
            >
              Activate
            </Button>
          )}
          {Kpi?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloseOutlined />}
              onClick={handleDectivate}
            >
              Deactivate
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayArrowOutlined />}
            onClick={handleRun}
            ref={addRowRef} // this is to move the box to close to the top of the screen
          >
            Run
          </Button>
        </FlexBetween>
      </FlexBetween>

      <DetailBlock>
        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>{Kpi?.description}</Typography>
          <Typography>Kpi Type: {Kpi?.implementor}</Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>
            Created: <NiceDate>{Kpi?.createdAt}</NiceDate>
          </Typography>
          <Typography>
            Modified: <NiceDate>{Kpi?.modifiedAt}</NiceDate>
          </Typography>
          <Typography>
            Last Seen: <NiceDate>{Kpi?.lastContact}</NiceDate>
          </Typography>
          <Typography>
            Last Run: <NiceDate>{Kpi?.lastRun}</NiceDate>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <KeyVal
            label="Active"
            value={Kpi?.active ? "Yes" : "Not Running"}
            tip="Enable the kpi if you want it to run"
          />

          <KeyVal
            label="Is Avilable"
            value={Kpi?.isAvilable ? "Yes" : "Not Responding"}
          />

          {Kpi && (
            <KeyVal
              label="Job Status"
              value={Kpi?.jobStatus}
              link={`/job/${Kpi?.jobId}`}
              tip={`Open Job id:${Kpi?.jobId}`}
              makeBlue
            />
          )}
        </Box>
      </DetailBlock>

      <Box mt="20px" sx={{ width: "100%" }}>
        {/* <Box
          //sx={{ borderBottom: 1, borderColor: "divider" }}
          display="flex"
          //justifyContent="space-between"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Kpi Tabs"
            //orientation="vertical"
            variant="scrollable"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            //sx={{ minWidth: "15rem" }}
          > */}

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="KPI Tabs"
            //orientation="vertical"
            variant="scrollable"
            sx={{
              //borderRight: 1,
              borderColor: "divider",
              alignItems: "flex-start",
            }}
          >
            <Tab
              label={`KPI Data (${Kpi_data?.total})`}
              //label={`KPI Data (${selectedDimention?.label})`}
              {...a11yProps(0)}
              sx={{
                textTransform: "none",
                textAlign: "left",
                alignItems: "start",
              }}
            />
            <Tab
              label={`Job History (${Jobs?.total})`}
              {...a11yProps(1)}
              sx={{
                textTransform: "none",
                textAlign: "left",
                alignItems: "start",
              }}
            />
            <Tab
              label="Targets"
              {...a11yProps(2)}
              sx={{
                textTransform: "none",
                textAlign: "left",
                alignItems: "start",
              }}
            />
            <Tab
              label="Configuration"
              {...a11yProps(3)}
              sx={{
                textTransform: "none",
                textAlign: "left",
                alignItems: "start",
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {Kpi_data && !isFirstLoad && Kpi_UI && Kpi !== undefined ? (
            <TablePage
              masterId={id}
              dataRows={Kpi_data}
              dataColumns={columns}
              dataGetter={getKpiData}
              defaultColumnsVisability={Kpi_UI.columnsVisability}
              defaultSort={Kpi_UI.sort}
              refreshPoint={refreshPoint}
              kpi={Kpi}
              initialDimension={"Day"}
              serviceType={"kpi"}
            />
          ) : (
            <Typography>Loading</Typography>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {Jobs ? (
            <TablePage
              masterId={id}
              masterIdField="implementorId"
              dataRows={Jobs}
              dataColumns={jobColumns}
              dataGetter={getJobs}
              defaultColumnsVisability={jobColumnsVisability}
              defaultSort={"-startedAt"}
              refreshPoint={refreshPoint}
              serviceType={"jobs"}
            />
          ) : (
            <Typography>Empty Log...</Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {tabValue == 2 && (
            <Box gap="1rem" display={"flex"} justifyContent={"flex-end"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOld}
                    onChange={toggleOld}
                    inputProps={{ "aria-label": "toggleOld" }}
                  />
                }
                label="Show All Targets"
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleAddRow}
              >
                New Target
              </Button>
            </Box>
          )}

          {target_UI ? (
            <TablePage
              masterId={id}
              //masterIdField="implementorId"
              dataRows={targets}
              dataColumns={targetColumns}
              dataGetter={getKpiTargets}
              defaultColumnsVisability={target_UI.columnsVisability}
              defaultSort={"-when"}
              refreshPoint={refreshPoint}
              serviceType={"kpiTargets"}
            />
          ) : (
            <Typography>Loading</Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <StateSetAsConfigProvider id={id} showHidden={false} />
        </TabPanel>
        {/* </Box> */}
      </Box>

      <Popover
        open={openAddRow}
        anchorEl={anchorElAddRow}
        onClose={handelCloseAddRow}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeightAdd} width={areaWidthAdd}>
          <KpiAddTarget
            closerFunc={handelCloseAddRow}
            kpi={Kpi}
            refreshTableFunc={handelRefresh}
            measures={measures}
            dimensions={dimensions}
          />
        </Box>
      </Popover>
    </PageBlock>
  );
};

export default Kpi;
