import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import { addStateSetField, updateStateSetField } from "slices/state";
import { groupIntersectingEntries } from "@fullcalendar/core";

const separateAndTitleCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

const StateSetFieldCreateEdit = ({
  closerFunc,
  stateSetId,
  mode,
  currentNode,
  anchorNode,
  rowSchemaUpdater,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();


  const uiGroups = useSelector((state) => {
    const filteredList = state.stateSet.data.list?.filter(set => set.id === stateSetId);
    return filteredList ? filteredList[0]?.uiGroups?.filter((group) => group.parent !== "Screen")  ?? [] : [];
  });
  

  console.log("Node: ", currentNode);
  console.log("groups for node: ", uiGroups)

  const initialValues = {};

  if (mode === "EDIT") {
    //debugger;

    initialValues.field = anchorNode;
    initialValues.headerName = currentNode.uiHeader;
    initialValues.flex = parseFloat(currentNode.uiSize);

    if (currentNode.uiRenderCell === "") {
      initialValues.renderCell = "DEFAULT";
    } else {
      initialValues.renderCell = currentNode.uiRenderCell;
    }

    initialValues.type = currentNode.bsonType;
    initialValues.purpose = currentNode.purpose;

    if (currentNode.serviceType === "" | !currentNode.serviceType) {
      initialValues.serviceType = "DEFAULT";
    } else {
      initialValues.serviceType = currentNode.serviceType;
    }

    initialValues.serviceImplemenator = currentNode.serviceImplemenator;
    initialValues.serviceDimension = currentNode.serviceDimension;
    initialValues.lookUpLabel = currentNode.lookUpLabel;

    initialValues.sequence = parseInt(currentNode.sequence);
    initialValues.uiGroup = currentNode.uiGroup;
    initialValues.required = currentNode.required;
    initialValues.multiLine = currentNode.multiLine;
    initialValues.rows = currentNode.rows;
    initialValues.helperText = currentNode.helperText;
    initialValues.valueMode = currentNode.valueMode;
    initialValues.recheckDuration = currentNode.recheckDuration;
    initialValues.uiHide = currentNode.uiHide;
    initialValues.description = ""

    if (currentNode.uiSort === "") {
      initialValues.uiSort = "DEFAULT";
    } else {
      initialValues.uiSort = currentNode.uiSort;
    }

    initialValues.makeLink = currentNode.makeLink;
    initialValues.makeLinkBlue = currentNode.makeLinkBlue;
    initialValues.makeLinkIcon = currentNode.makeLinkIcon;
    initialValues.linkAddress = currentNode.linkAddress;
    initialValues.linkLabel = currentNode.linkLabel;
    initialValues.currencyField = currentNode.currencyField;
    initialValues.uiFocusLevel = currentNode.uiFocusLevel;
    initialValues.row = currentNode.row;
    initialValues.uiDetailHide = currentNode.uiDetailHide;

  } else {
    initialValues.field = "";
    initialValues.headerName = "";
    initialValues.flex = "1";
    initialValues.renderCell = "DEFAULT";
    initialValues.type = "string";
    initialValues.purpose = "data";
    initialValues.serviceType = "DEFAULT";
    initialValues.serviceImplemenator = "";
    initialValues.serviceDimension = "";
    initialValues.lookUpLabel = "";
    initialValues.sequence = parseInt(currentNode.sequence) + 1;
    initialValues.required = false;
    initialValues.multiLine = false;
    initialValues.rows = 0;
    initialValues.helperText = "";
    initialValues.valueMode = "User";
    initialValues.recheckDuration = 0;
    initialValues.uiHide = false;
    initialValues.uiSort = "DEFAULT";
    initialValues.uiGroup = "";
    initialValues.makeLink = false;
    initialValues.makeLinkBlue = false;
    initialValues.makeLinkIcon = false;
    initialValues.linkAddress = "";
    initialValues.linkLabel = "";
    initialValues.currencyField = "";
    initialValues.uiFocusLevel = 0;
    initialValues.row = 1;
    initialValues.uiDetailHide = false;
  }

  const renderCells = [
    {
      value: "DEFAULT",
      label: "Default",
    },
    {
      value: "<NiceUser>",
      label: "User",
    },
    {
      value: "<NiceDate>",
      label: "Formated Date & Time",
    },
    {
      value: "<NiceDateOnly>",
      label: "Formated Date",
    },
    {
      value: "<NiceDateUTC>",
      label: "Formated Date & Time in UTC",
    },
    {
      value: "<NiceOrchestrator>",
      label: "Formated Orchestrator",
    },
    {
      value: "<NiceOrganization>",
      label: "Formated Organization",
    },
    {
      value: "<NiceActive>",
      label: "Formated Active, give a tick if true and blank if false",
    },
    {
      value: "<NiceBoolean>",
      label: "Formated Boolean, displayes True or Fakse, also records not set",
    },
    {
      value: "<NiceObjectCell>",
      label:
        "Formated List of values, nice for things like Tags where the number in the object is not know",
    },
    {
      value: "<NiceSubscription>",
      label:
        "Formated Subscription, uses the name in the subscription utility AzureSubscriptions",
    },
    {
      value: "<NiceBillingPeriod>",
      label:
        "Formated Billing Period like YYYY-MM",
    },

    {
      value: "<NiceBillingFolder>",
      label:
        "Formated Billing Folder, holds billing files formated as YYYY-MM HH:mm",
    },
    {
      value: "<NiceCron>",
      label:
        "Formated Cron Schedule",
    },

    {
      value: "<NiceDuration>",
      label:
        "Formated Duration",
    },
    {
      value: "<NiceSize>",
      label:
        "Formated Size in tb,gb,mb,kb,b",
    },

  ];

  const types = [
    {
      value: "string",
      label: "String",
    },
    {
      value: "int64",
      label: "Integer",
    },
    {
      value: "double",
      label: "Floating Point ",
    },
    {
      value: "date",
      label: "Date & Time",
    },
    {
      value: "bool",
      label: "Boolean (True or False)",
    },
  ];

  const purposes = [
    {
      value: "data",
      label: "A field to hold data",
    },
    {
      value: "key",
      label: "Data that defines a key for the set",
    },
  ];

  const valueModes = [
    {
      value: "User",
      label: "A field the user maintaines",
    },
    {
      value: "System",
      label: "A filed the users can't chnage",
    },
  ];

  const uiSorts = [
    {
      value: "DEFAULT",
      label: "Not Sorted",
    },
    {
      value: "desc",
      label: "Decending",
    },
    {
      value: "asc",
      label: "Ascending",
    },
  ];

  const serviceTypes = [
    {
      value: "DEFAULT",
      label: "None",
    },
    {
      value: "kernel",
      label: "Core data from the core of OpsBlox (kernel)",
    },
    {
      value: "blox",
      label: "Data from a Blox",
    },
    {
      value: "kpi",
      label: "Data from a KPI",
    },
    {
      value: "utility",
      label: "Data from a Utility",
    },
    {
      value: "stateSet",
      label: "Data from a State Set",
    },
  ];

  const validationSchema = Yup.object().shape({
    field: Yup.string()
      .required("This field is required!")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Must contain uppercase, lowercase, _ and numbers with no whitespace"
      ),

    headerName: Yup.string().required("This field is required"),
    flex: Yup.number()
      .typeError("Flex must be a number")
      .required("This field is required")
      .min(0, "Flex must be at least 0")
      .max(16, "Flex must be at most 16"),
    sequence: Yup.number().typeError("Flex must be a number"),
    renderCell: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    purpose: Yup.string().required("This field is required"),
    serviceType: Yup.string().required("This field is required"),
    serviceImplemenator: Yup.string().when("serviceType", {
      is: (serviceType) => serviceType !== "DEFAULT",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().nullable(), // No validation when serviceType is 'DEFAULT'
    }),
    serviceDimension: Yup.string().when("serviceType", {
      is: (serviceType) => serviceType !== "DEFAULT",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().nullable(), // No validation when serviceType is 'DEFAULT'
    }),
    lookUpLabel: Yup.string().when("serviceType", {
      is: (serviceType) => serviceType !== "DEFAULT",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().nullable(), // No validation when serviceType is 'DEFAULT'
    }),

    rows: Yup.number().when("multiline", {
      is: true,
      then: Yup.number().required("This field is required"),
      otherwise: Yup.number().nullable(), // No validation when multiline is not true
    }),
  });

  return (
    <Box m="20px">
      <Header
        title={
          mode === "NEW"
            ? `Add field to state set below "${anchorNode}"`
            : `Edit "${anchorNode}"`
        }
      />

      <Formik
        onSubmit={(values, actions) => {
          const field = {
            stateSetId: stateSetId,
            field: { ...values },
          };

          try {
            field.field.uiFocusLevel = field.field.uiFocusLevel.toString();
          } catch (error) {
            console.error("An error occurred while converting uiFocusLevel to string:", error);
            field.field.uiFocusLevel = "1";
          }

          if (field.field.serviceType === "DEFAULT") {
            field.field.serviceType = "";
          }

          if (field.field.renderCell === "DEFAULT") {
            field.field.renderCell = "";
          }

          if (field.field.uiSort === "DEFAULT") {
            field.field.uiSort = "";
          }

          dispatch(clearMessage());

          //debugger;

          if (mode === "NEW") {
            dispatch(addStateSetField({ field }))
              .unwrap()
              .then(() => {
                //debugger;

                dispatch(setMessage("Field Added", "success"));
                closerFunc();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          } else {

            //debugger;

            dispatch(updateStateSetField({ field }))
              .unwrap()
              .then(() => {
                //debugger;

                dispatch(setMessage("Field Updated", "success"));
                closerFunc();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Field Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.field}
                name="field"
                error={!!touched.field && !!errors.field}
                helperText={touched.field && errors.field}
                sx={{ gridColumn: "span 1" }}
                disabled={mode === "EDIT"}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Header Name (Label)"
                onFocus={(e) => {
                  // Check if headerName is blank
                  if (!values.headerName.trim()) {
                    // Copy the value from the field field
                    handleChange({
                      target: {
                        name: "headerName",
                        value: separateAndTitleCase(values.field),
                      },
                    });
                  }
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.headerName}
                name="headerName"
                error={!!touched.headerName && !!errors.headerName}
                helperText={touched.headerName && errors.headerName}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Field Size (In Tables)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.flex}
                name="flex"
                error={!!touched.flex && !!errors.flex}
                helperText={touched.flex && errors.flex}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 16 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Sequence"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sequence}
                name="sequence"
                error={!!touched.sequence && !!errors.sequence}
                helperText={touched.sequence && errors.sequence}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 255 }}
                disabled={true}
              />

              <TextField
              select
                fullWidth
                variant="outlined"
                type="string"
                label="Ui Group"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.uiGroup}
                name="uiGroup"
                error={!!touched.uiGroup && !!errors.uiGroup}
                helperText={touched.uiGroup && errors.uiGroup}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 255 }}
              >

{uiGroups?.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}

                </TextField>

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Ui Focus Level"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.uiFocusLevel}
                name="uiFocusLevel"
                error={!!touched.uiFocusLevel && !!errors.uiFocusLevel}
                helperText={touched.uiFocusLevel && errors.uiFocusLevel}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 0, max: 255 }}
              />

<TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Row"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.row}
                name="row"
                error={!!touched.row && !!errors.row}
                helperText={touched.row && errors.row}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 255 }}
              />

<FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.uiDetailHide}
                    name="uiDetailHide"
                  />
                }
                label="Hide on detail UI"
              />


              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.required}
                    name="required"
                  />
                }
                label="Required"
              />

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Render as"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.renderCell}
                name="renderCell"
                error={!!touched.renderCell && !!errors.renderCell}
                helperText={touched.renderCell && errors.renderCell}
                sx={{ gridColumn: "span 1" }}
              >
                {renderCells.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Currency Field to use"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.currencyField}
                name="currencyField"
                error={!!touched.currencyField && !!errors.currencyField}
                helperText={touched.currencyField && errors.currencyField}
                sx={{ gridColumn: "span 1" }}
              />
                

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Field Type"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value !== "string") {
                    setFieldValue("multiline", false);
                  }
                }}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 1" }}
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        setFieldValue("rows", 1);
                      } else {
                        setFieldValue("rows", 0);
                      }
                    }}
                    checked={values.multiLine}
                    name="multiLine"
                    disabled={values.type !== "string"}
                  />
                }
                label="Multi Line"
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Rows"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rows}
                name="rows"
                error={!!touched.rows && !!errors.rows}
                helperText={touched.rows && errors.rows}
                sx={{ gridColumn: "span 1" }}
                disabled={values.multiLine != true}
                inputProps={{ min: 1, max: 20 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={values.uiHide}
                    name="uiHide"
                  />
                }
                label="Hide"
              />

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Sort Field"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.uiSort}
                name="uiSort"
                error={!!touched.uiSort && !!errors.uiSort}
                helperText={touched.uiSort && errors.uiSort}
                sx={{ gridColumn: "span 1" }}
              >
                {uiSorts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Field Purpose"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.purpose}
                name="purpose"
                error={!!touched.purpose && !!errors.purpose}
                helperText={touched.purpose && errors.purpose}
                sx={{ gridColumn: "span 1" }}
              >
                {purposes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Lookup type"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value === "DEFAULT") {
                    setFieldTouched("serviceImplemenator", false);
                    setFieldError("serviceImplemenator", false);
                    setFieldTouched("serviceDimension", false);
                    setFieldError("serviceDimension", false);
                    setFieldTouched("lookuplabel", false);
                    setFieldError("lookuplabel", false);
                  }
                }}
                value={values.serviceType}
                name="serviceType"
                error={!!touched.serviceType && !!errors.serviceType}
                helperText={touched.serviceType && errors.serviceType}
                sx={{ gridColumn: "span 1" }}
              >
                {serviceTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                // select
                fullWidth
                variant="outlined"
                type="text"
                label="Lookup Object"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serviceImplemenator}
                name="serviceImplemenator"
                error={
                  !!touched.serviceImplemenator && !!errors.serviceImplemenator
                }
                helperText={
                  touched.serviceImplemenator && errors.serviceImplemenator
                }
                sx={{ gridColumn: "span 1" }}
                disabled={values.serviceType === "DEFAULT"}
              >
                {/* {ServiceImplemenators.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>

              <TextField
                // select
                fullWidth
                variant="outlined"
                type="text"
                label="Lookup Field"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serviceDimension}
                name="serviceDimension"
                error={!!touched.serviceDimension && !!errors.serviceDimension}
                helperText={touched.serviceDimension && errors.serviceDimension}
                sx={{ gridColumn: "span 1" }}
                disabled={values.serviceType === "DEFAULT"}
              >
                {/* {serviceDimensions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>

              <TextField
                // select
                fullWidth
                variant="outlined"
                type="text"
                label="Lookup Label"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lookUpLabel}
                name="lookUpLabel"
                error={!!touched.lookUpLabel && !!errors.lookUpLabel}
                helperText={touched.lookUpLabel && errors.lookUpLabel}
                sx={{ gridColumn: "span 1" }}
                disabled={values.serviceType === "DEFAULT"}
              >
                {/* {serviceDimensions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>


              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Helper text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.helperText}
                name="helperText"
                error={!!touched.helperText && !!errors.helperText}
                helperText={touched.helperText && errors.helperText}
                sx={{ gridColumn: "span 1" }}
              />

<TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Field Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Input Mode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.valueMode}
                name="valueMode"
                error={!!touched.valueMode && !!errors.valueMode}
                helperText={touched.valueMode && errors.valueMode}
                sx={{ gridColumn: "span 1" }}
              >
                {valueModes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={values.makeLink}
                    name="makeLink"
                  />
                }
                label="Display as a Link"
              />

<FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={values.makeLinkIcon}
                    name="makeLinkIcon"
                    disabled={!values.makeLink}
                  />
                }
                label="Add a follow icon"
              />

<FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={values.makeLinkBlue}
                    name="makeLinkBlue"
                    disabled={!values.makeLink}
                  />
                }
                label="Change to a highlighted Link"
              />

<TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Link Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.linkAddress}
                name="linkAddress"
                error={!!touched.linkAddress && !!errors.linkAddress}
                helperText={touched.linkAddress && errors.linkAddress}
                sx={{ gridColumn: "span 1" }}
                disabled={!values.makeLink}
              />


<TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Link Label"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.linkLabel}
                name="linkLabel"
                error={!!touched.linkLabel && !!errors.linkLabel}
                helperText={touched.linkLabel && errors.linkLabel}
                sx={{ gridColumn: "span 1" }}
                disabled={!values.makeLink}
              />

            </Box>

            <Box display="flex" justifyContent="end" mt="1rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {mode === "NEW" ? "Add Field" : "Save"}
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default StateSetFieldCreateEdit;
