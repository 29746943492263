import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import CatalogueService from "../services/catalogue.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const deleteCataloguePage = createAsyncThunk(
  "CataloguePage/delete",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CatalogueService.deleteCataloguePage(id);
      return { CataloguePage : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createCatalogue = createAsyncThunk(
  "Catalogue/create",
  async ({ catalogue }, thunkAPI) => {
    try {
      const data = await CatalogueService.createCatalogue(catalogue);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createCatalogueImage = createAsyncThunk(
  "CatalogueImage/create",
  async ({ newImage }, thunkAPI) => {
    try {
      //debugger
      const response = await CatalogueService.createCatalogueImage(newImage);

      const imageData = {
        data: response.data, // Extract the data you need from the response
        status: response.status,
        // Extract other necessary fields...
      };
      
      return { imageData };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const createCataloguePage = createAsyncThunk(
  "cataloguePage/create",
  async ({ cataloguePage }, thunkAPI) => {
    try {
      const data = await CatalogueService.createCataloguePage(cataloguePage);
      const id = cataloguePage.catalogueId
      thunkAPI.dispatch(getCatalogue({id}));
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateCatalogue = createAsyncThunk(
  "Catalogue/update",
  async ({ catalogue }, thunkAPI) => {
    try {
      const data = await CatalogueService.updateCatalogue(catalogue);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCataloguePage = createAsyncThunk(
  "CataloguePage/update",
  async ({ cataloguePage }, thunkAPI) => {
    try {
      const data = await CatalogueService.updateCataloguePage(cataloguePage);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateCataloguePageSequenceParent = createAsyncThunk(
  "CataloguePage/updateSequencePage",
  async ({ cataloguePage }, thunkAPI) => {
    try {
      const data = await CatalogueService.updateCataloguePageSequenceParent(cataloguePage);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getCatalogues = createAsyncThunk(
  "Catalogue/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCatalogues(search);
      return { catalogues: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCatalogue = createAsyncThunk(
  "Catalogue/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCatalogue(id);
      return { catalogue: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCataloguePages = createAsyncThunk(
  "CataloguePage/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCataloguePages(search);
      return { pages: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCataloguePage = createAsyncThunk(
  "CataloguePage/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCataloguePage(id);
      return { page: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCatalogueFilterValues = createAsyncThunk(
  "Catalogue/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCatalogueFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCataloguePageFilterValues = createAsyncThunk(
  "CataloguePage/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCataloguePageFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCatalogueUi = createAsyncThunk(
  "Catalogue/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await CatalogueService.getCatalogueUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCataloguePageUi = createAsyncThunk(
  "CataloguePage/get_ui",
  async (_, thunkAPI) => {
    try {
      const data = await CatalogueService.getCataloguePageUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCataloguePageTypes = createAsyncThunk(
  "CataloguePage/get_types",
  async (_, thunkAPI) => {
    try {
      const data = await CatalogueService.getCataloguePageType();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  catalogue: {data: list , loading: false, Ui: [], filter_values: []},
  page: {data: list , loading: false, Ui: [] ,filter_values: []},
  image: {data: list , loading: false, Ui: [] ,filter_values: []},
  pageTypes: []
}

const CatalogueSlice = createSlice({
  name: "Catalogue",
  initialState,

  extraReducers: {


    [deleteCataloguePage.pending]: (state, action) => {
      state.page.loading = true;
    },
    [deleteCataloguePage.fulfilled]: (state, action) => {
      // Filter out the page that matches the id in the action payload

      //debugger
      const updatedPageList = state.page.data.list.filter((page) => page.id !== action.payload.CataloguePage.id);
      
      // Update the state with the filtered list
      state.page.data.list = updatedPageList;
      
      // Set loading to false
      state.page.loading = false;
    },
    
    [deleteCataloguePage.rejected]: (state, action) => {
      state.data = list;
      state.page.loading = false;
    },



    [createCatalogueImage.pending]: (state, action) => {
      state.image.loading = true;
    },
    [createCatalogueImage.fulfilled]: (state, action) => {

      //debugger
      state.image.data.list.push(action.payload.imageData.data);
      state.image.data.total ++;
      state.image.loading = false;
    },
    [createCatalogueImage.rejected]: (state, action) => {
      state.image.loading = false;
    },


    [getCataloguePageTypes.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCataloguePageTypes.fulfilled]: (state, action) => {
      //debugger;
      
      state.pageTypes = action.payload.data.pageTypes;
      state.catalogue.loading = false;
    },
    [getCataloguePageTypes.rejected]: (state, action) => {

      state.pageTypes = null;
      state.catalogue.loading = false;

      
    },




    [getCatalogueUi.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCatalogueUi.fulfilled]: (state, action) => {

      state.catalogue.Ui = action.payload.ui;
      state.catalogue.loading = false;

      
    },
    [getCatalogueUi.rejected]: (state, action) => {
      state.catalogue.Ui = null;
      state.catalogue.loading = false;
    },
    
    [getCataloguePageUi.pending]: (state, action) => {
      state.page.loading = true;
    },
    [getCataloguePageUi.fulfilled]: (state, action) => {
      state.page.Ui = action.payload.ui;
      state.page.loading = false;
    },
    [getCataloguePageUi.rejected]: (state, action) => {
      state.page.Ui = null;
      state.page.loading = false;
    },

    [getCatalogueFilterValues.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCatalogueFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.catalogue.filter_values = action.payload.filter_values;
      state.catalogue.loading = false;
    },
    [getCatalogueFilterValues.rejected]: (state, action) => {
      state.catalogue.filter_values = list;
      state.catalogue.loading = false;
    },


    [getCataloguePageFilterValues.pending]: (state, action) => {
      state.page.loading = true;
    },
    [getCataloguePageFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.page.filter_values = action.payload.filter_values;
      state.page.loading = false;
    },
    [getCataloguePageFilterValues.rejected]: (state, action) => {
      state.page.filter_values = list;
      state.page.loading = false;
    },


    [createCatalogue.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [createCatalogue.fulfilled]: (state, action) => {

      state.catalogue.data.list.push(action.payload.data.data);
      state.catalogue.data.total ++;
      state.catalogue.loading = false;
    },
    [createCatalogue.rejected]: (state, action) => {
      state.catalogue.loading = false;
    },



    [createCataloguePage.pending]: (state, action) => {

      state.page.loading = true;
    },
    [createCataloguePage.fulfilled]: (state, action) => {

      state.page.data.list.push(action.payload.data.data);
      state.page.data.total ++;
      state.page.loading = false;
    },
    [createCataloguePage.rejected]: (state, action) => {
      state.page.loading = false;
    },



    [getCatalogues.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCatalogues.fulfilled]: (state, action) => {
      state.catalogue.data = action.payload.catalogues;
      state.catalogue.loading = false;
    },
    [getCatalogues.rejected]: (state, action) => {
      state.catalogueData.data = list;
      state.catalogue.loading = false;
    },



    [getCataloguePages.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCataloguePages.fulfilled]: (state, action) => {
      state.page.data = action.payload.pages;
      state.catalogue.loading = false;
    },
    [getCataloguePages.rejected]: (state, action) => {
      state.page.data = list;
      state.catalogue.loading = false;
    },



    [updateCatalogue.fulfilled]: (state, action) => {

      const updatedCatalogue = state.catalogue.data.list.map((Catalogue) => {
        if (Catalogue.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Catalogue;
        }
      });
      state.catalogue.data.list = updatedCatalogue;
    },

    [updateCataloguePage.fulfilled]: (state, action) => {

      const updatedPage = state.page.data.list.map((page) => {
        if (page.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return page;
        }
      });
      state.page.data.list = updatedPage;
    },

    [updateCataloguePageSequenceParent.pending]: (state, action) => {
      state.catalogue.loading = true;
    },

    [updateCataloguePageSequenceParent.fulfilled]: (state, action) => {

    
      // Create a map of updates for quick lookup
      const updateMap = new Map(action.payload.data.updates.map(update => [update.id, update]));
    
    
      // Update the page list in a single pass
      state.page.data.list = state.page.data.list.map((page) => {
        const update = updateMap.get(page.id);
        
        if (update) {
          return {
            ...page,
            sequence: update.sequence,
            parentPage: update.parentPage,
          };
        }
        
        return page;
      });
    
      // Sort the updated list by `sequence`
  state.page.data.list.sort((a, b) => a.sequence - b.sequence);
  
      state.catalogue.loading = false;
    },
    
    
    
    [updateCataloguePageSequenceParent.rejected]: (state, action) => {
      state.catalogue.loading = false;
    },


    [getCatalogue.pending]: (state, action) => {
      state.catalogue.loading = true;
    },
    [getCatalogue.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.catalogue;

      const existingIndex = state.catalogue.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.catalogue.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.catalogue.data.list.push(newPayload);
      }


      state.catalogue.loading = false;
    },
    [getCatalogue.rejected]: (state, action) => {
      state.catalogue.loading = false;
    },


    
    [getCataloguePage.pending]: (state, action) => {
      state.page.loading = true;
    },
    [getCataloguePage.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.page;

      const existingIndex = state.page.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.page.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.page.data.list.push(newPayload);
      }


      state.pageloading = false;
    },
    [getCataloguePage.rejected]: (state, action) => {
      state.pageloading = false;
    },



  },
});

const { reducer } = CatalogueSlice;
export default reducer;
