import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Chip,
  Link,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridPagination,
  GridCellModesModel,
  GridCellModes,
  GridToolbarExport,
  GridColumnsPanel,
  GridColumnsMenuItem,
} from "@mui/x-data-grid";

import {
  ArrowDropDown,
  Bolt,
  RefreshOutlined,
  RestartAltOutlined,
  StopCircleOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import NiceDuration from "components/global/NiceDuration";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "slices/job";
import { setMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";

import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import KeyVal from "components/global/KeyVal";
import NiceLink from "components/global/NiceLink";
import DataGridCustomColumnMenu from "components/DataGridCustomColumnMenu";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceUser from "components/global/NiceUser";

const Jobs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.job.data);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh =() =>{

    setRefreshPoint(now())
  }

  const columnsVisability = {
    organization: false,
    orchestrator: false,
    modifiedAt: false,
    jobOriginId: false,
  }

  const columns = [
    {
      field: "id",
      headerName: "Job Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <NiceLink label={id} link={`/job/${id}`} tip="Open Job" makeBlue />
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: .5,
    },
    {
      field: "jobOrigin",
      headerName: "Origin",
      flex: .5,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      flex: 1,
      renderCell: ({ row: { submittedBy } }) => {
        return <NiceUser>{submittedBy}</NiceUser>;
      },
    },
    {
      field: "implementorId",
      headerName: "Run by",
      flex: 1,
      renderCell: ({ row: { id, implementorId, name, type, jobOriginId, args } }) => {

        if(type === "kernel"){

          if (implementorId === "backup"){
            return (
              <NiceLink
                label={name}
                link={`/backup/${args}`}
                tip={`Details`}
                makeBlue
              />
            );
          }

          if (implementorId === "backupHouseKeeping"){
            return (
              <NiceLink
                label={name}
                link={`/job/${id}`}
                tip={`Details`}
                makeBlue
              />
            );
          }

          return (
            <NiceLink
              label={name}
              link={`/${implementorId}`}
              tip={`Details`}
              makeBlue
            />
          );

        }

        if(type === "copilot"){

          return (
            <NiceLink
              label={jobOriginId}
              link={`/copilot/prompt/${args}`}
              tip={`Details for prompt`}
              makeBlue
            />
          );

        }

        if(type === "search"){

          return (
            <NiceLink
              label={jobOriginId}
              link={`/search_prompt/${args}`}
              tip={`Details for search`}
              makeBlue
            />
          );

        }


        return (
          <NiceLink
            label={name}
            link={`/${type}/${implementorId}`}
            tip={`${type} Details`}
            makeBlue
          />
        );
      },
    },
    {
      field: "jobOriginId",
      headerName: "Origin Id",
      flex: .5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: .8,
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: ({ row: { createdAt } }) => {
        return <NiceDate>{createdAt}</NiceDate>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Update",
      flex: .8,
      type: "date",
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
    {
      field: "startedAt",
      headerName: "Started",
      flex: .8,
      type: "date",
      renderCell: ({ row: { startedAt } }) => {
        return <NiceDate>{startedAt}</NiceDate>;
      },
    },
    {
      field: "stoppedAt",
      headerName: "Stopped",
      flex: .8,
      type: "date",
      renderCell: ({ row: { stoppedAt } }) => {
        return <NiceDate>{stoppedAt}</NiceDate>;
      },
    },
    {
      field: "duration",
      headerName: "Run Time",
      flex: .8,
      type: "number",
      renderCell: ({ row: { duration } }) => {
        return <NiceDuration>{duration}</NiceDuration>;
      },
    },
    {
      field: "itemsProcessed",
      headerName: "Items Processed",
      flex: .7,
      type: "number",
    },
    {
      field: "args",
      headerName: "Job Args",
      flex: 1,
    },
    

  
  ];

  return (
    <PageBlock>
      <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="Jobs" subtitle="" count={rows.total} />
        <FlexBetween gap=".5rem">

          <Button variant="outlined" color="primary" onClick={handelRefresh} >
            <RefreshOutlined />
          </Button>

          <Button
            variant="outlined"
            color="primary"
            endIcon={<ArrowDropDown />}
          >
            Actions
          </Button>
        </FlexBetween>
      </FlexBetween>

      <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getJobs}
          defaultColumnsVisability={columnsVisability}
          defaultSort={"-createdAt"}
          //initialFilter="[status]=Pending"
          refreshPoint={refreshPoint}
          serviceType={"job"}
        />
      
    </PageBlock>
  );
};

export default Jobs;
