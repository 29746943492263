import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const NiceBillingFolder = (props) => {

  let period = props.children;

  const str = period.toString();

  //debugger;

// Grab the first 4 characters
const year = str.substring(0, 4);
const month = str.substring(4,6)
const day = str.substring(6,8)
const time = str.substring(8,10) + ":" + str.substring(10,12)

const NicePeriod = year + "-" + month + "-" + day

  return (
    <Box>
    {NicePeriod} {time}
    </Box>
  );
};

export default NiceBillingFolder;
