import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Switch,
  useTheme,
  Slider,
  Tabs,
  Tab,
} from "@mui/material";

import { clearMessage } from "slices/message";
import DetailBlock from "./DetailBlock";
import { ColorModeContext } from "theme";
import { updatePreferenceByUser } from "slices/auth";
import TabPanel from "./TabPanel";
import ObjectDetails from "./ObjectDetails";
import { now } from "moment";

const NavBarPreferences = ({ copilotOpenSetter, copilotSizeSetter }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user: currentUser } = useSelector((state) => state.auth);

  const showHiddenFields = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "showHidden"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  const [showHidden, setShowHidden] = React.useState(showHiddenFields);

  const toggleHidden = (event) => {
    setShowHidden(event.target.checked);
    //now update the user preference

    dispatch(clearMessage());

    const prefValue = event.target.checked ? "true" : "false";
    const preference = {
      preference_value: prefValue,
      name: "showHidden",
      username: currentUser.user.username,
    };
    dispatch(updatePreferenceByUser({ preference }));
  };

  const showTechnicalNames = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "showTechnicalNames"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  const [showTechnical, setShowTechnical] = React.useState(showTechnicalNames);

  const toggleTechnical = (event) => {
    setShowTechnical(event.target.checked);
    //now update the user preference

    dispatch(clearMessage());

    const prefValue = event.target.checked ? "true" : "false";
    const preference = {
      preference_value: prefValue,
      name: "showTechnicalNames",
      username: currentUser.user.username,
    };
    dispatch(updatePreferenceByUser({ preference }));
  };

  const copilotMode = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "copilotOpen"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  const [copilotOpen, setCopilotOpen] = React.useState(copilotMode);

  const copilotSize = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "copilotSize"
    )?.preferenceValue;

    return preference ? preference : "25";
  });

  const [copilotSizeLocal, setCopilotSizeLocal] = React.useState(copilotSize);

  const currentMode = theme.palette.mode === "dark" ? true : false;

  const [darkMode, setDarkMode] = React.useState(currentMode);
  const colorMode = useContext(ColorModeContext);

  const toggleDarkMode = (event) => {
    setDarkMode(event.target.checked);

    //first toggle the mode
    colorMode.toggleColorMode();

    //now update the user preference

    dispatch(clearMessage());

    const preference = {
      preference_value: theme.palette.mode === "light" ? "dark" : "light",
      name: "useDarkMode",
      username: currentUser.user.username,
    };
    dispatch(updatePreferenceByUser({ preference }));
  };

  const toggleCopilot = (event) => {
    setCopilotOpen(event.target.checked);
    copilotOpenSetter(event.target.checked);

    dispatch(clearMessage());

    const preference = {
      preference_value: event.target.checked === true ? "true" : "false",
      name: "copilotOpen",
      username: currentUser.user.username,
    };
    dispatch(updatePreferenceByUser({ preference }));
  };

  const handelChangeCopilotSize = (event) => {
    setCopilotSizeLocal(event.target.value);
    copilotSizeSetter(String(event.target.value));
  };

  const MAX = 80;
  const MIN = 15;
  const marks = [
    {
      value: MIN,
      label: "",
    },
    {
      value: MAX,
      label: "",
    },
  ];

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  return (
    <Box m="20px">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Settings Tabs"
      >
        <Tab label="User Preferences" {...a11yProps(0)} />
        <Tab label="Defaults" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <DetailBlock mb={"1rem"} mt="1rem">
          <Stack direction={"column"} ml={"1rem"}>
            <Typography
              variant="h4"
              color={theme.palette.text.main}
              fontWeight="bold"
              sx={{ mb: ".4Rem", mt: ".2rem" }}
            >
              Display
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  inputProps={{ "aria-label": "toggleDarkMode" }}
                />
              }
              label="Use Dark Mode"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showHidden}
                  onChange={toggleHidden}
                  inputProps={{ "aria-label": "toggleHidden" }}
                />
              }
              label="Show Hidden Fields"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showTechnical}
                  onChange={toggleTechnical}
                  inputProps={{ "aria-label": "toggleTechnical" }}
                />
              }
              label="Show Technical Field names"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={copilotOpen}
                  onChange={toggleCopilot}
                  inputProps={{ "aria-label": "toggleCopilot" }}
                />
              }
              label="Work with Copilot"
            />

            <Typography>Copilot Size</Typography>
            <Slider
              value={copilotSizeLocal}
              onChange={handelChangeCopilotSize}
              inputProps={{ "aria-label": "copilotSize" }}
              marks={marks}
              step={5}
              valueLabelDisplay="auto"
              min={MIN}
              max={MAX}
            />
          </Stack>
        </DetailBlock>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <DetailBlock mb={"1rem"}>
          <Stack direction={"column"} ml={"1rem"}>
            <Typography
              variant="h4"
              color={theme.palette.text.main}
              fontWeight="bold"
              sx={{ mb: ".4Rem", mt: ".2rem" }}
            >
              Defaults
            </Typography>
            {/* <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={toggleDarkMode}
                inputProps={{ "aria-label": "toggleDarkMode" }}
              />
            }
            label="Dark Mode"
          /> */}
          </Stack>
        </DetailBlock>
      </TabPanel>
    </Box>
  );
};

export default NavBarPreferences;
