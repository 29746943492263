import {
  Typography,
  Box,
  useTheme,
  Snackbar,
  Button,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { clearMessage, setMessage } from "../../slices/message";

const MessageArea = (props) => {
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //   const action = (
  //     <React.Fragment>
  //       <Button color="primary" size="small" onClick={handleClose}>
  //         UNDO
  //       </Button>
  //       <IconButton
  //         size="small"
  //         aria-label="close"
  //         color="inherit"
  //         onClick={handleClose}
  //       >
  //         <CloseIcon fontSize="small" />
  //       </IconButton>
  //     </React.Fragment>
  //   );

  useEffect(() => {
    if (message.message != null && message.message !== "") {
      setOpen(true);
    }
  }, [message]);

  //debugger;

  if (message.message != null && message.message !== "") {
    //debugger;

    let messageType = "error"; //default

    if (message.type != null || message.type !== "") {
      //debugger;
      messageType = message.type;
    }

    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    );
  }
};

export default MessageArea;
