import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  validateYupSchema,
  useField,
} from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { createWorkflowStep, updateWorkflowStep } from "slices/worklow_step";
import { SaveOutlined } from "@mui/icons-material";
import { getCustomerNetwork } from "slices/customer_network";

const StepConfig = ({ data, closer, wfStep }) => {
  const dispatch = useDispatch();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const selectedNode = useSelector((state) => state.workflowUi.selectedNode);
  const customerNetwork = useSelector((state) => state.customerNetwork.data);

  const [network, setNetwork] = useState([]);

  useEffect(() => {
    //create the list for the combobox

    if (customerNetwork?.total > 0) {
      const newList = customerNetwork.list.map((item) => ({
        value: item.role,
        label: item.userId != "" ? item.role + ' (' + item.userId + ')' : item.role
      }));

      setNetwork(newList);
    }

    if (customerNetwork.total < 1) {
      const search = {};

      search.page = 0;
      search.page_size = 1000;
      search.filter = "";
      search.sort = "role";

      dispatch(getCustomerNetwork({ search }));
    }
  }, [customerNetwork]);

  const initialValues = {
    stepName: data.name,
    stepDescription: data.description,
    stepNote: data.note,
    role: data.role,
  };

  const validationSchema = Yup.object().shape({
    stepName: Yup.string()
      .required("This field is required!")
      .min(6, "Must be a min of 6 chars"),


    role: Yup.string().required('Role is required'),

  });

  return (
    <Box mt="1rem">
      <Formik
        onSubmit={(values, actions) => {
          let step = {
            workflow_id: data.workflow_id,
            name: values.stepName,
            description: values.stepDescription,
            type: data.type,
            implementor: data.implementor,
            implementorId: data.implementorId,
            sequence: selectedNode - 1,
            note: values.stepNote,
            id: data.stepId,
            role: values.role,
          };

          if (wfStep?.type == "Selecting" || wfStep == undefined) {
            //new one so do a create

            dispatch(createWorkflowStep({ step }))
              .unwrap()
              .then(() => {
                closer();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          } else {
            //its an update
            dispatch(updateWorkflowStep({ step }))
              .unwrap()
              .then(() => {
                closer();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Step Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stepName}
                name="stepName"
                error={!!touched.stepName && !!errors.stepName}
                helperText={touched.stepName && errors.stepName}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                multiline
                maxRows={4}
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stepDescription}
                name="stepDescription"
                error={!!touched.stepDescription && !!errors.stepDescription}
                helperText={touched.stepDescription && errors.stepDescription}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                multiline
                maxRows={4}
                label="Note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stepNote}
                name="stepNote"
                error={!!touched.stepNote && !!errors.stepNote}
                helperText={touched.stepNote && errors.stepNote}
                sx={{ gridColumn: "span 1" }}
              />

              <Autocomplete
                id="role-autocomplete"
                options={network}

                onChange={(e, value) =>
                  setFieldValue("role", value?.value || "")
                }
                //getOptionLabel={(option) => option.label}

                value={values.role}
                onBlur={handleBlur}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.role && errors.role)}
                    fullWidth
                    helperText={touched.role && errors.role}
                    label="Role"
                    name="role"
                    variant="outlined"
                    //value={network.find(option => option.value === values.role)?.label || ''}
                  />
                )}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={2}>
                {/* <Button
                  type="button"
                  color="error"
                  variant="contained"
                  //onClick={closerFunc}
                >
                  Cancel
                </Button> */}

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveOutlined />}
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Update Step
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default StepConfig;
