import api from "./api";

const getDimenionValues = (search) => {

  return api.get("/dimensions",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};





const DimensionSevice = {
  getDimenionValues,
};

export default DimensionSevice;