import KeyVal from "components/global/KeyVal"
import cronstrue from 'cronstrue';

const NiceCron = ({cron_string,show_original,label}) => {

  let cron = cron_string
  let human = "calculating"

  if (cron != undefined){
    human = cronstrue.toString(cron)
  }
  
  let val = ""
  
  if (show_original){
    val = human + "( " + cron + ")"
  }else{
    val = human
  }
    
return (

  <KeyVal label = {label} value = {val}  >
    {label} 
  </KeyVal>
  )
}

export default NiceCron;