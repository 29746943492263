import { Box, Button, Popover, Typography } from "@mui/material";

import {
  RefreshOutlined,
  DeleteOutline,
  CheckOutlined,
  CloseOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";

import Header from "components/global/Header";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getStateSets, getStateSetServiceUi, getStateUi, updateStateSetActive } from "slices/state";
import { clearMessage } from "slices/message";

import { useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";
import StateSetCreateEdit from "components/state/StateSetCreateEdit";
import { renderColumn } from "common/renderColumn";
import { processString } from "common/helpers";

const StateSets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.stateSet.data);

  const ui = useSelector((state) => state.stateSet.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getStateSetServiceUi({}));
  }, [rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [createEditMode, setCreateEditMode] = useState("NEW");

  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    //debugger;

    setCreateEditMode("NEW");

    setAnchorEl(configRef.current);
  };

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleActivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const stateSet = { id: item, active: true };

      dispatch(updateStateSetActive({ stateSet }));
    });
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const stateSet = { id: item, active: false };

      dispatch(updateStateSetActive({ stateSet }));
    });
  };

  const handleOpen = () => {
    navigate("/state/" + selectionModel[0]);
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon: <CheckOutlined />,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon: <CloseOutlined />,
      callBack: handleDectivate,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: "",
    },
  ];

  let columns = [];

  if (ui?.columns) {
    columns = [...ui.columns]
      .sort((a, b) => (Number(a.sequence) > Number(b.sequence) ? 1 : -1))
      .map((column) => {
        const updatedColumn = { ...column }; // create a copy of the original object

        //debugger;

        if (updatedColumn.makeLink) {
          //modify to wrap in a nice link

          const originalRenderCell = updatedColumn.renderCell;

          updatedColumn.renderCell = ({ row }) => {
            const labelToUse =
              originalRenderCell !== ""
                ? renderColumn(column, row)
                : processString(updatedColumn.linkLabel, row);

            return (
              <NiceLink
                label={labelToUse}
                link={processString(updatedColumn.linkAddress, row)}
                tip={processString(updatedColumn.helperText, row)}
                makeBlue={updatedColumn.makeLinkBlue}
                showFollowIcon={updatedColumn.makeLinkIcon}
              />
            );
          };
        } else {
          if (updatedColumn.renderCell !== "") {
            // check if renderCell exists
            updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
          }
        }

        return updatedColumn;
      });
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <StateSetCreateEdit
            closerFunc={handelCloseAddEdit}
            //stateSet = {stateSet}
            mode={createEditMode}
          />
        </Box>
      </Popover>

      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="State Sets" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handelOpenAddEdit}
            >
              New State Set
            </Button>
          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (

          
        <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getStateSets}
          defaultColumnsVisability={ui.columnsVisability}
          defaultSort={ui.sort}
          refreshPoint={refreshPoint}
          selectionModel={selectionModel}
          selectionSetter={setSelectionModel}
          serviceType={"stateSet"}
        />

      ) : (
        <Typography>Loading</Typography>
      )}

      </PageBlock>
    </>
  );
};

export default StateSets;
