import {
  FlagOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlaylistAddCheckOutlined,
  VerticalAlignBottomOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import { getBlox } from "slices/blox";
import { getUtilities, getUtility } from "slices/utility";
import { getWorkflowContribution } from "slices/worklow_contribution";
import FlowNode from "./FlowNode";

const WorkflowStep = memo(({ data, isConnectable }) => {

  const dispatch = useDispatch();

  const contriubtions = useSelector(
    (state) => state.workflowContributions.data.list
  );

  const blox = useSelector((state) => state.blox.data.list);

  const utilities = useSelector((state) => state.utility.data.list);

  let icon = "";
  const [name, setName] = useState("Loading");

  useEffect(() => {

    if (data.type == "WorkflowContribution") {
      icon = <PlaylistAddCheckOutlined />;
  
      let newName = contriubtions.find((contribution) => contribution.id === data.implementorId)
  
      //debugger;
  
      if(newName == undefined )
      {
        dispatch(getWorkflowContribution({id:data.implementorId}))
      }else{
        setName(newName.name);
      }
    }

    if (data.type == "Utility") {
      icon = <FlagOutlined />;

      let newName = utilities.find((contribution) => contribution.id === data.implementorId)
  
      //debugger;
  
      if(newName == undefined )
      {
        dispatch(getUtility({id:data.implementorId}))
      }else{
        setName(newName.name);
      }

    }
  
    if (data.type == "Blox") {
      icon = <WidgetsOutlined />;

      let newName = blox.find((contribution) => contribution.id === data.implementorId)
  
      //debugger;
  
      if(newName == undefined )
      {
        dispatch(getBlox({id:data.implementorId}))
      }else{
        setName(newName.name);
      }


    }

    if (data.type == "Selecting") {
      icon = <VerticalAlignBottomOutlined />;
      setName("");
    }


  }, [contriubtions, blox,utilities]);


  

  

  return (
    <FlowNode
      icon={icon}
      isConnectable
      type="default"
      id={data.id}
      stepId={data.stepId}
      nodeType={data.type}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h5">{data.name}</Typography>
        <Typography noWrap overflow="hidden" textOverflow="ellipsis">
          {name}
        </Typography>

        <Typography sx={{ fontStyle: "italic" }}>{data.note}</Typography>
      </Box>
    </FlowNode>
  );
});

export default WorkflowStep;
