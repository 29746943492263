import api from "./api";



const updateKpi = (data) => {
  return api.patch("/kpi",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const updateKpiActive = (data) => {
  return api.patch("/kpi/active",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};



const getKpis = (search) => {

  return api.get("/kpi",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getKpiCurrentTargets = (search) => {

  return api.get("/kpi/current_target",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getKpiTargets = (search) => {

  return api.get("/kpi/target",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getKpi = (id) => {
  return api.get("/kpi/"+id)
  .then((response) => {
    return response.data;
  });
};

const getKpiTarget = (id) => {
  return api.get("/kpi/target/"+id)
  .then((response) => {
    return response.data;
  });
};

const getKpiUi = (id) => {
  return api.get("/kpi/ui/"+id)
  .then((response) => {
    return response.data;
  });
};

const getKpiTargetUi = (id) => {
  return api.get("/kpi/ui_target/"+id)
  .then((response) => {
    return response.data;
  });
};

const getKpiData = (search) => {

  return api.get("/kpi/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};


const getKpiFilterValues = (search) => {

  return api.get("/kpi/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getKpiAggregateValues = (search) => {

  return api.get("/kpi/aggregatevalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};


const runKpi = (id) => {
  return api.get("/kpi/run/"+id)
  .then((response) => {
    return response.data;
  });
};

const addKpiTarget = (row) => {
  return api.post("/kpi/target", {
    ...row,
  }).then((response) => {
    return response.data;
  });
};

const KpiSevice = {
  getKpis,
  updateKpi,
  getKpi,
  updateKpiActive,
  getKpiData,
  getKpiUi,
  runKpi,
  getKpiFilterValues,
  getKpiAggregateValues,
  getKpiTarget,
  getKpiTargets,
  addKpiTarget,
  getKpiTargetUi,
  getKpiCurrentTargets,
};

export default KpiSevice;