import { Box, Typography, Button, Tab, Tabs } from "@mui/material";

import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSchedule,
  getSchedule,
  updateScheduleActive,
} from "slices/schedule";
import { clearMessage } from "slices/message";
import Header from "components/global/Header";
import { getJobs } from "slices/job";
import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  RefreshOutlined,
} from "@mui/icons-material";
import NiceCron from "components/global/NiceCron";
import NiceLink from "components/global/NiceLink";
import KeyVal from "components/global/KeyVal";
import TablePage from "components/global/TablePage";
import TabPanel from "components/global/TabPanel";
import { now } from "moment";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import ConfirmationDialog from "components/global/ConformationDialogue";

const Schedule = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const configRef = React.useRef(null);

  const navigate = useNavigate();

  const schedule = useSelector((state) =>
    state.schedules.data.list?.find((schedule) => schedule.id === id)
  );

  const Jobs = useSelector((state) => state.job.data);

  const [tabValue, setTabValue] = React.useState(0);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!Schedule) {
      //need to get the Schedule form the API
      dispatch(getSchedule({ id }));
    }
  }, [Schedule]);

  const handleActivate = () => {
    dispatch(clearMessage());
    const schedule = { id: id, active: true };
    dispatch(updateScheduleActive({ schedule }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const schedule = { id: id, active: false };
    dispatch(updateScheduleActive({ schedule }));
  };

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    setOpenDialog(false); // Close the dialog first

    dispatch(deleteSchedule({ id }));  // Perform the delete operation
    navigate("/schedule"); // Navigate to the schedule page
  };

  const jobColumnsVisability = {
    organization: false,
    orchestrator: false,
    modifiedAt: false,
  };

  const jobColumns = [
    {
      field: "id",
      headerName: "Job Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <NiceLink label={id} link={`/job/${id}`} tip="Open Job" makeBlue />
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "jobOrigin",
      headerName: "Origin",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: ({ row: { createdAt } }) => {
        return <NiceDate>{createdAt}</NiceDate>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Update",
      flex: 1,
      type: "date",
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
    {
      field: "startedAt",
      headerName: "Started",
      flex: 1,
      type: "date",
      renderCell: ({ row: { startedAt } }) => {
        return <NiceDate>{startedAt}</NiceDate>;
      },
    },
    {
      field: "stoppedAt",
      headerName: "Stopped",
      flex: 1,
      type: "date",
      renderCell: ({ row: { stoppedAt } }) => {
        return <NiceDate>{stoppedAt}</NiceDate>;
      },
    },
    {
      field: "duration",
      headerName: "Run Time",
      flex: 1,
      type: "number",
      renderCell: ({ row: { duration } }) => {
        return <NiceDuration>{duration}</NiceDuration>;
      },
    },
    {
      field: "itemsProcessed",
      headerName: "Items Processed",
      flex: 1,
      type: "number",
    },
  ];

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs
          lastOverride={schedule?.name ? schedule?.name : id}
        />
        <FlexBetween>
          <Header title={schedule?.name} subtitle={schedule.id} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>

            {!schedule?.active && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CheckOutlined />}
                onClick={handleActivate}
              >
                Activate
              </Button>
            )}
            {schedule?.active && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseOutlined />}
                onClick={handleDectivate}
              >
                Deactivate
              </Button>
            )}

            <Button
              variant="outlined"
              color="primary"
              startIcon={<DeleteOutline />}
              onClick={handleDelete}
            >
              Delete
            </Button>

            <Box ref={configRef} />
          </FlexBetween>
        </FlexBetween>

        <DetailBlock>
          <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
            <Typography>Schedule Type: {schedule?.type}</Typography>
            <Typography>
              Created: <NiceDate>{schedule?.createdAt}</NiceDate>
            </Typography>
            <Typography>
              Valid From: <NiceDate>{schedule?.validFrom}</NiceDate>
            </Typography>
            <Typography>
              Valid To: <NiceDate>{schedule?.validTo}</NiceDate>
            </Typography>

            <Typography>
              Last updated: <NiceDate>{schedule?.modifiedAt}</NiceDate>
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
            <KeyVal
              label="Implemenator"
              value={schedule?.implementor}
              link={
                schedule?.type === "utility"
                  ? `/utility/${schedule?.implementor}`
                  : schedule?.type === "blox"
                  ? `/blox/${schedule?.implementor}`
                  : `/kpi/${schedule?.implementor}`
              }
              makeBlue
            />

            <Typography>
              <KeyVal
                label="Active"
                value={schedule?.active ? "Yes" : "Not Running"}
                tip="Enable the utility if you want it to run"
              />
            </Typography>
            <Typography>Implemented By: {schedule?.implementor}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
            <NiceCron
              cron_string={schedule?.cron}
              show_original={true}
              label="Cron"
            />
            <Typography>Args: {schedule?.args}</Typography>
          </Box>
        </DetailBlock>

        <Box mt="20px" sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Schedule Tabs"
            >
              <Tab label="Data" {...a11yProps(0)} />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            {Jobs ? (
              <TablePage
                masterId={id}
                masterIdField="jobOriginId"
                dataRows={Jobs}
                dataColumns={jobColumns}
                dataGetter={getJobs}
                defaultColumnsVisability={jobColumnsVisability}
                defaultSort={"-startedAt"}
                refreshPoint={refreshPoint}
                leadObject={"Jobs"}
              />
            ) : (
              <Typography>Empty Log...</Typography>
            )}
          </TabPanel>
        </Box>
      </PageBlock>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Deletion"
        content={`Are you sure you want to delete ${
          schedule.name ? schedule.name : schedule.id
        }  ?`}
        confirmeColour="error"
      />
    </>
  );
};

export default Schedule;
