
import Header from "../../components/global/Header";

import Typography from "@mui/material/Typography";
import PageBlock from "components/global/PageBlock";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getThisOrchestrator } from "slices/orchestrator";

const Status = () => {


  const dispatch = useDispatch();

  const orchestrator = useSelector((state) =>
    state.orchestrators.thisOrchestrator
  );

  //console.log("Orchestrator: " , orchestrator)

  useEffect(() => {
    if (!orchestrator.version) {
      //need to get the Orchestrator form the API
      dispatch(getThisOrchestrator());
    }

  }, [orchestrator,dispatch]);

  const REACT_PORTAL_VERSION = window.__RUNTIME_CONFIG__.REACT_PORTAL_VERSION;
  const hub_version = orchestrator.version ? orchestrator.version : "Loading";

  return (
    <PageBlock>
      <Header title="Service Status" subtitle="" />

      <Typography mr="2rem" ml="2rem">
          Portal Version - {REACT_PORTAL_VERSION}
        </Typography>
        
        <Typography mr="2rem" ml="2rem">
          Orchestrator / Hub Version - {hub_version}
        </Typography>


    </PageBlock>
  );
};

export default Status;
