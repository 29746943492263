import React from "react";
import { List, Box, Button, Divider } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CataloguePageMenuItem from "./CataloguePageMenuItem";
import CustomDragLayer from "./CustomDragLayer";

const CataloguePageMenu = ({
  pageTree,
  selectedPage,
  addPageHandler,
  deletePageHandler,
  editPageHandler,
  parentPageHandler,
  pages,
  menuWidth,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height="100%"
      justifyContent={"space-between"}
    >
      <DndProvider backend={HTML5Backend}>
        <CustomDragLayer menuWidth={menuWidth} />
        <List
          sx={{ width: "100%", bgcolor: "background.default", pr: ".3rem" }}
          aria-labelledby="catalogue_list"
        >
          {/* add the top divider to allow dropping right at the top  */}

          <CataloguePageMenuItem
                isDivider={true}
                key={"TOP"}
                nextSequence={1}
                node={{ id: "TOP" }}
                parentPageHandler={parentPageHandler}
              />

          {pageTree.map((node) => (
            <React.Fragment key={"rfagment" + node.id}>
              

              <CataloguePageMenuItem
                key={node.id}
                node={node}
                depth={0}
                selectedPage={selectedPage}
                pages={pages}
                deletePageHandler={deletePageHandler}
                editPageHandler={editPageHandler}
                addPageHandler={addPageHandler}
                parentPageHandler={parentPageHandler}
                shouldOpen={
                  node.children.length > 0 &&
                  (node.id === selectedPage ||
                  node.children.some((child) => child.id === selectedPage))
                }
                isInGroup={node.children.length > 0}
                parentId={node.id}
              />

              <CataloguePageMenuItem
                isDivider={true}
                key={"BELOW" + node.id}
                node={{ id: "BELOW" + node.id }}
                nextSequence={node.sequence + 1}
                parentPageHandler={parentPageHandler}
              />
            </React.Fragment>
          ))}
        </List>
      </DndProvider>
      <Button
        variant="contained"
        color="primary"
        onClick={addPageHandler}
        startIcon={<Add />}
        sx={{ mr: "1rem", mb: ".3rem" }}
      >
        Add new page
      </Button>
    </Box>
  );
};

export default CataloguePageMenu;
