import { useTheme } from "@emotion/react";
import { Box, Link } from "@mui/material";
import { services } from "common/Services.js";
import { cloneElement } from "react";
import { Link as RouterLink } from "react-router-dom";

const NiceService = (props) => {
  //console.log("date: ", moment(props.children))

  const theme = useTheme();

  const wantedService = props?.wantedService;
  const objectId = props?.children;

  if (wantedService) {
    //lookup the service
    const thisService = services.find(
      (service) => service.serviceType === wantedService
    );

    let linkTo = "";

    // switch (wantedService){

    //   case "copilot":
    //     //get the name of the chat

    //   break:
    //   default:

    // }

    //thisService.name + " - " + objectId

    if (thisService) {
      return (
        <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
          {cloneElement(thisService.icon, {
            sx: { color: theme.palette.primary.main },
          })}

          <Link
            to={linkTo}
            component={RouterLink}
            underline="hover"
            fontSize="inherit"
          >
            {thisService.name + " - " + objectId}
          </Link>
        </Box>
      );
    } else {
      return objectId;
    }
  } else {
    return objectId;
  }
};

export default NiceService;
