import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage, setPopupMessage } from "./message";

import AuthService from "../services/auth.service";
import PreferenceService from "services/preference.service";
import TokenService from "services/token.service";

const user = JSON.parse(localStorage.getItem("user"));

const list = {
  list: [],
  pageSize: 25,
  page: 1,
  total: 0,
  nextPage: 0,
};

const user_preferences = { data: list, loading: false };
const user_sessions = { data: list, loading: false };
const user_roles = { data: list, loading: false };
const userTemplate = {
  username: "notLoggedIn",
};

export const register = createAsyncThunk(
  "auth/register",
  async ({ user }, thunkAPI) => {
    try {
      const data = await AuthService.register(user);
      thunkAPI.dispatch(setMessage(data.data.message));
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "auth/updateUserPassword",

  async ({ user }, thunkAPI) => {
    try {
      const data = await AuthService.updateUserPassword(user);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",

  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      //go get the ACL for the user
      let search = {
        username: username,
      };
      //thunkAPI.dispatch(getCurrentUsersRoles({search}));

      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSessions = createAsyncThunk(
  "auth/get_sessions",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AuthService.getSessions(search);
      return { sessions: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSession = createAsyncThunk(
  "Session/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await AuthService.getSession(id);
      return { session: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSession = createAsyncThunk(
  "auth/updateSession",
  async ({ session }, thunkAPI) => {
    try {
      const data = await AuthService.updateSession(session);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserRole = createAsyncThunk(
  "auth/updateUserRole",
  async ({ role }, thunkAPI) => {
    try {
      const data = await AuthService.updateUserRole(role);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserRoles = createAsyncThunk(
  "auth/get_user_roles",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AuthService.getUserRoles(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserRole = createAsyncThunk(
  "Role/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await AuthService.getUserRole(id);
      return { role: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePreferenceByUser = createAsyncThunk(
  "Preference/updateByUser",
  async ({ preference }, thunkAPI) => {
    try {
      const data = await PreferenceService.updatePreferenceByUser(preference);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const listPreference = createAsyncThunk(
  "Preference/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await PreferenceService.listPreference(search);
      return { Preference: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPreference = createAsyncThunk(
  "Preference/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreference(id);
      return { Preference: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getPreferenceFilterValues = createAsyncThunk(
  "Preference/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreferenceFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getPreferenceUi = createAsyncThunk(
  "Preference/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await PreferenceService.getPreferenceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updatePreference = createAsyncThunk(
  "Preference/update",
  async ({ preference }, thunkAPI) => {
    try {
      const data = await PreferenceService.updatePreference(preference);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const refreshToken = createAsyncThunk(
  "auth/refresh",
  async (newAccessTokenPayload) => {}
);

const userHasRole = (state, role) => {
  const foundRole = state.auth?.user?.roles?.find((obj) => obj.roleId === role);

  if (foundRole !== undefined) {
    return true;
  } else {
    return false;
  }
};

const initialState = user
  ? { isLoggedIn: true, user, user_roles, user_sessions, user_preferences }
  : { isLoggedIn: false, user: userTemplate, user_roles, user_sessions, user_preferences};

const authSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: {

    [updatePreference.fulfilled]: (state, action) => {

      const updatedPreference = state.user_preferences.data.list.map((Preference) => {
        if (Preference.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Preference;
        }
      });
      state.data.list = updatedPreference;
    },

    

    [getPreferenceUi.pending]: (state, action) => {
      state.user_preferences.loading = true;
    },
    [getPreferenceUi.fulfilled]: (state, action) => {
      state.user_preferences.Ui = action.payload.ui;
      state.user_preferences.loading = false;
    },
    [getPreferenceUi.rejected]: (state, action) => {
      state.user_preferences.Ui = null;
      state.user_preferences.loading = false;
    },

    [getPreferenceFilterValues.pending]: (state, action) => {
      state.user_preferences.loading = true;
    },
    [getPreferenceFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.user_preferences.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getPreferenceFilterValues.rejected]: (state, action) => {
      state.user_preferences.filter_values = list;
      state.loading = false;
    },





    [listPreference.pending]: (state, action) => {
      state.user_preferences.loading = true;
    },
    [listPreference.fulfilled]: (state, action) => {

      //debugger

      state.user_preferences.data = action.payload.Preference;
      state.user_preferences.loading = false;
    },
    [listPreference.rejected]: (state, action) => {
      state.user_preferences.data = list;
      state.user_preferences.loading = false;
    },



    [getPreference.pending]: (state, action) => {
      state.user_preferences.loading = true;
    },
    [getPreference.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.Preference;

      const existingIndex = state.user_preferences.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.user_preferences.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.user_preferences.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getPreference.rejected]: (state, action) => {
      state.user_preferences.loading = false;
    },

    [updatePreferenceByUser.fulfilled]: (state, action) => {

      const updatedPreference = state.user_preferences.data.list.map((Preference) => {
        if (Preference.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Preference;
        }
      });
      state.user_preferences.data.list = updatedPreference;

      //update the user held value as well 
      const userUpdatedPreference = state.user.preferences.map((preference) => {
        if (preference.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return preference;
        }
      });
      
      // Check if the payload preference was found in the array
      const isPreferenceFound = state.user.preferences.some((preference) => preference.id === action.payload.data.id);
      
      // If the preference wasn't found, add it to the array
      if (!isPreferenceFound) {
        userUpdatedPreference.push(action.payload.data);
      }
      
      state.user.preferences = userUpdatedPreference;
      
      TokenService.setUser(state.user)

    },

    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },

    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },

    [updateUserPassword.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user.user = action.payload.user;
    },
    [updateUserPassword.rejected]: (state, action) => {},

    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = userTemplate;
    },

    [refreshToken.fulfilled]: (state, action) => {
      state.user.accessToken = action.meta.arg.accessToken;
      state.user.accessTokenExpiresAt = action.meta.arg.accessTokenExpiresAt;
    },

    [getUserRoles.pending]: (state, action) => {
      state.user_roles.loading = true;
    },
    [getUserRoles.fulfilled]: (state, action) => {
      state.user_roles.data = action.payload.data;
      state.user_roles.loading = false;
    },
    [getUserRoles.rejected]: (state, action) => {
      state.user_roles.data = list;
      state.user_roles.loading = false;
    },

    [getSessions.pending]: (state, action) => {
      state.user_sessions.loading = true;
    },
    [getSessions.fulfilled]: (state, action) => {
      state.user_sessions.data = action.payload.sessions;
      state.user_sessions.loading = false;
    },
    [getSessions.rejected]: (state, action) => {
      state.user_sessions.data = list;
      state.user_sessions.loading = false;
    },
    [updateSession.fulfilled]: (state, action) => {
      //debugger;

      let sessions = state.user_sessions.data.list;
      const session = sessions.find(
        (session) => session.id === action.payload.data.id
      );

      session.isBlocked = action.payload.data.isBlocked;
    },

    [getSession.pending]: (state, action) => {
      state.loading = true;
    },
    [getSession.fulfilled]: (state, action) => {
      //debugger;

      const newPayload = action.payload.utility;

      const existingIndex = state.user_sessions.data.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.user_sessions.data[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.user_sessions.data.push(newPayload);
      }

      state.loading = false;
    },
    [getSession.rejected]: (state, action) => {
      state.loading = false;
    },

    [getUserRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserRole.fulfilled]: (state, action) => {
      //debugger;

      const newPayload = action.payload.utility;

      const existingIndex = state.user_roles.data.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.user_roles.data[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.user_roles.data.push(newPayload);
      }

      state.loading = false;
    },
    [getUserRole.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateUserRole.fulfilled]: (state, action) => {
      
      let userRoles = state.user_roles.data.list;
      let role = userRoles.find((role) => role.id === action.payload.data.id);

      role = action.payload.data;

      const updatedRoles = userRoles.map((r) => (r.id === role.id ? role : r));

      return {
        ...state,
        user_roles: {
          ...state.user_roles,
          data: {
            ...state.user_roles.data,
            list: updatedRoles,
          },
        },
      };
    },
  },
});

const { reducer, actions } = authSlice;
export default reducer;
export { userHasRole};
