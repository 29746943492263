import {
  GridColumnMenuContainer,
  GridColumnsMenuItem,
  GridFilterMenuItem,
  HideGridColMenuItem,
  SortGridMenuItems,
} from "@mui/x-data-grid";

const DataGridCustomColumnMenu = (props) => {
  const { hideMenu, currentColumn, open } = props;
  return (
    <GridColumnMenuContainer
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      open={open}
    >
      <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
      {/* <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
      <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} /> */}
    </GridColumnMenuContainer>
  );
};

export default DataGridCustomColumnMenu;
