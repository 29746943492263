import { CheckOutlined, ThumbDownOutlined, ThumbUpOutlined, TrendingDownOutlined, TrendingFlatOutlined, TrendingUpOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";



const NiceComplexity = (props) => {
  let complexity = props.children;


  if (complexity === "Low"){
    return (<Chip label="Low" color={"success"} size="small"/>)
  }

  if (complexity === "Medium"){
    return (<Chip  label="Medium" color={"info"} size="small"/>)
  }

  if (complexity === "High"){
    return (<Chip  label="High" color={"error"} size="small"/>)
  }

};

export default NiceComplexity;
