import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import { addStateSetField, updateStateSetField } from "slices/state";

const UIGroupCreateEdit = ({
  closerFunc,
  stateSetId,
  mode,
  currentNode,
  anchorNode,
  groupsUpdater,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  console.log("Node: ", currentNode);

  const initialValues = {};

  if (mode === "EDIT") {
    //debugger;

    initialValues.name = currentNode.name;
    initialValues.parent = currentNode.parent;
    initialValues.row = currentNode.row;
    initialValues.column = currentNode.column;
    initialValues.columns = currentNode.columns;
    initialValues.visible = currentNode.visible;
    initialValues.icon = currentNode.icon;
  } else {
    initialValues.name = "";
    initialValues.parent = anchorNode.name;
    initialValues.row = 1;
    initialValues.column = 1;
    initialValues.columns = 1;
    initialValues.visible = true;
    initialValues.icon = "";
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("This field is required!")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Must contain uppercase, lowercase, _ and numbers with no whitespace"
      ),
  });

  return (
    <Box m="20px">
      <Header
        title={
          mode === "NEW"
            ? `Add new UI Group to UI Group "${anchorNode.name}"`
            : `Edit UI Group "${anchorNode.name}"`
        }
      />

      <Formik
        onSubmit={(values, actions) => {
          groupsUpdater(values, mode);
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Group"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parent}
                name="parent"
                error={!!touched.parent && !!errors.parent}
                helperText={touched.parent && errors.parent}
                sx={{ gridColumn: "span 1" }}
                disabled={mode === "NEW"}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Group"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 1" }}
                disabled={mode === "EDIT"}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Column"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.column}
                name="column"
                error={!!touched.column && !!errors.column}
                helperText={touched.column && errors.column}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: anchorNode.columns }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Row"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.row}
                name="row"
                error={!!touched.row && !!errors.row}
                helperText={touched.row && errors.row}
                sx={{ gridColumn: "span 1" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.visible}
                    name="visible"
                  />
                }
                label="Show the group on the screen"
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Icon"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.icon}
                name="icon"
                error={!!touched.icon && !!errors.icon}
                helperText={touched.icon && errors.icon}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="1rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {mode === "NEW" ? "Add Group" : "Save"}
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UIGroupCreateEdit;
