import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";

import ELK from 'elkjs/lib/elk.bundled.js';

import CloudOrgNode from "components/cloud_org_graph/CloudOrgNode";

import { getCloudOrgStructure } from "slices/cloudOrgStructure";

const CloudOrgGraph = ({ id, refreshPoint, nodeSelector }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const nodeWidth = 400;
  const nodeHeight = 150;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);



  const orgNodes = useSelector((state) => state.cloudOrgStructure.data);

  const rootNode = orgNodes?.list.find((org) => id ? org.id === id : org.resourceType === "Organization")

  const proOptionsReactFlow = { hideAttribution: true };

  const nodeTypes = useMemo(
    () => ({
      cloudOrgNode: CloudOrgNode,
    }),
    []
  );

  // const edgeTypes = useMemo(
  //   () => ({
  //     buttonEdge: ButtonEdge,
  //   }),
  //   []
  // );

  const handlePaneClick = () => {
    //dispatch(setSelectedNode(null));
  };

  const uiDefaults = {
    nodeSpacing: 250,
    firstNodeY: 10,
    xOffset: 5,
  };


  const elk = new ELK();

// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'UP',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.spacing.nodeNode': '80',
};

const getLayoutedElements = (nodes, edges, options = {}) => {

  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout
      // direction.
      targetPosition: 'top',
      sourcePosition: 'bottom',

      // Hardcode a width and height for elk to use when layouting.
      width: nodeWidth,
      height: nodeHeight,
    })),
    edges: edges,
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        // React Flow expects a position property on the node instead of `x`
        // and `y` fields.
        position: { x: node.x, y: node.y },
      })),

      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
};

  const loadServicesNodes = () => {
    console.log("initial load for:", id);
    console.log("Org Nodes: ", orgNodes);


    if (orgNodes?.total > 0) {
      let initialNodes = [];
      let initialEdges = [];

      // Initialize an empty object to store the counts
      const relationshipCounts = {};

      // Iterate through the array and count occurrences of each relationship
      orgNodes.list.forEach((obj) => {
        const relationship = obj.relationship;
        // Check if the relationship exists in the counts object
        if (relationshipCounts.hasOwnProperty(relationship)) {
          // Increment the count if the relationship already exists
          relationshipCounts[relationship]++;
        } else {
          // Initialize the count to 1 if the relationship is encountered for the first time
          relationshipCounts[relationship] = 1;
        }
      });

      //debugger

      initialNodes.push({
        id: rootNode.id,
        position: { x: 5, y: 5 },
        data: {
          width: nodeWidth,
          height: nodeHeight,
          nodeType: "root",
          id:rootNode.id,
          relationshipCounts: relationshipCounts,
            customerName: rootNode.customerName,
            resourceType: rootNode.resourceType,
            subResourceType: rootNode.subResourceType,
            nodeSelector:nodeSelector
        },
        type: "cloudOrgNode",
      });

      let yPos = uiDefaults.firstNodeY + uiDefaults.nodeSpacing;

      orgNodes.list.filter((node) => node.id !==rootNode.id ).forEach((node) => {
        //debugger;

        let newNode = {
          id: node.id,
          //position: { x: 5, y: yPos },
          type: "cloudOrgNode",
          data: {
            //type: service .type,

            width: nodeWidth,
            height: nodeHeight,
            //relationshipCounts: relationshipCounts,
            rootName: rootNode.customerName,
            customerName: node.customerName,
            resourceType: node.resourceType,
            subResourceType: node.subResourceType,
            id:node.id,
            nodeSelector:nodeSelector
          },
        };

        let eId = `e${node.id}`;

        let newEdge = {
          id: eId,

          type: "default",
          // markerEnd: {
          //   type: MarkerType.ArrowClosed,
          //   width: 35,
          //   height: 35,
          // },
        };

        yPos = yPos + uiDefaults.nodeSpacing;

        // let rootNodeIsParent = orgNodes.list.some(
        //   (service) =>
        //     node.implementorParent === id &&
        //     node.typeParent == serviceType
        // );

        const rootNodeIsParent =
          node.implementorParent === id

        //debugger

          newNode.data.nodeType = "child";
          newNode.data.serviceType = node.typeChild;
          newNode.data.implementorId = node.implementorChild;

          newEdge.sourceHandle = "manages_source";
          newEdge.targetHandle = "manages_target";


            newEdge.source = node.id;
            newEdge.target = node.managedBy
       

        initialNodes.push(newNode);
        initialEdges.push(newEdge);
      });

      //layout the nodes
      getLayoutedElements(initialNodes, initialEdges, elkOptions).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      })

      console.log("Edges: ", initialEdges);
      console.log("Nodes: ", initialNodes);
    }
  };

  const configRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = () => {
    setAnchorEl(configRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //dispatch(setSelectedNode(null));
  };

  useEffect(() => {

    const search = {
      page: 0,
      page_size: 10000,
      filter: "",
      sort: "",
    };

    dispatch(getCloudOrgStructure({ search }));
  }, [refreshPoint]);



  useEffect(() => {
    if (rootNode && orgNodes.total > 0) {
      //debugger
      loadServicesNodes();
    }

  }, [orgNodes]);

  if (rootNode && orgNodes.total > 0) {
    return (
      <Box height={areaHeight} display="flex">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          //onEdgesChange={onEdgesChange}
          proOptions={proOptionsReactFlow}
          nodeTypes={nodeTypes}
          //edgeTypes={edgeTypes}
          onPaneClick={handlePaneClick}
          //defaultViewport={defaultViewport}
          fitView
          minZoom={0}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </Box>
    );
  } else {
    return (
      <Box height={areaHeight} display="flex">
        Loading...
      </Box>
    );
  }
};

export default CloudOrgGraph;
