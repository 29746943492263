import {
  AutoAwesomeMotionOutlined,
  FlagOutlined,
  InsightsOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlaylistAddCheckOutlined,
  VerticalAlignBottomOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import { getBlox } from "slices/blox";
import { getUtilities, getUtility } from "slices/utility";
import { getWorkflowContribution } from "slices/worklow_contribution";
import WhereUsedBaseNode from "components/where_used/WhereUsedBaseNode";
import { getStateSet } from "slices/state";
import { getKpi } from "slices/kpi";

const WhereUsedNode = memo(({ data }) => {
  const dispatch = useDispatch();

  const blox = useSelector((state) => state.blox.data.list);
  const utilities = useSelector((state) => state.utility.data.list);
  const stateSet = useSelector((state) => state.stateSet.data.list);
  const kpi = useSelector((state) => state.kpi.data.list);

  const [name, setName] = useState("Loading");
  const [icon, setIcon] = useState(null);

  let howUsed = "This Service"

  if (data.nodeType === "child"){

     howUsed = data.relationship == "Config"
      ? "Provides Configuration for " + data.rootName
      : data.relationship === "Reads"
      ? "provides Data to "+ data.rootName
      : data.relationship === "Writes"
      ? "Is Updated by " + data.rootName
      : data.relationship === "Manages"
      ? "Is Managed by " + data.rootName
      : data.relationship;
    
  }

  if (data.nodeType === "parent"){

    howUsed = data.relationship == "Config"
      ? "Is Configgured by " + data.rootName
      : data.relationship === "Reads"
      ? "Is read by "+ data.rootName
      : data.relationship === "Writes"
      ? "Updates " + data.rootName 
      : data.relationship === "Manages"
      ? "Manages " + data.rootName  
      : data.relationship;
  }


  useEffect(() => {
    if (data.serviceType == "utility") {
      setIcon(<FlagOutlined sx={{ fontSize: 40 }} />);

      let newName = utilities.find(
        (utility) => utility.id === data.implementorId
      );
      if (newName == undefined) {
        dispatch(getUtility({ id: data.implementorId }));
      } else {
        setName(newName.name);
      }
    }

    if (data.serviceType == "stateSet") {
      setIcon(<AutoAwesomeMotionOutlined sx={{ fontSize: 40 }} />);

      let newName = stateSet.find(
        (contribution) => contribution.id === data.implementorId
      );
      if (newName == undefined) {
        dispatch(getStateSet({ id: data.implementorId }));
      } else {
        setName(newName.name);
      }
    }

    if (data.serviceType == "kpi") {
      setIcon(<InsightsOutlined sx={{ fontSize: 40 }} />);

      let newName = kpi.find(
        (contribution) => contribution.id === data.implementorId
      );
      if (newName == undefined) {
        dispatch(getKpi({ id: data.implementorId }));
      } else {
        setName(newName.name);
      }
    }

    if (data.serviceType == "blox") {
      setIcon(<WidgetsOutlined sx={{ fontSize: 40 }} />);

      let newName = blox.find(
        (contribution) => contribution.id === data.implementorId
      );

      if (newName == undefined) {
        dispatch(getBlox({ id: data.implementorId }));
      } else {
        setName(newName.name);
      }
    }
  }, [blox, utilities, stateSet, kpi]);

  return (
    <WhereUsedBaseNode
      nodeType={data.nodeType}
      id={data.id}
      width={data.width}
      height={data.height}
      relationshipCounts={data.relationshipCounts}
      relationship={data.relationship}
    >
      <Box display="flex" flexDirection="column" alignItems={"flex-start"}>
        <Box display={"flex"} gap={".4rem"} alignItems={"center"} mb={".7rem"}>
          {icon}
          <Typography variant="h3">{name}</Typography>
        </Box>

        <Typography variant="h5">{data.serviceType}</Typography>
        <Typography>{howUsed}</Typography>
      </Box>
    </WhereUsedBaseNode>
  );
});

export default WhereUsedNode;
