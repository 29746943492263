import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import WorkflowService from "../services/workflow.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false};

export const updateWorkflow = createAsyncThunk(
  "Workflow/update",
  async ({ org }, thunkAPI) => {
    try {
      const data = await WorkflowService.updateWorkflow(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateWorkflowActive = createAsyncThunk(
  "Workflow/update_active",
  async ({ workflow }, thunkAPI) => {
    try {
      const data = await WorkflowService.updateWorkflowActive(workflow);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflows = createAsyncThunk(
  "Workflow/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowService.getWorkflows(search);
      return { Workflows: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflow = createAsyncThunk(
  "Workflow/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowService.getWorkflow(id);
      return { workflow: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowUi = createAsyncThunk(
  "Workflow/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowService.getWorkflowUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowData = createAsyncThunk(
  "Workflow/get_data_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowService.getWorkflowData(search);
      return { workflow_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runWorkflow = createAsyncThunk(
  "Workflow/run",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowService.runWorkflow(id);
      thunkAPI.dispatch(
        setMessage({
          message: `Workflow started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process workflow ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearWorkflowUi = createAction("Workflow/clearWorkflowUi");

const initialState = {
  data: list,
  loading: false,
  workflow_data: list,
  workflowUi: null,
  workflowRunJob: null,
};

const WorkflowSlice = createSlice({
  name: "Workflows",
  initialState,

  extraReducers: {
    [clearWorkflowUi]: (state, action) => {
      state.workflowUi = null;
      state.loading = false;
    },

    [getWorkflowUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowUi.fulfilled]: (state, action) => {
      state.workflowUi = action.payload.ui;
      state.loading = false;
    },
    [getWorkflowUi.rejected]: (state, action) => {
      state.workflowUi = null;
      state.loading = false;
    },

    [runWorkflow.pending]: (state, action) => {
      state.loading = true;
    },
    [runWorkflow.fulfilled]: (state, action) => {
      state.workflowRunJob = action.payload.job;
      state.loading = false;

      //update the rows that this job was created for 
      let utilities = state.data.list;
      let thisWorkflow = utilities.find(
        (workflow) => workflow.id === action.payload.job.implementorId
      );

      thisWorkflow.jobStatus = action.payload.job.status;
      thisWorkflow.jobId = action.payload.job.id;

    },
    [runWorkflow.rejected]: (state, action) => {
      state.workflowRunJob = null;
      state.loading = false;
    },

    [getWorkflowData.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowData.fulfilled]: (state, action) => {
      //debugger;

      state.workflow_data = action.payload.workflow_data;
      state.loading = false;
    },
    [getWorkflowData.rejected]: (state, action) => {
      state.workflow_data = list;
      state.loading = false;
    },

    [getWorkflows.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflows.fulfilled]: (state, action) => {
      state.data = action.payload.Workflows;
      state.loading = false;
    },
    [getWorkflows.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateWorkflowActive.fulfilled]: (state, action) => {
      let utilities = state.data.list;
      let thisWorkflow = utilities.find(
        (workflow) => workflow.id === action.payload.data.id
      );
      thisWorkflow.active = action.payload.data.active;
    },

    [updateWorkflow.fulfilled]: (state, action) => {
      debugger;

      let organizations = state.data.list;
      let thisOrg = organizations.find(
        (org) => org.id === action.payload.data.id
      );

      thisOrg.name = action.payload.data.name;
      thisOrg.domain = action.payload.data.domain;
      thisOrg.active = action.payload.data.active;
    },

    [getWorkflow.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflow.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.workflow;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getWorkflow.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = WorkflowSlice;
export default reducer;
