import api from "./api";

const updateOpportunity = (opportunity) => {
  return api
    .patch("/opportunity", {
      ...opportunity,
    })
    .then((response) => {
      return response.data;
    });
};


const getOpportunities = (search) => {
  return api
    .get("/opportunity", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getOpportunityNotes = (search) => {
  return api
    .get("/opportunity/note", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getOpportunity = (id) => {
  return api.get("/opportunity/" + id).then((response) => {
    return response.data;
  });
};

const getOpportunityAggregateValues = (search) => {

  return api.get("/opportunity/aggregatevalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const createOpportunity = (step) => {
  return api.post("/opportunity", {
    ...step,
  });
};

const getOpportunityFilterValues = (search) => {

  return api.get("/opportunity/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getOpportunityUi = () => {
  return api.get("/opportunity/ui")
  .then((response) => {
    return response.data;
  });
};


const deleteOpportunity = (id) => {
  return api.delete("/opportunity/" + id).then((response) => {
    return response.data;
  });
};


const OpportunityService = {
  deleteOpportunity,
  getOpportunities,
  updateOpportunity,
  getOpportunity,
  getOpportunityNotes,
  getOpportunityFilterValues,
  createOpportunity,
  getOpportunityUi,
  getOpportunityAggregateValues,

};

export default OpportunityService;
