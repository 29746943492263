import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { clearMessage, setMessage } from "../../slices/message";
import { getUser, updateUser } from "../../slices/user";

const NiceUser = (props) => {
  const dispatch = useDispatch();

  // debugger;

  //const optionSetter = props.optionSetter;

  const wantedUser = props.children;

  const user = useSelector((state) =>
    state.users.data.list?.find((user) => user.username === wantedUser)
  );

  //

  // console.log("User: ", user);
  // console.log("wanted User: ", wantedUser);

  useEffect(() => {
    if (!user && wantedUser != "" && wantedUser != undefined && wantedUser != "All") {
      //need to get the user to display
      let username = wantedUser;
      dispatch(getUser({ username }));
    }
  }, [wantedUser]);




  // if (optionSetter){

  //   if(user){
  //   optionSetter(wantedUser , `${user.firstName} ${user.lastName} (${user.username})`)
  //   }
  // }

  return (
    <>
      {user ? (
        <Box display="flex" justifyContent="start" alignItems="baseline">
          <Typography display="inline">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography variant="title" color="inherit" noWrap>
            &nbsp;
          </Typography>
          <Typography
            variant="small"
            display="inline"
          >{`(${user.username})`}</Typography>
        </Box>
      ) : (
        <Box display="flex" justifyContent="start" alignItems="baseline">
          <Typography display="inline">{wantedUser}</Typography>
        </Box>
      )}
    </>
  );
};

export default NiceUser;
