import axios from "axios";

//const API_URL = "http://192.168.55.192:8080/v1/"; //mac
//const API_URL = "http://localhost:8080/v1/";

//const API_URL = "http://localhost:8082/v1/";

//const API_URL = "http://localhost:63088/v1";

//const API_URL = "/api/v1";

//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL


const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;