import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getServiceDimensionValues } from "slices/dimensions";




const NiceCataloguePage = (props) => {

  const dispatch = useDispatch();


  const wantedResource = props.children;

  const page = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "kernel" &&
          dimension.implementor === "cataloguePage" &&
          dimension.field === "id"
      )?.values.find((page) => page.value === wantedResource)
  );


  //console.log("Requested Page: " , wantedResource , " Found " , page)
  
  useEffect(() => {
    //on mount get some defaults

    if (!page) {
      let search = {
        type: "kernel",
        implementor: "cataloguePage",
        field: "id",
        lookUpLabel: "<name>",
        id: wantedResource
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    
  }, []);


  return (
    <>
      {page ? (
        <Box>{page.label}</Box>
      ) : (
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceCataloguePage;
