import { Typography, Box, useTheme ,Link, Avatar} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect  } from "react";
import { clearMessage, setMessage } from "../../slices/message";
import md5 from 'md5';

const ProfileAvatar = (props) => {

    const theme = useTheme();
    
const baseURL = 'https://www.gravatar.com/avatar/'

//make the email all lower case and remove white space

var newEmail = props.user.email.toLocaleLowerCase().trim();

let encodedVal = md5(newEmail);

const URL = baseURL + encodedVal + '?s=200&d=mp';

let size = parseInt(props.size);

return (

    <Avatar 
        alt={props.user.firstName +' ' + props.user.lastName } 
        src = {URL} 
        sx={{ width: size, height: size, borderWidth: "1px", 
        borderStyle: "solid", 
        borderColor: theme.palette.primary.main, }}
    />
)

}

export default ProfileAvatar;