import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { clearMessage, setMessage } from "../../slices/message";
import { getUser, updateUser } from "../../slices/user";
import KeyVal from "./KeyVal";

const NiceObjectCell = (props) => {
  const dispatch = useDispatch();
  
  //debugger;

  const cellObject = props.children;

  if (cellObject) {
    return (
      <Box>
        {Object.keys(cellObject).map((fieldName) => (
          <div key={fieldName}>
            <KeyVal
              label={fieldName}
              value={cellObject[fieldName]}
              correctLabel={true}
            />
          </div>
        ))}
      </Box>
    );
  }
};

export default NiceObjectCell;
