import api from "./api";

const updateStateSet = (stateSet) => {
  return api
    .patch("/state_set", {
      ...stateSet,
    })
    .then((response) => {
      return response.data;
    });
};

const updateStateSetActive = (stateSet) => {
  return api
    .patch("/state_set/active", {
      ...stateSet,
    })
    .then((response) => {
      return response.data;
    });
};

const getStateSets = (search) => {
  return api
    .get("/state_set", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getStateSet = (id) => {
  return api.get("/state_set/" + id).then((response) => {
    return response.data;
  });
};

const createStateSet = (step) => {
  return api.post("/state_set", {
    ...step,
  });
};

const deleteStateSet = (id) => {
  return api.delete("/state_set/" + id).then((response) => {
    return response.data;
  });
};

const deleteStateSetRow = (id, rowId) => {
  return api.delete("/state_set/data/" + id + "/" + rowId).then((response) => {
    return response.data;
  });
};

const getStateSetServiceUi = () => {
  return api.get("/state_set/serviceui")
  .then((response) => {
    return response.data;
  });
};

const getStateUi = (id) => {
  return api.get("/state_set/ui/"+id)
  .then((response) => {
    return response.data;
  });
};

const updateStateSetData = (data) => {
  return api
    .patch("/state_set/data", {
      ...data,
    })
    .then((response) => {
      return response.data;
    });
};

const getStateData = (search) => {

  return api.get("/state_set/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getStateDataId = (id) => {

  return api.get("/state_set/data/"+id,)
  .then((response) => {
    return response.data;
  });
};

const addStateSetField = (field) => {
  return api.post("/state_set/meta", {
    ...field,
  }).then((response) => {
    return response.data;
  });
};

const addStateSetRow = (row) => {
  return api.post("/state_set/data", {
    ...row,
  }).then((response) => {
    return response.data;
  });
};

const updateStateSetRowSchema = (schema) => {
  return api.patch("/state_set/schema", {
    ...schema,
  }).then((response) => {
    return response.data;
  });
};

const updateStateSetField = (schema) => {
  return api.patch("/state_set/meta", {
    ...schema,
  }).then((response) => {
    return response.data;
  });
};

const getStateSetFilterValues = (search) => {

  return api.get("/state_set/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};


const StateService = {
  getStateDataId,
  getStateUi,
  getStateSets,
  updateStateSet,
  getStateSet,
  updateStateSetActive,
  createStateSet,
  deleteStateSet,
  getStateData,
  addStateSetField,
  updateStateSetRowSchema,
  updateStateSetField,
  getStateSetFilterValues,
  updateStateSetData,
  addStateSetRow,
  deleteStateSetRow,
  getStateSetServiceUi,
};

export default StateService;
