import api from "./api";



const updateOrchestrator = (org) => {
  return api.patch("/orchestrator",
  {
    ...org
  })
  .then((response) => {
    return response.data;
  });
};





const getOrchestrators = (search) => {

  return api.get("/orchestrator",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getOrchestrator = (id) => {
  return api.get("/orchestrator/"+id)
  .then((response) => {
    return response.data;
  });
};

const getThisOrchestrator = () => {
  return api.get("/orchestrator/this")
  .then((response) => {
    return response.data;
  });
};


const OrchestratorSevice = {
  getOrchestrators,
  updateOrchestrator,
  getOrchestrator,
  getThisOrchestrator,
};

export default OrchestratorSevice;