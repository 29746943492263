import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { DateTimeField, DateTimePicker } from "@mui/x-date-pickers";

import {
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  Divider,
  DialogTitle,
  DialogActions,
  Popover,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  Switch,
  List,
  Autocomplete,
  Chip,
  Stack,
  MenuItem,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import FlexBetween from "./FlexBetween";
import { Box } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import moment from "moment-timezone";
import { getKpiFilterValues } from "slices/kpi";
import NiceDate from "./NiceDate";
import { getJobFilterValues, getJobLogFilterValues } from "slices/job";
import { getUtilityFilterValues } from "slices/utility";
import { getStateSetFilterValues, updateStateSetRowValueData } from "slices/state";
import { useGridSelector } from "@mui/x-data-grid";
import { clearMessage } from "slices/message";

const TableMassEdit = ({ columns, closer, selectedRows, props }) => {
  const dispatch = useDispatch();

  const filteredColumns = columns.filter((obj) => {
    return obj.valueMode === "User";
  });

  const filters = useSelector((state) => state.tablePage.config.filters);
  const masterId = useSelector((state) => state.tablePage.config.masterId);
  const masterIdField = useSelector(
    (state) => state.tablePage.config.masterIdField
  );
  const dimension = useSelector((state) => state.tablePage.config.dimension);
  const stateSetId = useSelector((state) => state.tablePage.config.stateSetId);
  const serviceType = useSelector(
    (state) => state.tablePage.config.serviceType
  );

  const [property, setProperty] = React.useState(null);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  const newValues = useSelector(
    (state) => state[serviceType]?.filter_values?.stringValues
  );

  const [filterMode, setFilterMode] = React.useState("specific");

  const handleChange = (event) => {
    setFilterMode(event.target.value);
  };

  const [columnValues, setColumnValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const column = columns?.find((col) => col.field === property);

  let valuesGetter = null;


  switch (serviceType) {
    case "kpis":
      valuesGetter = getKpiFilterValues
      break;

    case "blox":
      //valuesGetter = getBlox
      break;
    case "utilities":
      valuesGetter = getUtilityFilterValues
      break;

    case "stateSet":
      valuesGetter = getStateSetFilterValues
      break;

    case "jobs":
      valuesGetter = getJobFilterValues
      break;

    case "joblog":
      valuesGetter = getJobLogFilterValues
      break;

    default:
      break;
  }


  useEffect(() => {
    //debugger;

    if (!property) {
      //cant do anything yet so return untill we have a property selected
      return;
    }

    //const column = columns?.find((col) => col.id === property)

    const search = {
      id: masterId,
      idField: masterIdField,
      field: column?.field,
      filter: filters,
      dimension: dimension,
      stateSetId: stateSetId,
    };

    //remove any alls form the filter and the column we are looking for if its there

    if (search.filter && search?.filter.length > 0) {
      // Split the string on '|'
      const parts = search.filter.split("|");
      const thisFilter = `[${column?.field}]`;

      //console.log("This filter: ", thisFilter);

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all") && !part.startsWith(thisFilter);
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");

      //console.log("filter filter: ", search.filter);
    }

    //console.log("Search for filter values in mass edit", search);

    dispatch(valuesGetter({ search })).then(() => {
      setIsLoading(false); // Mark loading as complete after data is fetched
    });

    
  }, [property]);

  useEffect(() => {
    if (!isLoading) {
      setColumnValues(newValues);
    }
  }, [isLoading]);

  const [valueList, setValueList] = useState(() => {
    const values = [
      // {
      //   value: "all",
      //   label: "All",
      // },
    ];

    return values;
  });

  useEffect(() => {
    //add the values to the list

    if (columnValues) {
      const newValues = columnValues?.map((value) => ({
        value: value,
        label: value,
      }));

      // Add the new values to the existing values
      setValueList((prevValueList) => [...prevValueList, ...newValues]);
    }
  }, [columnValues]);

  const [listValue, setListValue] = React.useState([]);

  const [value, setValue] = React.useState(null);

  const boolOperators = [
    {
      value: true,
      label: "is True",
    },
    {
      value: false,
      label: "is False",
    },
  ];


  const saveRows = () =>{

    //loop over the rows and set the fields to the new value 

    dispatch(clearMessage())

    selectedRows.forEach((item) => {

      debugger;

      switch (serviceType) {
        case "kpis":
          break;
    
        case "blox":
          break;
        case "utilities":
          break;
    
        case "stateSet":

        const stateValue = {
          stateSetId: stateSetId,
          data: {
            id: item,
            [property]: value,
          },
        };

          dispatch(updateStateSetRowValueData({stateValue}))
          closer()
          break;
    
        case "jobs":
          break;
    
        default:
          break;
      }
    });
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"space-between"}
      width="20rem"
      m="1rem"
      gap="1rem"
      sx={{ bgcolor: "background.paper" }}
    >
      <Typography variant="H2">
        Mass Edit ({selectedRows.length} rows)
      </Typography>

      <TextField
        sx={{ minWidth: "15rem" }}
        select
        label="Property"
        variant="outlined"
        inputProps={{ readOnly: false }}
        value={property}
        onChange={(event) => {
          setProperty(event.target.value);
        }}
        size="small"
      >
        {filteredColumns?.map((option) => (
          <MenuItem key={option.headerName} value={option.field}>
            <ListItemText primary={option.headerName} />
          </MenuItem>
        ))}
      </TextField>

      <FormControl>
        <RadioGroup
          row
          aria-labelledby="filter_mode"
          value={filterMode}
          onChange={handleChange}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="select"
            control={<Radio />}
            label="Select"
            disabled={!property}
          />
          <FormControlLabel
            value="specific"
            control={<Radio />}
            label="Specific Value"
            disabled={!property}
          />
        </RadioGroup>
      </FormControl>

      {filterMode === "specific" && (
        <>
          {column?.type === "date" ? (
            <DateTimePicker
              timezone="system"
              label="Value"
              value={value}
              //onChange={(newValue) => {setValue(newValue.unix())}}
              onChange={setValue}
              views={["year", "month", "day", "hours", "minutes"]}
              disabled={!property}
            />
          ) : column?.type === "boolean" ? (
            <TextField
            select
              label="Value"
              variant="outlined"
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              size="small"
              disabled={!property}
            >

{boolOperators?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}

            </TextField>
          ) : ( column?.type === "number" ? (


            <TextField
              type={column?.type}
              label="Value"
              variant="outlined"
              value={value}
              // onChange={(event) => {
              //   setValue(event.target.value);
              // }}

              onChange={(event) => {

                 

                const numericValue = parseFloat(event.target.value);
                if (!isNaN(numericValue) && value !== numericValue) {
                  setValue(numericValue);
                }
              }}

              size="small"
              disabled={!property}
            ></TextField>
          ) : (

            <TextField
            label="Value"
            variant="outlined"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            size="small"
            disabled={!property}
          ></TextField>

          ))}
        </>
      )}

      {filterMode === "select" && (
        <FormControl sx={{ minWidth: "20rem" }}>
          <InputLabel id="select_value_label">Value List</InputLabel>
          <Select
            input={<OutlinedInput label="Value List" />}
            labelId="select_value_label"
            multiple
            MenuProps={MenuProps}
            value={listValue}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return "None"; // Display "None" if nothing is selected
              } else if (selected.length === 1) {
                if (column?.type === "date") {
                  return `${selected.length} items selected`;
                } else {
                  return selected[0]; // Display the selected value if only one is selected
                }
              } else {
                return `${selected.length} items selected`; // Display the count if multiple items are selected
              }
            }}
            onChange={(event) => {
              setListValue(event.target.value);
              if (column?.type === "date") {
                console.log("date:", event.target.value);
                // const valuesArray = event.target.value.split(",");
                const momentsArray = event.target.value.map((value) =>
                  moment(value)
                );
                // debugger;

                setValue(event.target.value);
              } else {
                setValue(event.target.value);
              }
            }}
            size="small"
          >
            {valueList?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={listValue.indexOf(option.value) > -1} />
                {column?.type === "date" ? (
                  <ListItemText primary={<NiceDate>{option.label}</NiceDate>} />
                ) : (
                  <ListItemText primary={option.label} />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Box display="flex" gap="1rem" justifyContent="flex-end">
        <Button onClick={closer}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={saveRows}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default TableMassEdit;
