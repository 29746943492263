import api from "./api";

const updateDashboard = (dashboard) => {
  return api
    .patch("/dashboard", {
      ...dashboard,
    })
    .then((response) => {
      return response.data;
    });
};

const updateDashboardActive = (dashboard) => {
  return api
    .patch("/dashboard/active", {
      ...dashboard,
    })
    .then((response) => {
      return response.data;
    });
};

const getDashboards = (search) => {
  return api
    .get("/dashboard", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getDashboard = (id) => {
  return api.get("/dashboard/" + id).then((response) => {
    return response.data;
  });
};



const createDashboard = (dashboard) => {
  return api.post("/dashboard", {
    ...dashboard,
  });
};

const deleteDashboard = (id) => {
  return api.delete("/dashboard/" + id).then((response) => {
    return response.data;
  });
};



const getDashboardFilterValues = (search) => {

  return api.get("/dashboard/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const createDashboardWidget = (widget) => {
  return api.post("/dashboard/widget", {
    ...widget,
  });
};

const deleteDashboardWidget = (id) => {
  return api.delete("/dashboard/widget" + id).then((response) => {
    return response.data;
  });
};

const getDashboardWidgets = (search) => {
  return api
    .get("/dashboard/widget", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getDashboardWidget = (id) => {
  return api.get("/dashboard/widget" + id).then((response) => {
    return response.data;
  });
};


const DashboardService = {

  getDashboards,
  updateDashboard,
  getDashboard,
  updateDashboardActive,
  createDashboard,
  createDashboardWidget,
  deleteDashboard,
  getDashboardFilterValues,
  deleteDashboardWidget,
  getDashboardWidgets,
  getDashboardWidget,
};

export default DashboardService;
