import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";

import { getLastSearchPromptByUser, updateSearchFeedback } from "slices/search";
import KeyVal from "./KeyVal";
import useInterval from "common/useInterval";
import Header from "./Header";
import NiceSaerchResult from "./NiceSearchResult";
import { ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";

const NavBarSearch = ({ maxHeightList, closer }) => {
  const dispatch = useDispatch();
  

  const { user: currentUser } = useSelector((state) => state.auth);

  const searchPrompt = useSelector((state) => state?.search?.lastPrompt);

  useEffect(() => {
    const user = currentUser.user.username;

    dispatch(getLastSearchPromptByUser({ user }));
  }, []);

  useInterval(() => {
    //debugger
    if (
      searchPrompt.jobId === "" ||
      (searchPrompt.jobStatus !== "Completed" &&
        searchPrompt.jobStatus !== "Failed")
    ) {
      const user = currentUser.user.username;
      dispatch(getLastSearchPromptByUser({ user }));
    }
  }, 500);

  const handleFeedback = (result) => {
    const prompt = {
      id: searchPrompt.id,
      userFeedback: result,
    };
    dispatch(updateSearchFeedback({ prompt }));
  };

  const feedbackGoodColour = searchPrompt?.userFeedback
    ? searchPrompt?.userFeedback === "Good"
      ? "success"
      : "default"
    : "default";
  const feedbackBadColour = searchPrompt?.userFeedback
    ? searchPrompt?.userFeedback === "Bad"
      ? "error"
      : "default"
    : "default";

  return (
    <Box m="20px" height={"100%"}>
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box
            display={"flex"}
            flexDirection={"Row"}
            justifyContent={"space-between"}
          >
            <Header
              title="Search Results"
              subtitle={`for search: ${
                searchPrompt?.prompt || "No prompt available"
              }`}
            />

            <Box
              display={"flex"}
              flexDirection={"Row"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} flexDirection={"column"} mr={"3rem"}>
                <Typography>Results Ratings </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  mt={".3rem"}
                >
                  {searchPrompt?.userFeedback ? (
                    searchPrompt.userFeedback === "Good" ? <Chip color="success" label="Good"/> : <Chip color="error"label="Bad"/> 
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleFeedback("Good")}
                        color={feedbackGoodColour}
                      >
                        <ThumbUpOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => handleFeedback("Bad")}
                        color={feedbackBadColour}
                      >
                        <ThumbDownOutlined />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <KeyVal
                  label={"Total Results:"}
                  value={searchPrompt?.response?.length}
                />
                <KeyVal
                  label={"Relevent Results:"}
                  value={
                    searchPrompt?.response?.filter(
                      (response) => response.isRelevant === true
                    ).length
                  }
                />

                <KeyVal
                  label={"Search Time:"}
                  value={searchPrompt?.duration}
                  uiRenderType={"<NiceDuration>"}
                />
              </Box>
            </Box>
          </Box>

          <Divider flexItem />

          <Box
            mt={".3rem"}
            mb={".3rem"}
            sx={{
              overflowY: "auto",
              maxHeight: maxHeightList,
            }}
          >
            <List>
              {searchPrompt?.response
                ?.slice() // Create a shallow copy of the array
                .filter((response) => response.isRelevant === true)
                .map((response) => (
                  <NiceSaerchResult
                    key={response.id}
                    searchResult={response}
                    closer={closer}
                  />
                ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NavBarSearch;
