import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import UserService from "../services/user.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0
};


//const users = {data: list, loading: false};


export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ( {user},thunkAPI) => {
    try {
      const data = await UserService.updateUser(user);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getUsers = createAsyncThunk(
  "auth/get_users",
  async ({search}, thunkAPI) => {
    try {

      const data = await UserService.getUsers(search);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUser = createAsyncThunk(
  "auth/get_user",
  async ({username}, thunkAPI) => {
    try {
      const data = await UserService.getUser(username);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



const initialState = {data: list, loading: false}

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {

    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.data = action.payload.users; 
      state.loading = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.data = list
      state.loading = false;
    },

    [updateUser.fulfilled]: (state, action) => {

      debugger
      
      let users = state.data.list;
      let thisUser = users.find((user) => user.username === action.payload.data.username)

      thisUser.firstName = action.payload.data.firstName
      thisUser.lastName = action.payload.data.lastName
      thisUser.email = action.payload.data.email
      thisUser.note = action.payload.data.note
      thisUser.avatar = action.payload.data.avatar
      thisUser.active = action.payload.data.active

    },

    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.users)
      state.data.total ++;

      state.loading = false;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});


const { reducer } = userSlice;
export default reducer;