import api from "./api";



const updateUser = (user) => {
  return api.patch("/user",
  {
    ...user
  })
  .then((response) => {
    return response.data;
  });
};




const getUsers = (search) => {

  return api.get("/user",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getUser = (username) => {
  return api.get("/user/"+username)
  .then((response) => {
    return response.data;
  });
};



const UserSevice = {
  getUsers,
  updateUser,
  getUser,
};

export default UserSevice;