import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetKpiAggregateValues,
  getWidgetOpportunityAggregateValues,
  getWidgetUtilityAggregateValues,
  setDashboardRenderStateVariable,
} from "slices/dashboard";
import {
  getBillingPeriodForReportingPeriod,
  getNiceCurrency,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
} from "../../../common/helpers";
import { useSearchParams } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";

const DashboardVariable = ({
  name,
  icon,
  top,
  left,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );



  const description = config?.description;

  const [value,setValue] = useState(null)

  const period = parsePeriod(config?.period, searchParams);
  const billingPeriod = getBillingPeriodForReportingPeriod(period);
  const dimension = parseDimension(config?.dimension, searchParams);
  const filter = getWhenFilterForReportingPeriod(period);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard && widgetData.widget === widget
    )
  );

  const [lastValue, setLastValue] = useState(null);

  useEffect(() => {
    if (config) {
      let localFilter = filter;
      if (widget.userFilters) {
        localFilter = `${localFilter}|${renderState?.filter}`;
      }

      let search = {
        dashboard,
        widget,
        function: config.function,
        id: config.implementorId,
        fields: config.valueField,
        dimension,
        descriptors: config.currencyField,
        billingPeriod,
      };

      const parts = localFilter?.split("|");
      const filteredParts = parts?.filter((part) => !part.endsWith("=all"));
      search.filter = filteredParts?.join("|");

      switch (config.serviceType) {
        case "Kpi":
          dispatch(getWidgetKpiAggregateValues({ search }));
          break;
        case "Utility":
          dispatch(getWidgetUtilityAggregateValues({ search }));
          break;
        case "opportunity":
          dispatch(getWidgetOpportunityAggregateValues({ search }));
          break;
        default:
          console.log("Service type:", config.serviceType, "is not enabled for widget data");
      }

      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dispatch, dashboard, widget, config, filter, renderState, billingPeriod, dimension]);

  useEffect(() => {
    let value = widgetData?.rows[0]?.[config.valueField];
    const currency = widgetData?.rows[0]?.["descriptor_" + config.currencyField];
    if (config) {

    if (config.currencyField) {
      value = getNiceCurrency(value, currency);
    }

    if (value !== lastValue && value) {


      const variable = {
        dashboard,
        label: value,
        value:widget,
      };

      dispatch(setDashboardRenderStateVariable(variable));
      setLastValue(value);
    }else{
      setValue(value)
    }
  }
  }, [dispatch, dashboard, config, widgetData, lastValue]);

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      sx={{ border: "1px solid", borderColor: theme.palette.grey[300] }}
      borderRadius="0.55rem"
    >
      <FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main }}>
          {name}
        </Typography>
        {icon}
      </FlexBetween>

      {value}

      <FlexBetween gap="1rem">
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>

  )
};

export default DashboardVariable;
