import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const NiceActive = (props) => {
  let active = props.children;

  return (
    <Box>
      {active === true && <CheckOutlined />}
      {active === false && null} {/* Render nothing */}
      {active === undefined && "Not Set"}
    </Box>
  );
};

export default NiceActive;
