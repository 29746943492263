import React, { useEffect, useState } from "react";
import FlexBetween from "components/global/FlexBetween";
import Header from "components/global/Header";
import {
  DownloadOutlined,
  EditOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  Divider,
} from "@mui/material";
import StatBox from "components/dashboard/charts/StatBox";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { now } from "moment";
import TitleBox from "components/dashboard/charts/TitleBox";
import { useParams, useSearchParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDashboard,
  getDashboardWidgetsForRendering,
  getWidgetConfigForRendering,
} from "slices/dashboard";
import WidgetBarChart from "components/dashboard/charts/WidgetBarChart";
import WidgetLineChart from "components/dashboard/charts/WidgetLineChart";
import DashboardFilter from "components/dashboard/DashboardFilter";
import PlaceHolder from "components/dashboard/charts/PlaceHolder";
import WidgetTable from "components/dashboard/charts/WidgetTable";
import { DynamicIcon } from "common/dynamicIcon";
import WidgetPieChart from "components/dashboard/charts/WidgetPieChart";
import WidgetGagueKPITarget from "components/dashboard/charts/WidgetGagueKPITaraget";
import StatBoxTopX from "components/dashboard/charts/StatBoxTopX";
import DashboardVariable from "components/dashboard/charts/WidgetDashboardVaraible";
import { processStringForDashboardFilters } from "common/helpers";
import DashboardMenu from "components/dashboard/DashboardMenu";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const handelDownload = () => {};

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const dashboard = useSelector((state) =>
    state.dashboards.data.list?.find((dashboard) => dashboard.id === id)
  );

  const widgets = useSelector(
    (state) =>
      state.dashboards.widgetsForRendering.filter(
        (widget) => widget.dashboard === id
      ),
    shallowEqual
  );

  // Sort widgets based on top and left values
  const sortedWidgets = [...widgets].sort((a, b) => {
    // Compare by top values first
    if (a.top !== b.top) {
      return a.top - b.top;
    }

    // If top values are the same, compare by left values
    return a.left - b.left;
  });

  const configList = useSelector(
    (state) => state.dashboards.configForRendering
  );

  console.log("Config list ", configList);

  useEffect(() => {
    if (dashboard) {
      const search = {
        filter: "[dashboard]=" + id,
      };

      console.log("Dispatching getDashboardWidgets:", search);
      dispatch(getDashboardWidgetsForRendering({ search }));

      const defaultPeriod =
        searchParams.get("period") !== null
          ? searchParams.get("period")
          : dashboard?.initialPeriod
          ? dashboard?.initialPeriod
          : "CurrentMonth";

      setPeriod(defaultPeriod);

      const defaultDimension =
        searchParams.get("dimension") !== null
          ? searchParams.get("dimension")
          : dashboard?.initialDimension
          ? dashboard?.initialDimension
          : "Month";

      setDimension(defaultDimension);

      const defaultBaseline =
        searchParams.get("baseline") !== null
          ? searchParams.get("baseline")
          : dashboard?.initialBaseLine
          ? dashboard?.initialBaseLine
          : "";

      setBaseline(defaultBaseline);

      const defaultOwner =
        searchParams.get("owner") !== null
          ? searchParams.get("owner")
          : dashboard?.initialOwner
          ? dashboard?.initialOwner
          : "";

      setOwner(defaultOwner);

      const defaultFilter =
        searchParams.get("filter") !== null
          ? searchParams.get("filter")
          : dashboard?.itinialFilter
          ? dashboard?.itinialFilter
          : "";

      setFilter(defaultFilter);
    } else {
      console.log("Dispatching getDashboard:", id);
      dispatch(getDashboard({ id }));
    }
  }, [id, dashboard]);

  const [loadingWidgetConfig, setLoadingWidgetConfig] = useState(true);

  //Load the config for each widget
  //Load the column list for each widget
  useEffect(() => {
    if (widgets && widgets.length > 0) {
      let pendingRequests = widgets ? widgets.length : 0;
      setLoadingWidgetConfig(true);

      widgets.forEach(function (widget) {
        const search = {
          stateSetId: widget.type,
          filter: "[id]=" + widget.id,
        };

        console.log("Dispatching getWidgetConfig:", search);

        // Dispatch the action
        dispatch(getWidgetConfigForRendering({ search }))
          .then(() => {
            // Decrease the pending request count after completion
            pendingRequests--;
            //debugger
            // Check if all requests are completed
            if (pendingRequests === 0) {
              setLoadingWidgetConfig(false);
            }
          })
          .catch((error) => {
            // Handle errors if needed
            console.error("Error fetching widget config:", error);
          });
      });
    }
  }, [widgets, dispatch]);

  const [period, setPeriod] = useState("");
  const [baseline, setBaseline] = useState("");
  const [owner, setOwner] = useState("");

  const [filter, setFilter] = useState("");

  const [dimension, setDimension] = useState("");

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find(
      (state) => state.dashboard === dashboard?.id
    )
  );

  let renderTitle = dashboard?.title || dashboard?.name;

  if (renderState) {
    renderTitle = processStringForDashboardFilters(renderTitle, renderState);
  }

  const menuWidth = 300;

  return (
    <PageBlock>
      <SimpleBreadcrumbs lastOverride={dashboard?.name || null}/>

      <Box
        display={"flex"}
        width={"100%"}
        minHeight={"75vh"}
        gap={".3rem"}
        mt={"20px"}
        sx={{ borderTop: 1, borderColor: "divider", flexGrow: 1 }}
      >
        {dashboard?.menu &&
        <Box
          mt=".3rem"
          display={"flex"}
          width={`${menuWidth}px`}
          minHeight={"100%"}
          flexDirection={"column"}
          gap={"1rem"}
          sx={{
            //borderBottom: 1,
            borderRight: 1,
            borderColor: "divider",
            flexGrow: 1,
            overflowY: "auto", // Enable vertical scrolling if needed
          }}
        >
          
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"space-between"}
            pr={".3rem"}
          >
            <Typography variant="h3">{dashboard?.name}</Typography>
          </Box>

          <DashboardMenu menu={ dashboard?.menu } selectedMenu = {dashboard?.id}/>
        </Box>
}
        <Box
          mt=".3rem"
          ml={dashboard?.menu ? "1rem" : ""}
          gap="1rem"
          sx={{
            //borderBottom: 1,

            width: "100%",
            display: "flex",
            flexGrow: 1,
          }}
          flexDirection={"column"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"space-between"}
            pr={".3rem"}
          >
            <Header title={renderTitle} subtitle={dashboard?.description} />

            <Box display={"flex"} gap={".3rem"} flexDirection={"row"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handelRefresh}
              >
                <RefreshOutlined />
              </Button>

              <Divider orientation="vertical" flexItem />

              <Button
                variant="outlined"
                color="primary"
                onClick={handelRefresh}
              >
                <EditOutlined />
              </Button>

              <Button
                variant="outlined"
                color="primary"
                startIcon={<DownloadOutlined />}
                onClick={handelDownload}
              >
                Download
              </Button>
            </Box>
          </Box>

          <Box>
            {!loadingWidgetConfig && (
              <DashboardFilter
                dashboard={dashboard}
                dimension={dimension}
                dimensionSetter={setDimension}
                filters={filter}
                filterSetter={setFilter}
                period={period}
                periodSetter={setPeriod}
                dashboardRefresher={handelRefresh}
                baseline={baseline}
                baselineSetter={setBaseline}
                owner={owner}
                ownerSetter={setOwner}
              />
            )}
          </Box>

          <Box
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="2.5rem"
            gap="20px"
            sx={{
              "& > div": {
                gridColumn: isNonMediumScreens ? undefined : "span 12",
              },
            }}
          >
            {sortedWidgets.map((widget) => {
              switch (widget.type) {
                case "TitleBox":
                  //debugger;

                  const TitleBoxConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !TitleBoxConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={1}
                        type={widget.type}
                      />
                    );
                  }

                  return (
                    <TitleBox
                      dashboard={id}
                      key={widget.id}
                      title={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      top={widget.top}
                      left={widget.left}
                      columns={widget.width}
                      refreshPoint={refreshPoint}
                      config={TitleBoxConfig}
                    />
                  );

                case "StatBox":
                  //debugger;

                  const StatBoxConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !StatBoxConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                      />
                    );
                  }

                  return (
                    <StatBox
                      key={widget.id}
                      title={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={StatBoxConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "StatBoxTopX":
                  //debugger;

                  const StatBoxConfigTopX = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !StatBoxConfigTopX) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                      />
                    );
                  }

                  return (
                    <StatBoxTopX
                      key={widget.id}
                      title={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={StatBoxConfigTopX}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "GagueKPITarget":
                  //debugger;

                  const WidgetGagueKPITargetConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !WidgetGagueKPITargetConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                      />
                    );
                  }

                  return (
                    <WidgetGagueKPITarget
                      key={widget.id}
                      title={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={WidgetGagueKPITargetConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "WidgetTable":
                  //debugger;

                  const WidgetTableConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !WidgetTableConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                        message={`Attempting to load config for widget ${widget.name} with id ${widget.id}`}
                      />
                    );
                  }

                  return (
                    <WidgetTable
                      key={widget.id}
                      name={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={WidgetTableConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "BarChart":
                  //debugger;

                  const BarChartConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !BarChartConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                        message={`Attempting to load config for widget ${widget.name} with id ${widget.id}`}
                      />
                    );
                  }

                  return (
                    <WidgetBarChart
                      key={widget.id}
                      name={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={BarChartConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "LineChart":
                  //debugger;

                  if (loadingWidgetConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                      />
                    );
                  }

                  return (
                    <WidgetLineChart
                      key={widget.id}
                      title={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={configList?.find(
                        (config) => config.id === widget.id
                      )}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "PieChart":
                  //debugger;

                  const PieChartConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  if (loadingWidgetConfig || !PieChartConfig) {
                    return (
                      <PlaceHolder
                        key={widget.id}
                        icon={<DynamicIcon iconName={widget.icon} />}
                        top={widget.top}
                        left={widget.left}
                        columns={widget.width}
                        rows={widget.height}
                        type={widget.type}
                        message={`Attempting to load config for widget ${widget.name} with id ${widget.id}`}
                      />
                    );
                  }

                  return (
                    <WidgetPieChart
                      key={widget.id}
                      name={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={PieChartConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                case "DashboardVariable":
                  //debugger;

                  const DashboardVariableConfig = configList?.find(
                    (config) => config.id === widget.id
                  );

                  // if (loadingWidgetConfig || !DashboardVariableConfig) {
                  //   return (
                  //     <PlaceHolder
                  //       key={widget.id}
                  //       icon={<DynamicIcon iconName={widget.icon} />}
                  //       top={widget.top}
                  //       left={widget.left}
                  //       columns={widget.width}
                  //       rows={widget.height}
                  //       type={widget.type}
                  //       message={`Attempting to load config for widget ${widget.name} with id ${widget.id}`}
                  //     />
                  //   );
                  // }

                  return (
                    <DashboardVariable
                      key={widget.id}
                      name={widget.name}
                      icon={<DynamicIcon iconName={widget.icon} />}
                      columns={widget.width}
                      rows={widget.height}
                      top={widget.top}
                      left={widget.left}
                      dashboard={id}
                      widget={widget.id}
                      config={DashboardVariableConfig}
                      refreshPoint={refreshPoint}
                      useFilters={widget.useFilters}
                    />
                  );

                default:
                  return <Box>Widget Type missing, bad version??</Box>; // Handle unknown widget types
              }
            })}
          </Box>
        </Box>
      </Box>

    </PageBlock>
  );
};

export default Dashboard;
