import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "../../../components/global/NiceDateOnly";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoles, updateUserRole } from "../../../slices/auth";
import { setMessage ,clearMessage} from "../../../slices/message";
import CheckIcon from "@mui/icons-material/Check";
import NiceUser from "../../../components/global/NiceUser";
import NiceOrganization from "components/global/NiceOrganization";
import { CheckOutlined, CloseOutlined, DeleteOutlined, OpenInNewOutlined, RefreshOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";
import NiceActive from "components/global/NiceActive";

const UserRoles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.auth.user_roles);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const columnsVisability = {
    id: false,
    organization: false,
    orchestrator: false,
  };


  const handleActivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const role = { id: item, active: true };

      dispatch(updateUserRole({ role }));
    });

  }

  const handleDectivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const role = { id: item, active: false };

      dispatch(updateUserRole({ role }));
    });

  }


  const handleOpen = () => {

    navigate("/roles/" + selectionModel[0])
  }

  const actionMenuItems = [
    
    {
      text: "Details",
      icon:<OpenInNewOutlined/>,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon:<CheckOutlined/>,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon:<CloseOutlined/>,
      callBack: handleDectivate,
    },


    {
      text: "",
      icon:null,
      callBack: "",
      isDivider:true,
    },

    {
      text: "Delete",
      icon: <DeleteOutlined />,
      callBack: "",
    },

  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },

    {
      field: "username",
      headerName: "User",
      flex: 1,

      renderCell: ({ row: { username, id } }) => {
        return <NiceLink label={<NiceUser>{username}</NiceUser>} link={`/roles/${id}`} tip={"Open the Assignment"} makeBlue/>;
      },


    },
    {
      field: "roleId",
      headerName: "Role",
      flex: 1,
      //cellClassName: "name-column--cell",
      filterColumn: true,
    },
    {
      field: "objectType",
      headerName: "Applies to",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "objectId",
      headerName: "Specifically",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "active",
      headerName: "Role Active",
      type: "boolean",
      editable: true,
      renderCell: ({ row: { active } }) => {
        return <Box>{active && <CheckIcon />}</Box>;
      },
    },

    
  ];

  return (

    <PageBlock>
      <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="Roles" subtitle="" />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
          
          <TableActionsButton selectedRows={selectionModel} menuItems={actionMenuItems}/>

          <Button variant="contained" color="primary">
            Add role to user
          </Button>
        </FlexBetween>
      </FlexBetween>

      <TablePage
        dataRows={roles?.data}
        dataColumns={columns}
        dataGetter={getUserRoles}
        defaultColumnsVisability={columnsVisability}
        defaultSort={"-roleId"}
        refreshPoint={refreshPoint}
        selectionModel={selectionModel}
        selectionSetter={setSelectionModel}
      />
    </PageBlock>


  );
};

export default UserRoles;
