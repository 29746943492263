import React from "react";
import {
  Dialog,

  DialogContent,

  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import NiceMarkdown from "./NiceMarkdown";


const InfoDialogue = ({ open, onClose, title, content }) => {

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box >
        <Typography
          variant="h3"
          color={theme.palette.grey[900]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>

        <NiceMarkdown>{content}</NiceMarkdown>

          <Box display="flex" justifyContent="center" mt="2rem">
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                Ok
              </Button>

            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InfoDialogue;
