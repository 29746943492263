const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const DetailBlock = styled(Box) (({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: ".3rem",
  borderRadius:".2rem",
  gap:"2rem",
  backgroundColor: theme.palette.background.default,

  border: '1px solid', borderColor:  theme.palette.grey[300]
}));

export default DetailBlock;
