import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getCloudOrgStructureByType, getCloudOrgStructureElement } from "slices/cloudOrgStructure";



const NiceCloudOrgElement = (props) => {
  const dispatch = useDispatch();

  const wantedResource = props.children;

  //debugger

  const element = useSelector((state) =>
  state.cloudOrgStructure?.data?.list?.find(
    (org) =>
    org.id === wantedResource
  )
  );



  useEffect(() => {

        
    if (!element && wantedResource && wantedResource != "Cloud"){
        const id = wantedResource
        dispatch(getCloudOrgStructureElement({id}));

    }
  }, [wantedResource]);


  return (
    <>
      {element ? (
        <Box>{element.name}</Box>
      ) : (
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceCloudOrgElement;
