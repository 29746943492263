import { Box, useTheme, Button, TextField, Stack } from "@mui/material";
import Header from "../../../components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { tokens } from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import NiceDate from "../../../components/global/NiceDateOnly";
import ProfileAvatar from "../../../components/user/ProfileAvatar";
import { useParams, useSearchParams } from "react-router-dom";
import { getUser, updateUser } from "../../../slices/user";
import { setMessage, clearMessage } from "../../../slices/message";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import PasswordIcon from "@mui/icons-material/Password";
import ChangePassword from "../../../components/user/PasswordChange";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const UserDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const { username } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const thisUser = useSelector((state) =>
    state.users.data.list.find((user) => user.username === username)
  );

  useEffect(() => {
    if (!thisUser) {
      //need to get the currentUser to display
      //see if its in the users store

      dispatch(getUser({ username }));
    }

    // if (searchParams.get("mode") == "edit"){
    //   debugger;
    //   titleMode = "Edit "
    //   seteditMode(true)
    // }else{
    //   titleMode = ""
    //   seteditMode(false)
    // }
  }, [thisUser]);

  const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {};

    if (values) {
      Object.entries(values).forEach((entry) => {
        let key = entry[0];
        let value = entry[1];

        if (value !== initialValues[key]) {
          modifiedValues[key] = value;
        }
      });
    }

    return modifiedValues;
  };

  var initialValues = {
    firstName: thisUser?.firstName,
    lastName: thisUser?.lastName,
    email: thisUser?.email,
    note: thisUser?.note,
  };

  const handlePassword = () => {
    setPasswordOpen(true);
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
  });

  const [passwordOpen, setPasswordOpen] = React.useState(false);

  const handleClose = () => {
    setPasswordOpen(false);
  };

  return (
    <>
      <Dialog open={passwordOpen}>
        <DialogContent>
          <ChangePassword user={thisUser} closer={handleClose} />
        </DialogContent>
      </Dialog>

      {thisUser && (
        <Box m="1.5rem 2.5rem" height="75vh">
          <Header
            title={thisUser.firstName + " " + thisUser.lastName}
            subtitle={thisUser.username}
          />

          <Box>
            <Formik
              onSubmit={(values, actions) => {
                // this function should return the only modified values.
                const modifiedValues = getModifiedValues(values, initialValues);

                const user = {
                  username: username,
                  ...modifiedValues,
                };
                dispatch(clearMessage());

                dispatch(updateUser({ user }))
                  .unwrap()
                  .then(() => {
                    actions.setSubmitting(false);
                  })
                  .catch(() => {
                    actions.setSubmitting(false);
                  });
              }}
              validationSchema={checkoutSchema}
              initialValues={initialValues}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      width: 500,
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{ gridColumn: "span 4" }}
                    >
                      <ProfileAvatar user={thisUser} size="150" />
                    </Box>

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="First Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      name="firstName"
                      error={!!touched.firstName && !!errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Last Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      name="lastName"
                      error={!!touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                      sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 4" }}
                    />

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                      name="note"
                      error={!!touched.note && !!errors.note}
                      helperText={touched.note && errors.note}
                      sx={{ gridColumn: "span 4" }}
                    />

                    <Box
                      display="flex"
                      justifyContent="end"
                      mt="20px"
                      sx={{ gridColumn: "span 4" }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          startIcon={<PasswordIcon />}
                          onClick={() => handlePassword("edit")}
                        >
                          Change Password
                        </Button>

                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          Update User
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserDetail;
