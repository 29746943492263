import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Chip,
  Link,
  Tabs,
  Tab,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridPagination,
  GridCellModesModel,
  GridCellModes,
  GridToolbarExport,
  GridColumnsPanel,
  GridColumnsMenuItem,
} from "@mui/x-data-grid";

import {
  ArrowDropDown,
  Bolt,
  Cancel,
  CancelOutlined,
  RefreshOutlined,
  RestartAltOutlined,
  SaveOutlined,
  StopCircleOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import React, { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "slices/message";

import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import TabPanel from "components/global/TabPanel";
import PropTypes from "prop-types";
import { getWorkflowContributions } from "slices/worklow_contribution";
import { getBloxs } from "slices/blox";
import { getUtilities } from "slices/utility";
import ContributionCard from "./ContributionCard";
import { setSelectedNode } from "slices/workflowUI";
import StepConfig from "./StepConfig";

const ContributionPicker = ({ workflow_id, closer, node }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [avilableContributions, setAvilableContributions] = React.useState([]);

  const steps = useSelector((state) => state.workflowSteps.data.list);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handelCancel = () => {
    closer();
  };

  let options = [];

  const step = steps.find((step) => step.id === node?.data.stepId)

  const initialNodeData = {
    id: step?.implementorId,
    type: node?.data.type,
    name: node?.data.name,
    description: node?.data.description,
    implementor: step?.implementor,
    implementorId: step?.implementorId,
    workflow_id: workflow_id,
    stepId: step?.id,
    note: node?.data.note,
    role: step?.role,
  };

  const makeContributionSelection = (data) => {

    setSelectContribution(data)
    setTabValue(1)
  }

  const [selectedContribution, setSelectContribution] =
    React.useState(initialNodeData);


    const initialTabValue = selectedContribution.type === "Selecting" ? 0 : 1;

    const [tabValue, setTabValue] = React.useState(initialTabValue);

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const contriubtions = useSelector(
    (state) => state.workflowContributions.data
  );

  const blox = useSelector((state) => state.blox.data);

  const utilities = useSelector((state) => state.utility.data);

  const [checkedUtility, setCheckedUtility] = React.useState(true);

  const handleChangeUtility = (event) => {
    setCheckedUtility(event.target.checked);
  };

  const [checkedContribution, setCheckedContribution] = React.useState(true);

  const handleChangeContribution = (event) => {
    setCheckedContribution(event.target.checked);
  };

  const [checkedBlox, setCheckedBlox] = React.useState(true);

  const handleChangeBlox = (event) => {
    setCheckedBlox(event.target.checked);
  };

  useEffect(() => {
    const search = {};

    search.page = 0;
    search.page_size = 1000;
    search.filter = `[active]=true`;
    search.sort = "name";

    dispatch(getWorkflowContributions({ search }));
    dispatch(getBloxs({ search }));
    dispatch(getUtilities({ search }));
  }, []);

  useEffect(() => {
    let newContributions = [];

    //create a list of all contribution types

      contriubtions?.list.forEach((contribution) => {
        let newContribution = {
          id: contribution.id,
          type: "WorkflowContribution",
          name: contribution.name,
          description: contribution.description,
          implementor: contribution.id,
          implementorId: contribution.implementor,
          workflow_id: workflow_id,
          display: checkedContribution,
        };

        newContributions.push(newContribution);

  })
  

      // blox?.list.forEach((blox) => {
      //   let newContribution = {
      //     id: blox.id,
      //     type: "Blox",
      //     name: blox.name,
      //     description: blox.description,
      //     implementor: blox.id,
      //     implementorId: blox.implementor,
      //     workflow_id: workflow_id,
      //     display: checkedBlox,
      //   };

      //   newContributions.push(newContribution);
      // });


      utilities?.list.forEach((utility) => {
        let newContribution = {
          id: utility.id,
          type: "Utility",
          name: utility.name,
          description: utility.description,
          implementor: utility.id,
          implementorId: utility.implementor,
          workflow_id: workflow_id,
          display: checkedUtility,
        };

        newContributions.push(newContribution);
      });


    setAvilableContributions(newContributions);
  }, [
    contriubtions,
    blox,
    utilities,
    checkedUtility,
    checkedBlox,
    checkedContribution,
  ]);

  //stepper stuff

  //end of stepper


  
  const contributionData = avilableContributions.find((contribution) => contribution.implementorId === step?.implementorId)

  return (
    <Box mt="20px" sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Config Tabs"
        >
          <Tab label={initialTabValue == 0 ? "Select Contribution" : contributionData ? contributionData.name : selectedContribution.name} {...a11yProps(0)} />
          <Tab label="Configgure Contribution" {...a11yProps(1)} disabled={selectedContribution.type == "Selecting"} />
        </Tabs>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap=".5rem"
          mr="1rem"
        >
          {/* <Button
            variant="outlined"
            startIcon={<CancelOutlined />}
            sx={{ height: "2rem" }}
            onClick={handelCancel}
          >
            Cancel
          </Button> */}

          {/* <IconButton onClick={handelCancel}><CancelOutlined/></IconButton> */}
        </Box>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" m=".5rem">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedContribution}
                    onChange={handleChangeContribution}
                    inputProps={{ "aria-label": "contribution" }}
                  />
                }
                label="Contributions"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedBlox}
                    onChange={handleChangeBlox}
                    inputProps={{ "aria-label": "blox" }}
                  />
                }
                label="Blox"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedUtility}
                    onChange={handleChangeUtility}
                    inputProps={{ "aria-label": "utility" }}
                  />
                }
                label="Utilities"
              />

              {/* <FormControlLabel
                control={
                  <Autocomplete
                  size="small"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Controllable" />
                    )}
                  />
                }
                lebel="Contribution "
              /> */}
            </FormGroup>
          </Box>

          <Box display="flex" sx={{ overflow: "auto" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              p=".3rem"
              sx={{ overflow: "auto" }}
            >
              {avilableContributions.filter(item => item.display).map((item, index) => (

                <Grid item xs={2} sm={4} md={4} key={index}>
                  <ContributionCard
                    data={item}
                    selectContribution={makeContributionSelection}
                    isSelected={selectedContribution.id === item.id}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <StepConfig data={selectedContribution} closer={closer}  wfStep = {step}/>
      </TabPanel>
    </Box>
  );
};

export default ContributionPicker;
