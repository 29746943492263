import api from "./api";



const updateWorkflowContribution = (data) => {
  return api.patch("/workflowcontribution",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const updateWorkflowContributionActive = (data) => {
  return api.patch("/workflowcontribution/active",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};



const getWorkflowContributions = (search) => {

  return api.get("/workflowcontribution",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getWorkflowContribution = (id) => {
  return api.get("/workflowcontribution/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflowContributionUi = (id) => {
  return api.get("/workflowcontribution/ui/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflowContributionData = (search) => {

  return api.get("/workflowcontribution/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};




const WorkflowContributionService = {
  getWorkflowContributions,
  updateWorkflowContribution,
  getWorkflowContribution,
  getWorkflowContributionData,
  getWorkflowContributionUi,
  updateWorkflowContributionActive,
};

export default WorkflowContributionService;