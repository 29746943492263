import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import ConfigService from "services/config.service";


export const getConfig = createAsyncThunk(
  "Config/get",

  async ({}, thunkAPI) => {
    try {
      const data = await ConfigService.getConfig();
      return { Config: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getConfigUi = createAsyncThunk(
  "Config/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await ConfigService.getConfigUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateConfig = createAsyncThunk(
  "Config/update",
  async ({ config }, thunkAPI) => {
    try {
      const data = await ConfigService.updateConfig(config);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  values: {},
  loading: false,
  Ui: [],
}

const ConfigSlice = createSlice({
  name: "Config",
  initialState,

  extraReducers: {

    [updateConfig.fulfilled]: (state, action) => {
      state.values = action.payload.data
    },

    

    [getConfigUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getConfigUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getConfigUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },





    [getConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getConfig.fulfilled]: (state, action) => {

      //debugger;

      state.values = action.payload.Config;
      state.loading = false;
    },
    [getConfig.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});

const { reducer } = ConfigSlice;
export default reducer;
