import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  IconButton,
  ListItemText,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckOutlined, ClearOutlined, EditOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDimensionValues } from "slices/dimensions";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import NiceBoolean from "./NiceBoolean";
import { renderType } from "common/renderType";
import reactToString from "common/helpers";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = "Click To Edit";

const EditableFieldString = ({
  fieldType,
  field,
  initialValue,
  tip,
  rows,
  required,
  serviceImplemenator,
  serviceType,
  serviceDimension,
  serviceFilter,
  lookUpLabel,
  displayValue,
  uiRenderType,
  currencyCode,
  onChange = () => {},
  setIsUpdating,
  ...props
}) => {
  const dispatch = useDispatch();

  const searchRef = useRef(null);

  const [buttonClicked, setButtonClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = React.useState("");

  const [options, setOptions] = useState([]);

  const [isLookupBased, setIsLookupBased] = useState(
    serviceType && serviceImplemenator && serviceDimension
  );

  const values = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === serviceType &&
          dimension.implementor === serviceImplemenator &&
          dimension.field === serviceDimension
      )?.values
  );

  useEffect(() => {
    if (!values && isLookupBased) {
      if (serviceType && serviceImplemenator && serviceDimension) {
        let search = {
          type: serviceType,
          implementor: serviceImplemenator,
          field: serviceDimension,
          lookUpLabel: lookUpLabel,
        };

        dispatch(getServiceDimensionValues({ search }));
      }
    } else {
      if (isLookupBased) {
        // add the values here

        const newOptions = values?.map((value) => {
          //let renderedValue = value.label;

          let t = renderType(uiRenderType, value.value, "", "");

          //console.log("rednered type:", typeof renderedValue);

          return {
            label: value.value,
            niceVersion: value.label,
            niceLabel: "",
          };
        });

        setOptions(newOptions);
        setValue(newOptions.find((option) => option.label === initialValue));
      } else {
        // not lookup based so basic value
        setValue(initialValue);
      }
    }
  }, [values]);

  const handleChange = (e) => setValue(e.target.value);

  const handelEditMode = () => {
    setEditing(true);

    if (setIsUpdating !== undefined) {
      setIsUpdating(true);
    }
  };

  const handelDisplayMode = () => {
    setEditing(false);
    if (setIsUpdating !== undefined) {
      setIsUpdating(false);
    }
  };

  const handelSave = (e) => {
    if (isLookupBased) {
      onChange(value.label);
    } else {
      onChange(value);
    }
    handelDisplayMode();
  };

  const handelReset = (e) => {
    setValue(initialValue);
    handelDisplayMode();
  };

  console.log(
    "Lookup settings for: ",
    field,
    " in lookup mode : ",
    isLookupBased
  );
  console.log(
    "Field: ",
    field,
    "\nService Type: ",
    serviceType,
    "\nService Implemenator: ",
    serviceImplemenator,
    "\nDimensionID: ",
    serviceDimension,
    "\nOptions: ",
    options,
    "\nCurrent Value: ",
    value
  );

  if (isEditing) {
    if (isLookupBased) {
      //debugger

      return (
        <Box
          display={"flex"}
          alignContent={"start"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          <Autocomplete
            ref={searchRef}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="search"
            options={options}
            sx={{ mb: "0.4rem", flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                //label="Search"
                variant="standard"
                size="small"
              />
            )}
            getOptionLabel={(option) => (option ? option.niceVersion : value)}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.niceVersion, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.niceVersion, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />

          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              fontSize="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          alignContent={"start"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          <TextField
            size="small"
            variant="standard"
            type={fieldType}
            value={value}
            rows={rows}
            multiline={rows > 0}
            onChange={handleChange}
            onBlur={() => {
              if (!buttonClicked) {
                handelReset();
              }
            }}
            autoFocus
            //sx={{flexGrow: 1}}
            sx={{ flex: 1 }}
          />
          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    }
  }

  return (
    <Tooltip title={tip} placement="bottom-start">
      <Stack gap=".2rem" direction="row" alignItems={"flex-start"}
        p={"2px"}
        onClick={handelEditMode}
        // sx={{
        //   borderRadius: 1,
        //   cursor: "pointer",
        //   border: "1px solid transparent", // Initially transparent border
        //   "&:hover": {
        //     border: "1px solid #000", // Change border color on hover
        //   },
        // }}

        sx={{
          cursor: "pointer",
        }}


      >
        {displayValue}

        <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <EditOutlined fontSize="small" />
            </IconButton>

      </Stack>
    </Tooltip>
  );
};

export default EditableFieldString;
