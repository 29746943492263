import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getServiceDimensionValues } from "slices/dimensions";




const NiceCatalogue = (props) => {

  const dispatch = useDispatch();


  const wantedResource = props.children;

  const catalogue = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "kernel" &&
          dimension.implementor === "catalogue" &&
          dimension.field === "id"
      )?.values.find((catalogue) => catalogue.value === wantedResource)
  );


  //console.log("Requested Catalogue: " , wantedResource , " Found " , catalogue)
  
  useEffect(() => {
    //on mount get some defaults

    if (!catalogue) {
      let search = {
        type: "kernel",
        implementor: "catalogue",
        field: "id",
        lookUpLabel: "<name>",
        id: wantedResource
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    
  }, []);


  return (
    <>
      {catalogue ? (
        <Box>{catalogue.label}</Box>
      ) : (
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceCatalogue;
