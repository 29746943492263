import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Provider } from 'react-redux'
import store from './store'
import setupInterceptors from "./services/setupInterceptors";

import App from "./App";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);

setupInterceptors(store);
