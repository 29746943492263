import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import {
  ErrorOutlineOutlined,
  LightbulbOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";

import { createCatalogueImage } from "slices/catalogue";

import MDEditor from "@uiw/react-md-editor";
import { commands } from "@uiw/react-md-editor";

import NiceMarkdown from "components/global/NiceMarkdown";

const CataloguePageEditor = ({page, handleSetPageUpdated , handleUpdateContent}) => {
  const dispatch = useDispatch();

  const [isProcessingImage, setIsProcessingImage] = useState(false);

  const [markdown, setMarkdown] = useState(page.content);

  console.log("editing: ", markdown)

  const handlePageChanged = (markdown) => {
    setMarkdown(markdown)
    handleSetPageUpdated(true)
    handleUpdateContent(markdown)
  }



  // useEffect(() => {
  //   setMarkdown(page?.content);
  // },[]);

  const handlePaste = async (event) => {
    if (!isProcessingImage) {
      setIsProcessingImage(true);

      const clipboardData = event.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // Check if the clipboard item is an image
        if (item.type.startsWith("image/")) {
          const blob = item.getAsFile();

          //debugger

          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64Image = reader.result.split(",")[1]; // Remove the data:image/png;base64, part

              // Prepare the newImage object with base64 encoded image
              const newImage = {
                image: base64Image,
              };

              // Perform the upload and handle the pasted blob
              dispatch(createCatalogueImage({ newImage }))
                .unwrap()
                .then((imageReturn) => {
                  const newImageData = imageReturn.imageData.data;

                  if (imageReturn && imageReturn.imageData.status === 200) {
                    // Insert the uploaded image as Markdown
                    document.execCommand(
                      "insertText",
                      false,
                      `![${newImageData.imageName}](${newImageData.imageUrl})\n`
                    );
                  } else {
                    document.execCommand(
                      "insertText",
                      false,
                      "ERROR: Image has not been stored on the server"
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error uploading image:", error);
                  document.execCommand(
                    "insertText",
                    false,
                    "ERROR: Image upload failed"
                  );
                })
                .finally(() => {
                  setIsProcessingImage(false); // Reset isProcessingImage after the asynchronous operations are finished
                });

              event.preventDefault();
            };

            //reader.readAsDataURL(blob); // Start reading the blob as data URL
          }
        }
      }
    }
  };

  const helpUrl = "/catalogue/663a19db1242002d9120d634";

  // Create a custom help command
  const customHelpCommand = {
    name: "help",
    keyCommand: "help",
    buttonProps: { "aria-label": "Help" },
    icon: (
      <svg width="12" height="12" viewBox="0 0 20 20">
        <path
          fill="currentColor"
          d="M9.66 0a9.66 9.66 0 109.66 9.66A9.67 9.67 0 009.66 0zm1 14.88a1.15 1.15 0 11-1.15 1.15 1.15 1.15 0 011.15-1.15zm1.39-4.48l-.65.63a2.11 2.11 0 00-.6 1.08v.37H8.66v-.47a3 3 0 01.79-1.54l.93-.91a1.44 1.44 0 00.39-1 1.52 1.52 0 00-2.61-1 1.51 1.51 0 00-.31 1.06H6.5a2.93 2.93 0 01.41-1.59 3.15 3.15 0 01.84-.93 3.56 3.56 0 015 1.42 3.27 3.27 0 01-.3 3.24z"
        />
      </svg>
    ),
    execute: () => window.open(helpUrl, "_blank"),
  };

  const customNoteCommand = {
    name: "note",
    keyCommand: "note",
    buttonProps: { "aria-label": "Note", title: "Add a Note" },
    icon: <ErrorOutlineOutlined />,
    execute: (state, api) => {
      // Insert the :note text at the cursor position
      const noteText = ":note ";
      api.replaceSelection(noteText);
      // Move the cursor to the end of the inserted text
      api.setSelectionRange({
        start: state.selection.start + noteText.length,
        end: state.selection.start + noteText.length,
      });
    },
  };

  const customTipCommand = {
    name: "tip",
    keyCommand: "tip",
    buttonProps: { "aria-label": "Note", title: "Add a Tip" },
    icon: <LightbulbOutlined />,
    execute: (state, api) => {
      // Insert the :tip text at the cursor position
      const tipText = ":tip ";
      api.replaceSelection(tipText);
      // Move the cursor to the end of the inserted text
      api.setSelectionRange({
        start: state.selection.start + tipText.length,
        end: state.selection.start + tipText.length,
      });
    },
  };

  const customWarningCommand = {
    name: "warning",
    keyCommand: "warning",
    buttonProps: { "aria-label": "Note", title: "Add a Warning" },
    icon: <WarningAmberOutlined />,
    execute: (state, api) => {
      // Insert the :warning text at the cursor position
      const warningText = ":warning ";
      api.replaceSelection(warningText);
      // Move the cursor to the end of the inserted text
      api.setSelectionRange({
        start: state.selection.start + warningText.length,
        end: state.selection.start + warningText.length,
      });
    },
  };

  const defaultCommands = commands.getCommands().filter((cmd) => {
    if (cmd.name === "title") {
      // If the command is 'title', filter its children array
      cmd.children = cmd.children.filter((child) => child.name !== "title6");
    }
    return cmd.name !== "help";
  });

  // Merge the custom help command with the default commands
  let customToolbar = [...defaultCommands, customHelpCommand];

  //debugger

  // Find the index of the divider command
  const dividerIndex = customToolbar.findIndex((cmd) => cmd.name === "help");

  const customToolbarFinal = [
    ...customToolbar.slice(0, dividerIndex),
    customNoteCommand,
    customTipCommand,
    customWarningCommand,
    { keyCommand: "divider" },
    ...customToolbar.slice(dividerIndex),
  ];

  return (
    <MDEditor
      commands={customToolbarFinal}
      value={markdown}
      onChange={handlePageChanged}
      height={"100%"}
      style={{ width: "100%" }}
      preview="live"
      textareaProps={{
        spellCheck: true,
        onPaste: handlePaste,
      }}
      components={{
        preview: (source) => {
          return <NiceMarkdown>{source}</NiceMarkdown>;
        },
      }}
    />
  );
};

export default CataloguePageEditor;
