import React, { useRef } from "react";
import {
  Toolbar,
  Stack,
  Divider,
  Box,
  Typography,
  Button,
  List,
  Fab,
  Paper,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  FormControl,
  FormGroup,
} from "@mui/material";
import {
  PsychologyOutlined,
  SendOutlined,
  PlaylistAddOutlined,
  CheckBox,
} from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import CopilotResponse from "./copilotResponse";
import {
  createCopilotConversation,
  createCopilotPrompt,
  getCopilotConversation,
  getCopilotPrompts,
} from "slices/copilot";
import useInterval from "common/useInterval";

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(6)} + 1px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(7)})`,
  // },

  width: 0,
});

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  zIndex: (theme) => theme.zIndex.drawer + 10,
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
  width: drawerwidth,
  flexShrink: 0,
  whiteSpace: "pre-wrap",
  //boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerwidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ResponsePaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  margin: "10px 0 0px 0",
  padding: theme.spacing(1),
  //...theme.typography.body1,
  maxHeight: "calc(100vh - 360px)", // set for a text area of 4 rows
  //maxWidth: "200px",
  overflowY: "auto",
}));

const Copilot = ({
  drawerWidth,
  isCopilotOpen,
  setIsCopilotOpen,
  isNonMobile,
}) => {
  //const navigate = useNavigate();
  //const theme = useTheme();

  const listRef = useRef(null);

  const dispatch = useDispatch();

  //console.log("API:" , swagger_path)

  const { user: currentUser } = useSelector((state) => state.auth);

  const currentConversationId = useSelector((state) =>
    state.auth?.user?.preferences?.find(
      (preference) => preference?.name === "currentConversation"
    )
  )?.preferenceValue;

  const currentConversation = useSelector((state) =>
    state.copilot.conversation.data.list.find(
      (conversation) => conversation.id === currentConversationId
    )
  );

  const prompts = useSelector((state) =>
    state.copilot.prompt.data.list.filter(
      (prompt) => prompt.conversationId === currentConversationId
    ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) 
  );

  const lastPromptId = prompts.length > 0 ? prompts[prompts.length - 1].id : null;

  const isConversationIsWaitingForResponse = prompts?.some(
    (item) => item.jobStatus === "Pending" || item.jobStatus === "Running"
  );

  //console.log("Conversation is waiting:", isConversationIsWaitingForResponse);

  useInterval(() => {
    if (isConversationIsWaitingForResponse) {
      const search = {
        id: currentConversationId,
        idField: "conversationId",
      };

      dispatch(getCopilotPrompts({ search }));
    }
  }, 500);

  useEffect(() => {
    if (!currentConversation && currentConversationId) {
      const id = currentConversationId;
      dispatch(getCopilotConversation({ id }));
    }
  }, [currentConversation, dispatch, currentConversationId]);

  useEffect(() => {
    const search = {
      id: currentConversationId,
      idField: "conversationId",
    };

    dispatch(getCopilotPrompts({ search }));
  }, [currentConversationId, dispatch]);

  //console.log("console ", currentConversation , currentConversationId )

  useEffect(() => {
    // Scroll to the last item when items change

    if (listRef.current) {
      const list = listRef.current;
      const lastItem = list.lastElementChild;
      if (lastItem) {
        lastItem.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [prompts]);

  const [prompt, setPrompt] = React.useState("");
  const [promptLength, setPromptLength] = React.useState(0);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handelSendPrompt();
      event.stopPropagation();
    }
  };

  const constructCommaSeparatedStringOfKnowlageArea = () => {
    const keysToInclude = ['web', 'opsblox', 'company', 'consumption'];
    return keysToInclude
      .filter(key => state[key])
      .join(',');
  };

  const handelSendPrompt = () => {
    const copilotPrompt = {
      conversationId: currentConversationId,
      prompt: prompt,
      knowlageArea: constructCommaSeparatedStringOfKnowlageArea()
    };

    dispatch(createCopilotPrompt({ copilotPrompt }));
    setPrompt("");
    setPromptLength(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const handelUpdatePrompt = (event) => {
    setPrompt(event.target.value);
    setPromptLength(event.target.value.length);
  };

  const handelNewChat = () => {
    const copilotConversation = {
      user: currentUser.user.username,
    };
    dispatch(createCopilotConversation({ copilotConversation }));
  };

  

  const [state, setState] = React.useState({
    everywhere: true,
    web: true,
    opsblox: true,
    company: true,
    consumption: true,
  });

  const { everywhere, web, opsblox, company, consumption } = state;

  const handleChange = (event) => {
    const { name, checked } = event.target;

    // If 'everywhere' is changed, update other checkboxes accordingly
    if (name === 'everywhere') {
      setState((prevState) => ({
        everywhere: checked, 
        web: checked ? true :prevState.web,
        opsblox: checked ? true : prevState.opsblox,
        company: checked ? true : prevState.company,
        consumption: checked ? true : prevState.consumption,
      }));
    } else {
      setState({
        ...state,
        [name]: checked,
      });
    }
  };

  return (
    <Drawer
      open={isCopilotOpen}
      onClose={() => setIsCopilotOpen(false)}
      variant="permanent"
      anchor="right"
      drawerwidth={`${drawerWidth}%`}
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          //boxSizing: "border-box",
          borderWidth: isNonMobile ? 1 : "2px",
          whiteSpace: "pre-wrap",
        },
      }}
    >
      <Toolbar />
      <Box
        p={1}
        display={"flex"}
        flexDirection={"column"}
        //justifyContent={"space-between"}
        height={"100%"}
        maxHeight={"100%"}
        width={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          gap="0rem"
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack gap="1" direction={"row"} mb={0.0}>
              <PsychologyOutlined sx={{ fontSize: "30px" }} />
              <Typography
                variant="h3"
                //color={theme.palette.secondary[100]}
                fontWeight="bold"
              >
                Copilot
              </Typography>
            </Stack>

            <Button
              variant="contained"
              color="primary"
              onClick={handelNewChat}
              sx={{ mt: 1, mb: 1 }}
              endIcon={<PlaylistAddOutlined />}
            >
              New
            </Button>
          </Box>

          {currentConversation?.name}
          <Divider />
        </Box>

        <ResponsePaper variant="outlined">
          <List ref={listRef}>
            {prompts.map((prompt) => {
              return (
                <React.Fragment key={prompt.id}>
                  <CopilotResponse
                    key={prompt.id + "_prompt"}
                    user={currentUser}
                    message={prompt.prompt}
                    id={prompt.id + "_prompt"}
                  />
                  <CopilotResponse
                    key={prompt.id + "_response"}
                    user={"Copilot"}
                    message={prompt.response}
                    isWaiting={
                      prompt.jobStatus === "Pending" ||
                      prompt.jobStatus === "Running"
                    }
                    id={prompt.id + "_response"}
                    lastPrompt={lastPromptId === prompt.id}
                    copilotPrompt={prompt}
                  />
                </React.Fragment>
              );
            })}
          </List>
        </ResponsePaper>

        {/* Options for search */}

        <Box display="flex" justifyContent={"left"} alignContent={"center"} ml={"1rem"}>
          <FormGroup component="fieldset" row>


            <FormControlLabel
              control={
                <Checkbox
                  checked={everywhere}
                  onChange={handleChange}
                  name="everywhere"
                  size="small"
                />
              }
              label={
                <Typography style={{ fontSize: "0.8rem" }}>Everywhere</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={web}
                  onChange={handleChange}
                  name="web"
                  size="small"
                  disabled={everywhere} 
                />
              }
              label={
                <Typography style={{ fontSize: "0.8rem" }}>Web</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={opsblox}
                  onChange={handleChange}
                  name="opsblox"
                  size="small"
                  disabled={everywhere} 
                />
              }
              label={
                <Typography style={{ fontSize: "0.8rem" }}>OpsBlox</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={company}
                  onChange={handleChange}
                  name="company"
                  size="small"
                  disabled={everywhere} 
                />
              }
              label={
                <Typography style={{ fontSize: "0.8rem" }}>My Company</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={consumption}
                  onChange={handleChange}
                  name="consumption"
                  size="small"
                  disabled={everywhere} 
                />
              }
              label={
                <Typography style={{ fontSize: "0.8rem" }}>
                  Consumption
                </Typography>
              }
            />
          </FormGroup>
        </Box>

        <TextField
          id="prompt"
          placeholder="I want to...(Shift + Enter for new line)"
          multiline
          rows={4}
          helperText={`${promptLength} / 1000`}
          value={prompt}
          onChange={handelUpdatePrompt}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
        />
        <Fab sx={fabStyle} size="small" onClick={handelSendPrompt}>
          <SendOutlined />
        </Fab>
      </Box>
    </Drawer>
  );
};

export default Copilot;
