import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetKpiAggregateValues,
  getWidgetUtilityAggregateValues,
} from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";
import moment from "moment";
import {
  getBillingPeriodForReportingPeriod,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
  processStringForDashboardFilters,
  niceFormatData,
} from "../../../common/helpers";
import { tokens } from "theme";
import { useSearchParams } from "react-router-dom";

import {
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const WidgetLineChart = ({
  name,
  icon,
  top,
  left,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
  useFilters,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const lineColour = "black";

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  //console.log("Passed config: ", config);
  const title = config?.title;
  const description = config?.description;

  const serviceType = config?.serviceType;
  const currencyField = config?.currencyField;
  const valueField = config?.valueField;
  const groupBy = config?.groupBy;
  const sortBy = config?.sortBy;
  let indexBy = config?.indexBy;
  const xAxisFormatter = config?.xAxisFormatter;
  const yAxisFormatter = config?.yAxisFormatter;
  const showLabel = config?.showLabel;
  const showLegend = config?.showLegend;


  const yAxisRightvalueField = config?.yAxisRightValueField;
  const yAxisRightFormatter = config?.yAxisRightFormatter;

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const renderTitle = processStringForDashboardFilters(title, renderState);
  const renderDescription = processStringForDashboardFilters(
    description,
    renderState
  );

  const columnsForRendering = useSelector(
    (state) =>
      state.dashboards.columnsForRendering?.find(
        (widgetData) =>
          widgetData.dashboard === dashboard && widgetData.widget === widget
      )?.columns
  );

  //calculate the when range based on the selected dimension

  const period = parsePeriod(config?.period, searchParams);

  const billingPeriod = getBillingPeriodForReportingPeriod(
    config?.billingPeriod
  );

  const dimension = parseDimension(config?.dimension, searchParams);

  const filter = getWhenFilterForReportingPeriod(period);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard && widgetData.widget === widget
    )
  );

  //console.log("Widget data ", widgetData)

  console.log(
    "render line chart: ",
    widget,
    " refresh time of ",
    refreshPoint,
    " ",
    renderTitle
  );

  //Get the widget Data along with the Columns this Widget Provides
  useEffect(() => {
    //debugger

    if (config) {
      //need to augment the filter, we have the filter from the periods now add what is set in the renderstate

      let localFilter = filter;

      //debugger;

      if (useFilters) {
        localFilter = localFilter + "|" + renderState.filter;
      }

      //process the fileds list looking for negitives and remove them
      let fields = valueField.replace(/(^|,)-/g, "$1");

      //add the file for thr right axis if needed
      if (yAxisRightvalueField) {
        fields += "," + yAxisRightvalueField;
      }

      let search = {
        dashboard: dashboard,
        widget: widget,

        function: config.function,
        id: config.implementorId,
        fields: fields,
        dimension: dimension,

        //descriptors: currencyField,
        billingPeriod: billingPeriod,
        groupBy: groupBy,
      };

      if (currencyField) {
        search.descriptors = currencyField;
      }

      // Split the string on '|'
      const parts = localFilter.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");

      //debugger;

      switch (serviceType) {
        case "Kpi":
          console.log("Dispatching getWidgetKpiAggregateValues:", search);
          dispatch(getWidgetKpiAggregateValues({ search }));

          break;

        case "Utility":
          console.log("Dispatching getWidgetUtilityAggregateValues:", search);
          dispatch(getWidgetUtilityAggregateValues({ search }));

          break;

        default:

          console.log("not implemented")
      }

      search = {
        dashboard: dashboard,
        widget: widget,
        id: config.implementorId,
        serviceType: serviceType,
      };

      console.log("Dispatching getWidgetColumnValues:", search);
      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dashboard, widget, config, refreshPoint]);

  if (!widgetData?.rows || !config) {
    return <Box>Loading...</Box>;
  }

  const sortedData = [...widgetData?.rows].sort((a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];

    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    } else {
      return 0;
    }
  });

  //debugger;

  let groupedData = [];
  let series = [];

  const groupBys = groupBy.split(",");
  const fields = valueField.split(",");

  if (groupBys.length > 2) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography
              variant="h4"
              sx={{ color: theme.palette.text.main }}
            >
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            To many Group By fields
          </Typography>
        </Box>
      </Box>
    );
  }

  if (groupBys.length > 1 && fields.length > 1) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        backgroundColor={theme.palette.background.alt}
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography
              variant="h4"
              sx={{ color: theme.palette.text.main }}
            >
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            You cant have multi group by fields as well as multi value fileds,
            pick one
          </Typography>
        </Box>
      </Box>
    );
  }

  if (groupBys.length === 2) {
    //process the groupbys into the stacked data set needed for the graphs
    console.log("Data befor group:", sortedData);

    const groupField = groupBys.filter((item) => item !== indexBy);

    groupedData = sortedData?.reduce((accumulator, currentItem) => {
      const existingEntry = accumulator.find(
        (entry) => entry.when === currentItem.when
      );

      // Function to round numeric values to 2 decimal places
      const roundNumericValue = (value) =>
        typeof value === "number" ? parseFloat(value.toFixed(2)) : value;

      if (existingEntry) {
        existingEntry[currentItem[groupField]] = roundNumericValue(
          currentItem[valueField]
        );
      } else {
        const newEntry = {
          when: currentItem.when,
          [currentItem[groupField]]: roundNumericValue(currentItem[valueField]),
        };
        accumulator.push(newEntry);
      }

      return accumulator;
    }, []);

    //create keys array, this is all fields in the groupd data minus the index by

    series = Array.from(
      new Set(
        groupedData?.reduce((fieldNames, entry) => {
          Object.keys(entry).forEach((fieldName) => {
            if (
              fieldName !== indexBy &&
              !fieldNames.some((obj) => obj.dataKey === fieldName)
            ) {
              //define the item for the series
              let item = {
                dataKey: fieldName,
              };

              item.label = fieldName;

              //set if this is the right hand line component
              if (fieldName === yAxisRightvalueField) {
                item.type = "line";
                item.yAxisKey = "rightAxisId";

                //add formatting if needed
                if (yAxisRightFormatter) {
                  item.valueFormatter = (value) =>
                    niceFormatData(yAxisRightFormatter, value, currency);
                }
              } else {
                item.type = "line";
                item.yAxisKey = "leftAxisId";

                //add formatting if needed
                if (yAxisFormatter) {
                  item.valueFormatter = (value) =>
                    niceFormatData(yAxisFormatter, value, currency);
                }
              }

              fieldNames.push(item);
            }
          });
          return fieldNames;
        }, [])
      )
    );

    console.log("Data after group:", groupedData);
  } else {
    //check if we have an array of value fields

    if (fields.length > 1) {
      //process the fields into the stacked data set needed for the graphs
      console.log("Data befor field group:", sortedData);

      // const keyToRemove = "descriptor_" + currencyField;

      // groupedData= sortedData.map(obj => {
      //   // Destructure the object and create a new one without the specified key
      //   const { [keyToRemove]: removedKey, ...rest } = obj;
      //   return rest;
      // });

      groupedData = sortedData.map((obj) => {
        // Destructure the object and create a new one without the specified key
        const { ["descriptor_" + currencyField]: removedKey, ...rest } = obj;

        // Round numeric values to 2 decimal places
        const roundedValues = Object.keys(rest)?.reduce((acc, key) => {
          // acc[key] =
          //   typeof rest[key] === "number"
          //     ? parseFloat(rest[key].toFixed(2))
          //     : rest[key];

          const roundedValue =
            typeof rest[key] === "number"
              ? parseFloat(rest[key].toFixed(2))
              : rest[key];
          acc[key] = roundedValue;

          // Check if the key is in valueFields and starts with a negitive

          const negKey = "-" + key;

          if (fields.includes(negKey)) {
            acc[key] = -roundedValue; // Negate the rounded value
          }

          return acc;
        }, {});

        return roundedValues;
      });

      //create series array, this is all fields in the groupd data minus the index by

      console.log("Columns: ", columnsForRendering);
      console.log("Widget: ", widget);

      series = Array.from(
        new Set(
          groupedData?.reduce((fieldNames, entry) => {
            Object.keys(entry).forEach((fieldName) => {
              if (
                fieldName !== indexBy &&
                !fieldNames.some((obj) => obj.dataKey === fieldName)
              ) {
                //define the item for the series
                let item = {
                  dataKey: fieldName,
                };

                //lookup human name for field
                const name = columnsForRendering.find(
                  (col) => col.field === fieldName
                )?.headerName;
                item.label = name;

                //set if this is the right hand line component
                if (fieldName === yAxisRightvalueField) {
                  item.type = "line";
                  item.yAxisKey = "rightAxisId";

                  //add formatting if needed
                  if (yAxisRightFormatter) {
                    item.valueFormatter = (value) =>
                      niceFormatData(yAxisRightFormatter, value, currency);
                  }
                } else {
                  item.type = "line";
                  item.yAxisKey = "leftAxisId";


                  //add formatting if needed
                  if (yAxisFormatter) {
                    item.valueFormatter = (value) =>
                      niceFormatData(yAxisFormatter, value, currency);
                  }
                }

                fieldNames.push(item);
              }
            });
            return fieldNames;
          }, [])
        )
      );

      console.log("Data after field group:", groupedData);
    } else {
      //no array of fields
      //force the index by to be the single group by

      //groupedData = [...sortedData];

      groupedData = sortedData.map((item) => {
        const roundedItem = { ...item }; // Create a shallow copy of the item

        // Loop through each property in the item
        for (const key in roundedItem) {
          if (Object.prototype.hasOwnProperty.call(roundedItem, key)) {
            // Check if the value is a number
            if (typeof roundedItem[key] === "number") {
              // Round numeric values to 2 decimal places
              roundedItem[key] = parseFloat(roundedItem[key].toFixed(2));

              // Check if the key is in valueFields and starts with a negitive

              const negKey = "-" + key;

              if (fields.includes(negKey)) {
                roundedItem[key] = -roundedItem[key]; // Negate the rounded value
                //debugger
              }
            }
          }
        }

        return roundedItem;
      });

      indexBy = groupBy;

      const noNegField = valueField.replace(/(^|,)-/g, "$1");

      let item = {
        dataKey: noNegField,
      };

      //lookup human name for field
      const name = columnsForRendering.find(
        (col) => col.field === noNegField
      )?.headerName;
      item.label = name;

      item.type = "line";
      item.yAxisKey = "leftAxisId";

      //add formatting if needed
      if (yAxisFormatter) {
        item.valueFormatter = (value) =>
          niceFormatData(yAxisFormatter, value, currency);
      }

      series.push(item);
    }
  }

  console.log("Series:", series);

  const currency = widgetData?.rows[0]?.["descriptor_" + currencyField];

  //debugger;

  const yAxisLabel = config?.yAxisLabel
    ? config?.yAxisLabel
    : currency
    ? currency
    : "No yAxis set";

  const yAxisRightLabel = config?.yAxisRightLabel
    ? config?.yAxisRightLabel
    : currency
    ? currency
    : "No yAxis set";

  const xAxisLabel = config?.xAxisLabel
    ? config?.xAxisLabel
    : groupBy
    ? groupBy
    : "No xAxis set";

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
      borderRadius="0.55rem"
    >
      <Box display="flex" flexDirection="column">
        <FlexBetween>
          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            {renderTitle}
          </Typography>
          {icon}
        </FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main }}>
          {renderDescription}
        </Typography>
      </Box>

      {groupedData.length > 0 && (
        <ResponsiveChartContainer
          onClick={(e, k, l) => {
            console.log(k);
            console.log(l);
          }} //story 497 need to get the pull request for this https://github.com/mui/mui-x/issues/10549
          dataset={groupedData}
          series={series}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "when",
              id: "x-axis-id",
              valueFormatter: (value) =>
                niceFormatData(xAxisFormatter, value, currency),
              tickLabelStyle: {
                angle: 45,
                textAnchor: "start",
                fontSize: 12,
              },
            },
          ]}
          yAxis={[
            {
              id: "leftAxisId",
              scaleType: "linear",
              valueFormatter: (value) =>
                niceFormatData(yAxisFormatter, value, currency),
            },
            {
              id: "rightAxisId",
              scaleType: "linear",
              valueFormatter: (value) =>
                niceFormatData(yAxisRightFormatter, value, currency),
            },
          ]}
          rightAxis="rightAxisId"
          leftAxis="leftAxis"
          margin={{ left: 70, right: 70, bottom: 90 }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-25px, 0)",
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              transform: "translate(30px, 0)",
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              transform: "translate(0px, 50px)",
            },
          }}
        >
          {showLegend && <ChartsLegend direction="row" />}

          <LinePlot />
          <MarkPlot />

          <ChartsXAxis
            label={xAxisLabel}
            position="bottom"
            axisId="x-axis-id"
          />

          <ChartsYAxis axisId="leftAxisId" position="left" label={yAxisLabel} />

          {yAxisRightvalueField && (
            <ChartsYAxis
              axisId="rightAxisId"
              position="right"
              label={yAxisRightLabel}
            />
          )}

          <ChartsTooltip />
          <ChartsAxisHighlight
            x={"line"}
            y={"line" }
          />
        </ResponsiveChartContainer>
      )}
    </Box>
  );
};

export default WidgetLineChart;
