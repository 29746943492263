import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";

import {
  WidgetsOutlined,
  PlayCircleOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  RefreshOutlined,
  ArrowDropDown,
  DeleteOutline,
  PlayArrowOutlined,
  CheckOutlined,
  CompressOutlined,
  CloseOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getUtilities,
  updateUtilityActive,
  runUtility,
  getUtilityServiceUi,
} from "slices/utility";
import { setMessage, clearMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";
import NiceActive from "components/global/NiceActive";
import { renderColumn } from "common/renderColumn";
import { processString } from "common/helpers";

const Utilities = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.utility.data);

  const ui = useSelector((state) => state.utility.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getUtilityServiceUi({}));
  }, [rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  let columns = [];

  if (ui?.columns) {
    columns = [...ui.columns] 
      .sort((a, b) => (Number(a.sequence) > Number(b.sequence) ? 1 : -1))
      .map((column) => {
        const updatedColumn = { ...column }; // create a copy of the original object

        //debugger;

        if (updatedColumn.makeLink) {
          //modify to wrap in a nice link

          const originalRenderCell = updatedColumn.renderCell;

          updatedColumn.renderCell = ({ row }) => {
            const labelToUse =
              originalRenderCell !== ""
                ? renderColumn(column, row)
                : processString(updatedColumn.linkLabel, row);

            return (
              <NiceLink
                label={labelToUse}
                link={processString(updatedColumn.linkAddress, row)}
                tip={processString(updatedColumn.helperText, row)}
                makeBlue={updatedColumn.makeLinkBlue}
                showFollowIcon={updatedColumn.makeLinkIcon}
              />
            );
          };
        } else {
          if (updatedColumn.renderCell !== "") {
            // check if renderCell exists
            updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
          }
        }

        return updatedColumn;
      });
  }

  const handleRun = () => {
    dispatch(clearMessage());

    selectionModel.forEach((id) => {
      dispatch(runUtility({ id }));
    });
  };

  const handleActivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const utility = { id: item, active: true };

      dispatch(updateUtilityActive({ utility }));
    });
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const utility = { id: item, active: false };

      dispatch(updateUtilityActive({ utility }));
    });
  };

  const handleOpen = () => {
    navigate("/utility/" + selectionModel[0]);
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon: <CheckOutlined />,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon: <CloseOutlined />,
      callBack: handleDectivate,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Run",
      icon: <PlayArrowOutlined />,
      callBack: handleRun,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: "",
    },
  ];

 

  return (
    <PageBlock>
      <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="Utilities" subtitle="" />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          <TableActionsButton
            selectedRows={selectionModel}
            menuItems={actionMenuItems}
          />

          <Button variant="contained" color="primary">
            New Custom Utility
          </Button>
        </FlexBetween>
      </FlexBetween>

      {rows && ui?.columns?.length > 0 ? (
        <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getUtilities}
          defaultColumnsVisability={ui.columnsVisability}
          defaultSort={ui.sort}
          refreshPoint={refreshPoint}
          selectionModel={selectionModel}
          selectionSetter={setSelectionModel}
          serviceType={"utility"}
        />
      ) : (
        <Typography>Loading</Typography>
      )}
    </PageBlock>
  );
};

export default Utilities;
