import { createSlice } from "@reduxjs/toolkit";

const initialNodes = [];
const initialEdges = [];
const defaults = {
  nodeSpacing: 100,
  menuSpacing: 120,
  firstNodeY: 10,
  xOffset: 5,
};

const initialState = {
  nodes: initialNodes,
  edges: initialEdges,
  defaults: defaults,
  menuOpen: false,
  selectedNode: null,
};

const createEdges = (state) => {
  let newEdges = [];

  let previousID = "1";
  let previousNodeType = "";

  let secondNode = state.nodes[1];
  let edgeType = "buttonEdge";

  if (secondNode.type === "menuNode") {
    edgeType = "";
  }

  newEdges.push({
    id: "e1-2",
    source: "1",
    target: "2",
    type: edgeType,
  });

  previousNodeType = secondNode.type;

  state.nodes.forEach((node) => {
    //debugger;

    if (previousID > 1) {
      let eId = `e${previousID}-${node.id}`;

      if (node.type === "menuNode" || previousNodeType === "menuNode") {
        edgeType = "";
      } else {
        edgeType = "buttonEdge";
      }

      newEdges.push({
        id: eId,
        source: previousID,
        target: node.id,
        type: edgeType,
      });
    }
    previousID = node.id;
    previousNodeType = node.type;
  });

  return newEdges;
};

const createNodeSpacing = (state, nodes) => {
  //debugger;

  let currentY = state.defaults.firstNodeY;
  let firstNode = true;
  let nodeId = 0;
  let lastNodeType = "";

  const updatedNodes = nodes.map((node) => {
    if (!firstNode) {
      if (lastNodeType === "menuNode") {
        currentY = currentY + state.defaults.menuSpacing;
      } else {
        currentY = currentY + state.defaults.nodeSpacing;
      }
    }

    firstNode = false;
    nodeId++;
    lastNodeType = node.type;

    return {
      ...node,
      id: nodeId.toString(),
      data: { ...node.data, id: nodeId.toString() },
      position: {
        ...node.position,
        y: currentY,
      },
    };
  });

  return updatedNodes;
};

const workflowUISlice = createSlice({
  name: "workFlowUI",
  initialState,

  reducers: {
    initUi: (state, action) => {
      const newNodes = [
        {
          id: "1",
          position: { x: defaults.xOffset, y: defaults.firstNodeY },
          data: { label: "Start" },
          type: "startNode",
        },
        {
          id: "2",
          position: {
            x: defaults.xOffset,
            y: defaults.firstNodeY + defaults.nodeSpacing,
          },
          data: { label: "Stop" },
          type: "stopNode",
        },
      ];

      const newEdges = [
        {
          id: "e1-2",
          source: "1",
          target: "2",
          type: "buttonEdge",
        },
      ];

      state.nodes = newNodes;
      state.edges = newEdges;
    },

    setSelectedNode: (state, action) => {
      state.selectedNode = action.payload;
    },

    setNodes: (state, action) => {
      state.nodes = action.payload;
    },

    setEdges: (state, action) => {
      state.edges = action.payload;
    },

    addContributionSelectionNode: (state, action) => {
      //close the menu and add a contribtuon node at the id

      const id = action.payload;

      //debugger;

      const updatedNodes = state.nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node.data, type: "Selecting", name: "Configgure Contribution" },
            type: "stepNode",
            selectable: true,
          };
        } else {
          return node;
        }
      });


      state.nodes = createNodeSpacing(state, updatedNodes);
      state.edges = createEdges(state);
      state.menuOpen = false;


    },

    closeMenu: (state, payload) => {
      let menuNodeID = payload.payload.id;

      const updatedNodes = state.nodes.filter((elem) => elem.id !== menuNodeID);

      state.nodes = createNodeSpacing(state, updatedNodes);
      state.edges = createEdges(state);
      state.menuOpen = false;
    },

    removeContributionSelectionNode: (state, payload) => {
      let menuNodeID = payload.payload.id;

      const updatedNodes = state.nodes.filter((elem) => elem.id !== menuNodeID);

      state.nodes = createNodeSpacing(state, updatedNodes);
      state.edges = createEdges(state);
      state.menuOpen = false;
    },

    insertMenu: (state, payload) => {
      let nodes = {};

      if (state.menuOpen) {
        //find the menuNode and close it befor adding a new one

        nodes = state.nodes.filter((elem) => elem.type !== "menuNode");
      } else {
        nodes = state.nodes;
      }

      let edgeId = payload.payload.id;
      // Locate the target node, move it and all subsequent nodes down

      const thisEdge = state.edges.find((edge) => edge.id == edgeId);
      const targetNode = nodes.find((node) => node.id == thisEdge.target);

      const updatedNodes = nodes.flatMap((node) => {
        if (parseInt(node.id) < targetNode.id) {
          return node;
        }
        if (parseInt(node.id) == targetNode.id) {
          //debugger;

          const newNode = {
            id: targetNode.id,
            type: "menuNode",
            data: { id: targetNode.id },
            position: {
              x: defaults.xOffset,
              y: defaults.firstNodeY,
            },
          };

          const updatedNode = {
            ...node,
            id: (parseInt(node.id) + 1).toString(),
          };
          return [newNode, updatedNode];
        }
        return {
          ...node,
          id: (parseInt(node.id) + 1).toString(),
        };
      });

      state.nodes = createNodeSpacing(state, updatedNodes);
      state.edges = createEdges(state);
      state.menuOpen = true;
    },

    clearUi: () => {
      return { initialState };
    },
  },
});

const { reducer, actions } = workflowUISlice;

export const {
  initUi,
  clearUi,
  setNodes,
  setEdges,
  insertMenu,
  closeMenu,
  addContributionSelectionNode,
  setSelectedNode,
  removeContributionSelectionNode,
} = actions;
export default reducer;
