import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import CopilotService from "../services/copilot.service";
import { updatePreferenceByUser } from "./auth";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const createCopilotConversation = createAsyncThunk(
  "CopilotConversation/create",
  async ({ copilotConversation }, thunkAPI) => {
    try {
      const response = await CopilotService.createCopilotConversation(copilotConversation);

      const data = {
        data: response.data, 
        status: response.status,
      };


      const preference = {
        preference_value: data.data.id,
        name: "currentConversation",
        username: copilotConversation.user,
      };
      thunkAPI.dispatch(updatePreferenceByUser({preference}));


      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const createCopilotPrompt = createAsyncThunk(
  "CopilotPromot/create",
  async ({ copilotPrompt }, thunkAPI) => {
    try {
      const response = await CopilotService.createCopilotPrompt(copilotPrompt);
      const id = copilotPrompt.conversationId
      thunkAPI.dispatch(getCopilotConversation({id}));

      const data = {
        data: response.data, 
        status: response.status,
      };


      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateCopilotConversation = createAsyncThunk(
  "CopilotConversation/update",
  async ({ copilotConversation }, thunkAPI) => {
    try {
      const data = await CopilotService.updateCopilotConversation(copilotConversation);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getCopilotConversations = createAsyncThunk(
  "CopilotConversation/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotConversations(search);
      return { conversations: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCopilotConversation = createAsyncThunk(
  "CopilotConversation/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotConversation(id);
      return { conversation: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCopilotPrompts = createAsyncThunk(
  "CopilotPrompt/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotPrompts(search);
      return { prompts: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCopilotPrompt = createAsyncThunk(
  "CopilotPrompt/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotPrompt(id);
      return { prompt: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCopilotConversationFilterValues = createAsyncThunk(
  "CopilotConversation/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotConversationFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCopilotPromptFilterValues = createAsyncThunk(
  "CopilotPrompt/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotPromptFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getCopilotConversationUi = createAsyncThunk(
  "CopilotConversation/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotConversationUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCopilotPromptUi = createAsyncThunk(
  "CopilotPrompt/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await CopilotService.getCopilotPromptUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateCopilotFeedback = createAsyncThunk(
  "Copilot/update",
  async ({ prompt }, thunkAPI) => {
    try {
      const data = await CopilotService.updateCopilotFeedback(prompt);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  conversation: {data: list , loading: false, Ui: [], filter_values: []},
  prompt: {data: list , loading: false, Ui: [] ,filter_values: []},
}

const CopilotSlice = createSlice({
  name: "Copilot",
  initialState,

  extraReducers: {

    [updateCopilotFeedback.fulfilled]: (state, action) => {

      const updatedPrompt = state.prompt.data.list.map((Prompt) => {
        if (Prompt.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Prompt;
        }
      });

      state.prompt.data.list = updatedPrompt;

    },

    [getCopilotConversationUi.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [getCopilotConversationUi.fulfilled]: (state, action) => {
      state.conversation.Ui = action.payload.ui;
      state.conversation.loading = false;
    },
    [getCopilotConversationUi.rejected]: (state, action) => {
      state.conversation.Ui = null;
      state.conversation.loading = false;
    },
    
    [getCopilotPromptUi.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getCopilotPromptUi.fulfilled]: (state, action) => {
      state.prompt.Ui = action.payload.ui;
      state.prompt.loading = false;
    },
    [getCopilotPromptUi.rejected]: (state, action) => {
      state.prompt.Ui = null;
      state.prompt.loading = false;
    },

    [getCopilotConversationFilterValues.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [getCopilotConversationFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.conversation.filter_values = action.payload.filter_values;
      state.conversation.loading = false;
    },
    [getCopilotConversationFilterValues.rejected]: (state, action) => {
      state.conversation.filter_values = list;
      state.conversation.loading = false;
    },


    [getCopilotPromptFilterValues.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getCopilotPromptFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.prompt.filter_values = action.payload.filter_values;
      state.prompt.loading = false;
    },
    [getCopilotPromptFilterValues.rejected]: (state, action) => {
      state.prompt.filter_values = list;
      state.prompt.loading = false;
    },


    [createCopilotConversation.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [createCopilotConversation.fulfilled]: (state, action) => {

      state.conversation.data.list.push(action.payload.data.data);
      state.conversation.data.total ++;
      state.conversation.loading = false;
    },
    [createCopilotConversation.rejected]: (state, action) => {
      state.conversation.loading = false;
    },



    [createCopilotPrompt.pending]: (state, action) => {

      state.prompt.loading = true;
    },
    [createCopilotPrompt.fulfilled]: (state, action) => {

      state.prompt.data.list.push(action.payload.data.data);
      state.prompt.data.total ++;
      state.prompt.loading = false;
    },
    [createCopilotPrompt.rejected]: (state, action) => {
      state.prompt.loading = false;
    },



    [getCopilotConversations.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [getCopilotConversations.fulfilled]: (state, action) => {
      state.conversation.data = action.payload.conversations;
      state.conversation.loading = false;
    },
    [getCopilotConversations.rejected]: (state, action) => {
      state.conversationData.data = list;
      state.conversation.loading = false;
    },



    [getCopilotPrompts.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [getCopilotPrompts.fulfilled]: (state, action) => {
      state.prompt.data = action.payload.prompts;
      state.conversation.loading = false;
    },
    [getCopilotPrompts.rejected]: (state, action) => {
      state.prompt.data = list;
      state.conversation.loading = false;
    },



    [updateCopilotConversation.fulfilled]: (state, action) => {

      const updatedCopilotConversation = state.data.list.map((CopilotConversation) => {
        if (CopilotConversation.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return CopilotConversation;
        }
      });
      state.conversation.data.list = updatedCopilotConversation;
    },



    [getCopilotConversation.pending]: (state, action) => {
      state.conversation.loading = true;
    },
    [getCopilotConversation.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.conversation;

      const existingIndex = state.conversation.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.conversation.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.conversation.data.list.push(newPayload);
      }


      state.conversation.loading = false;
    },
    [getCopilotConversation.rejected]: (state, action) => {
      state.conversation.loading = false;
    },


    
    [getCopilotPrompt.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getCopilotPrompt.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.prompt;

      const existingIndex = state.prompt.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.prompt.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.prompt.data.list.push(newPayload);
      }


      state.promptloading = false;
    },
    [getCopilotPrompt.rejected]: (state, action) => {
      state.promptloading = false;
    },



  },
});

const { reducer } = CopilotSlice;
export default reducer;
