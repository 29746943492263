import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import {
  addStateSetField,
  addStateSetRow,
  createStateSet,
  updateStateSet,
  updateStateSetField,
} from "slices/state";

const StateSetAddRow = ({ closerFunc, stateSet, refreshTableFunc }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate()

  const dispatch = useDispatch();

  const initialValues = {
    id: "",
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .required("This field is required!")
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        "Must contain uppercase, lowercase, _, and numbers with no whitespace"
      ),
  });

  return (
    <Box m="20px">
      <Header title={`New Row for "${stateSet.name}"`} />

      <Formik
        onSubmit={(values, actions) => {
          const rowToAdd = { ...values };

          rowToAdd.organization = stateSet.organization
          rowToAdd.orchestrator = stateSet.orchestrator

          const row = {
            stateSetId: stateSet.id,
            row: rowToAdd

          }

          dispatch(clearMessage());

          dispatch(addStateSetRow({ row }))
            .unwrap()
            .then(() => {
              //debugger;

              refreshTableFunc();
              closerFunc();

              let link = `/state/${stateSet.id}/${rowToAdd.id}`
              navigate(link)

            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Row ID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                name="id"
                error={!!touched.id && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="1rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Add Row
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default StateSetAddRow;
