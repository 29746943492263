import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Diamond,
  Search,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  Divider,
  DialogTitle,
  DialogActions,
  Popover,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  Switch,
  List,
  Autocomplete,
  Chip,
  Popper,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridPagination,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import FlexBetween from "components/global/FlexBetween";
import { Box } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import DataGridFilterCreate from "components/DataGridFilterCreate";
import DataGridFilterDimension from "components/DataGridFilterDimension";

import { getCurrentBillingPeriod } from "common/billingPeriod";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getStateSetFilterValues } from "slices/state";
import NiceBillingPeriod from "components/global/NiceBillingPeriod";
import DataGridFilterBillingPeriod from "components/DataGridFilterBillingPeriod";
import { getReportingPeriodsForAvilableBillingPeriods } from "common/helpers";
import { setDashboardRenderState } from "slices/dashboard";
import DataGridFilterReportingPeriod from "components/DataGridFilterReportingPeriod";
import { useSearchParams } from "react-router-dom";
import { getCustomerNetwork } from "slices/customer_network";
import NiceDate from "components/global/NiceDate";

const createFilterObjectArray = (str) => {
  if (str.length > 1) {
    const filters = str.split("|");

    const arr = filters.map((filter) => {
      //debugger;

      //split the filter into the filed and the filter its self
      let key, value;
      const idx = filter.indexOf("=");
      key = filter.slice(0, idx);
      value = filter.slice(idx + 1);

      //remove the [] arround the filed
      const field = key.replace(/\[|\]/g, "");

      //look for the operator, if none is found then it must be the = used in the initial slit so add it back in
      let operatorSearch = value.match(/(<>|<=|<|>=|>|!=)/);
      let val, operator;

      if (operatorSearch === null) {
        operator = "=";
        val = value;
      } else {
        operator = operatorSearch[0];
        val = value.split(operator)[1];
      }

      return {
        key: field + "_" + operator + "_" + val,
        field: field,
        operator: operator,
        value: val,
      };
    });

    return arr;
  } else {
    return [];
  }
};

const DashboardFilter = ({
  dashboard,
  dimension,
  dimensionSetter,
  filters,
  filterSetter,
  period,
  periodSetter,
  dashboardRefresher,
  baseline,
  baselineSetter,
  owner,
  ownerSetter,
}) => {
  const searchRef = useRef(null);

  const [searchInput, setSearchInput] = useState("");
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [editFilter, setEditFilter] = React.useState("");
  const [editFilterColumn, setEditFilterColumn] = React.useState("");

  const [newFilters, setNewFilters] = React.useState(
    createFilterObjectArray(filters)
  );

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find(
      (state) => state.dashboard === dashboard.id
    )
  );

  //debugger

  const columns = dashboard.parameters;

  //const anyStillLoading = columnsForRendering.find((obj) => obj.loading === true);

  console.log("Columns for filters: ", columns);

  // let scopeColumns = [];

  // if (!anyStillLoading) {
  //   //process into the list of possible scope filters
  //   //loop over the array of widget columns and keep only the ones that are commen

  //   //debugger

  //   let commonFields = null;

  //   // Step 1: Iterate over the outer array
  //   columnsForRendering.forEach((outerObj) => {
  //     // Step 2: Extract the "columns" array
  //     const columnsArray = outerObj.columns;

  //     // If it's the first iteration, initialize commonFields with all fields
  //     if (commonFields === null) {
  //       commonFields = columnsArray.map((column) => column.field);
  //     } else {
  //       // Otherwise, retain only fields present in both commonFields and columnsArray
  //       commonFields = commonFields.filter((field) =>
  //         columnsArray.some((column) => column.field === field)
  //       );
  //     }
  //   });

  //   // Step 3: Create a new array with common fields
  //   scopeColumns = columnsForRendering.map((outerObj) => {
  //     const columnsArray = outerObj.columns;

  //     // Create a new object with only common fields
  //     const newObj = {};
  //     commonFields.forEach((field) => {
  //       const matchingColumn = columnsArray.find(
  //         (column) => column.field === field
  //       );
  //       if (matchingColumn) {
  //         newObj[field] = matchingColumn;
  //       }
  //     });

  //     return newObj;
  //   });
  // }

  // console.log("Scope columns: " , scopeColumns)

  const handleDelete = (chipToDelete) => {
    const updatedFilters = newFilters.filter((chip) => {
      return (
        // chip.field !== chipToDelete.field ||
        // chip.operator !== chipToDelete.operator ||
        // chip.value !== chipToDelete.value

        chip.key !== chipToDelete.key
      );
    });

    updateFilterAPI(updatedFilters);
  };

  const updateFilterAPI = (filters) => {
    //debugger;

    let filterString = "";

    filters.forEach((filter) => {
      filterString += "[" + filter.field + "]";

      if (filter.operator != "=") {
        filterString += "=";
      }

      switch (filter.operator) {
        case "NN*":
          filterString += filter.value + "*|";
          break;
        case "*NN":
          filterString += "*" + filter.value + "|";
          break;
        case "*NN*":
          filterString += "*" + filter.value + "*|";
          break;
        case "null":
          filterString += "null" + "|";
          break;
        case "notnull":
          filterString += "!=null" + "|";
          break;
        default:
          //do nothing special
          filterString += filter.operator + filter.value + "|";
      }
    });

    //knock off the last '|' seperator
    filterString = filterString.slice(0, -1);

    //debugger;

    filterSetter(filterString);
  };

  var dimensions = [];

  dimensions.push({ value: "Minute", label: "By Minute" });
  dimensions.push({ value: "Hour", label: "By Hour" });
  dimensions.push({ value: "Day", label: "By Day" });
  dimensions.push({ value: "Week", label: "By Week" });
  dimensions.push({ value: "Month", label: "By Month" });
  dimensions.push({ value: "Quarter", label: "By Quarter" });
  dimensions.push({ value: "Year", label: "By year" });

  const addFilter = (newFilter) => {
    //create a filter URL and pass back to the parent, this will update the screen which will in turn flow back here to
    //create the filters needed for display after the API has been consumed

    //debugger;

    if (newFilter != null) {
      let activeFilters = [...newFilters];

      // Check if we need to amend or add
      const indexToUpdate = activeFilters.findIndex(
        (obj) => obj.key === newFilter.oldKey
      );

      if (indexToUpdate !== -1) {
        // Replace the existing filter with newFilter
        activeFilters[indexToUpdate] = newFilter;
      } else {
        // Add newFilter to the array if it doesn't exist
        activeFilters.push(newFilter);
      }

      updateFilterAPI(activeFilters);
    }
  };

  const clearFilters = () => {
    // if (kpi) {
    //   // Remove any non-dimension filters that are set
    //   const filteredFilters = newFilters.filter((filter) => {
    //     const column = columns.find((obj) => obj.field === filter.field);
    //     return column && column.purpose === "dimension";
    //   });
    //   //debugger;
    //   updateFilterAPI(filteredFilters);
    // } else {
    //   filterSetter([]);
    // }
  };

  const [anchorElFilterSettings, setAnchorElFilterSettings] = useState(null);

  const isOpenFilterSettings = Boolean(anchorElFilterSettings);

  const [anchorElDimensionSettings, setAnchorElDimensionSettings] =
    useState(null);

  const isOpenDimensionSettings = Boolean(anchorElDimensionSettings);

  const handleCloseDimensionSettings = () => {
    setAnchorElDimensionSettings(null);
  };

  const [anchorElOwnerSettings, setAnchorElOwnerSettings] = useState(null);

  const isOpenOwnerSettings = Boolean(anchorElOwnerSettings);

  const handleCloseOwnerSettings = () => {
    setAnchorElOwnerSettings(null);
  };

  const [anchorElPeriodSettings, setAnchorElPeriodSettings] = useState(null);

  const isOpenPeriodSettings = Boolean(anchorElPeriodSettings);

  const handleClosePeriodSettings = () => {
    setAnchorElPeriodSettings(null);
  };

  const [anchorElBaselineSettings, setAnchorElBaselineSettings] =
    useState(null);

  const isOpenBaselineSettings = Boolean(anchorElBaselineSettings);

  const handleCloseBaselineSettings = () => {
    setAnchorElBaselineSettings(null);
  };

  const handleCloseFilterSettings = () => {
    setAnchorElFilterSettings(null);
    setValue("");
    setInputValue("");
    setEditFilter("");
    setEditFilterColumn("");
  };

  const handleOpenFilterSettngs = (event, newValue) => {
    if (newValue != null) {
      setAnchorElFilterSettings(searchRef.current);
      setEditFilter("");
      setEditFilterColumn("");
    }
  };

  const handelOpenEditFilter = (event, column, filter) => {
    setAnchorElFilterSettings(event.currentTarget);
    setEditFilter(filter);
    setEditFilterColumn(column);
  };

  const handelOpenDimensionPicker = (event) => {
    setAnchorElDimensionSettings(event.currentTarget);
  };

  const handelOpenBillingPeriodsPicker = (event) => {
    setAnchorElPeriodSettings(event.currentTarget);
  };

  const handelOpenBaselinePicker = (event) => {
    setAnchorElBaselineSettings(event.currentTarget);
  };

  const handelOpenOwnerPicker = (event) => {
    setAnchorElOwnerSettings(event.currentTarget);
  };

  const [owners, setOwners] = useState([]);

  const [reportingPeriods, setReportingPeriods] = useState([]);

  const [baselinePeriods, setbaselinePeriods] = useState([]);

  const avilableBillingPeriods = useSelector(
    (state) => state.stateSet?.filter_values?.stringValues
  );

  const customerNetwork = useSelector((state) => state.customerNetwork.data);

  useEffect(() => {

    //debugger

    if ((dashboard) ) {
      //build the list of paramaters as filters

        //debugger;

        let filterBuilder = filters ? filters : "";

        if (dashboard.parameters) {
          if (filters === "") {
            //filters is blank so create the filter string based on the paramaters in the dashboard
            const parameterKeys = Object.keys(dashboard?.parameters);

            for (const key of parameterKeys) {
              const parameter = dashboard.parameters[key];

              filterBuilder += "[" + parameter.field + "]=all|";
            }

            filterBuilder = filterBuilder.slice(0, -1);

            setNewFilters(createFilterObjectArray(filterBuilder));
          } else {
            //just update the values based on the new filters passed in the props
            setNewFilters(createFilterObjectArray(filters));
          }
        }

        //get the current dashbard vars
        const currentVariables = renderState?.variables || [];

        const config = {
          dashboard: dashboard?.id,
          dimensions: dimensions,
          dimension: dimension,
          periods: reportingPeriods,
          period: period,
          baseline: baseline,
          owner: owner,
          filter: filterBuilder,
          baselinePeriods: baselinePeriods,
          variables: currentVariables, //add the vars back unchanged
          built: true,
        };

        //console.log("Update RenderState", config);

        const params = {
          period: period,
          dimension: dimension,
          baseline: baseline,
          owner: owner,
          filter: filterBuilder,
        };

        // Add the "tab" field conditionally
        const tabValue = searchParams.get("tab");
        if (tabValue) {
          params.tab = tabValue;
        }

        dispatch(setDashboardRenderState(config));

        setSearchParams(params, { replace: true });

        //refresh the dashboard, this will let the widgets know to update
        dashboardRefresher();
      }
    
  }, [reportingPeriods, period, dimension, owner, filters, baseline]);

  useEffect(() => {
    if (!avilableBillingPeriods) {
      const search = {
        id: "",
        idField: "",
        field: "billingPeriod",
        filter: "",
        dimension: "",
        stateSetId: "CUSTOMER_BILLING_PERIODS",
        billingPeriod: "",
      };

      console.log("Get Billing Periods", search);

      dispatch(getStateSetFilterValues({ search }));
    } else {
      const sortedBillingPeriods = [...avilableBillingPeriods].sort();

      setReportingPeriods(
        getReportingPeriodsForAvilableBillingPeriods(sortedBillingPeriods)
      );

      //debugger
      setbaselinePeriods(sortedBillingPeriods);
    }
  }, [avilableBillingPeriods]);

  useEffect(() => {
    if (!customerNetwork || customerNetwork.list.length == 0) {
      console.log("Get Customer Network");

      dispatch(getCustomerNetwork());
    } else {
      //format for owners

      const localOwners = customerNetwork.list.map((item) => ({
        value: item.id,
        label: item.roleName,
      }));

      setOwners(localOwners);
    }
  }, [customerNetwork]);

  const thisReportingPeriod = reportingPeriods.find(
    (obj) => obj.value === period
  );

  const thisDimension = dimensions.find((obj) => obj.value === dimension);

  const thisBaseline = baselinePeriods.find((obj) => obj === baseline);

  const thisOwner = owners?.find((obj) => obj.value === owner);

  console.log("thisOwner ", thisOwner, " owner ", owner, " owners ", owners);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        //height="40px"
        flexDirection="column"
      >
        <Box
          component="ul"
          display="flex"
          justifyContent="flex-start"
          p="0rem"
          height="1rem"
        >
          {thisReportingPeriod ? (
            <ListItem
              key="reporting period_key"
              sx={{ padding: "0px 1rem 0px 0px" }}
            >
              <Chip
                onClick={(event) => handelOpenBillingPeriodsPicker(event)}
                size="small"
                label={
                  <>
                    Period ={" "}
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "bold",
                      }}
                    >
                      {thisReportingPeriod.label}
                    </Typography>
                  </>
                }
                sx={{
                  borderRadius: "5px",
                }}
              />
            </ListItem>
          ) : (
            <Box>Loading...</Box>
          )}

          {thisDimension ? (
            <ListItem key="dimensionkey" sx={{ padding: "0px 1rem 0px 0px" }}>
              <Chip
                onClick={(event) => handelOpenDimensionPicker(event)}
                size="small"
                label={
                  <>
                    View{" "}
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "bold",
                      }}
                    >
                      {thisDimension.label}
                    </Typography>
                  </>
                }
                sx={{
                  borderRadius: "5px",
                }}
              />
            </ListItem>
          ) : (
            <Box>Loading...</Box>
          )}

          {thisBaseline ? (
            <ListItem key="baseline_key" sx={{ padding: "0px 1rem 0px 0px" }}>
              <Chip
                onClick={(event) => handelOpenBaselinePicker(event)}
                size="small"
                label={
                  <>
                    Baseline ={" "}
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "bold",
                      }}
                    >
                      <NiceBillingPeriod>{thisBaseline}</NiceBillingPeriod>
                    </Typography>
                  </>
                }
                sx={{
                  borderRadius: "5px",
                }}
              />
            </ListItem>
          ) : (
            <Box>Loading...</Box>
          )}

          {thisOwner ? (
            <ListItem key="owner_key" sx={{ padding: "0px 1rem 0px 0px" }}>
              <Chip
                onClick={(event) => handelOpenOwnerPicker(event)}
                size="small"
                label={
                  <>
                    Owner ={" "}
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "bold",
                      }}
                    >
                      {thisOwner.label}
                    </Typography>
                  </>
                }
                sx={{
                  borderRadius: "5px",
                }}
              />
            </ListItem>
          ) : (
            <Box>Loading...</Box>
          )}
        </Box>

        <Box
          component="ul"
          display="flex"
          justifyContent="flex-start"
          p="0rem"
          height="1rem"
        >
          {newFilters?.map((filter) => {
            const column = columns.find((obj) => obj.field === filter.field);

            return (
              <ListItem key={filter.key} sx={{ padding: "0px 1rem 0px 0px" }}>
                <Chip
                  onClick={(event) => {
                    handelOpenEditFilter(event, column, filter);
                  }}
                  size="small"
                  label={
                    <>
                      {column.headerName}{" "}
                      <Typography
                        component="span"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        {/* {filter.operator} {column.type === "date" ? filter.value === "all" ? "all" : <NiceDate>{filter.value}</NiceDate> : filter.value} */}
                        {filter.operator === "<>" ? "From" : filter.operator}{" "}
                        {(() => {
                          const values = filter.value.split(",");
                          if (values.length > 1) {
                            //check if this is a range or an array
                            if (filter.operator === "<>") {
                              if (column.type === "date") {
                                return (
                                  <>
                                    <NiceDate>{values[0]}</NiceDate> To{" "}
                                    <NiceDate>{values[1]}</NiceDate>
                                  </>
                                ); // Display the range
                              } else {
                                return `${values[0]} to ${values[1]}`; // Display the range
                              }
                            }
                            return `${values.length} values selected`; // Display the number of elements in the array
                          } else if (filter.value === "all") {
                            return "all"; // Display "all" if filter.value is "all"
                          } else if (column.type === "date") {
                            return <NiceDate>{filter.value}</NiceDate>; // Display the formatted date
                          } else {
                            return filter.value; // Display the value as is
                          }
                        })()}
                      </Typography>
                    </>
                  }
                  // onDelete={
                  //   column.purpose !== "dimension"
                  //     ? () => {
                  //         handleDelete(filter);
                  //       }
                  //     : null
                  // }
                  sx={{
                    borderRadius: "5px",
                  }}
                />
              </ListItem>
            );
          })}
        </Box>
      </Box>

      <Popover
        anchorEl={anchorElFilterSettings}
        id="filter-settings"
        open={isOpenFilterSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterCreate
          column={editFilterColumn !== "" ? editFilterColumn : value}
          closer={handleCloseFilterSettings}
          saver={addFilter}
          currentFilterValues={editFilter}
          filters={filters}
          // kpi={kpi}
          // dimension={dimension}
          // masterIdField={masterIdField}
          // masterId={masterId}
          //serviceType={serviceType}
          // stateSetId={stateSetId}
          //billingPeriod={billingPeriod}
        />
      </Popover>

      <Popover
        anchorEl={anchorElDimensionSettings}
        id="dimension-settings"
        open={isOpenDimensionSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterDimension
          closer={handleCloseDimensionSettings}
          saver={dimensionSetter}
          currentDimension={dimension}
          dimensions={dimensions}
        />
      </Popover>

      <Popover
        anchorEl={anchorElPeriodSettings}
        id="preiod-settings"
        open={isOpenPeriodSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterReportingPeriod
          closer={handleClosePeriodSettings}
          saver={periodSetter}
          currentPeriod={period}
          periods={reportingPeriods}
        />
      </Popover>

      <Popover
        anchorEl={anchorElBaselineSettings}
        id="baseline-settings"
        open={isOpenBaselineSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterBillingPeriod
          closer={handleCloseBaselineSettings}
          saver={baselineSetter}
          currentBillingPeriod={baseline}
          billingPeriods={baselinePeriods}
        />
      </Popover>

      <Popover
        anchorEl={anchorElOwnerSettings}
        id="owner-settings"
        open={isOpenOwnerSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterReportingPeriod
          closer={handleCloseOwnerSettings}
          saver={ownerSetter}
          currentPeriod={owner}
          periods={owners}
        />
      </Popover>
    </>
  );
};

export default DashboardFilter;
