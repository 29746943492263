import React from "react";
import { Menu, MenuItem, ListItemIcon, Divider } from "@mui/material";
import { Add, DeleteOutline, ModeEditOutline, MoveUpOutlined, SearchOutlined, OpenInNewOutlined, InfoOutlined } from "@mui/icons-material";

const CataloguePageMenuContextMenu = ({ anchorEl, handleMenuClose, thisPage, handleMenuItemClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        },
      }}
    >
      <MenuItem onClick={() => handleMenuItemClick("addSubpage")}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        Add Sub-page
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("edit")}>
        <ListItemIcon>
          <ModeEditOutline fontSize="small" />
        </ListItemIcon>
        Edit
      </MenuItem>

      <MenuItem onClick={() => handleMenuItemClick("move")} disabled={true}>
        <ListItemIcon>
          <MoveUpOutlined fontSize="small" />
        </ListItemIcon>
        Move Page To New Catalogue
      </MenuItem>

      <Divider flexItem />
      <MenuItem onClick={() => handleMenuItemClick("delete")}>
        <ListItemIcon>
          <DeleteOutline fontSize="small" />
        </ListItemIcon>
        Delete
      </MenuItem>
      <Divider flexItem />
      <MenuItem onClick={() => handleMenuItemClick("details")} >
        <ListItemIcon>
          <SearchOutlined fontSize="small" />
        </ListItemIcon>
        Opend in admin Mode
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("openInNewTab")} disabled={thisPage?.hideContent}>
        <ListItemIcon>
          <OpenInNewOutlined fontSize="small" />
        </ListItemIcon>
        Open in New Tab
      </MenuItem>
    </Menu>
  );
};

export default CataloguePageMenuContextMenu;
