import { Box, Typography, useTheme, Button, Popover } from "@mui/material";

import {
  RefreshOutlined,
  OpenInNewOutlined,
  PlayArrowOutlined,
  CloudDownloadOutlined,
  RestoreFromTrashOutlined,
  RestorePageOutlined,
  DeleteOutline,
  TouchAppOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";

import { renderColumn } from "common/renderColumn";
import {
  deleteBackup,
  getBackupUi,
  getBackups,
  runBackup,
} from "slices/backup";
import { useNavigate } from "react-router-dom";
import { clearMessage } from "slices/message";
import ConfirmationDialog from "components/global/ConformationDialogue";
import InfoDialogue from "components/global/InfoDialogue";

const Backup = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const rows = useSelector((state) => state.backup.data);
  const ui = useSelector((state) => state.backup.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getBackupUi({}));
  }, [dispatch, rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const configRef = React.useRef(null);

  //create the command to do a restore assumng the user has this file
  const handleCreateRestoreCommand = () => {
    const selectedBackup = rows?.list.find(
      (backup) => backup.id === selectionModel[0]
    );

    const command = `mongorestore --archive=${selectedBackup.fileName} --nsFrom="${selectedBackup.databaseName}" --nsTo="new_database"`
    setRestoreCommandMessage(command)
    setOpenInfoDialog(true);
  };

  const handleDelete = () => {
    const selected = selectionModel.length;

    if (selected > 1) {
      let message = "Are you sure you want to delete backups:";

      selectionModel.forEach((item) => {
        const selectedBackup = rows?.list.find((backup) => backup.id === item);
        message += "\n" + selectedBackup.name;
      });

      setDeleteBackupMessage(message);
      setOpenDialog(true);
    } else {
      const selectedBackup = rows?.list.find(
        (backup) => backup.id === selectionModel[0]
      );
      setDeleteBackupMessage(
        "Are you sure you want to delete backup\n" + selectedBackup.name
      );
      setOpenDialog(true);
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const [deleteBackupMessage, setDeleteBackupMessage] = useState(null);
  const [restoreCommandMessage, setRestoreCommandMessage] = useState(null);
  

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenInfoDialog(false);
  };

  const handleDialogConfirm = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const id = item;
      dispatch(deleteBackup({ id }));
    });
    setOpenDialog(false);
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleOpen = () => {
    navigate("/backup/" + selectionModel[0]);
  };

  const handleRun = () => {
    dispatch(clearMessage());
    dispatch(runBackup());
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },
    {
      text: "Download",
      icon: <CloudDownloadOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
      disabled: true,
    },
    {
      text: "Restore",
      icon: <RestorePageOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
      disabled: true,
    },
    {
      text: "Restore Command",
      icon: <TouchAppOutlined />,
      callBack: handleCreateRestoreCommand,
      singleItemOnly: true,
      disabled: false,
    },
    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: handleDelete,
    },
  ];

  let columns = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;

    inputString = inputString.replace(/_id/g, "id");

    const processedString = inputString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (ui?.columns) {
    columns = ui.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Backups" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrowOutlined />}
              onClick={handleRun}
            >
              Run backup now
            </Button>
          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (
          <TablePage
            dataRows={rows}
            dataColumns={columns}
            dataGetter={getBackups}
            defaultColumnsVisability={ui.columnsVisability}
            defaultSort={ui.sort}
            refreshPoint={refreshPoint}
            selectionModel={selectionModel}
            selectionSetter={setSelectionModel}
            serviceType={"backup"}
          />
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Deletion"
        content={deleteBackupMessage}
      />

      <InfoDialogue
        open={openInfoDialog}
        onClose={handleDialogClose}
        title="Restore Command"
        content={restoreCommandMessage}
      />
    </>
  );
};

export default Backup;
