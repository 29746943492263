import api from "./api";



const updateUtility = (data) => {
  return api.patch("/utility",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const updateUtilityActive = (data) => {
  return api.patch("/utility/active",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};



const getUtilities = (search) => {

  return api.get("/utility",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getUtility = (id) => {
  return api.get("/utility/"+id)
  .then((response) => {
    return response.data;
  });
};

const getUtilityUi = (id) => {
  return api.get("/utility/ui/"+id)
  .then((response) => {
    return response.data;
  });
};

const getUtilityServiceUi = () => {
  return api.get("/utility/serviceui")
  .then((response) => {
    return response.data;
  });
};

const getUtilityData = (search) => {

  return api.get("/utility/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const runUtility = (id) => {
  return api.get("/utility/run/"+id)
  .then((response) => {
    return response.data;
  });
};

const getUtilityFilterValues = (search) => {

  return api.get("/utility/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getUtilityAggregateValues = (search) => {

  return api.get("/utility/aggregatevalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const UtilitySevice = {
  getUtilities,
  updateUtility,
  getUtility,
  updateUtilityActive,
  getUtilityData,
  getUtilityUi,
  runUtility,
  getUtilityFilterValues,
  getUtilityAggregateValues,
  getUtilityServiceUi,
};

export default UtilitySevice;