import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import UtilityService from "../services/utility.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false};

export const updateUtility = createAsyncThunk(
  "Utility/update",
  async ({ org }, thunkAPI) => {
    try {
      const data = await UtilityService.updateUtility(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUtilityActive = createAsyncThunk(
  "Utility/update_active",
  async ({ utility }, thunkAPI) => {
    try {
      const data = await UtilityService.updateUtilityActive(utility);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUtilities = createAsyncThunk(
  "Utility/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await UtilityService.getUtilities(search);
      return { Utilities: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUtility = createAsyncThunk(
  "Utility/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await UtilityService.getUtility(id);
      return { utility: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUtilityUi = createAsyncThunk(
  "Utility/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await UtilityService.getUtilityUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getUtilityServiceUi = createAsyncThunk(
  "Utility/get_serviceui",
  async ( {}, thunkAPI) => {
    try {
      const data = await UtilityService.getUtilityServiceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getUtilityData = createAsyncThunk(
  "Utility/get_data_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await UtilityService.getUtilityData(search);
      return { utility_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runUtility = createAsyncThunk(
  "Utility/run",
  async ({ id }, thunkAPI) => {
    try {
      const data = await UtilityService.runUtility(id);
      thunkAPI.dispatch(
        setMessage({
          message: `Utility started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process utility ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUtilityFilterValues = createAsyncThunk(
  "Utility/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await UtilityService.getUtilityFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearUtilityUi = createAction("Utility/clearUtilityUi");

const initialState = {
  data: list,
  loading: false,
  utility_data: list,
  utilityUi: [],
  utilityRunJob: null,
  filter_values: [],
  Ui: [],
};

const UtilitySlice = createSlice({
  name: "Utilities",
  initialState,

  extraReducers: {

    [getUtilityServiceUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtilityServiceUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getUtilityServiceUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [clearUtilityUi]: (state, action) => {
      state.utilityUi = null;
      state.loading = false;
    },

    [getUtilityUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtilityUi.fulfilled]: (state, action) => {
      state.utilityUi = action.payload.ui;
      state.loading = false;
    },
    [getUtilityUi.rejected]: (state, action) => {
      state.utilityUi = null;
      state.loading = false;
    },

    [runUtility.pending]: (state, action) => {
      state.loading = true;
    },
    [runUtility.fulfilled]: (state, action) => {
      state.utilityRunJob = action.payload.job;
      state.loading = false;

      //update the rows that this job was created for 
      let utilities = state.data.list;
      let thisUtility = utilities.find(
        (utility) => utility.id === action.payload.job.implementorId
      );

      thisUtility.jobStatus = action.payload.job.status;
      thisUtility.jobId = action.payload.job.id;

    },
    [runUtility.rejected]: (state, action) => {
      state.utilityRunJob = null;
      state.loading = false;
    },

    [getUtilityData.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtilityData.fulfilled]: (state, action) => {
      //debugger;

      state.utility_data = action.payload.utility_data;
      state.loading = false;
    },
    [getUtilityData.rejected]: (state, action) => {
      state.utility_data = list;
      state.loading = false;
    },

    [getUtilities.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtilities.fulfilled]: (state, action) => {
      state.data = action.payload.Utilities;
      state.loading = false;
    },
    [getUtilities.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateUtilityActive.fulfilled]: (state, action) => {
      let utilities = state.data.list;
      let thisUtility = utilities.find(
        (utility) => utility.id === action.payload.data.id
      );
      thisUtility.active = action.payload.data.active;
    },

    [updateUtility.fulfilled]: (state, action) => {
      debugger;

      let organizations = state.data.list;
      let thisOrg = organizations.find(
        (org) => org.id === action.payload.data.id
      );

      thisOrg.name = action.payload.data.name;
      thisOrg.domain = action.payload.data.domain;
      thisOrg.active = action.payload.data.active;
    },


    [getUtilityFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtilityFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getUtilityFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },


    [getUtility.pending]: (state, action) => {
      state.loading = true;
    },
    [getUtility.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.utility;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getUtility.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = UtilitySlice;
export default reducer;
