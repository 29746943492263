import React from "react";
import { Box, Icon, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useSelector } from "react-redux";
import { processStringForDashboardFilters } from "common/helpers";

const TitleBox = ({ dashboard, name, icon, columns, left, top , config }) => {
  const theme = useTheme();



  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const title = config?.title;

  const renderTitle = processStringForDashboardFilters(title,renderState)

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} / span 1`}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      p=".3rem 1rem"
      flex="1 1 100%"
      sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
      borderRadius="0.55rem"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
          {renderTitle}
        </Typography>
      </Stack>
    </Box>
  );
};

export default TitleBox;
