import {
  CheckBoxOutlineBlank,
  ContentPasteGoOutlined,
  CopyAllOutlined,
  DeleteOutline,
  MenuOutlined,
  NoteAddOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import {
  closeMenu,
  removeContributionSelectionNode,
  setSelectedNode,
} from "slices/workflowUI";
import { deleteWorkflowStep } from "slices/worklow_step";

const FlowNode = memo(
  ({ icon, isConnectable, type, children, id, stepId, nodeType }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const selectedNode = useSelector((state) => state.workflowUi.selectedNode);

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handelDeleteStep = () => {
      //debugger;

      if (nodeType == "Selecting") {
        dispatch(removeContributionSelectionNode({ id }));
      } else {
        dispatch(deleteWorkflowStep({ id: stepId }));
      }
    };

    const handelNodeSelected = () => {
      dispatch(setSelectedNode(id));
    };

    let thisIcon = <CheckBoxOutlineBlank />;
    let pointer = "";
    let useable = false;

    //debugger;

    if (icon != undefined) {
      thisIcon = icon;
    }

    let outputOn = false;
    let inputOn = false;

    if (type == "output" || type == "default") {
      outputOn = true;
    }

    if (type == "input" || type == "default") {
      inputOn = true;
    }

    if (type == "default") {
      pointer = "pointer";
      useable = true;
    }

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding=".3rem"
          borderRadius=".3rem"
          gap=".5rem"
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            backgroundColor:
              selectedNode === id
                ? theme.palette.secondary.main
                : theme.palette.background.light,
            borderStyle: "solid",
            width: "20rem",
            minHeight: "2.5rem",
            cursor: pointer,
          }}
          onClick={useable ? handelNodeSelected : null}
        >
          <Box display="flex" justifyContent="flex-start" gap=".5rem">
            {thisIcon}

            {children}
          </Box>

          {useable && (
            <Box display="flex" justifyContent="flex-end" gap=".5rem">
              <Divider orientation="vertical" variant="middle" flexItem />

              <Tooltip title="Step Options">
                <IconButton onClick={handleClick}>
                  <MenuOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {outputOn && (
            <Handle
              type="source"
              position={Position.Bottom}
              id="OUT"
              isConnectable={isConnectable}
              style={{
                left: "1.7rem",
              }}
            />
          )}
          {inputOn && (
            <Handle
              type="target"
              position={Position.Top}
              id="IN"
              isConnectable={isConnectable}
              style={{
                left: "1.7rem",
              }}
            />
          )}
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="node-menu"
          open={isOpen}
          onClose={handleClose}
          //onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: "15rem",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <NoteAddOutlined />
            </ListItemIcon>
            Add Note to Step
          </MenuItem>

          <MenuItem
            onClick={() => {
              handelNodeSelected();
              handleClose();
            }}
          >
            <ListItemIcon>
              <OpenInNewOutlined />
            </ListItemIcon>
            See details
          </MenuItem>


          <Divider />

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <CopyAllOutlined />
            </ListItemIcon>
            Copy Step
          </MenuItem>

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <ContentPasteGoOutlined />
            </ListItemIcon>
            Paste Above
          </MenuItem>

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <ContentPasteGoOutlined />
            </ListItemIcon>
            Paste Below
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              handelDeleteStep();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            Remove step
          </MenuItem>
        </Menu>
      </>
    );
  }
);

export default FlowNode;
