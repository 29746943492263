import { CheckOutlined, ThumbDownOutlined, ThumbUpOutlined, TrendingDownOutlined, TrendingFlatOutlined, TrendingUpOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";



const NiceOpportunityStage = (props) => {
  let stage = props.children;


  if (stage === "To Be Validated"){
    return (<Chip icon={<WarningAmberOutlined />} label="To Be Validated" color={"info"} size="small"/>)
  }

  if (stage === "Rejected"){
    return (<Chip icon={<ThumbDownOutlined />} label="Rejected" color={"error"} size="small"/>)
  }

  if (stage === "To Be Prevented"){
    return (<Chip icon={<ThumbUpOutlined />} label="To Be Prevented" color={"success"} size="small"/>)
  }

};

export default NiceOpportunityStage;
