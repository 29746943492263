
import {  Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, {  useEffect } from "react";
import { getCloudOrgStructureElement } from "slices/cloudOrgStructure";


const NiceResourceGroup = (props) => {
  const dispatch = useDispatch();


  const wantedResource = props.children;

  const resourceGroup = useSelector((state) =>
    state.cloudOrgStructure?.data?.list?.find(
      (org) =>
      org.id === wantedResource
    )
    );


  useEffect(() => {
    if (wantedResource !== "billingAccount") {
      if (
        !resourceGroup &&
        wantedResource !== "" &&
        wantedResource !== undefined
      ) {
        
        const id = wantedResource
        dispatch(getCloudOrgStructureElement({id}));

      }
    }
  }, [dispatch, resourceGroup, wantedResource]);

  if (wantedResource === "billingAccount") {
    //special case just return a nice name
    return <Box>Billing Account</Box>;
  }




  return (
    <>
      {resourceGroup ? (
        <Box>{resourceGroup.name}</Box>
      ) : (
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceResourceGroup;
