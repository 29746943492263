import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import KeyVal from "components/global/KeyVal";
import { getUserRole, getUserRoles} from "slices/auth";


const UserRole = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const Role= useSelector((state) => state.auth.user_roles.data.list?.find((Role) => Role.id ===id ));


  useEffect(() => {
    if (!Role) {
      //need to get the AzurePrice form the API
      dispatch(getUserRole({ id }));
    }
  }, [Role]);

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <Header title={Role?.id} subtitle={Role?.username} />

      <DetailBlock>
        <Box display="flex" flexDirection="column" gap=".2rem" m="1rem">
          {Object.entries(Role).map(([key, value]) => {
            return <KeyVal label={key} value={value} correctLabel></KeyVal>;
          })}{" "}
        </Box>
      </DetailBlock>
    </PageBlock>
  );
};

export default UserRole;
