import {  Button } from "@mui/material";

import { RefreshOutlined } from "@mui/icons-material";

import Header from "components/global/Header";
import React, { useState } from "react";

import {useParams } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";

import { now } from "moment";

import ObjectDetails from "components/global/ObjectDetails";

const TablePageRowDetail = ({ serviceType }) => {
  const { id } = useParams();
  const { rowId } = useParams();

  //debugger;

  const [refreshPoint, setRefreshPoint] = useState(now());
  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [pageTitle, setPageTitle] = React.useState("");

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={pageTitle} subtitle={rowId} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
        </FlexBetween>
      </FlexBetween>

      <ObjectDetails
        serviceType={serviceType}
        id={id}
        rowId={rowId}
        refreshPoint={refreshPoint}
        titleSetter={setPageTitle}
      />
    </PageBlock>
  );
};

export default TablePageRowDetail;
