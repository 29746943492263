import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getWorkflow } from "slices/workflow";

const NiceWorkflow = (props) => {
 
  const dispatch = useDispatch();

  const workflow = useSelector((state) =>
    state.workflows.data.list?.find((workflow) => workflow.id === props.children)
  );


  useEffect(() => {
    //debugger;

    if (!workflow) {
      
      //need to get the workflow to display
      let id = props.children;

      dispatch(getWorkflow({ id }));
    }
  }, [workflow]);

  return (
    <>
      {workflow && (
        <Box display="flex" justifyContent="start" alignItems="baseline">
          {`${workflow.name}`}
        </Box>
      )}
    </>
  );
};

export default NiceWorkflow;
