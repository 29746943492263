import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const NiceBillingPeriod = (props) => {
  let period = props.children;

  const str = period.toString();

  // Grab the first 4 characters
  const year = str.substring(0, 4);
  const month = str.substring(4, 6);

  const NicePeriod = year + "-" + month;

  return NicePeriod;
};

export default NiceBillingPeriod;
