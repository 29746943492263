import Moment from "react-moment";
import { Typography, Box, useTheme, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";

import KeyVal from "./KeyVal";
import { DynamicIcon } from "common/dynamicIcon";
import { getServiceType } from "slices/services";
import { useDispatch, useSelector } from "react-redux";
import { ConstructionOutlined } from "@mui/icons-material";

const NiceBasicSearchResult = ({ searchResult, closer }) => {
  const dispatch = useDispatch();

  const serviceType = useSelector((state) =>
    state.services?.serviceTypes?.find(
      (serviceType) => serviceType.serviceType === searchResult.type
    )
  );



  //debugger

  useEffect(() => {
    if (!serviceType) {
      dispatch(getServiceType({ serviceType: searchResult.type }));
    }
  }, [dispatch, searchResult.type, serviceType]);

 


  if (!serviceType) {
    return (
      <Box key={searchResult.objectId}>
        Missing Service Type
      </Box>
    );
  }

  console.log("relevant:" , searchResult.isRelevant)

  return (

      <Box display={"flex"} flexDirection={"column"} gap={"0"} mb={"1rem"}>
        <Box display={"flex"} flexDirection={"column"} gap={"0"}>
          <Box display={"flex"} flexDirection={"row"} gap={".5rem"}>
            <DynamicIcon iconName={serviceType.icon} />
            <Typography variant="h4">{searchResult?.name}</Typography>
          </Box>
          <KeyVal label={serviceType.name} labelBold value={searchResult.objectId}/>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={".1rem"}>
          <KeyVal label={"Revevance"} labelBold value={searchResult.relevance}/>
          <KeyVal label={"Stastistical Gap"} labelBold value={searchResult.stastisticalGap}/>
          <KeyVal label={"Result Cluster"} labelBold value={searchResult.resultCluster}/>
          <KeyVal label={"Is Relevant"} labelBold value={searchResult.isRelevant} uiRenderType={"<NiceBoolean>"}/>
        </Box>
      </Box>

  );
};



const NiceBasicSearchResultList = (props) => {
  //debugger;

  const cellObject = props.children;

  if (Array.isArray(cellObject)) {
    return (
      <Box>
        {cellObject.map((item, index) => (
          <NiceBasicSearchResult searchResult={item}/>
        ))}
      </Box>
    );
  } else {
    return <Box>Not an Array</Box>;
  }
};

export default NiceBasicSearchResultList;
