import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import BackupService from "../services/backup.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};





export const getBackups = createAsyncThunk(
  "Backup/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await BackupService.getBackups(search);
      return { backups: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBackup = createAsyncThunk(
  "Backup/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await BackupService.getBackup(id);
      return { backup: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBackupUi = createAsyncThunk(
  "Backup/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await BackupService.getBackupUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const runBackup = createAsyncThunk(
  "Backup/run",
  async ( _, thunkAPI) => {
    try {
      const data = await BackupService.runBackup();

      thunkAPI.dispatch(
        setMessage({
          message: `Backup started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process backup ${data.JobOriginId}`,
        })
      );
      return { job: data };
    } catch (error) {

      console.log("Error in reducer is: ", error )
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBackupFilterValues = createAsyncThunk(
  "Backup/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await BackupService.getBackupFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteBackup= createAsyncThunk(
  "Backup/delete",

  async ({ id }, thunkAPI) => {
    try {
      const data = await BackupService.deleteBackup(id);
      return { Backup: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  loading: false,
  backupUi: [],
  backupRunJob: null,
  filter_values: [],
  Ui: [],
};

const BackupSlice = createSlice({
  name: "backups",
  initialState,

  extraReducers: {

    [deleteBackup.pending]: (state, action) => {
      state.data.loading = true;
    },
    [deleteBackup.fulfilled]: (state, action) => {
      // Filter out the data that matches the id in the action payload

      //debugger
      const updatedPageList = state.data.list.filter((backup) => backup.id !== action.payload.Backup.id);
      
      // Update the state with the filtered list
      state.data.list = updatedPageList;
      
      // Set loading to false
      state.data.loading = false;
    },
    
    [deleteBackup.rejected]: (state, action) => {
      state.data = list;
      state.page.loading = false;
    },



    [getBackups.pending]: (state, action) => {
      state.loading = true;
    },
    [getBackups.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.backups;
      state.loading = false;
    },
    [getBackups.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },


    [getBackupUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getBackupUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getBackupUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [runBackup.pending]: (state, action) => {
      state.loading = true;
    },
    [runBackup.fulfilled]: (state, action) => {
      state.backupRunJob = action.payload.job;
      state.loading = false;

      // //update the rows that this job was created for 
      // let backups = state.data.list;
      // let thisBackup = backups.find(
      //   (backup) => backup.id === action.payload.job.OriginId
      // );

      // thisBackup.jobStatus = action.payload.job.status;
      // thisBackup.jobId = action.payload.job.id;

    },
    [runBackup.rejected]: (state, action) => {
      state.backupRunJob = null;
      state.loading = false;
    },



    [getBackupFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getBackupFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getBackupFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },


    [getBackup.pending]: (state, action) => {
      state.loading = true;
    },
    [getBackup.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.backup;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getBackup.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = BackupSlice;
export default reducer;
