import Moment from 'react-moment';
import moment from "moment-timezone";

const NiceDateUTC = (props) => {

  const dateToDisplay = moment(props.children).utc()

  return (

    <Moment format="DD-MMM-YYYY HH:mm:ss">
      {dateToDisplay}
    </Moment>

  );
}

export default NiceDateUTC;
