import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import OrganizationService from "../services/organization.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0
};


//const users = {data: list, loading: false};


export const updateOrganization = createAsyncThunk(
  "org/update",
  async ( {org},thunkAPI) => {
    try {
      const data = await OrganizationService.updateOrganization(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getOrganizations = createAsyncThunk(
  "org/get_orgs",
  async ({search}, thunkAPI) => {
    try {

      const data = await OrganizationService.getOrganizations(search);
      return { organizations: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOrganization = createAsyncThunk(
  "org/get_org",
  async ({id}, thunkAPI) => {
    try {
      const data = await OrganizationService.getOrganization(id);
      return { organization: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



const initialState = {data: list, loading: false}

const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  extraReducers: {

    [getOrganizations.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.data = action.payload.organizations; 
      state.loading = false;
    },
    [getOrganizations.rejected]: (state, action) => {
      state.data = list
      state.loading = false;
    },

    [updateOrganization.fulfilled]: (state, action) => {

      debugger
      
      let organizations = state.data.list;
      let thisOrg = organizations.find((org) => org.id === action.payload.data.id)

      thisOrg.name = action.payload.data.name
      thisOrg.domain = action.payload.data.domain
      thisOrg.active = action.payload.data.active

    },

    [getOrganization.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrganization.fulfilled]: (state, action) => {

      //debugger;
      const newPayload = action.payload.organization;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }
      state.loading = false;
    },
    [getOrganization.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});


const { reducer } = organizationSlice;
export default reducer;