import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";

import WhereUsedNode from "components/where_used/WhereUsedNode";

import { getServiceRelated } from "slices/services";
import { getKpi } from "slices/kpi";
import { getUtility } from "slices/utility";
import { getBlox } from "slices/blox";
import { getStateSet } from "slices/state";

import ELK from "elkjs/lib/elk.bundled.js";

const WhereUsedService = ({ id, serviceType, refreshPoint }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const nodeWidth = 400;
  const nodeHeight = 150;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const implemenatorDetails = useSelector((state) => {
    return state[serviceType]?.data.list.find((item) => item.id === id);
  });

  const services = useSelector((state) => state.services.data);

  const proOptionsReactFlow = { hideAttribution: true };

  const nodeTypes = useMemo(
    () => ({
      whereUsedNode: WhereUsedNode,
    }),
    []
  );

  // const edgeTypes = useMemo(
  //   () => ({
  //     buttonEdge: ButtonEdge,
  //   }),
  //   []
  // );

  const elk = new ELK();

  // - https://www.eclipse.org/elk/reference/algorithms.html
  // - https://www.eclipse.org/elk/reference/options.html
  const elkOptions = {
    "elk.algorithm": "layered",
    "elk.direction": "UP",
    "elk.layered.spacing.nodeNodeBetweenLayers": "100",
    "elk.spacing.nodeNode": "80",
  };

  const getLayoutedElements = (nodes, edges, options = {}) => {
    const graph = {
      id: "root",
      layoutOptions: options,
      children: nodes.map((node) => ({
        ...node,
        // Adjust the target and source handle positions based on the layout
        // direction.
        targetPosition: "top",
        sourcePosition: "bottom",

        // Hardcode a width and height for elk to use when layouting.
        width: nodeWidth,
        height: nodeHeight,
      })),
      edges: edges,
    };

    return elk
      .layout(graph)
      .then((layoutedGraph) => ({
        nodes: layoutedGraph.children.map((node) => ({
          ...node,
          // React Flow expects a position property on the node instead of `x`
          // and `y` fields.
          position: { x: node.x, y: node.y },
        })),

        edges: layoutedGraph.edges,
      }))
      .catch(console.error);
  };

  const handlePaneClick = () => {
    //dispatch(setSelectedNode(null));
  };

  const uiDefaults = {
    nodeSpacing: 250,
    firstNodeY: 10,
    xOffset: 5,
  };

  const loadServicesNodes = () => {
    console.log("initial load for:", id);
    console.log("Services: ", services);

    if (services?.total > 0) {
      let initialNodes = [];
      let initialEdges = [];

      // Initialize an empty object to store the counts
      const relationshipCounts = {};

      // Iterate through the array and count occurrences of each relationship
      services.list.forEach((obj) => {
        const relationship = obj.relationship;
        // Check if the relationship exists in the counts object
        if (relationshipCounts.hasOwnProperty(relationship)) {
          // Increment the count if the relationship already exists
          relationshipCounts[relationship]++;
        } else {
          // Initialize the count to 1 if the relationship is encountered for the first time
          relationshipCounts[relationship] = 1;
        }
      });

      initialNodes.push({
        id: implemenatorDetails.id,
        position: { x: 5, y: 5 },
        data: {
          width: nodeWidth,
          height: nodeHeight,
          nodeType: "root",
          relationshipCounts: relationshipCounts,
          serviceType: serviceType,
          implementorId: implemenatorDetails.id,
        },
        type: "whereUsedNode",
      });

      let yPos = uiDefaults.firstNodeY + uiDefaults.nodeSpacing;

      services.list.forEach((service) => {
        //debugger;

        let newNode = {
          id: service.id,
          position: { x: 5, y: yPos },
          type: "whereUsedNode",
          data: {
            //type: service .type,

            relationship: service.relationship,

            width: nodeWidth,
            height: nodeHeight,
            //relationshipCounts: relationshipCounts,
            rootName: implemenatorDetails.name,
          },
        };

        let eId = `e${service.id}`;

        let newEdge = {
          id: eId,

          type: "default",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 35,
            height: 35,
          },
        };

        yPos = yPos + uiDefaults.nodeSpacing;

        // let rootNodeIsParent = services.list.some(
        //   (service) =>
        //     service.implementorParent === id &&
        //     service.typeParent == serviceType
        // );

        const rootNodeIsParentInThisService =
          service.implementorParent === id &&
          service.typeParent === serviceType;

        //debugger
        if (rootNodeIsParentInThisService) {
          //this nod is acting as a child in this service

          newNode.data.nodeType = "child";
          newNode.data.serviceType = service.typeChild;
          newNode.data.implementorId = service.implementorChild;

          newEdge.sourceHandle = service.relationship + "_source";
          newEdge.targetHandle = service.relationship + "_target";

          if (service.relationship !== "Reads") {
            newEdge.source = implemenatorDetails.id;
            newEdge.target = service.id;
          } else {
            newEdge.source = service.id;
            newEdge.target = implemenatorDetails.id;
          }
        } else {
          //this node is acting as the parent in this service
          newNode.data.nodeType = "parent";
          newNode.data.serviceType = service.typeParent;
          newNode.data.implementorId = service.implementorParent;

          newEdge.sourceHandle = service.relationship + "_source";
          newEdge.targetHandle = service.relationship + "_target";

          if (service.relationship !== "Reads") {
            newEdge.source = implemenatorDetails.id;
            newEdge.target = service.id;
          } else {
            newEdge.source = service.id;
            newEdge.target = implemenatorDetails.id;
          }
        }

        initialNodes.push(newNode);
        initialEdges.push(newEdge);
      });

      //layout the nodes

      //layout the nodes
      getLayoutedElements(initialNodes, initialEdges, elkOptions).then(
        ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
          setNodes(layoutedNodes);
          setEdges(layoutedEdges);
        }
      );

      console.log("Edges: ", initialEdges);
      console.log("Nodes: ", initialNodes);
    }
  };

  // console.log("Edges: ", edges);
  // console.log("Nodes: ", nodes);

  const configRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = () => {
    setAnchorEl(configRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //dispatch(setSelectedNode(null));
  };

  useEffect(() => {
    //initial load to make sure we have all the data
    //debugger;

    const service = {
      implementor_type: serviceType,
      implementor_id: id,
    };

    dispatch(getServiceRelated({ service }));

    if (!implemenatorDetails) {
      switch (serviceType) {
        case "kpi":
          dispatch(getKpi({ id }));
          break;

        case "utility":
          dispatch(getUtility({ id }));
          break;
        case "blox":
          dispatch(getBlox({ id }));
          break;
        case "stateSet":
          dispatch(getStateSet({ id }));
          break;
      }
    }
  }, []);

  useEffect(() => {
    const service = {
      implementor_type: serviceType,
      implementor_id: id,
    };

    dispatch(getServiceRelated({ service }));
  }, [refreshPoint]);

  useEffect(() => {
    if (implemenatorDetails && services.total > 0) {
      //debugger
      loadServicesNodes();
    }
    //dispatch(initUi())
  }, [implemenatorDetails, services]);

  if (implemenatorDetails && services.total > 0) {
    return (
      <Box height={areaHeight} display="flex">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          //onEdgesChange={onEdgesChange}
          proOptions={proOptionsReactFlow}
          nodeTypes={nodeTypes}
          //edgeTypes={edgeTypes}
          onPaneClick={handlePaneClick}
          //defaultViewport={defaultViewport}
          fitView
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </Box>
    );
  } else {
    return (
      <Box height={areaHeight} display="flex">
        Loading...
      </Box>
    );
  }
};

export default WhereUsedService;
