
import { renderType } from "./renderType";

export function renderColumn(column, row, columns) {
  //wrapper for the table page to use the renderType lookups

  if (column.renderCell === "<NiceCloudOrgElement>") {
    //debugger;

    return renderType(
      column.renderCell,
      row[column.field],
      row[column.currencyField],
      null,
      null,
      row[column.resourceType],
    );
  }

  if (column.renderCell === "<NiceCurrency>") {
    //debugger;

    return renderType(
      column.renderCell,
      row[column.field],
      row[column.currencyField],
      null,
      null,
    );
  }

  if (column.renderCell === "<NiceTarget>") {
    return renderType(
      column.renderCell,
      row[column.field],
      null,
      row["type"],
      null,
    );
  }

  if (column.renderCell === "<NiceMeasure>") {

    //debugger;
    const friendlyNameColumn = columns.find((col) => col.field == row[column.field])
    return renderType(
      column.renderCell,
      friendlyNameColumn?.headerName ? friendlyNameColumn.headerName : row[column.field],
      null,
      null,
      
    );
  }


  return renderType(column.renderCell, row[column.field]);
}
