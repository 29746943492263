import React from "react";
import { Box, Icon, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useSelector } from "react-redux";
import { processStringForDashboardFilters } from "common/helpers";

const PlaceHolder = ({ icon, columns, left, top, rows, type, message }) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} / span ${rows}`}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      p=".3rem 1rem"
      flex="1 1 100%"
      //backgroundColor={theme.palette.background.alt}

      sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
      borderRadius="0.55rem"
      alignItems="start"
    >
      <Stack direction="column" alignItems="start">
        <Stack direction="row" alignItems="start" gap={1}>
          {icon}
          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            Loading ... {type}
          </Typography>
        </Stack>
        <Typography variant="h6" sx={{ color: theme.palette.text.main}}>
          {message}
        </Typography>
      </Stack>
    </Box>
  );
};

export default PlaceHolder;
