import React, { useState } from "react";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "components/global/Navbar";
import Footer from "components/global/Footer";
import MessageArea from "components/global/Message";
import Copilot from "components/copilot/copilot";
import { styled } from "@mui/material/styles";

//const drawerWidth = "25%";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, copilotsize }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: `${copilotsize}%`,
      overflowY: "hidden",
      "&::-webkit-scrollbar": {
        width: "0.5em",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#999",
        outline: "1px solid slategrey",
      },
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

const Layout = ({
  copilotSize,
  setCopilotSize,
  setIsCopilotOpen,
  isCopilotOpen,
  setIsNavMenuOpen,
  isNavMenuOpen,
  menuArea,
}) => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <MessageArea />

      <Box flexGrow={1}>
        <Navbar
          copilotSize={copilotSize}
          setCopilotSize={setCopilotSize}
          isCopilotOpen={isCopilotOpen}
          setIsCopilotOpen={setIsCopilotOpen}
          isNavMenuOpen={isNavMenuOpen}
          setIsNavMenuOpen={setIsNavMenuOpen}
          menuArea={menuArea}
        />

        <Toolbar />

        <Main open={isCopilotOpen} copilotsize={copilotSize}>
          <Outlet />
          {/* <Footer /> */}
        </Main>

        {isLoggedIn && isCopilotOpen && (
          <Copilot
            isNonMobile={isNonMobile}
            drawerWidth={copilotSize}
            isCopilotOpen={isCopilotOpen}
            setIsCopilotOpen={setIsCopilotOpen}
          />
        )}
      </Box>
    </Box>
  );
};

export default Layout;
