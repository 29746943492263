import React from "react";
import { useDragLayer } from "react-dnd";

const CustomDragPreview = ({ item, width  }) => (
  <div style={{ padding: "5px", width: width * .7, background: "rgba(255,255,255,0.8)", borderRadius: "5px", boxShadow: "0 2px 8px rgba(0,0,0,0.3)" }}>
    {item.name}
  </div>
);

const CustomDragLayer = ({menuWidth}) => {
    const {
      itemType,
      isDragging,
      item,
      initialOffset,
      currentOffset,
    } = useDragLayer((monitor) => ({
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    }));
  
    if (!isDragging || !currentOffset) {
      return null;
    }
  
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
  
    return (
      <div
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          top: 0,
          left: 0,

          zIndex: 1000, // Ensure the preview is on top
          transform,
          WebkitTransform: transform,
        }}
      >
        <CustomDragPreview item={item} width={menuWidth}/>
      </div>
    );
  };

export default CustomDragLayer;