import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  validateYupSchema,
  useField,
} from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  Autocomplete,
  Grid,
} from "@mui/material";

import KeyVal from "components/global/KeyVal";
import Header from "components/global/Header";
import {
  updateStateSetValueData,
  updateStateSetRowValueData,
  getStateSet,
  getStateUi,
  getStateDataId,
} from "slices/state";

const StateSetAsConfigProvider = ({ id, showHidden }) => {
  const dispatch = useDispatch();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const StateSet = useSelector((state) =>
    state.stateSet.data.list?.find((set) => set.id === id)
  );

  const set_values = useSelector((state) => state.stateSet.values);

  const schema = useSelector((state) => state.stateSet.stateUi);

  useEffect(() => {
    //debugger;

    if (!StateSet) {
      //need to get the StateSet form the API
      dispatch(getStateSet({ id }));
    }

    dispatch(getStateUi({ id }));

    dispatch(getStateDataId({ id }));

  }, [StateSet, id]);

  const handelUpdate = (field, newVal) => {
    //debugger;

    const stateValue = {
      stateSetId: StateSet.id,
      data: {
        id: set_values.id,
        [field]: newVal,
      },
    };

    dispatch(updateStateSetValueData({ stateValue }));
  };

  //console.log("Cols: " , schema.columns)

  if (!StateSet || !schema || !set_values) {
    return <Box>Loading ...</Box>;
  } else {
    return (
      <Box display="flex" flexDirection="column" gap=".6rem" maxWidth={"50rem"}>
        {schema?.columns?.map((item) => {
          if (item.uiHide !== true || showHidden) {
            return (
              <KeyVal
                key={item.field}
                label={item.headerName}
                value={
                  set_values[item.field] !== undefined
                    ? set_values[item.field]
                    : "Not Set"
                }
                tip={item.helperText}
                fieldType={item.type}
                field={item.field}
                updater={handelUpdate}
                lookupCollection={item.lookupCollection}
                lookupIdField={item.lookupIdField}
                lookupLabel={item.lookupLabel}
                flexDirection="column"
                canEdit={item.valueMode === "System" || item.field === "id" ? false : true}
                uiRenderType={
                  set_values[item.field] !== undefined ? item.renderCell : ""
                }
                multiLine={item.multiLine}
                rows={item.rows}
              />
            );
          }
        })}
      </Box>
    );
  }
};

export default StateSetAsConfigProvider;
