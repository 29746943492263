import { AccountTreeOutlined, HelpOutlineOutlined, MonetizationOnOutlined, PatternOutlined, PolicyOutlined } from "@mui/icons-material"


export const catalogueTypes = new Map()

catalogueTypes.set("Pricing", <MonetizationOnOutlined/>)
catalogueTypes.set("Standard Operating Procedure", <AccountTreeOutlined/>)
catalogueTypes.set("FinOps Policies", <PolicyOutlined/>)
catalogueTypes.set("OpsBlox Help", <HelpOutlineOutlined/>)
catalogueTypes.set("Pattern Library", <PatternOutlined/>)
