import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";


//https://giggster.com/guide/complementary-colors/
//http://mcg.mbitson.com/#!?mcgpalette0=%2314842f

// color design tokens export
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  secondary: {
    50: '#e3f0e6',
    100: '#b9dac1',
    200: '#8ac297',
    300: '#5ba96d',
    400: '#37964e',
    500: '#14842f', //main secondary colour 
    600: '#127c2a',
    700: '#0e7123',
    800: '#0b671d',
    900: '#065412',
  },


  primary1:{
    50: '#f5e5e3',
  100: '#e7beb8',
  200: '#d79389',
  300: '#c7685a',
  400: '#bb4736',
  500: '#af2713', //OpsBlox red
  600: '#a82311',
  700: '#9f1d0e',
  800: '#96170b',
  900: '#860e06',

  },

  primary:{

  50: '#e0f6fd',
  100: '#b3e7fb',
  200: '#80d8f8',
  300: '#4dc8f5',
  400: '#26bcf2',
  500: '#00b0f0',
  600: '#00a9ee',
  700: '#00a0ec',
  800: '#0097e9',
  900: '#0087e5',
},

  secondary3: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#ffe3a3",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },

};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);


export const tokens = (mode) =>{

  return (mode === "dark" 
  ?{
    ...tokensDark
  }
  :
  {
    ...tokensLight
  })
} 

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[800],
              light: tokensDark.primary[800],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.primary[600],
              alt: tokensDark.primary[500],
            },
            action:{
              hover: tokensDark.secondary[100],
            },
            text:{
              primary: tokensDark.grey[900],
              secondary: tokensDark.grey[800],
              light: tokensDark.grey[100],
            }
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.primary[500],
              light: tokensDark.primary[100],
              contrastText: tokensDark.grey[10], // Define contrast text
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.primary[100],
              light: tokensDark.primary[300],
              contrastText: tokensDark.grey[900], // Define contrast text
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[300],
              contrastText: tokensDark.grey[100], // Define contrast text
            },
            // note:{
            //   main: '#EFD9FD',
            //   contrastText: tokensDark.grey[900], // Define contrast text
            // },
            markdownNote:{
              main: '#EFD9FD',
              contrastText: tokensDark.grey[900], // Define contrast text
            },
            markdownTip:{
              main: tokensDark.primary[100],
              contrastText: tokensDark.grey[900], // Define contrast text
            },
            markdownWarning:{
              main: tokensDark.secondary3[300],
              contrastText: tokensDark.grey[900], // Define contrast text
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.primary[300],
              light: tokensDark.grey[50],
              contrastText: tokensDark.grey[100], // Define contrast text
            },
            action:{
              hover: tokensDark.primary[100],
            },
            text:{
              primary: tokensDark.grey[900],
              secondary: tokensDark.grey[800],
              light: tokensDark.grey[100],
            }

          }),
    },
    
    typography: {
      fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
      fontSize: 14,
      body1: {
        fontSize: 14, // Ensure body1 uses the base font size
      },
      h1: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 35,
        fontWeight: "bold"
      },
      h2: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 29,
        fontWeight: "bold"
      },
      h3: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 25,
        fontWeight: "bold"
      },
      h4: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 21,
        fontWeight: "bold"
      },
      h5: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 17,
        fontWeight: "bold"
      },
      h6: {
        fontFamily: ["Segoe UI", "Inter", "sans-serif"].join(","),
        fontSize: 15,
        fontWeight: "bold"
      },
    },


  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});



export const useMode = () => {
  // Get the color mode preference from Redux state
  const colorModePreference = useSelector((state) => {
    const preference =
      state.auth.user.preferences?.find(
        (preference) => preference.name === "useDarkMode"
      )?.preferenceValue;

    return preference ? preference : null;
  });

  const initialMode = colorModePreference === "dark" ? "dark" : "light";

  const [mode, setMode] = useState(initialMode);

  const colorMode = useMemo(() => {
    const toggleColorMode = () =>
      setMode((prev) => (prev === "light" ? "dark" : "light"));

    return {
      toggleColorMode,
    };
  }, [setMode]);


  const mergedTypography = {
    ...themeSettings(mode).typography,
    //...chatTypography,
  };

  const mergedThemeSettings = {
    ...themeSettings(mode),
    typography: mergedTypography,
  };

  //debugger
  const theme = useMemo(() => createTheme(mergedThemeSettings), [mode]);

  return [theme, colorMode];
};