import React, { useRef } from "react";
import {
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
  Typography,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";

import {
  CancelOutlined,
  PsychologyOutlined,
  SendOutlined,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from "@mui/icons-material";

import ProfileAvatar from "components/user/ProfileAvatar";
import NiceMarkdown from "components/global/NiceMarkdown";
import { useDispatch } from "react-redux";
import { updateCopilotFeedback } from "slices/copilot";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const CopilotResponse = ({
  user,
  message,
  isWaiting,
  id,
  lastPrompt,
  copilotPrompt,
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme(); // Get the theme object

  const [badSelected, setBadSelected] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleFeedback = (result) => {
    const prompt = {
      id: copilotPrompt.id,
      userFeedback: result,
      userFeedbackMessage: feedback,

    };
    dispatch(updateCopilotFeedback({ prompt }));
  };

  return (
    //<ListItem sx={{ padding: 0 }} alignItems="flex-start">
    <ListItem
      sx={{
        padding: 0,
        wordBreak: "break-word", // Allow long words to break and wrap
      }}
      alignItems="flex-start"
      ref={containerRef}
      key={id}
    >
      <Box display={"flex"} flexDirection={"row"} mb=".6rem">
        <ListItemAvatar sx={{ minWidth: 35 }}>
          <Avatar sx={{ width: 25, height: 25 }}>
            {user === "Copilot" ? (
              <PsychologyOutlined />
            ) : (
              <ProfileAvatar user={user.user} size="25" />
            )}
          </Avatar>
        </ListItemAvatar>

        <Box display={"flex"} flexDirection={"column"} mb=".1rem">
          <ListItemText
            primary={
              user === "Copilot"
                ? "OpsBlox.io"
                : user.user.firstName + " " + user.user.lastName
            }
            //secondary={message}
            primaryTypographyProps={{ fontSize: "medium", fontWeight: "bold" }}
            //secondaryTypographyProps={{ fontSize:"medium"  }}
          />

          {isWaiting ? (
            <LinearProgress />
          ) : (
            <React.Fragment>
              <NiceMarkdown>{message}</NiceMarkdown>
              {lastPrompt && copilotPrompt.userFeedback && (
                <Box
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: '8px', 
                  padding: '8px 16px', 
                  maxWidth: 'fit-content', 
                }}
              >
                <Typography >Thanks for your feedback...</Typography>
                </Box>
              )}

              {lastPrompt && !copilotPrompt.userFeedback && (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                  >
                    <Tooltip title="Good response">
                      <IconButton
                        onClick={() => handleFeedback("Good")}
                        disabled={badSelected}
                      >
                        <ThumbUpOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Bad response">
                      <IconButton
                        onClick={() => setBadSelected(true)}
                        disabled={badSelected}
                      >
                        <ThumbDownOutlined />
                      </IconButton>
                    </Tooltip>
                    {badSelected && (
                      <React.Fragment>
                        <TextField
                          id="feedback"
                          label="tell us more..."
                          value={feedback}
                          size="small"
                          onChange={(event) => {
                            setFeedback(event.target.value);
                          }}
                        />

                        <IconButton onClick={() => handleFeedback("Bad")}>
                          <SendOutlined />
                        </IconButton>

                        <IconButton onClick={() => setBadSelected(false)}>
                          <CancelOutlined />
                        </IconButton>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

export default CopilotResponse;
