import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CheckOutlined,
  FlagOutlined,
  Height,
  PlaylistAddCheckOutlined,
  VerticalAlignBottomOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "slices/message";
import { create, createWorkflowStep } from "slices/worklow_step";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ContributionCard({
  data,
  selectContribution,
  isSelected,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handelSelectContribution = () => {
    selectContribution(data);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let icon = "";

  if (data.type == "WorkflowContribution") {
    icon = <PlaylistAddCheckOutlined />;
  }

  if (data.type == "Utility") {
    icon = <FlagOutlined />;
  }

  if (data.type == "Blox") {
    icon = <WidgetsOutlined />;
  }

  console.log("selected: ", isSelected);

  return (
    <Card
      sx={{
        maxWidth: "25rem",
        minHeight: "15rem",
        bgcolor: isSelected
          ? theme.palette.secondary.main
          : theme.palette.background.light,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              width: 56,
              height: 56,
            }}
          >
            {icon}
          </Avatar>
        }
        title={
          <Typography variant="h5" component="div">
            {data.name}
          </Typography>
        }
        subheader={data.type}
      />

      <CardContent>
        <Typography variant="body">{data.description}</Typography>
      </CardContent>

      <CardActions disableSpacing>
        {!isSelected && (
          <Button
            variant="contained"
            startIcon={<CheckOutlined />}
            color="primary"
            onClick={handelSelectContribution}
          >
            Use
          </Button>
        )}

        <IconButton aria-label="add to favorites" ml=".5rem">
          <FavoriteIcon />
        </IconButton>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

            <Typography>{data.implementor}</Typography>
            <Typography>{data.implementorId}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
