import api from "./api";



const updateWorkflowStep = (data) => {
  return api.patch("/workflowstep",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const updateWorkflowStepActive = (step) => {
  return api.patch("/workflowstep/active",
  {
    ...step
  })
  .then((response) => {
    return response.data;
  });
};



const getWorkflowSteps = (search) => {

  return api.get("/workflowstep",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getWorkflowStep = (id) => {
  return api.get("/workflowstep/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflowStepUi = (id) => {
  return api.get("/workflowstep/ui/"+id)
  .then((response) => {
    return response.data;
  });
};

const getWorkflowStepData = (search) => {

  return api.get("/workflowstep/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const createWorkflowStep = (step) => {
  return api.post("/workflowstep", {
    ...step
  });
};

const deleteWorkflowStep = (id) => {

  return api.delete("/workflowstep/"+id)
  .then((response) => {
    return response.data;
  });
};



const WorkflowStepService = {
  getWorkflowSteps,
  updateWorkflowStep,
  getWorkflowStep,
  getWorkflowStepData,
  getWorkflowStepUi,
  updateWorkflowStepActive,
  createWorkflowStep,
  deleteWorkflowStep,
};

export default WorkflowStepService;