import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Button,
  Popover,
  Tabs,
  Tab,
} from "@mui/material";
import { ChevronRightOutlined, MenuOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { userHasRole } from "slices/auth";
import { Link as RouterLink } from "react-router-dom";
import TabPanel from "./TabPanel";

import GetMenuItems from "./MenuItems";

const MegaMenu = ({ isNavMenuOpen, setIsNavMenuOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [activeArea, setActiveArea] = useState({});
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeItemName, setActiveItemName] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
  const swagger_URL = window.__RUNTIME_CONFIG__.REACT_APP_SWAGGER_URL;
  //const swagger_path = `${API_URL}swagger/`
  const swagger_path = swagger_URL;

  //console.log("API:" , swagger_path)

  const [anchorElMega, setAnchorElMega] = useState(null);
  const isOpenMega = Boolean(anchorElMega);
  const handleClickMenu = (event) => setAnchorElMega(event.currentTarget);
  const handleCloseMenu = () => setAnchorElMega(null);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const user = useSelector((state) => state.auth.user.user);

  const isAdmin = useSelector((state) => userHasRole(state, "SuperAdmin"));
  const isCustAdmin = useSelector((state) =>
    userHasRole(state, "CustomerAdmin")
  );

  const navAreas = GetMenuItems(user, swagger_path);

  const findParentAndSelectedItem = (navAreas, area) => {
    let parentItem = null;
    let selectedItem = null;

    for (const parent of navAreas) {
      if (parent.mainItems) {
        for (const item of parent.mainItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }

      if (parent.currentStatusItems) {
        for (const item of parent.currentStatusItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }

      if (parent.setupItems) {
        for (const item of parent.setupItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }

      if (parent.configgurationItems) {
        for (const item of parent.configgurationItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }

      if (parent.customItems) {
        for (const item of parent.customItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }

      if (parent.masterDataItems) {
        for (const item of parent.masterDataItems) {
          const parts = item.link?.split("/");
          let link = "";

          if (parts === undefined) {
            link = item.link;
          } else {
            link = parts[0];
          }

          if (link === area) {
            selectedItem = item;
            parentItem = parent;
            break; // Found the item, no need to continue searching
          }
        }
      }




      if (selectedItem) {
        break; // Found the item, no need to continue searching
      }
    }

    return { parentItem, selectedItem };
  };

  const RenderItems = (items, column) => {
    let start,
      end = 0;

    if (column === 1) {
      start = 0;
      end = navAreas.length + 1;
    }

    if (column === 2) {
      start = navAreas.length + 1;
      end = navAreas.length * 2 + 2;
    }

    if (column === 3) {
      start = navAreas.length * 2 + 2;
      end = navAreas.length * 3 + 3;
    }

    if (column === 4) {
      start = navAreas.length * 3 + 3;
      end = navAreas.length * 4 + 4;
    }

    return (
      <List sx={{ width: menuWidth }}>
        {items
          ?.slice(start, end)
          .map(({ text, icon, link, external, header }) => {
            if (header) {
              return (
                <ListItem key={"header_" + text} disablePadding>
                  <ListItemIcon
                    sx={{
                      minWidth: "33px",
                      ml: ".4rem",
                      color:
                        activeItemName === text
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    }}
                  >
                    {icon}
                  </ListItemIcon>

                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  />
                </ListItem>
              );
            }

            return (
              <ListItem key={"item_" + text} disablePadding>
                <ListItemButton
                  component={external ? "a" : RouterLink} // Use 'a' for external links and RouterLink for internal links
                  href={external ? link : undefined}
                  target={external ? "_blank" : undefined}
                  to={external ? undefined : `/${link}`} // Provide "to" prop for internal links
                  onClick={(event) => {
                    // Close the menu
                    handleCloseMenu();
                  }}
                  sx={{
                    ml: ".4rem",
                    mt: 0,
                    mb: 0,
                    pt: 0,
                    pb: 0,
                    backgroundColor:
                      activeItemName === text
                        ? theme.palette.primary.light
                        : "transparent",
                    color:
                      activeItemName === text
                        ? theme.palette.text.main
                        : theme.palette.text.main,
                  }}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    );
  };

  const menuWidth = 250;

  useEffect(() => {
    //get the first element from the path,
    //look up the menu item that has this link and then find its parent,
    //set that as the active area

    const areaToSearch = pathname.substring(1);
    const parts = areaToSearch.split("/");
    const area = parts[0];

    const { parentItem, selectedItem } = findParentAndSelectedItem(
      navAreas,
      area
    );

    // console.log("Area: ", area);
    // console.log("Parent Item:", parentItem);
    // console.log("Selected Item:", selectedItem);

    setActiveAreaName(parentItem?.text);
    setActiveItemName(selectedItem?.text);
    setActiveArea(parentItem);
  }, [pathname]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Button
        onClick={handleClickMenu}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textTransform: "none",
          gap: "1rem",
        }}
      >
        <MenuOutlined sx={{ fontSize: 25 }} />

        <Box textAlign="left">
          <Typography
            fontWeight="bold"
            fontSize="1.2rem"
            sx={{
              color: theme.palette.primary.main,

              gap: "1rem",
            }}
          >
            {/* {activeAreaName} */}
            {activeItemName}
          </Typography>
        </Box>
      </Button>

      <Popover
        id="megaMenu"
        open={isOpenMega}
        anchorEl={anchorElMega}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="row" gap=".1rem">
          <List
            sx={{
              width: menuWidth,
              backgroundColor: theme.palette.background.light,
            }}
          >
            {navAreas.map((item) => {
              return (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setActiveArea(item);
                      setActiveAreaName(item.text);
                    }}
                    sx={{
                      backgroundColor:
                        activeAreaName === item.text
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        activeAreaName === item.text
                          ? theme.palette.text.main
                          : theme.palette.text.main,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "33px",
                        ml: ".4rem",
                        color:
                          activeAreaName === item.text
                            ? theme.palette.text.main
                            : theme.palette.text.main,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>

                    <ListItemText primary={item.text} />

                    <ChevronRightOutlined sx={{ ml: "auto" }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>

          <Box sx={{ minWidth: menuWidth * 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Menu Tabs"
              >
                <Tab
                  label={activeAreaName !== "Admin" ? "Main" : "Current Status"}
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    activeAreaName !== "Admin" ? "Setup" : "Configguration"
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label={activeAreaName !== "Admin" ? "Custom" : "Master Data"}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <Box display="flex" flexDirection="row" gap=".2rem">
                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.mainItems, 1)
                  : RenderItems(activeArea?.currentStatusItems, 1)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.mainItems, 2)
                  : RenderItems(activeArea?.currentStatusItems, 2)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.mainItems, 3)
                  : RenderItems(activeArea?.currentStatusItems, 3)}
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Box display="flex" flexDirection="row" gap=".2rem">
              {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.setupItems, 1)
                  : RenderItems(activeArea?.configItems, 1)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.setupItems, 2)
                  : RenderItems(activeArea?.configItems, 2)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.setupItems, 3)
                  : RenderItems(activeArea?.configItems, 3)}
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Box display="flex" flexDirection="row" gap=".2rem">
              {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.customItems, 1)
                  : RenderItems(activeArea?.masterDataItems, 1)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.customItems, 2)
                  : RenderItems(activeArea?.masterDataItems, 2)}

                <Divider orientation="vertical" flexItem variant="middle" />

                {activeAreaName !== "Admin"
                  ? RenderItems(activeArea?.customItems, 3)
                  : RenderItems(activeArea?.masterDataItems, 3)}
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default MegaMenu;
