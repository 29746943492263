import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getWorkflowContribution,
  updateWorkflowContribution,
  getWorkflowContributionUi,
  getWorkflowContributionData,
  clearWorkflowContributionUi,
  runWorkflowContribution, updateWorkflowContributionActive ,
} from "slices/worklow_contribution";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import KeyVal from "components/global/KeyVal";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import {
  CheckOutlined,
  CloseOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceActive from "components/global/NiceActive";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WorkflowContribution = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const WorkflowContribution = useSelector((state) =>
    state.workflowContributions.data.list?.find((org) => org.id === id)
  );

  const WorkflowContribution_data = useSelector((state) => state.workflowContributions.WorkflowContribution_data);

  const WorkflowContribution_UI = useSelector((state) => state.workflowContributions.WorkflowContributionUi);

  const [tabValue, setTabValue] = React.useState(0);

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    //need to get the WorkflowContribution UI form the API
    dispatch(getWorkflowContributionUi({ id }));

    if (!WorkflowContribution) {
      //need to get the WorkflowContribution form the API
      dispatch(getWorkflowContribution({ id }));
    }
  }, [WorkflowContribution, id]);

  useEffect(() => {
    if (isFirstLoad) {
      //set the face that we have an uptodat UI block to use
      setIsFirstLoad(false);
      dispatch(clearWorkflowContributionUi());
    }
  }, [id]);

  useEffect(() => {
    dispatch(getWorkflowContribution({ id }));
  }, [refreshPoint]);

  const handleRun = () => {
    dispatch(clearMessage());
    dispatch(runWorkflowContribution({ id }));
  };

  const handleActivate = () => {
    dispatch(clearMessage());
    const WorkflowContribution = { id: id, active: true };
    dispatch(updateWorkflowContributionActive({ WorkflowContribution }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const WorkflowContribution = { id: id, active: false };
    dispatch(updateWorkflowContributionActive({ WorkflowContribution }));
  };

  let columns = [];

  if (WorkflowContribution_UI?.columns) {
    columns = WorkflowContribution_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object
      if (updatedColumn.renderCell) {
        // check if renderCell exists
        updatedColumn.renderCell = ({ row }) => {
          //debugger;

          switch (column.renderCell) {
            case "<NiceOrchestrator>":
              return <NiceOrchestrator>{row[column.field]}</NiceOrchestrator>;
            case "<NiceOrganization>":
              return <NiceOrganization>{row[column.field]}</NiceOrganization>;
            case "<NiceDate>":
              return <NiceDate>{row[column.field]}</NiceDate>;
              case "<NiceActive>":
                return <NiceActive>{row[column.field]}</NiceActive>;
            default:
              //this is an error so remove the render, will keep it safe
              return "";
          }
        };
      }
      return updatedColumn;
    });
  }

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={WorkflowContribution?.name} subtitle={WorkflowContribution?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          {!WorkflowContribution?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckOutlined />}
              onClick={handleActivate}
            >
              Activate
            </Button>
          )}
          {WorkflowContribution?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloseOutlined />}
              onClick={handleDectivate}
            >
              Deactivate
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayArrowOutlined />}
            onClick={handleRun}
          >
            Run
          </Button>
        </FlexBetween>
      </FlexBetween>

      <DetailBlock>
        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>{WorkflowContribution?.description}</Typography>
          <Typography>WorkflowContribution Type: {WorkflowContribution?.implementor}</Typography>
          <Typography>
            Last Run:{" "}
            {WorkflowContribution?.active ? <NiceDate>{WorkflowContribution?.lastRun}</NiceDate> : "n/a"}
          </Typography>
          <Typography>
            Identified Last Time:{" "}
            {WorkflowContribution?.active ? WorkflowContribution?.updatesLastTime : "n/a"}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>
            Created: <NiceDate>{WorkflowContribution?.createdAt}</NiceDate>
          </Typography>
          <Typography>
            Modified: <NiceDate>{WorkflowContribution?.modifiedAt}</NiceDate>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <KeyVal
            label="Active"
            value={WorkflowContribution?.active ? "Yes" : "Not Running at Orchestrator"}
            tip="Enable the WorkflowContribution if you want it to run"
          />

          <KeyVal
            label="Job Status"
            value={WorkflowContribution?.jobStatus}
            link={`/job/${WorkflowContribution?.jobId}`}
            tip={`Open Job id:${WorkflowContribution?.jobId}`}
            makeBlue
          />
        </Box>
      </DetailBlock>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="WorkflowContribution Tabs"
          >
            <Tab
              label={`Resources (${WorkflowContribution_data?.total})`}
              {...a11yProps(0)}
            />
            <Tab label="Job History" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          {WorkflowContribution_data && !isFirstLoad && WorkflowContribution_UI ? (
            <TablePage
              masterId={id}
              dataRows={WorkflowContribution_data}
              dataColumns={columns}
              dataGetter={getWorkflowContributionData}
              defaultColumnsVisability={WorkflowContribution_UI.columnsVisability}
              defaultSort={WorkflowContribution_UI.sort}
              refreshPoint={refreshPoint}
            />
          ) : (
            <Typography>Loading</Typography>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          History
        </TabPanel>
      </Box>
    </PageBlock>
  );
};

export default WorkflowContribution;
