import { Box, Typography, Button, Popover } from "@mui/material";

import { RefreshOutlined, OpenInNewOutlined, DeleteOutline } from "@mui/icons-material";

import Header from "components/global/Header";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";

import { renderColumn } from "common/renderColumn";

import { deleteCataloguePage, getCataloguePageUi, getCataloguePages } from "slices/catalogue";
import { clearMessage } from "slices/message";
import CataloguePageAdd from "components/catalogue/CataloguePageCreate";

const AdminCataloguePageIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.catalogue.page.data);
  const ui = useSelector((state) => state.catalogue.page.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getCataloguePageUi({}));
  }, [rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    dispatch(clearMessage());
    setAnchorEl(configRef.current);
  };

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("30vw");

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleOpen = () => {
    navigate("/catalogue/page/" + selectionModel[0]);
  };

  const handleDelete = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const id = item

      dispatch(deleteCataloguePage({ id }));
    });

  }

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },
    {
      text: "",
      icon:null,
      callBack: "",
      isDivider:true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: handleDelete,
    },
  ];

  let columns = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;

    inputString = inputString.replace(/_id/g, "id");

    const processedString = inputString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (ui?.columns) {
    columns = ui.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  //console.log("data data", rows, " ", ui);

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Catalogue Pages" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button
              variant="outlined"
              color="primary"
              onClick={handelRefresh}
            >
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handelOpenAddEdit}
            >
              New Page
            </Button>
          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (
          <TablePage
            dataRows={rows}
            dataColumns={columns}
            dataGetter={getCataloguePages}
            defaultColumnsVisability={ui.columnsVisability}
            defaultSort={ui.sort}
            refreshPoint={refreshPoint}
            selectionModel={selectionModel}
            selectionSetter={setSelectionModel}
            serviceType={"cataloguePage"}
          />
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <CataloguePageAdd
            closerFunc={handelCloseAddEdit}
            //cataloguePage={cataloguePage}
          />
        </Box>
      </Popover>

    </>
  );
};

export default AdminCataloguePageIndex;
