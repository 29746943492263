import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";

import {
  WidgetsOutlined,
  PlayCircleOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  RefreshOutlined,
  ArrowDropDown,
  DeleteOutline,
  PlayArrowOutlined,
  CheckOutlined,
  CompressOutlined,
  CloseOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkflowSteps,
  updateWorkflowStepActive,
  runWorkflowStep,
} from "slices/worklow_step";
import { setMessage, clearMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";
import NiceActive from "components/global/NiceActive";
import NiceWorkflow from "components/global/NiceWorkflow";

const WorkflowSteps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.workflowSteps.data);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const columnsVisability = {
    id: false,
    organization: false,
    orchestrator: false,
  };

  const handleRun = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((id) => {
      dispatch(runWorkflowStep({id}));
    });

  }

  const handleActivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const WorkflowStep = { id: item, active: true };

      dispatch(updateWorkflowStepActive({ WorkflowStep }));
    });

  }

  const handleDectivate = () =>{

    dispatch(clearMessage())

    selectionModel.forEach((item) => {
      const WorkflowStep = { id: item, active: false };

      dispatch(updateWorkflowStepActive({ WorkflowStep }));
    });

  }


  const handleOpen = () => {

    navigate("/WorkflowStep/" + selectionModel[0])
  }

  const actionMenuItems = [
    
    {
      text: "Details",
      icon:<OpenInNewOutlined/>,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon:<CheckOutlined/>,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon:<CloseOutlined/>,
      callBack: handleDectivate,
    },


    {
      text: "",
      icon:null,
      callBack: "",
      isDivider:true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: "",
    },

  ];

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "workflowId",
      headerName: "Workflow",
      flex: .5,
      renderCell: ({ row: { workflowId } }) => {
        return <NiceWorkflow>{workflowId}</NiceWorkflow>;
      },
    },
    {
      field: "name",
      headerName: "WorkflowStep",
      flex: 2,
      renderCell: ({ row: { name, id } }) => {
        return <NiceLink label={name} link={`/workflowstep/${id}`} tip={"Open the Step"} makeBlue/>;
      },
    },
    
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },

    {
      field: "ImplementorId",
      headerName: "Uses",
      flex: 1,
      renderCell: ({ row: { ImplementorId, name, type } }) => {
        return (
          <NiceLink
            label={name}
            link={`/${type}/${ImplementorId}`}
            tip={`${type} Details`}
            makeBlue
          />
        );
      },
    },

    
  ];


  return (
    <PageBlock>
      <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="WorkflowSteps" subtitle="" />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
          
          <TableActionsButton selectedRows={selectionModel} menuItems={actionMenuItems}/>

          <Button variant="contained" color="primary">
            New Custom WorkflowStep
          </Button>
        </FlexBetween>
      </FlexBetween>

      <TablePage
        dataRows={rows}
        dataColumns={columns}
        dataGetter={getWorkflowSteps}
        defaultColumnsVisability={columnsVisability}
        defaultSort={"name"}
        refreshPoint={refreshPoint}
        selectionModel={selectionModel}
        selectionSetter={setSelectionModel}
      />
    </PageBlock>
  );
};

export default WorkflowSteps;
