import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import StateService from "../services/state.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const createStateSet = createAsyncThunk(
  "StateSet/create",
  async ({ stateSet }, thunkAPI) => {
    try {
      const data = await StateService.createStateSet(stateSet);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addStateSetRow = createAsyncThunk(
  "StateSet/addRow",
  async ({ row }, thunkAPI) => {
    try {
      const data = await StateService.addStateSetRow(row);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteStateSet = createAsyncThunk(
  "StateSet/delete",

  async ({ id }, thunkAPI) => {

    debugger;
    try {
      const data = await StateService.deleteStateSet(id);
      return { StateSets : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteStateSetRow = createAsyncThunk(
  "StateSet/deleteRow",

  async ( row, thunkAPI) => {

    try {
      const data = await StateService.deleteStateSetRow(row.stateSetId , row.rowId);
      return { data};
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStateSet = createAsyncThunk(
  "StateSet/update",
  async ({ stateSet }, thunkAPI) => {
    try {
      const data = await StateService.updateStateSet(stateSet);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateStateSetRowSchema = createAsyncThunk(
  "StateSet/updateRowSchema",
  async ({ schema }, thunkAPI) => {
    try {
      const data = await StateService.updateStateSetRowSchema(schema);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateStateSetActive = createAsyncThunk(
  "StateSet/update_active",
  async ({ stateSet }, thunkAPI) => {
    try {

      const data = await StateService.updateStateSetActive(stateSet);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStateSets = createAsyncThunk(
  "StateSet/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await StateService.getStateSets(search);
      return { StateSets: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStateSet = createAsyncThunk(
  "StateSet/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await StateService.getStateSet(id);
      return { StateSet: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getStateSetData = createAsyncThunk(
  "StateSet/get_data",
  async ({ search }, thunkAPI) => {
    try {
      const data = await StateService.getStateData(search);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);





export const getStateUi = createAsyncThunk(
  "State/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await StateService.getStateUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStateDataId = createAsyncThunk(
  "State/get_data_id",
  async ({ id }, thunkAPI) => {
    try {
      const data = await StateService.getStateDataId(id);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addStateSetField = createAsyncThunk(
  "state/addField",
  async ({ field }, thunkAPI) => {
    try {
      const data = await StateService.addStateSetField(field);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStateSetField = createAsyncThunk(
  "state/updateField",
  async ({ field }, thunkAPI) => {
    try {
      const data = await StateService.updateStateSetField(field);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStateSetFilterValues = createAsyncThunk(
  "StateSet/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await StateService.getStateSetFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStateSetValueData = createAsyncThunk(
  "state/updateData",
  async ({ stateValue }, thunkAPI) => {
    try {
      const updatedData = await StateService.updateStateSetData(stateValue);
      return { updatedData };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStateSetRowValueData = createAsyncThunk(
  "state/updateRowData",
  async ({ stateValue }, thunkAPI) => {
    try {
      const updatedData = await StateService.updateStateSetData(stateValue);
      return { updatedData };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getStateSetServiceUi = createAsyncThunk(
  "StateSet/get_serviceui",
  async ( _, thunkAPI) => {
    try {
      const data = await StateService.getStateSetServiceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const clearStateUi = createAction("State/clear_ui");

const initialState = {
  data: list,
  loading: false,
  rows: list,
  values: [],
  stateUi: [],
  filter_values: [],
  Ui: [],
}

const StateSetSlice = createSlice({
  name: "StateSets",
  initialState,

  extraReducers: {

    [getStateSetServiceUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateSetServiceUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getStateSetServiceUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getStateSetFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateSetFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getStateSetFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },


    [getStateSetData.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateSetData.fulfilled]: (state, action) => {
      //debugger;

      state.rows = action.payload.data;
      state.loading = false;
    },
    [getStateSetData.rejected]: (state, action) => {
      state.rows = list;
      state.loading = false;
    },


    [clearStateUi]: (state, action) => {
      state.stateUi = null;
      state.loading = false;
    },

    [getStateUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateUi.fulfilled]: (state, action) => {
      state.stateUi = action.payload.ui;
      state.loading = false;
    },
    [getStateUi.rejected]: (state, action) => {
      state.stateUi = null;
      state.loading = false;
    },

    [getStateDataId.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateDataId.fulfilled]: (state, action) => {
      state.values = action.payload.data;
      state.loading = false;
    },
    [getStateDataId.rejected]: (state, action) => {
      state.values = null;
      state.loading = false;
    },

    [addStateSetRow.pending]: (state, action) => {
      state.loading = true;
    },
    [addStateSetRow.fulfilled]: (state, action) => {

      state.loading= false

    },
    [addStateSetRow.rejected]: (state, action) => {
      state.loading = false;
    },


    [createStateSet.pending]: (state, action) => {
      state.loading = true;
    },
    [createStateSet.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.data.data);
      state.data.total ++;
      state.loading = false;
    },
    [createStateSet.rejected]: (state, action) => {
      state.loading = false;
    },

    [addStateSetField.pending]: (state, action) => {
      state.loading = true;
    },
    [addStateSetField.fulfilled]: (state, action) => {

      const updatedStateSet = state.data.list.map((StateSet) => {
        if (StateSet.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return StateSet;
        }
      });
      state.data.list = updatedStateSet;
      state.loading= false
    },
    [addStateSetField.rejected]: (state, action) => {
      state.loading = false;
    },
    

    [updateStateSetValueData.pending]: (state, action) => {
      state.loading = true;
    },


    [updateStateSetValueData.fulfilled]: (state, action) => {

      state.values = action.payload.updatedData;
      state.loading= false
    },
    [updateStateSetValueData.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateStateSetRowValueData.pending]: (state, action) => {
      state.loading = true;
    },

    [updateStateSetRowValueData.fulfilled]: (state, action) => {

      //debugger;

      const updatedRow = state.rows.list.map((row) => {
        if (row.id === action.payload.updatedData.id) {
          return action.payload.updatedData;
        } else {
          return row;
        }
      });
      state.rows.list = updatedRow;
      state.loading= false
    },
    [updateStateSetRowValueData.rejected]: (state, action) => {
      state.loading = false;
    },



    [updateStateSetField.pending]: (state, action) => {
      state.loading = true;
    },

    [updateStateSetField.fulfilled]: (state, action) => {

      const updatedStateSet = state.data.list.map((StateSet) => {
        if (StateSet.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return StateSet;
        }
      });
      state.data.list = updatedStateSet;
      state.loading= false
    },
    [updateStateSetField.rejected]: (state, action) => {
      state.loading = false;
    },


    [updateStateSetRowSchema.pending]: (state, action) => {
      state.loading = true;
    },
    [updateStateSetRowSchema.fulfilled]: (state, action) => {

      const updatedStateSet = state.data.list.map((StateSet) => {
        if (StateSet.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return StateSet;
        }
      });
      state.data.list = updatedStateSet;
      state.loading= false
    },
    [updateStateSetRowSchema.rejected]: (state, action) => {
      state.loading = false;
    },


    [getStateSets.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateSets.fulfilled]: (state, action) => {
      state.data = action.payload.StateSets;
      state.loading = false;
    },
    [getStateSets.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [deleteStateSet.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteStateSet.fulfilled]: (state, action) => {

      state.data = action.payload.StateSets;
      state.loading = false;
    },
    [deleteStateSet.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },


    [deleteStateSetRow.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteStateSetRow.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteStateSetRow.rejected]: (state, action) => {
      state.loading = false;
    },


    [updateStateSetActive.fulfilled]: (state, action) => {
      const updatedStateSet = state.data.list.map((StateSet) => {
        if (StateSet.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return StateSet;
        }
      });
      state.data.list = updatedStateSet;
    },

    [updateStateSet.fulfilled]: (state, action) => {

      const updatedStateSet = state.data.list.map((StateSet) => {
        if (StateSet.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return StateSet;
        }
      });
      state.data.list = updatedStateSet;
    },

    [getStateSet.pending]: (state, action) => {
      state.loading = true;
    },
    [getStateSet.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.StateSet;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getStateSet.rejected]: (state, action) => {
      state.loading = false;
    },

  },
});

const { reducer } = StateSetSlice;
export default reducer;
