import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";


import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import { getOrchestrators } from "slices/orchestrator";
import { setMessage } from "slices/message";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckIcon from "@mui/icons-material/Check";
import NiceUser from "components/global/NiceUser";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import TablePage from "components/global/TablePage";
import { ArrowDropDown, RefreshOutlined } from "@mui/icons-material";

const Orchestrators = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.orchestrators.data);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      //debugger
      const id = newRow.id;
      const isBlocked = newRow.is_blocked;

      //dispatch(updateSession({id,isBlocked}));

      return newRow;
    },
    [Orchestrators]
  );

  const handleRowClick = (params) => {
    navigate("/orchestrator/" + params.id);
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    dispatch(setMessage("Error editing Row"));
  }, []);

  const columnsVisability = {
    id: false,
    organization: false,
  }


  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "trusts",
      headerName: "Parent",
      flex: 1,
      renderCell: ({ row: { trusts } }) => {
        if (trusts === "") {
          return <Box>None</Box>;
        } else {
          return <NiceOrchestrator>{trusts}</NiceOrchestrator>;
        }
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "lastContact",
      headerName: "Last Contact",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "active",
      headerName: "Active",
      type: "boolean",
      editable: true,
      renderCell: ({ row: { active } }) => {
        return <Box>{active && <CheckIcon />}</Box>;
      },
    },

    {
      headerName: "Actions",
      field: "",
      flex: 1,
      type: "actions",
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const handleUbBlock = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = false;

          //dispatch(updateSession({id,isBlocked}));
        };

        const handleBlock = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = true;

          //dispatch(updateSession({id,isBlocked}));
        };

        const handleDelete = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = true;

          //dispatch(updateSession({id,isBlocked}));
        };

        return (
          <Stack key={"STACK-" + params.row.id} direction="row" spacing={2}>
            {params.row.isBlocked ? (
              <IconButton
                key={"LOCK-" + params.row.id}
                aria-label="unblock"
                onClick={handleUbBlock}
              >
                <LockOpenIcon />
              </IconButton>
            ) : (
              <IconButton
                key={"UNLOCK-" + params.row.id}
                aria-label="block"
                onClick={handleBlock}
              >
                <LockIcon />
              </IconButton>
            )}
            <IconButton
              key={"DELETE-" + params.row.id}
              aria-label="delete"
              onClick={handleDelete}
              disabled
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <PageBlock>
      <SimpleBreadcrumbs  />
      <FlexBetween>
        <Header title="Orchestrators" subtitle="" count={rows.total} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary">
            <RefreshOutlined />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<ArrowDropDown />}
          >
            Actions
          </Button>
        </FlexBetween>
      </FlexBetween>

      <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getOrchestrators}
          defaultColumnsVisability={columnsVisability}
          defaultSort={"-createdAt"}
        />
      
    </PageBlock>
  );
};

export default Orchestrators;
