
import { useDispatch, useSelector } from "react-redux";
import React, {  useEffect } from "react";
import {  getCloudOrgStructureElement } from "slices/cloudOrgStructure";
import { Box } from "@mui/material";


const NiceSubscription = (props) => {
  const dispatch = useDispatch();

  const wantedResource = props.children;

  const subscription = useSelector((state) =>
  state.cloudOrgStructure?.data?.list?.find(
    (org) =>
    org.id === wantedResource
  )
  );



  useEffect(() => {
    if (wantedResource !== "billingAccount") {
      if (
        !subscription &&
        wantedResource !== "" &&
        wantedResource !== undefined
      ) {
        
        const id = wantedResource
        dispatch(getCloudOrgStructureElement({id}));

      }
    }
  }, [dispatch, subscription, wantedResource]);

  if (wantedResource === "billingAccount") {
    //special case just return a nice name
    return <Box>Billing Account</Box>;
  }

  return (
    <>
      {subscription ? (
        <Box>{subscription.name}</Box>
      ) : (
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceSubscription;
