import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import BloxService from "../services/blox.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0
};

const ui = {
  columns: [],
  columnsVisability: {},
  sort: ""
};

//const users = {data: list, loading: false};


export const updateBlox = createAsyncThunk(
  "Blox/update",
  async ( {org},thunkAPI) => {
    try {
      const data = await BloxService.updateBlox(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBloxData = createAsyncThunk(
  "Blox/get_data_list",
  async ({search}, thunkAPI) => {
    try {

      const data = await BloxService.getBloxData(search);
      return { blox_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getBloxs = createAsyncThunk(
  "Blox/get_list",
  async ({search}, thunkAPI) => {
    try {

      const data = await BloxService.getBloxs(search);
      return { bloxs: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBlox = createAsyncThunk(
  "Blox/get",
  async ({id}, thunkAPI) => {
    try {
      const data = await BloxService.getBlox(id);
      return { bloxs: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBloxUi = createAsyncThunk(
  "Blox/get_ui",
  async ({id}, thunkAPI) => {
    try {
      const data = await BloxService.getBloxUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



const initialState = {data: list, loading: false, blox_data: list, bloxUi: null}

const bloxSlice = createSlice({
  name: "Bloxs",
  initialState,
  extraReducers: {

    [getBloxUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloxUi.fulfilled]: (state, action) => {
      state.bloxUi = action.payload.ui; 
      state.loading = false;
    },
    [getBloxUi.rejected]: (state, action) => {
      state.blox_data = null
      state.loading = false;
    },



    [getBloxData.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloxData.fulfilled]: (state, action) => {
      state.blox_data = action.payload.blox_data; 
      state.loading = false;
    },
    [getBloxData.rejected]: (state, action) => {
      state.blox_data = list
      state.loading = false;
    },

    [getBloxs.pending]: (state, action) => {
      state.loading = true;
    },
    [getBloxs.fulfilled]: (state, action) => {
      state.data = action.payload.bloxs; 
      state.loading = false;
    },
    [getBloxs.rejected]: (state, action) => {
      state.data = list
      state.loading = false;
    },

    [updateBlox.fulfilled]: (state, action) => {

      debugger
      
      let organizations = state.data.list;
      let thisOrg = organizations.find((org) => org.id === action.payload.data.id)

      thisOrg.name = action.payload.data.name
      thisOrg.domain = action.payload.data.domain
      thisOrg.active = action.payload.data.active

    },

    [getBlox.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlox.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.bloxs)
      state.data.total ++;

      state.loading = false;
    },
    [getBlox.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});


const { reducer } = bloxSlice;
export default reducer;