import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  validateYupSchema,
  useField,
} from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  Autocomplete,
  Grid,
} from "@mui/material";

import KeyVal from "components/global/KeyVal";
import Header from "components/global/Header";
import {
  updateStateSetValueData,
  updateStateSetRowValueData,
} from "slices/state";

const StateSetDataDisplayEdit = ({
  stateSet,
  set_values,
  schema,
  showHidden,
  showTechnicalName,
}) => {
  const dispatch = useDispatch();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  //debugger;

  const handelUpdate = (field, newVal) => {
    //debugger;
    const rowId = stateSet.tableMode ? set_values.id : stateSet.id;

    const stateValue = {
      stateSetId: stateSet.id,
      data: {
        id: rowId,
        [field]: newVal,
      },
    };

    if (stateSet.tableMode) {
      dispatch(updateStateSetRowValueData({ stateValue }));
    } else {
      dispatch(updateStateSetValueData({ stateValue }));
    }
  };

  //console.log("Cols: " , schema.columns)

  if (set_values === undefined) {
    return (
      <Box display="flex" flexDirection="column" gap=".6rem">
        <Typography>Loading ...</Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap=".6rem" maxWidth={"50rem"}>
      {schema?.columns.map((item) => {
        if (item.uiHide !== true || showHidden) {

          //console.log("Value: ", set_values[item.field])

          return (
            <KeyVal
              key={item.field}
              label={item.headerName}
              value={
                set_values[item.field] !== undefined
                  ? set_values[item.field]
                  : "Not Set"
              }
              tip={item.helperText}
              fieldType={item.type}
              field={item.field}
              updater={handelUpdate}
              serviceImplemenator={item.serviceImplemenator}
              serviceDimension={item.serviceDimension}
              serviceType={item.serviceType}
              lookUpLabel={item.lookUpLabel}
              flexDirection="column"
              canEdit={
                item.valueMode === "System" || item.field === "id"
                  ? false
                  : true
              }
              // uiRenderType={
              //   set_values[item.field] !== undefined ? item.renderCell : ""
              // }

              uiRenderType={item.renderCell}
              multiLine={item.multiLine}
              rows={item.rows}
              currencyCode={
                set_values["currencyCode"] !== undefined &&
                set_values["currencyCode"]
              }
              showTechnicalName={showTechnicalName}
            />
          );
        }
      })}
    </Box>
  );
};

export default StateSetDataDisplayEdit;
