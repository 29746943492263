import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate ,useLocation} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, TextField, Typography ,Stack} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../global/Header";
import { updateUserPassword } from "../../slices/auth";


import { login } from "../../slices/auth";
import { clearMessage, setMessage } from "../../slices/message";


const ChangePassword = (props) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");

  let navigate = useNavigate();

  const { state } = useLocation();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const user = props.user;
  const closerFunc = props.closer;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: user.username,
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  };

  const validationSchema = Yup.object().shape({

    oldPassword: Yup.string().required("This field is required!")
    .min(6, 'Must be a min of 6 chars'),
    newPassword1: Yup.string().required("This field is required!")
    .min(6, 'Must be a min of 6 chars'),
    newPassword2: Yup.string().required("This field is required!")
    .min(6, 'Must be a min of 6 chars'),
  });

  return (

    <Box m="20px">
      <Header title="Change Password" subtitle={"New Password for " + user.firstName + ' ' + user.lastName} />

      <Formik
         onSubmit={(values,actions) => {

          const user = {
            username: values.username,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword1,
          }

          dispatch(clearMessage())
          dispatch(updateUserPassword({user}))

          .unwrap()
          .then(() => {
            debugger;
            
            dispatch(setMessage("Password Updated"));
            closerFunc();
          })
          .catch(() => {
            actions.setSubmitting(false)
          });
  
      }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          //enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            isSubmitting,
            isValid,
            dirty,
            
          }) => (
          <form onSubmit={handleSubmit}>

            

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Current Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oldPassword}
                name="oldPassword"
                error={!!touched.oldPassword && !!errors.oldPassword}
                helperText={touched.oldPassword && errors.oldPassword}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="New Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword1}
                name="newPassword1"
                error={!!touched.newPassword1 && !!errors.newPassword1}
                helperText={touched.newPassword1 && errors.newPassword1}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Repeat Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword2}
                name="newPassword2"
                error={!!touched.newPassword2 && !!errors.newPassword2}
                helperText={touched.newPassword2 && errors.newPassword2}
                sx={{ gridColumn: "span 1" }}
              />
              
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Stack direction="row" spacing={2}>
                <Button type="button" color="error" variant="contained" onClick={closerFunc}>
                    Cancel
                </Button>

                <Button type="submit" color="primary" variant="contained" 
                  disabled = {isSubmitting || !isValid || !dirty}
                >
                    Change Password
                </Button>
              </Stack>
            </Box>

            

          </form>
        )}
      </Formik>
    </Box>

  );

};

export default ChangePassword;