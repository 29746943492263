import { CheckOutlined, TrendingDownOutlined, TrendingFlatOutlined, TrendingUpOutlined } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { getNiceCurrency, getNicePercentage } from "common/helpers";

const calculateTrend = (values) => {
  const n = values.length;
  let slope = 0;

  // Calculate the average of x and y values
  const xAvg = (n - 1) / 2;
  const yAvg = values.reduce((sum, val) => sum + val, 0) / n;

  // Calculate the slope using linear regression formula
  let numerator = 0;
  let denominator = 0;
  for (let i = 0; i < n; i++) {
    numerator += (i - xAvg) * (values[i] - yAvg);
    denominator += Math.pow(i - xAvg, 2);
  }
  if (denominator !== 0) {
    slope = numerator / denominator;
  }

  // Determine the trend based on the slope
  const trend = slope > 0 ? "Increasing" : slope < 0 ? "Decreasing" : "Stable";

  return trend;
};

const NiceTrend = (props) => {
  let row = props.children;

  const columns = props.columns;
  const trendColumns = columns.filter((col) => col.trendColumn === true)
  const desiredTrendDirection = row?.desiredTrendDirection ? row.desiredTrendDirection : "Increasing"

  //debugger;

  // Extract the values for all date columns
  const values = trendColumns.map(column => row[column.field]);

  // Calculate the trend for the values
  let trend;
  if (values.length < 2) {
    trend = "Unknown";
  } else {
    trend = calculateTrend(values)
  }

  console.log("Nice trend", "\nrow: ", row, "\nColumns: ", columns , "\nTrend: " , trend , "\nTrend type: " , desiredTrendDirection );

  let colour = "info"

  if (desiredTrendDirection == trend){
    colour = "success"
  }else{
    if (trend != "Stable"){
      colour = "error"
    }
  }

  if (trend === "Increasing"){
    return (<Chip icon={<TrendingUpOutlined />} label="Increasing" color={colour}/>)
  }

  if (trend === "Decreasing"){
    return (<Chip icon={<TrendingDownOutlined />} label="Decreasing" color={colour}/>)
  }

  if (trend === "Stable"){
    return (<Chip icon={<TrendingFlatOutlined />} label="Stable" color={colour}/>)
  }

};

export default NiceTrend;
