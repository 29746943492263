import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import OpportunityService from "../services/opportunity.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const createOpportunity = createAsyncThunk(
  "Opportunity/create",
  async ({ opportunity }, thunkAPI) => {
    try {
      const data = await OpportunityService.createOpportunity(opportunity);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const deleteOpportunity = createAsyncThunk(
  "Opportunity/delete",

  async ( id , thunkAPI) => {

    debugger;
    try {
      const data = await OpportunityService.deleteOpportunity(id);
      return { Opportunitys : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateOpportunity = createAsyncThunk(
  "Opportunity/update",
  async ({ opportunity }, thunkAPI) => {
    try {
      const data = await OpportunityService.updateOpportunity(opportunity);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getOpportunities = createAsyncThunk(
  "Opportunity/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunities(search);
      return { opportunities: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOpportunity = createAsyncThunk(
  "Opportunity/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunity(id);
      return { Opportunity: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getOpportunityFilterValues = createAsyncThunk(
  "Opportunity/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunityFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOpportunityNotes = createAsyncThunk(
  "OpportunityNotes/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunityNotes(search);
      return { notes: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOpportunityUi = createAsyncThunk(
  "Opportunity/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await OpportunityService.getOpportunityUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  loading: false,
  filter_values: [],
  notes: list,
  Ui: [],
}

const OpportunitySlice = createSlice({
  name: "Opportunity",
  initialState,

  extraReducers: {

    [getOpportunityUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunityUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getOpportunityUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getOpportunityFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunityFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getOpportunityFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },



    [createOpportunity.pending]: (state, action) => {
      state.loading = true;
    },
    [createOpportunity.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.data.data);
      state.data.total ++;
      state.loading = false;
    },
    [createOpportunity.rejected]: (state, action) => {
      state.loading = false;
    },



    [getOpportunities.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunities.fulfilled]: (state, action) => {
      state.data = action.payload.opportunities;
      state.loading = false;
    },
    [getOpportunities.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [deleteOpportunity.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOpportunity.fulfilled]: (state, action) => {
      const id = action.meta.arg.id;
    
      // Filter out the opportunity with the specified ID
      const updatedOpportunityList = state.data.list.filter(opportunity => opportunity.id !== id);
    
      state.data.list = updatedOpportunityList;
      state.loading = false;
    },
    [deleteOpportunity.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [updateOpportunity.fulfilled]: (state, action) => {

      const updatedOpportunity = state.data.list.map((Opportunity) => {
        if (Opportunity.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Opportunity;
        }
      });
      state.data.list = updatedOpportunity;
    },

    [getOpportunity.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunity.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.Opportunity;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getOpportunity.rejected]: (state, action) => {
      state.loading = false;
    },


    [getOpportunityNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunityNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.notes;
      state.loading = false;
    },
    [getOpportunityNotes.rejected]: (state, action) => {
      state.notes = list;
      state.loading = false;
    },


  },
});

const { reducer } = OpportunitySlice;
export default reducer;
