import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import { ChevronRightOutlined, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DynamicIcon } from "common/dynamicIcon";

const DashboardMenu = ({ menu, selectedDashboard }) => {
  const [openItems, setOpenItems] = useState({});
  const navigate = useNavigate();

  const handleClick = (node) => {
    if (!node.hideContent) {
      navigate(`/dashboard/${node.dashboard}`);
    }
  };

  //debugger

  const renderTree = (nodes, depth = 0) =>
    nodes.map((node) => {


      return (
        <div key={node.dashboard}>
          <ListItemButton
            selected={node.dashboard === selectedDashboard}
            onClick={() => handleClick(node)}
            sx={{ pl: 0, pt: 0, pb: 0, pr: 0, mb: 0.7 }}
            component="div"
          >
            <ListItemIcon ><DynamicIcon iconName={node.icon}/></ListItemIcon>
            <ListItemText primary={node.name} />
          </ListItemButton>
        </div>
      );
    });

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.default" }}
      aria-labelledby="dashboard_menu"
    >
      {renderTree(menu)}
    </List>
  );
};

export default DashboardMenu;
