import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const NicePercentage = (props) => {
  let value = props.children;

  let formattedValue = value;



    // Get the user's preferred language and region
    const userLanguage = navigator.language || navigator.userLanguage;

    // Extract the language and region from the user's preferences
    const [language, region] = userLanguage.split("-");

    // Define the desired number of decimal places dynamically
    const decimalPlaces = 0; // Set this value as needed

    // Use the user's language and region for formatting
    const formatter = new Intl.NumberFormat(`${language}-${region}`, {
      style: "percent",
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });

    // Format the currency value with the symbol
    formattedValue = formatter.format(value);
  

  //console.log("currency: ", formattedValue);

  return <Box>{formattedValue}</Box>;
};

export default NicePercentage;
