import api from "./api";



const listAuditLog = (search) => {
  return api
    .get("/auditlog", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};


const getAuditLog = (id) => {
  return api.get("/auditlog/" + id).then((response) => {
    return response.data;
  });
};

const getAuditLogLastService = (id) => {
  return api.get("/auditlog/top12services/" + id).then((response) => {
    return response.data;
  });
};

const getAuditLogLastResource = (id) => {
  return api.get("/auditlog/top12resources/" + id).then((response) => {
    return response.data;
  });
};


const getAuditLogFilterValues = (search) => {

  return api.get("/auditlog/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getAuditLogUi = () => {
  return api.get("/auditlog/ui")
  .then((response) => {
    return response.data;
  });
};

const AuditLogService = {
  listAuditLog,
  getAuditLog,
  getAuditLogFilterValues,
  getAuditLogUi,
  getAuditLogLastResource,
  getAuditLogLastService,

};

export default AuditLogService;
