import React, { useRef, useState } from "react";
import {
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import {
  ExpandMore,
  ChevronRightOutlined,
  MoreVertOutlined,
  TipsAndUpdates,
} from "@mui/icons-material";
import { useDrag, useDrop } from "react-dnd";
import CataloguePageMenuContextMenu from "./CataloguePageMenuContextMenu";
import { useNavigate } from "react-router-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTheme } from "@emotion/react";

const CataloguePageMenuItem = ({
  isDivider,
  node,
  depth,
  selectedPage,
  pages,
  deletePageHandler,
  editPageHandler,
  addPageHandler,
  sequencePageHandler,
  parentPageHandler,
  nextSequence,
  shouldOpen,
  isInGroup,
  parentId,
}) => {
  const navigate = useNavigate();
  const theme = useTheme(); // Get the theme object
  const [open, setOpen] = useState(shouldOpen);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNodeMenu, setSelectedNodeMenu] = useState(null);

  const thisPage = pages?.find((page) => page.id === selectedNodeMenu);

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const handleClick = (event, node) => {
    //debugger
    if (node.hideContent) {
      handleExpandClick(event, node);
    } else {
      navigate(`/catalogue/${node?.catalogueId}/${node?.id}`);
      //check if we have children, if we do expand as well but do not close
      if (node.children.length > 0) {
        if (!open) {
          handleExpandClick(event, node);
        }
      }
    }
  };

  const handleMenuClick = (event, node) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedNodeMenu(node?.id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNodeMenu(null);
  };

  const ref = useRef(null);

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: "TREE_NODE",
    item: { id: node?.id, name: node?.name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "TREE_NODE",
    drop: (draggedItem) => {

      //ehck if the dragged item is over its self this is not allowed

      if(draggedItem.id === node.id){return}

      // Check if the target is a page or a seperator, if its a page update the parent of this page
      // if its a seperator, we need to check if the seperater is in a page group, if it is we need to chnage the
      // group and set the sequence

      if (isDivider) {
        //check if the divider is in a group

        if (isInGroup) {
          //update the Parent and reset the sequence
          parentPageHandler({
            id: draggedItem.id,
            parentPage: parentId,
            sequence: nextSequence,
          });
        } else {
          parentPageHandler({
            id: draggedItem.id,
            parentPage: "",
            sequence: nextSequence,
          });
        }
      } else {
        //update the Parent and reset the sequence
        parentPageHandler({
          id: draggedItem.id,
          parentPage: node.id,
          sequence: 1,
        });
      }
    },
    hover: (draggedItem) => {
      // Optional: Handle hover logic if needed
      //console.log("Hovering over:", node.name);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Disable the default drag image
  preview(getEmptyImage(), { captureDraggingState: true });

  dragRef(dropRef(ref));

  const dropPositionColour = `${theme.palette.success.main}50`;

  if (isDivider) {
    return (
      <div
        key={"dropregion_" + node.id}
        ref={ref}
        style={{
          height: "3px",
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? dropPositionColour : "transparent", // Highlight color when hovering
        }}
      ></div>
    );
  } else {
    return (
      <React.Fragment key={"rfagment" + node.id}>
        <div
          key={"dropregion_" + node.id}
          ref={ref}
          style={{
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: isOver ? dropPositionColour : "transparent", // Highlight color when hovering
          }}
        >
          <ListItemButton
            key={"button_" + node.id}
            ref={dragRef}
            selected={node.id === selectedPage || node.id === selectedNodeMenu}
            onClick={(event) => handleClick(event, node)}
            sx={{
              pl: depth * 2,
              pt: 0,
              pb: 0,
              mb: 0.2,
              position: "relative",
              display: "flex",
              alignItems: "center",
              "&:hover .icon-button, & .icon-button.selected": {
                visibility: "visible",
              },
            }}
          >
            <ListItemIcon
              onClick={() => handleExpandClick()}
              sx={{ m: 0, minWidth: 0 }}
            >
              {node.children.length > 0 ? (
                open ? (
                  <ExpandMore />
                ) : (
                  <ChevronRightOutlined />
                )
              ) : (
                <div style={{ width: 24 }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={node.name} enterDelay={500} enterNextDelay={500}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {node.name}
                  </Typography>
                </Tooltip>
              }
              sx={{ flexGrow: 1, mr: 3 }}
            />
            <Tooltip title="More Options">
              <IconButton
                className={`icon-button ${
                  node.id === selectedNodeMenu ? "selected" : ""
                }`}
                sx={{
                  visibility: "hidden",
                  position: "absolute",
                  right: 0,
                  mr: ".3rem",
                  ml: "1rem",
                }}
                size="small"
                onClick={(event) => handleMenuClick(event, node)}
              >
                <MoreVertOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </ListItemButton>
        </div>

        {node.children.length > 0 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {node.children.map((child) => (
              <React.Fragment key={"rfagment_collapse" + child.id}>
                <CataloguePageMenuItem
                  isDivider={true}
                  key={"ABOVE_" + child.id}
                  nextSequence={child.sequence}
                  node={{ id: "ABOVE_" + child.id }}
                  sequencePageHandler={sequencePageHandler}
                  parentPageHandler={parentPageHandler}
                  isInGroup={true}
                  parentId={node.id}
                />

                <CataloguePageMenuItem
                  key={child.id}
                  node={child}
                  depth={depth + 1}
                  selectedPage={selectedPage}
                  pages={pages}
                  deletePageHandler={deletePageHandler}
                  editPageHandler={editPageHandler}
                  addPageHandler={addPageHandler}
                  sequencePageHandler={sequencePageHandler}
                  parentPageHandler={parentPageHandler}
                />
              </React.Fragment>
            ))}
          </Collapse>
        )}
        <CataloguePageMenuContextMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          selectedNodeMenu={selectedNodeMenu}
          thisPage={thisPage}
          handleMenuItemClick={(action) => {
            handleMenuClose();

            switch (action) {
              case "addSubpage":
                addPageHandler({
                  parentPage: thisPage.id,
                  sequence: thisPage.sequence,
                });

                break;

              case "delete":
                deletePageHandler(thisPage.id);

                break;

              case "edit":
                editPageHandler(thisPage.id);

                break;

              case "details":
                const urlDetails = `/catalogue_page_admin/${thisPage.id}?tab=0`;
                window.open(urlDetails, "_blank");

                break;

              case "openInNewTab":
                const url = `/catalogue/${thisPage.catalogueId}/${thisPage.id}`;
                window.open(url, "_blank");

                break;

              default:
              //do nothing
            }
          }}
        />
      </React.Fragment>
    );
  }
};

export default CataloguePageMenuItem;
