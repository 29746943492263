import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { getNiceCurrency } from "common/helpers";

const NiceCurrency = (props) => {
  let value = props.children;
  let currencyCode = props.currencyCode;

  //debugger;

  let formattedValue = value;

  if (currencyCode) {
    // Create an instance of Intl.NumberFormat with the currency code
    // const formatter = new Intl.NumberFormat("en-GB", {
    //   style: "currency",
    //   currency: currencyCode,
    // });

    formattedValue = getNiceCurrency(value,currencyCode)
  }

  //console.log("currency: ", formattedValue);

  return <Box>{formattedValue}</Box>;
};

export default NiceCurrency;
