import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";

import {
  ArrowDropDown,
  RefreshOutlined,
  WidgetsOutlined
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import { getBloxs } from "slices/blox";
import { setMessage } from "slices/message";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckIcon from "@mui/icons-material/Check";
import NiceUser from "components/global/NiceUser";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import TablePage from "components/global/TablePage";


const Bloxs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.blox.data);


  const handleRowClick = (params) => {
    navigate("/blox/" + params.id);
  };


  const columns = [
    
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: .5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: .5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Blox",
      flex: 1,
    },
    {
      field: "lastRun",
      headerName: "Last Run",
      flex: 1,
      type:"date",
      renderCell: ({ row: { lastRun } }) => {
        return <NiceDate>{lastRun}</NiceDate>;
      },
    },
    {
      field: "opertunitiesLastTime",
      headerName: "Opertunities Last Run",
      flex: 1,
      type:"number"
    },
    {
      field: "active",
      headerName: "Active",
      type: "boolean",
      editable: true,
      renderCell: ({ row: { active } }) => {
        return <Box>{active && <CheckIcon />}</Box>;
      },
    },

    {
      headerName: "Actions",
      field: "",
      flex: 1,
      type: "actions",
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const handleUbBlock = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = false;

          //dispatch(updateSession({id,isBlocked}));
        };

        const handleBlock = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = true;

          //dispatch(updateSession({id,isBlocked}));
        };

        const handleDelete = (e) => {
          const currentRow = params.row;
          const session_id = currentRow.id;

          const id = currentRow.id;
          const isBlocked = true;

          //dispatch(updateSession({id,isBlocked}));
        };

        return (
          <Stack key={"STACK-" + params.row.id} direction="row" spacing={2}>
            {params.row.isBlocked ? (
              <IconButton
                key={"LOCK-" + params.row.id}
                aria-label="unblock"
                onClick={handleUbBlock}
              >
                <LockOpenIcon />
              </IconButton>
            ) : (
              <IconButton
                key={"UNLOCK-" + params.row.id}
                aria-label="block"
                onClick={handleBlock}
              >
                <LockIcon />
              </IconButton>
            )}
            <IconButton
              key={"DELETE-" + params.row.id}
              aria-label="delete"
              onClick={handleDelete}
              disabled
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const columnsVisability = {
    organization: false,
    orchestrator: false,
  };


  return (
    <PageBlock>
     
     <SimpleBreadcrumbs />
      <FlexBetween>
        <Header title="Blox" subtitle="" count={rows.total}/>
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary">
            <RefreshOutlined />
          </Button>
          <Button variant="outlined" color="primary" endIcon={<ArrowDropDown />}>
            Actions
          </Button>
          <Button variant="contained" color="primary">
            New Manual Blox
          </Button>
          <Button variant="contained" color="primary">
            Define New Blox
          </Button>
        </FlexBetween>
      </FlexBetween>


      <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getBloxs}
          defaultColumnsVisability={columnsVisability}
          defaultSort={"-lastRun"}
          rowClick={handleRowClick}
        />

    </PageBlock>
  );
};

export default Bloxs;
