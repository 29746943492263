import React from "react";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";

const TabsVertical = ({ label, ...props }) => (
  <Tabs
    variant="scrollable"
    orientation="vertical"
    
    {...props}
    sx={{
      //minWidth:"10rem",
      borderRight: 1,
      borderColor: "divider",
      alignItems: "flex-start",
      paddingLeft: 0,
      ...props.sx, // Merge additional styles passed as props
    }}
  />
);

export default TabsVertical;
