import React, { useContext, useState, useEffect, useCallback } from "react";
import { Search, Settings, SettingsOutlined } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  Divider,
  DialogTitle,
  DialogActions,
  Popover,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  Switch,
  List,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridPagination,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import FlexBetween from "./global/FlexBetween";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/system";
import DataGridFilter from "./DataGridFilter";
import NiceDate from "./global/NiceDate";
import { useDispatch } from "react-redux";
import {
  setTablePageBillingPeriod,
  setTablePageDimension,
  setTablePageFilters,
  setTablePageKpi,
  setTablePageMasterId,
  setTablePageMasterIfField,
  setTablePageServiceType,
  setTablePageStateSetId,
} from "slices/tablePage";

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <>
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </>
  );
}

const DataGridCustomToolbar = ({
  pageSize,
  pageSizeSetter,
  columns,
  columnsVisible,
  columnsVisibleSetter,
  filter,
  filterSetter,
  refreshPoint,
  dimension,
  dimensionSetter,
  kpi,
  masterId,
  masterIdField,
  stateSetId,
  serviceType,
  billingPeriod,
  billingPeriodSetter,
  billingRelevant,
  props,
}) => {
  const dispatch = useDispatch();

  //update the redus for the table page values
  dispatch(setTablePageStateSetId(stateSetId));
  dispatch(setTablePageDimension(dimension));
  dispatch(setTablePageFilters(filter));
  dispatch(setTablePageKpi(kpi));
  dispatch(setTablePageMasterId(masterId));
  dispatch(setTablePageMasterIfField(masterIdField));
  dispatch(setTablePageServiceType(serviceType));
  dispatch(setTablePageBillingPeriod(billingPeriod));

  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const handleSettingsOpenClick = (event) => {
    setIsConfigOpen(true);
  };
  const handleClose = () => setIsConfigOpen(false);

  const [pageSizeNew, setPageSize] = React.useState(pageSize);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const [columnsChecked, setColumnsChecked] = React.useState(columnsVisible);

  const handleColumnsToggle = (value) => (event) => {
    const newChecked = { ...columnsChecked };
    newChecked[value] = event.target.checked;

    setColumnsChecked(newChecked);
  };

  const handelSettingsSave = () => {
    pageSizeSetter(pageSizeNew);
    columnsVisibleSetter(columnsChecked);
    setIsConfigOpen(false);
  };

  console.log("toolbar filter:", filter);
  return (
    <>
      <GridToolbarContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-end"
        >
          <Box display="flex" flexDirection="column">
            <DataGridFilter
              serviceType={serviceType}
              columns={columns}
              filters={filter}
              filterSetter={filterSetter}
              dimension={dimension}
              dimensionSetter={dimensionSetter}
              kpi={kpi}
              masterIdField={masterIdField}
              masterId={masterId}
              stateSetId={stateSetId}
              billingPeriod={billingPeriod}
              billingPeriodSetter={billingPeriodSetter}
              billingRelevant={billingRelevant}
            />
          </Box>

          <FlexBetween gap=".2rem">
            <Box display="flex" mr="1rem" gap=".2rem">
              Data as of <NiceDate>{refreshPoint}</NiceDate>
            </Box>

            <Divider
              orientation="vertical"
              color="primary"
              flexItem
              variant="middle"
            />

            <CustomPagination />

            <Divider
              orientation="vertical"
              color="primary"
              flexItem
              variant="middle"
            />

            <FlexBetween gap=".2rem">
              <IconButton
                aria-label="settings"
                onClick={handleSettingsOpenClick}
              >
                <SettingsOutlined />
              </IconButton>
              <Divider
                orientation="vertical"
                color="primary"
                flexItem
                variant="middle"
              />
              <GridToolbarExport />
            </FlexBetween>
          </FlexBetween>
        </Box>
      </GridToolbarContainer>

      <Dialog open={isConfigOpen}>
        <DialogTitle variant="h2">Settings</DialogTitle>
        <DialogContent>
          <Box width="500px">
            <Divider />

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              m="1rem  0rem"
            >
              <Box display="flex" flexDirection="column" width="40%">
                <Typography variant="h5">Page Size</Typography>

                <Box ml=".3rem">
                  <RadioGroup
                    aria-labelledby="page_size-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={pageSizeNew}
                    onChange={handlePageSizeChange}
                  >
                    <FormControlLabel
                      key={"page10"}
                      value="10"
                      control={<Radio />}
                      label="10"
                    />
                    <FormControlLabel
                      key={"page25"}
                      value="25"
                      control={<Radio />}
                      label="25"
                    />
                    <FormControlLabel
                      key={"page50"}
                      value="50"
                      control={<Radio />}
                      label="50"
                    />
                    <FormControlLabel
                      key={"page100"}
                      value="100"
                      control={<Radio />}
                      label="100"
                    />
                  </RadioGroup>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" width="60%">
                <Typography variant="h5">Columns</Typography>
                <Box>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {columns?.map((column) => (
                      <ListItem
                        sx={{ py: "0rem" }}
                        key={`switch-list-item-${column.field}`}
                      >
                        <Switch
                          key={`switch-list-switch-${column.field}`}
                          edge="start"
                          onChange={handleColumnsToggle(column.field)}
                          checked={columnsChecked[column.field] != false}
                          inputProps={{
                            "aria-labelledby": `switch-list-label-${column.field}`,
                          }}
                          disabled={
                            column.hideable == undefined
                              ? false
                              : !column.hideable
                          }
                        />
                        <ListItemText
                          key={`switch-list-label-${column.field}`}
                          id={`switch-list-label-${column.field}`}
                          primary={column.headerName}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>

            <Divider />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handelSettingsSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataGridCustomToolbar;
