import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getJob, updateJob, stopJob, getJobLogs } from "slices/job";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { RefreshOutlined, StopOutlined } from "@mui/icons-material";

import TabPanel from "components/global/TabPanel";
import KeyVal from "components/global/KeyVal";
import { now } from "moment";
import TablePage from "components/global/TablePage";
import NiceUser from "components/global/NiceUser";
import NiceMarkdown from "components/global/NiceMarkdown";

const Job = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const Job = useSelector((state) =>
    state.job.data.list?.find((job) => job.id === id)
  );

  const JobLog = useSelector((state) => state.job.logs);

  //console.log("Log:", JobLog)

  const [tabValue, setTabValue] = React.useState(0);

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!isFirstLoad) {
      //need to get the Job form the API
      dispatch(getJob({ id }));
      setIsFirstLoad(false);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getJob({ id }));
  }, [refreshPoint]);

  const handelStopJob = () => {
    dispatch(stopJob({ id }));
  };

  const columns = [
    {
      field: "when",
      headerName: "Log Time",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },

    {
      field: "message",
      headerName: "Log Entery",
      flex: 6,
      renderCell: ({ row: { message } }) => {
        return <NiceMarkdown>{message}</NiceMarkdown>;
      },
    },
  ];

  const columnsVisability = {};

  const [selectionModel, setSelectionModel] = React.useState([]);

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Job?.id} subtitle={Job?.name} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<StopOutlined />}
            disabled={
              Job?.status == "Pending" || Job?.status == "Running"
                ? false
                : true
            }
            onClick={handelStopJob}
          >
            Stop
          </Button>
        </FlexBetween>
      </FlexBetween>

      <DetailBlock>
        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>Job Type: {Job?.type}</Typography>
          <Typography>
            Created: <NiceDate>{Job?.createdAt}</NiceDate>
          </Typography>
          <Typography>
            Started: <NiceDate>{Job?.startedAt}</NiceDate>
          </Typography>
          <Typography>
            Stopped: <NiceDate>{Job?.stoppedAt}</NiceDate>
          </Typography>

          <KeyVal
            label="Duration"
            value={<NiceDuration>{Job?.duration}</NiceDuration>}
          />

          <Typography>
            Last updated: <NiceDate>{Job?.modifiedAt}</NiceDate>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <KeyVal
            label="Implemenator"
            value={Job?.implementor}
            link={
              Job
                ? Job?.type === "Utility"
                  ? `/utility/${Job?.implementor}`
                  : Job?.type === "Blox"
                  ? `/blox/${Job?.implementor}`
                  : Job?.type === "Kpi" ? `/kpi/${Job?.implementor}` : `/${Job?.implementor}`
                : "" // Return an empty string if Job is undefined
            }
            makeBlue
          />
          <Typography>Status: {Job?.status}</Typography>
          <Typography>Run by: {Job?.implementorId}</Typography>

          <KeyVal
            label="Submitted By"
            value={<NiceUser>{Job?.submittedBy}</NiceUser>}
          />
          <KeyVal label="Origin" value={Job?.jobOrigin} />
          <KeyVal label="Origin Id" value={Job?.jobOriginId} />
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>Items Processed: {Job?.itemsProcessed}</Typography>
          <Typography>Items Per Second: {Job?.itemsPerSecond}</Typography>
          <Typography>Job Args: {Job?.args}</Typography>
        </Box>
      </DetailBlock>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Job Tabs"
          >
            <Tab label={`Log (${JobLog?.total})`} {...a11yProps(0)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          {JobLog ? (
            <TablePage
              masterId={id}
              masterIdField="jobId"
              dataRows={JobLog}
              dataColumns={columns}
              dataGetter={getJobLogs}
              defaultColumnsVisability={columnsVisability}
              defaultSort={"-when"}
              refreshPoint={refreshPoint}
              serviceType={"JobLog"}
            />
          ) : (
            <Typography>Empty Log...</Typography>
          )}
        </TabPanel>
      </Box>
    </PageBlock>
  );
};

export default Job;
