import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const NiceBoolean = (props) => {
  let active = props.children;

  return (
    <>
      {active === true && "True"}
      {active === false && "False"}
      {active === undefined && "Not Set"}
      {active === "Not Set" && "Not Set"}
    </>
  );
};

export default NiceBoolean;
