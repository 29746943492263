import api from "./api";
import TokenService from "./token.service";

const register = (user) => {

  debugger
  return api.post("/user", {
    ...user
  });
};

const login = (username, password) => {
  return api
    .post("user_login", {
      username,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const getUserRoles = (search) => {

  return api.get("/userrole",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getUserRole = (id) => {

  return api.get("/userrole/"+id,)
  .then((response) => {
    return response.data;
  });
};


const updateUserPassword = (userPassword) => {
  return api.post("/user_password",
  {
    ...userPassword
  })
  .then((response) => {
    return response.data;
  });
};

const getSessions = (search) => {

  return api.get("/sessions",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getSession = (id) => {

  
  return api.get("/sessions/"+id)
  .then((response) => {
    return response.data;
  });
};

const updateSession = (session) => {

  return api.patch("/sessions",
  {
    ...session
  })
  .then((response) => {
    return response.data;
  });
};

const updateUserRole = (role) => {

  return api.patch("/userrole",
  {
    ...role
  })
  .then((response) => {
    return response.data;
  });
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  updateUserPassword,
  getCurrentUser,
  getSessions,
  updateSession,
  getUserRoles,
  getSession,
  getUserRole,
  updateUserRole,
};

export default AuthService;