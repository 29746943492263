import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, ListItem, ListItemButton } from "@mui/material";
import { getServiceType } from "slices/services";
import { DynamicIcon } from "common/dynamicIcon";
import { getSearchSummary } from "slices/search";
import NiceMarkdown from "./NiceMarkdown";
import { useNavigate } from "react-router-dom";

const NiceSearchResult = ({ searchResult, closer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const serviceType = useSelector((state) =>
    state.services?.serviceTypes?.find(
      (serviceType) => serviceType.serviceType === searchResult.type
    )
  );

  const documentSummary = useSelector((state) =>
    state.search?.searchDocuments?.find(
      (doccumentSummary) =>
        doccumentSummary.serviceType === searchResult.type &&
        doccumentSummary.id === searchResult.objectId &&
        doccumentSummary.implemenatorId === searchResult.implemenatorId
    )
  );

  //debugger

  useEffect(() => {
    if (!serviceType) {
      dispatch(getServiceType({ serviceType: searchResult.type }));
    }
  }, [dispatch, searchResult.type, serviceType]);

  useEffect(() => {
    if (!documentSummary) {
      const search = {
        serviceType: searchResult.type,
        objectId: searchResult.objectId,
        implemenatorId: searchResult.implemenatorId,
      };
      dispatch(getSearchSummary({ search }));
    }
  }, [
    dispatch,
    documentSummary,
    searchResult.objectId,
    searchResult.implemenatorId,
    searchResult.type,
    serviceType,
  ]);

  console.log(
    "Service type requested:",
    searchResult.type,
    " Found type: ",
    serviceType
  );

  if (!serviceType) {
    return (
      <ListItem key={searchResult.objectId} disablePadding>
        Missing Service Type
      </ListItem>
    );
  }

  return (
    <ListItem key={searchResult.objectId} disablePadding sx={{ mb: "1rem" }}>
      <ListItemButton sx={{
        borderRadius: 2,
        boxShadow: 0,
        transition: 'box-shadow 0.3s ease-in-out', 
        '&:hover': {
          boxShadow: 6,
        },
        textDecoration: 'none', 
      }}
      onClick={() => { navigate(documentSummary.link); closer(); }}

      
      >
        
      <Box display={"flex"} flexDirection={"column"} gap={"0"}>
        <Box display={"flex"} flexDirection={"column"} gap={"0"}>
          <Box display={"flex"} flexDirection={"row"} gap={".5rem"}>
            <DynamicIcon iconName={serviceType.icon} />
            <Typography variant="h4">{documentSummary?.title}</Typography>
          </Box>
          <Typography>{serviceType.name}</Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={".1rem"}>
          <Typography></Typography>
          <NiceMarkdown>{documentSummary?.summaryText}</NiceMarkdown>
        </Box>
      </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default NiceSearchResult;
