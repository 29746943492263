import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import SearchService from "../services/search.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};



export const createSearchPrompt = createAsyncThunk(
  "SearchPromot/create",
  async ({ searchPrompt }, thunkAPI) => {
    try {
      const data = await SearchService.createSearchPrompt(searchPrompt);

      const userId = data.data.user
      thunkAPI.dispatch(getLastSearchPromptByUser({userId}));

      return { prompt: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);





export const getSearchPrompts = createAsyncThunk(
  "SearchPrompt/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await SearchService.getSearchPrompts(search);
      return { prompts: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSearchPrompt = createAsyncThunk(
  "SearchPrompt/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await SearchService.getSearchPrompt(id);
      return { prompt: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLastSearchPromptByUser = createAsyncThunk(
  "SearchPromptByUser/get",

  async ({ user }, thunkAPI) => {
    try {
      const search = {
        id: user,
        idField: "user",
        sort: "-createdAt",
        pageSize: 1,
        page: 0,
      }
      const data = await SearchService.getSearchPrompts(search);
      return { prompt: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getSearchPromptFilterValues = createAsyncThunk(
  "SearchPrompt/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await SearchService.getSearchPromptFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getSearchPromptUi = createAsyncThunk(
  "SearchPrompt/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await SearchService.getSearchPromptUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getSearchSummary = createAsyncThunk(
  "Search/get_search_summary",
  async ({ search }, thunkAPI) => {
    try {
      const data = await SearchService.getSearchSummary(search);
      return { summary: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSearchFeedback = createAsyncThunk(
  "Search/update",
  async ({ prompt }, thunkAPI) => {
    try {
      const data = await SearchService.updateSearchFeedback(prompt);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  prompt: {data: list , loading: false, Ui: [] ,filter_values: [] },
  lastPrompt: null,
  lastPromptLoading: false,
  searchDocuments: [],
  searchDocumentsLoading: false,
}

const SearchSlice = createSlice({
  name: "Search",
  initialState,

  extraReducers: {

    [updateSearchFeedback.fulfilled]: (state, action) => {

      const updatedPrompt = state.prompt.data.list.map((Prompt) => {
        if (Prompt.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Prompt;
        }
      });

      state.prompt.data.list = updatedPrompt;

      state.lastPrompt.userFeedback = action.payload.data.userFeedback
    },


    [getSearchSummary.pending]: (state, action) => {
      state.searchDocumentsLoading = true;
    },
    [getSearchSummary.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.summary;

      const existingIndex = state.searchDocuments.findIndex(
        payload => payload.id === newPayload.id &&
        payload.serviceType === newPayload.serviceType && 
        payload.implemenatorId === newPayload.implemenatorId
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.searchDocuments[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.searchDocuments.push(newPayload);
      }


      state.searchDocumentsLoading = false;
    },
    [getSearchSummary.rejected]: (state, action) => {
      state.searchDocumentsLoading = false;
    },


    
    [getSearchPromptUi.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getSearchPromptUi.fulfilled]: (state, action) => {
      state.prompt.Ui = action.payload.ui;
      state.prompt.loading = false;
    },
    [getSearchPromptUi.rejected]: (state, action) => {
      state.prompt.Ui = null;
      state.prompt.loading = false;
    },



    [getSearchPromptFilterValues.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getSearchPromptFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.prompt.filter_values = action.payload.filter_values;
      state.prompt.loading = false;
    },
    [getSearchPromptFilterValues.rejected]: (state, action) => {
      state.prompt.filter_values = list;
      state.prompt.loading = false;
    },


    [createSearchPrompt.pending]: (state, action) => {

      state.prompt.loading = true;
    },
    [createSearchPrompt.fulfilled]: (state, action) => {

      //debugger
      state.prompt.data.list.push(action.payload.prompt);
      state.prompt.data.total ++;
      state.prompt.loading = false;
    },
    [createSearchPrompt.rejected]: (state, action) => {
      state.prompt.loading = false;
    },


    [getSearchPrompts.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getSearchPrompts.fulfilled]: (state, action) => {
      state.prompt.data = action.payload.prompts;
      state.prompt.loading = false;
    },
    [getSearchPrompts.rejected]: (state, action) => {
      state.prompt.data = list;
      state.prompt.loading = false;
    },




    [getLastSearchPromptByUser.pending]: (state, action) => {

      state.prompt.loading = true;
    },
    [getLastSearchPromptByUser.fulfilled]: (state, action) => {

      state.lastPrompt = action.payload.prompt?.list[0];
      state.lastPromptLoading = false;
    },
    [getLastSearchPromptByUser.rejected]: (state, action) => {
      state.lastPromptLoading = false;
    },

    
    [getSearchPrompt.pending]: (state, action) => {
      state.prompt.loading = true;
    },
    [getSearchPrompt.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.prompt;

      const existingIndex = state.prompt.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.prompt.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.prompt.data.list.push(newPayload);
      }


      state.promptloading = false;
    },
    [getSearchPrompt.rejected]: (state, action) => {
      state.promptloading = false;
    },



  },
});

const { reducer } = SearchSlice;
export default reducer;
