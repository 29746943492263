import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import ServiceService from "../services/services.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false}

export const getServiceTypes = createAsyncThunk(
  "Servicetypes/get_list",
  async (_, thunkAPI) => {
    try {

      const data = await ServiceService.getServiceTypes();
      return { Services: data.services };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServiceType = createAsyncThunk(
  "Servicetype/get",
  async ({ serviceType }, thunkAPI) => {
    try {
      const data = await ServiceService.getServiceType(serviceType);
      return { Services: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServices = createAsyncThunk(
  "Service/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await ServiceService.getServices(search);
      return { Services: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getService = createAsyncThunk(
  "Service/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await ServiceService.getService(id);
      return { Services: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServiceRelated = createAsyncThunk(
  "Service/getServiceRelated",
  async ({ service }, thunkAPI) => {
    //debugger;

    try {
      const data = await ServiceService.getServiceRelated(service);
      return { Services: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServiceUi = createAsyncThunk(
  "Service/get_ui",
  async (_, thunkAPI) => {
    try {
      const data = await ServiceService.getServiceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServiceFilterValues = createAsyncThunk(
  "Service/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await ServiceService.getServiceFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  data: list,
  serviceTypes: [],
  loading: false,
  filter_values: [],
  Ui: [],
};

const ServiceSlice = createSlice({
  name: "Services",
  initialState,

  extraReducers: {

    [getServiceTypes.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceTypes.fulfilled]: (state, action) => {

      //debugger
      state.serviceTypes = action.payload.Services;

      // console.log("reducer: ", action.payload.Services)
      // state.serviceTypes = [...action.payload.Services];

      state.loading = false;
    },
    [getServiceTypes.rejected]: (state, action) => {
      state.serviceTypes = [];
      state.loading = false;
    },



    [getServiceType.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceType.fulfilled]: (state, action) => {
      
      //debugger;

      const newPayload = action.payload.Services;

      // Find the index of the existing service type based on the ServiceType property
      const existingIndex = state.serviceTypes.findIndex(
        (service) => service.serviceType === newPayload.serviceType
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.serviceTypes[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.serviceTypes.push(newPayload);
      }


      state.loading = false;
    },
    [getServiceType.rejected]: (state, action) => {
      state.loading = false;
    },

    [getServiceUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getServiceUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getServiceFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getServiceFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },

    [getServices.pending]: (state, action) => {
      state.loading = true;
    },
    [getServices.fulfilled]: (state, action) => {
      state.data = action.payload.Services;
      state.loading = false;
    },
    [getServices.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getServiceRelated.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceRelated.fulfilled]: (state, action) => {
      state.data = action.payload.Services;
      state.loading = false;
    },
    [getServiceRelated.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getService.pending]: (state, action) => {
      state.loading = true;
    },
    [getService.fulfilled]: (state, action) => {
      //debugger;

      const newPayload = action.payload.Services;

      const existingIndex = state.data.list.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }

      state.loading = false;
    },
    [getService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = ServiceSlice;
export default reducer;
