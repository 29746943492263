import api from "./api";



const updateSchedule = (schedule) => {
  return api.patch("/schedule",
  {
    ...schedule
  })
  .then((response) => {
    return response.data;
  });
};

const createSchedule = (schedule) => {
  return api.post("/schedule", {
    ...schedule,
  });
};

const updateScheduleActive = (data) => {
  return api.patch("/schedule/active",
  {
    ...data
  })
  .then((response) => {
    return response.data;
  });
};

const getSchedules = (search) => {

  return api.get("/schedule",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getSchedule = (id) => {
  return api.get("/schedule/"+id)
  .then((response) => {
    return response.data;
  });
};

const deleteSchedule = (id) => {

  return api.delete("/schedule/" + id).then((response) => {
    return response.data;
  });
};

const ScheduleSevice = {
  getSchedules,
  updateSchedule,
  getSchedule,
  updateScheduleActive,
  createSchedule,
  deleteSchedule,
};

export default ScheduleSevice;