import React from "react";
import { useTheme } from "@mui/material";
import * as Icons from "@mui/icons-material";

export const DynamicIcon = ({ iconName }) => {
  const theme = useTheme();

  // Check if the icon exists in the Icons object
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    console.error(`Icon '${iconName}' not found`);
    return null;
  }

  return (
    <IconComponent
      sx={{ color: theme.palette.primary.main, fontSize: "24px" }}
    />
  );
};

