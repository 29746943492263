import NiceActive from "components/global/NiceActive";
import NiceArrayCell from "components/global/NiceArrayCell";
import NiceBillingFolder from "components/global/NiceBillingFolder";
import NiceBillingPeriod from "components/global/NiceBillingPeriod";
import NiceBoolean from "components/global/NiceBoolean";
import NiceCloudOrgElement from "components/global/NiceCloudOrgElement";
import NiceCloudOrgElementManagedBy from "components/global/NiceCloudOrgManagedBy";
import NiceComplexity from "components/global/NiceComplexity";
import NiceCron from "components/global/NiceCron";
import NiceCurrency from "components/global/NiceCurrency";
import NiceDate from "components/global/NiceDate";
import NiceDateOnly from "components/global/NiceDateOnly";
import NiceDateUTC from "components/global/NiceDateUTC";
import NiceDuration from "components/global/NiceDuration";
import NiceLocation from "components/global/NiceLocation";
import NiceMeasure from "components/global/NiceMeasure";
import NiceObjectCell from "components/global/NiceObjectCell";
import NiceOpportunityStage from "components/global/NiceOpportunityStage";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";
import NicePercentage from "components/global/NicePercentage";
import NiceResourceGroup from "components/global/NiceResourceGroup";
import NiceSize from "components/global/NiceSize";
import NiceSubscription from "components/global/NiceSubscription";
import NiceTarget from "components/global/NiceTarget";
import NiceTrend from "components/global/NiceTrend";
import NiceUser from "components/global/NiceUser";
import NiceMarkdown from "components/global/NiceMarkdown";
import NiceCatalogue from "components/global/NiceCatalogue";
import NiceCataloguePage from "components/global/NiceCataloguePage";
import NiceBasicSearchResultList from "components/global/NiceBasicSearchResultList";

export function renderType(
  type,
  value,
  currencyCode,
  targetType,
  columns,
  resourceType
) {
  //debugger;

  switch (type) {
    case "<NiceBasicSearchResultList>":
      return <NiceBasicSearchResultList>{value}</NiceBasicSearchResultList>;
    case "<NiceCataloguePage>":
      return <NiceCataloguePage>{value}</NiceCataloguePage>;
    case "<NiceCatalogue>":
      return <NiceCatalogue>{value}</NiceCatalogue>;
    case "<NiceOrchestrator>":
      return <NiceOrchestrator>{value}</NiceOrchestrator>;
    case "<NiceOrganization>":
      return <NiceOrganization>{value}</NiceOrganization>;
    case "<NiceDate>":
      return <NiceDate>{value}</NiceDate>;
    case "<NiceDateOnly>":
      return <NiceDateOnly>{value}</NiceDateOnly>;
    case "<NiceDateUTC>":
      return <NiceDateUTC>{value}</NiceDateUTC>;
    case "<NiceActive>":
      return <NiceActive>{value}</NiceActive>;
    case "<NiceBoolean>":
      return <NiceBoolean>{value}</NiceBoolean>;
    case "<NiceUser>":
      return <NiceUser>{value}</NiceUser>;
    case "<NiceObjectCell>":
      return <NiceObjectCell>{value}</NiceObjectCell>;
    case "<NiceSubscription>":
      return <NiceSubscription>{value}</NiceSubscription>;
    case "<NiceLocation>":
      return <NiceLocation>{value}</NiceLocation>;
    case "<NiceResourceGroup>":
      return <NiceResourceGroup>{value}</NiceResourceGroup>;
    case "<NiceBillingPeriod>":
      return <NiceBillingPeriod>{value}</NiceBillingPeriod>;

    case "<NiceBillingFolder>":
      return <NiceBillingFolder>{value}</NiceBillingFolder>;
    case "<NiceCron>":
      return <NiceCron>{value}</NiceCron>;
    case "<NiceDuration>":
      return <NiceDuration>{value}</NiceDuration>;
    case "<NiceMeasure>":
      return <NiceMeasure>{value}</NiceMeasure>;
    case "<NiceSize>":
      return <NiceSize>{value}</NiceSize>;
    case "<NicePercentage>":
      return <NicePercentage>{value}</NicePercentage>;

    case "<NiceOpportunityStage>":
      return <NiceOpportunityStage>{value}</NiceOpportunityStage>;
    case "<NiceComplexity>":
      return <NiceComplexity>{value}</NiceComplexity>;

    case "<NiceArrayCell>":
      return <NiceArrayCell>{value}</NiceArrayCell>;
    case "<NiceCloudOrgElementManagedBy>":
      return (
        <NiceCloudOrgElementManagedBy>{value}</NiceCloudOrgElementManagedBy>
      );

    case "<NiceMarkdown>":
      return <NiceMarkdown>{value}</NiceMarkdown>;

    case "<NiceTarget>":
      return <NiceTarget type={targetType}>{value}</NiceTarget>;

    case "<NiceTrend>":
      return <NiceTrend columns={columns}>{value}</NiceTrend>;

    case "<NiceCurrency>":
      return <NiceCurrency currencyCode={currencyCode}>{value}</NiceCurrency>;


    case "<NiceCloudOrgElement>":
      return (
        <NiceCloudOrgElement resourceType={resourceType}>
          {value}
        </NiceCloudOrgElement>
      );

    default:
      // This is an error, so return an empty string or handle it as needed.
      return "";
  }
}
