import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import StateService from "../services/state.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};



export const getCustomerNetwork = createAsyncThunk(
  "CustomerNetwork/get_list",
  async ( thunkAPI) => {
    try {

      const search = {
        page: 0,
        pageSize: 99999999,
        stateSetId: "CUSTOMER_NETWORK",
      };

      //debugger;

      const data = await StateService.getStateData( search );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



const initialState = {
  data: list,
  loading: false,
}

const CustomerNetworkSlice = createSlice({
  name: "CustomerNetwork",
  initialState,

  extraReducers: {

    [getCustomerNetwork.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerNetwork.fulfilled]: (state, action) => {
      //debugger;

      state.data = action.payload.data;
      state.loading = false;
    },
    [getCustomerNetwork.rejected]: (state, action) => {
      state.values = list;
      state.loading = false;
    },

  },
});

const { reducer } = CustomerNetworkSlice;
export default reducer;
