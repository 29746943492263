import { Box, useTheme, Link } from "@mui/material";
import Header from "../../components/global/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { Link as RouterLink } from "react-router-dom";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px" height="80vh">
      <Header title="FAQ" subtitle="Get going quickly with OpsBlox.io" />

      <Accordion sx={{ backgroundColor: theme.palette.background.alt }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={theme.palette.secondary[100]} variant="h3">
            What is opsblox.io?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Deliver Augmented FinOps Fundamentally remove cloud cost wastage
            through business empowerment and owned well governed and automated
            detect - remediate – prevent cycles
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: theme.palette.background.alt }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={theme.palette.secondary[100]} variant="h3">
            What can opsblox.io do for me?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Deliver Augmented FinOps Fundamentally remove cloud cost wastage
            through business empowerment and owned well governed and automated
            detect - remediate – prevent cycles
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
