import {
  ArrowDropDown,
  LaunchOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Link,
  Tooltip,
  Button,
  Menu,
  Divider,
  ListItemIcon,
  MenuItem,
  Popover,
} from "@mui/material";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import TableMassEdit from "./TableMassEdit";

const TableActionsButton = ({ selectedRows, menuItems, columns , filters,
  kpi,
  dimension,
  masterIdField,
  masterId,
  serviceType,
  stateSetId}) => {
  const navigate = useNavigate();

  const buttenRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const itemsSelected = selectedRows?.length;

  const [anchorElMassEditPopup, setAnchorElMassEditPopup] = useState(null);

  const isOpenMassEditPopup = Boolean(anchorElMassEditPopup);

  const handleCloseMassEditPopup = () => {
    setAnchorElMassEditPopup(null);
  };

  const handleOpenMassEditPopup = (event) => {
    setAnchorElMassEditPopup(buttenRef.current);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isOpen}
        onClose={handleClose}
        //onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: "15rem",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuItems?.map(
          (
            {
              text,
              icon,
              callBack,
              isDivider,
              singleItemOnly,
              disabled,
              isMassEdit,
            },
            index
          ) => {
            //debugger;

            if (isDivider) return <Divider key={index} />;

            if (icon) {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (isMassEdit) {
                      console.log("Mass edit");
                      handleOpenMassEditPopup();
                    } else {
                      callBack();
                    }
                    handleClose();
                  }}
                  disabled={(singleItemOnly && itemsSelected > 1) || disabled}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  {text}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  if (isMassEdit) {
                    handleOpenMassEditPopup();
                  } else {
                    callBack();
                  }
                  handleClose();
                }}
                disabled={(singleItemOnly && itemsSelected > 1) || disabled}
              >
                {text}
              </MenuItem>
            );
          }
        )}
      </Menu>

      <Button
        ref={buttenRef}
        variant="outlined"
        color="primary"
        endIcon={<ArrowDropDown />}
        disabled={selectedRows?.length > 0 ? false : true}
        onClick={handleClick}
      >
        Actions
      </Button>

      <Popover
        anchorEl={anchorElMassEditPopup}
        id="massEdit"
        open={isOpenMassEditPopup}
        //onClose={handleCloseMassEditPopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "-10rem 0.5rem" }}
      >
        <TableMassEdit
          columns={columns}
          closer={handleCloseMassEditPopup}
          selectedRows={selectedRows}
          filters={filters}
          kpi={kpi}
          dimension={dimension}
          masterIdField={masterIdField}
          masterId={masterId}
          serviceType={serviceType}
          stateSetId={stateSetId}
        />
      </Popover>
    </>
  );
};

export default TableActionsButton;
