import {
  CheckBoxOutlineBlank,
  ContentPasteGoOutlined,
  CopyAllOutlined,
  DeleteOutline,
  MenuOutlined,
  NoteAddOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import { useNavigate } from "react-router-dom";


const CloudOrgBaseNode = memo(
  ({
    children,
    id,
    nodeType,
    height,
    width,
    relationshipCounts,
    relationship,
    nodeSelector,
  }) => {
    const theme = useTheme();
    const dispatch = useDispatch();


    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handelNodeSelected = () => {
      
      if(nodeSelector){
        nodeSelector(id)
      }

    };

    let useable = true;

    //debugger;

    let uses = true;
    let manages = true;

    // if (relationshipCounts && nodeType == "root") {
    //   reads = relationshipCounts["Reads"] > 0 ? true : false;
    //   writes = relationshipCounts["Writes"] > 0 ? true : false;
    //   manages = relationshipCounts["Manages"] > 0 ? true : false;
    //   config = relationshipCounts["Config"] > 0 ? true : false;
    // } else {
    //   if (relationship == "Config") {
    //     config = true;
    //   }
    //   if (relationship == "Writes") {
    //     writes = true;
    //   }
    //   if (relationship == "Reads") {
    //     reads = true;
    //   }
    //   if (relationship == "Manages") {
    //     manages = true;
    //   }
    // }

    useable = true; //just set to true for these ones


    return (
      <>
        <Box
          padding=".3rem"
          borderRadius=".3rem"
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            // backgroundColor:
            //   selectedNode === id
            //     ? theme.palette.secondary.main
            //     : theme.palette.background.light,

            backgroundColor: theme.palette.background.light,
            borderStyle: "solid",
            width: width,
            height: height,
            cursor: "pointer",
          }}
          onClick={useable ? handelNodeSelected : null}
        >
          {children}
          {nodeType}

          {/* {useable && (
            <Box display="flex" justifyContent="flex-end" gap=".5rem">
              <Divider orientation="vertical" variant="middle" flexItem />

              <Tooltip title="Step Options">
                <IconButton onClick={handleClick}>
                  <MenuOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )} */}

          <Handle
            type={"source"}
            position={Position.Top}
            id="uses_source"
            //isConnectable={}
            style={
              {
                left: "33%",
              }
            }
          />

          <Handle
            type={"target"}
            position={Position.Bottom}
            id="uses_target"
            //isConnectable={}
            style={
              {
                left: "33%",
              }
            }
          />

          <Handle
            type={"source"}
            position={Position.Top}
            id="manages_source"
            //isConnectable={isConnectable}
            style={
              {
                left: "66%",
              }
            }
          />

          <Handle
            type={"target"}
            position={Position.Bottom}
            id="manages_target"
            //isConnectable={isConnectable}
            style={
              {
                left: "66%",
              }
            }
          />

          
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="node-menu"
          open={isOpen}
          onClose={handleClose}
          //onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: "15rem",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <NoteAddOutlined />
            </ListItemIcon>
            Add Note to Step
          </MenuItem>

          <MenuItem
            onClick={() => {
              handelNodeSelected();
              handleClose();
            }}
          >
            <ListItemIcon>
              <OpenInNewOutlined />
            </ListItemIcon>
            See details
          </MenuItem>

          <Divider />

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <CopyAllOutlined />
            </ListItemIcon>
            Copy Step
          </MenuItem>

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <ContentPasteGoOutlined />
            </ListItemIcon>
            Paste Above
          </MenuItem>

          <MenuItem
            disabled={nodeType == "Selecting"}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <ContentPasteGoOutlined />
            </ListItemIcon>
            Paste Below
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            Remove step
          </MenuItem>
        </Menu>
      </>
    );
  }
);

export default CloudOrgBaseNode;
