import Moment from "react-moment";
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getCloudOrgStructureByType, getCloudOrgStructureElement } from "slices/cloudOrgStructure";



const NiceCloudOrgElementManagedBy = (props) => {
  const dispatch = useDispatch();

  const wantedResource = props.children;

  //debugger
  
  const element = useSelector((state) =>
  state.cloudOrgStructure?.data?.list?.find(
    (org) =>
    org.id === wantedResource
  )
  );



  useEffect(() => {
    if (wantedResource != "billingAccount") {
      if (
        !element &&
        wantedResource != "" &&
        wantedResource != undefined
      ) {
        
        //debugger
        const id =wantedResource
        

        dispatch(getCloudOrgStructureElement({id}));

      }
    }
  }, [wantedResource]);


  return (
    <>
      {element ? (

        <Box display="flex" justifyContent="start" alignItems="baseline" flexDirection={"column"}>
          <Typography >{element.name}</Typography>
          <Typography
            variant="small"
            
          >{`(${element.resourceType})`}</Typography>
        </Box>

      ) : (
        
        <Box>{wantedResource}</Box>
      )}
    </>
  );
};

export default NiceCloudOrgElementManagedBy;
