import { PlayCircleFilledOutlined, StopCircleOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import FlowNode from "./FlowNode";

const WorkflowStop = memo(({ isConnectable }) => {
  return (
    <FlowNode  icon={<StopCircleOutlined/>} isConnectable type="input">
      <Typography variant="h5">Stop</Typography>
    </FlowNode>
  );
});

export default WorkflowStop;
