import { CheckBox, LaunchOutlined } from "@mui/icons-material";
import { Box, Typography, Link, Tooltip } from "@mui/material";

import KeyValLabel from "./KeyValLabel";
import KeyValValue from "./KeyValValue";

//CorrectLable: make the lable have title case with spaces
const KeyVal = ({
  label,
  value,
  link,
  tip,
  makeBlue,
  correctLabel,
  fieldType,
  field,
  updater,
  serviceImplemenator,
  serviceType,
  serviceDimension,
  serviceFilter,
  lookUpLabel,
  flexDirection,
  uiRenderType,
  canEdit,
  multiLine,
  rows,
  currencyCode,
  showTechnicalName,
  required,
  setIsUpdating,
  justified,
  labelBold,
}) => {
  let gap = ".4rem";
  let mode = "ROW";

  if (flexDirection === "column") {
    gap = "";
    mode = "COLUMN";
  }

  return (
    <Box
      width={"100%"}
      display="flex"
      justifyContent={justified ? "space-between" : "flex-start"}
      //alignItems="center"
      gap={gap}
      flexDirection={flexDirection}
      labelBold={labelBold}
    >
      <KeyValLabel
        label={label}
        correctLabel={correctLabel}
        field={field}
        showTechnicalName={showTechnicalName}
        labelBold={labelBold}
      />

      <KeyValValue
        value={value}
        link={link}
        tip={tip}
        makeBlue={makeBlue}
        fieldType={fieldType}
        field={field}
        updater={updater}
        serviceDimension={serviceDimension}
        serviceImplemenator={serviceImplemenator}
        serviceType={serviceType}
        serviceFilter={serviceFilter}
        lookUpLabel={lookUpLabel}
        uiRenderType={uiRenderType}
        canEdit={canEdit}
        multiLine={multiLine}
        rows={rows}
        currencyCode={currencyCode}
        required={required}
        setIsUpdating={setIsUpdating}
      />
    </Box>
  );
};

export default KeyVal;
