import React from 'react';
import Tab from '@mui/material/Tab';

const TabVertical = ({ label, ...props }) => (
  <Tab
    label={label}
    {...props}
    sx={{
      minWidth:"12rem",
      textTransform: "none",
      textAlign: "left",
      alignItems: "flex-start",
      paddingLeft: "0px !important", 
      //fontSize: "small",
      fontWeight: "Bold",
      ...props.sx, // Merge additional styles passed as props
    }}
  />
);

export default TabVertical;
