import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import WorkflowStepService from "../services/workflow_step.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const createWorkflowStep = createAsyncThunk(
  "WorkflowStep/create",
  async ({ step }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.createWorkflowStep(step);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteWorkflowStep = createAsyncThunk(
  "WorkflowStep/delete",

  async ({ id }, thunkAPI) => {

    debugger;
    try {
      const data = await WorkflowStepService.deleteWorkflowStep(id);
      return { WorkflowSteps : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateWorkflowStep = createAsyncThunk(
  "WorkflowStep/update",
  async ({ step}, thunkAPI) => {
    try {
      const data = await WorkflowStepService.updateWorkflowStep(step);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateWorkflowStepActive = createAsyncThunk(
  "WorkflowStep/update_active",
  async ({ WorkflowStep }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.updateWorkflowStepActive(WorkflowStep);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowSteps = createAsyncThunk(
  "WorkflowStep/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.getWorkflowSteps(search);
      return { WorkflowSteps: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowStep = createAsyncThunk(
  "WorkflowStep/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.getWorkflowStep(id);
      return { WorkflowStep: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowStepUi = createAsyncThunk(
  "WorkflowStep/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.getWorkflowStepUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowStepData = createAsyncThunk(
  "WorkflowStep/get_data_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.getWorkflowStepData(search);
      return { WorkflowStep_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runWorkflowStep = createAsyncThunk(
  "WorkflowStep/run",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowStepService.runWorkflowStep(id);
      thunkAPI.dispatch(
        setMessage({
          message: `WorkflowStep started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process WorkflowStep ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearWorkflowStepUi = createAction("WorkflowStep/clearWorkflowStepUi");

const initialState = {
  data: list,
  loading: false,
  WorkflowStep_data: list,
  WorkflowStepUi: null,
  WorkflowStepRunJob: null,
};

const WorkflowStepSlice = createSlice({
  name: "WorkflowSteps",
  initialState,

  extraReducers: {

    [clearWorkflowStepUi]: (state, action) => {
      state.WorkflowStepUi = null;
      state.loading = false;
    },

    [createWorkflowStep.pending]: (state, action) => {
      state.loading = true;
    },
    [createWorkflowStep.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.data.data);
      state.data.total ++;
      state.loading = false;
    },
    [createWorkflowStep.rejected]: (state, action) => {
      state.loading = false;
    },

    [getWorkflowStepUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowStepUi.fulfilled]: (state, action) => {
      state.WorkflowStepUi = action.payload.ui;
      state.loading = false;
    },
    [getWorkflowStepUi.rejected]: (state, action) => {
      state.WorkflowStepUi = null;
      state.loading = false;
    },

    [runWorkflowStep.pending]: (state, action) => {
      state.loading = true;
    },
    [runWorkflowStep.fulfilled]: (state, action) => {
      state.WorkflowStepRunJob = action.payload.job;
      state.loading = false;

      //update the rows that this job was created for 
      let WorkflowSteps = state.data.list;
      let thisWorkflowStep = WorkflowSteps.find(
        (WorkflowStep) => WorkflowStep.id === action.payload.job.implementorId
      );

      thisWorkflowStep.jobStatus = action.payload.job.status;
      thisWorkflowStep.jobId = action.payload.job.id;

    },
    [runWorkflowStep.rejected]: (state, action) => {
      state.WorkflowStepRunJob = null;
      state.loading = false;
    },

    [getWorkflowStepData.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowStepData.fulfilled]: (state, action) => {
      //debugger;

      state.WorkflowStep_data = action.payload.WorkflowStep_data;
      state.loading = false;
    },
    [getWorkflowStepData.rejected]: (state, action) => {
      state.WorkflowStep_data = list;
      state.loading = false;
    },

    [getWorkflowSteps.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowSteps.fulfilled]: (state, action) => {
      state.data = action.payload.WorkflowSteps;
      state.loading = false;
    },
    [getWorkflowSteps.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [deleteWorkflowStep.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteWorkflowStep.fulfilled]: (state, action) => {

      state.data = action.payload.WorkflowSteps;
      state.loading = false;
    },
    [deleteWorkflowStep.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateWorkflowStepActive.fulfilled]: (state, action) => {
      let WorkflowSteps = state.data.list;
      let thisWorkflowStep = WorkflowSteps.find(
        (WorkflowStep) => WorkflowStep.id === action.payload.data.id
      );
      thisWorkflowStep.active = action.payload.data.active;
    },

    [updateWorkflowStep.fulfilled]: (state, action) => {
      debugger;

      let WorkflowSteps = state.data.list;
      let thisWorkflowStep = WorkflowSteps.find(
        (WorkflowStep) => WorkflowStep.id === action.payload.data.id
      );

      thisWorkflowStep = action.payload.data;
    },

    [getWorkflowStep.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowStep.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.WorkflowStep;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getWorkflowStep.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = WorkflowStepSlice;
export default reducer;
