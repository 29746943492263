import { PlayCircleFilledOutlined, PlayCircleFilledWhiteOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import FlowNode from "./FlowNode";

const FlowWorkflowStart = memo(({ isConnectable }) => {
  return (
    <FlowNode icon={<PlayCircleFilledWhiteOutlined/>} isConnectable type="output">
      <Typography variant="h5">Start</Typography>
    </FlowNode>
  );
});

export default FlowWorkflowStart;
