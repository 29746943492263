import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { Link as RouterLink, useLocation } from "react-router-dom";

import { NavigateNext } from "@mui/icons-material";

const breadcrumbNameMap = {
  "/job": "Jobs",
  "/utility": "Utilities",
  "/blox": "Blox",
  "/price/azure": "Azure Price",
  "/price": "",
  "/dashboard": "Dashboards",
  "/dashboard/kpi": "KPIs",
  "/kpi": "KPIs",
  "/catalogue": "Catalogues",
  "/catalogue_admin": "Catalogues",
  "/catalogue_page_admin": "Catalogue Pages",
  "/cloudorgstructure": "Cloud Org Structure",
  "/search_prompt": "Search Prompts",
  "/backup": "Backup",
  "/state": "State Sets",
  "/schedule": "Schedule",
};

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

const SimpleBreadcrumbs = ({ penultimateOverride, lastOverride }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
      mb=".8rem"
    >
      <LinkRouter underline="hover" color="inherit" to="/">
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const penultimate = index === pathnames.length - 2;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        let nameOverride = breadcrumbNameMap[to];

        if (nameOverride === undefined) {
          nameOverride = decodeURIComponent(value);
        }

        if (nameOverride !== "") {
          if (last) {
            return (
              <Typography color="text.primary" key={to}>
                {lastOverride?.length > 0 ? lastOverride : nameOverride}
              </Typography>
            );
          } else {
            if (penultimate) {
              return (
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                  {penultimateOverride?.length > 0
                    ? penultimateOverride
                    : nameOverride}
                </LinkRouter>
              );
            } else {
              return (
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                  {nameOverride}
                </LinkRouter>
              );
            }
          }
        }
      })}
    </Breadcrumbs>
  );
};

export default SimpleBreadcrumbs;
