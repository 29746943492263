import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetCurrentTargets,
  getWidgetKpiAggregateValues,
  getWidgetUtilityAggregateValues,
} from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";
import moment from "moment";
import {
  getBillingPeriodForReportingPeriod,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
  processStringForDashboardFilters,
  niceFormatData,
  parseBaseLinePeriod,
  getWhenFilterForBillingPeriod,
  getBaseLineAsFirstDay,
  convertFilterToLastSecond,
} from "../../../common/helpers";
import { tokens } from "theme";
import { useSearchParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { renderColumn } from "common/renderColumn";
import { renderType } from "common/renderType";
import DataGridTwoLineHeader from "components/DataGridTwoLineHeader";

const WidgetTable = ({
  name,
  icon,
  top,
  left,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
  useFilters,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectionModel, setSelectionModel] = React.useState([]);

  //console.log("Passed config: ", config);
  const title = config?.title;
  const description = config?.description;

  const serviceType = config?.serviceType;
  const currencyField = config?.currencyField;
  const nonAggregateFields = config?.nonAggregateFields;

  const valueField = config?.valueField;
  const groupBy = config?.groupBy;
  const sortBy = config?.sortBy;

  const showTargets = config?.showTargets;
  const showTrend = config?.showTrend;

  

  const valueFieldHeader = config?.valueFieldHeader;

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const renderTitle = processStringForDashboardFilters(title, renderState);
  const renderDescription = processStringForDashboardFilters(
    description,
    renderState
  );

  const columnsForRendering = useSelector(
    (state) =>
      state.dashboards.columnsForRendering?.find(
        (widgetData) =>
          widgetData.dashboard === dashboard && widgetData.widget === widget
      )?.columns
  );

  //calculate the when range based on the selected dimension

  const period = parsePeriod(config?.period, searchParams);

  const billingPeriod = getBillingPeriodForReportingPeriod(
    config?.billingPeriod
  );

  const baseLinePeriod = parseBaseLinePeriod(
    config?.baseLinePeriod,
    searchParams
  );

  const dimension = parseDimension(config?.dimension, searchParams);

  const filter = getWhenFilterForReportingPeriod(period);
  const lastDayOfPeriod = convertFilterToLastSecond(filter);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard &&
        widgetData.widget === widget &&
        widgetData.area === ""
    )
  );

  const widgetBaseLineData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard &&
        widgetData.widget === widget &&
        widgetData.area === "baseLine"
    )
  )?.rows;

  const widgetTargetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard &&
        widgetData.widget === widget &&
        widgetData.area === "target"
    )
  )?.rows;

  //console.log("Widget data ", widgetData)

  console.log(
    "render widget table: ",
    widget,
    " refresh time of ",
    refreshPoint,
    " ",
    renderTitle
  );

  //Get the widget Data along with the Columns this Widget Provides
  useEffect(() => {
    //debugger

    if (config) {
      //need to augment the filter, we have the filter from the periods now add what is set in the renderstate

      let localFilter = filter;

      //debugger;

      if (useFilters) {
        localFilter = localFilter + "|" + renderState?.filter;
      }

      //process the fileds list looking for negitives and remove them
      let fields = valueField.replace(/(^|,)-/g, "$1");

      let search = {
        dashboard: dashboard,
        widget: widget,
        area: "",

        function: config.function,
        id: config.implementorId,
        fields: fields,
        dimension: dimension,
        sort: sortBy,

        //descriptors: currencyField,
        billingPeriod: billingPeriod,
        groupBy: groupBy,
      };

      if (currencyField) {
        search.descriptors = currencyField;
      }

      if (nonAggregateFields) {
        // Add nonAggregateFields to search.descriptors
        search.descriptors = search.descriptors
          ? search.descriptors + "," + nonAggregateFields
          : nonAggregateFields;
      }

      // Split the string on '|'
      const parts = localFilter.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");

      //debugger;

      switch (serviceType) {
        case "Kpi":
          console.log("Dispatching getWidgetKpiAggregateValues:", search);
          dispatch(getWidgetKpiAggregateValues({ search }));

          break;

        case "Utility":
          console.log("Dispatching getWidgetUtilityAggregateValues:", search);
          dispatch(getWidgetUtilityAggregateValues({ search }));

          break;
      }

      //if the user has configgured a baseline we need to get that as well
      if (baseLinePeriod) {
        //debugger

        localFilter = getWhenFilterForBillingPeriod(baseLinePeriod);

        if (useFilters) {
          localFilter = localFilter + "|" + renderState.filter;
        }

        // Split the string on '|'
        const parts = localFilter.split("|");

        // Process each part and filter out [when]=all
        const filteredParts = parts.filter((part) => {
          // Check if the part does not end with '=all'
          return !part.endsWith("=all");
        });

        // Join the filtered parts back together with '|'

        const baseLineSearch = { ...search };

        baseLineSearch.filter = filteredParts.join("|");
        baseLineSearch.area = "baseLine";

        //debugger;

        switch (serviceType) {
          case "Kpi":
            console.log(
              "Dispatching getWidgetKpiAggregateValues for baseline:",
              baseLineSearch
            );
            dispatch(getWidgetKpiAggregateValues({ search: baseLineSearch }));

            break;

          case "Utility":
            console.log(
              "Dispatching getWidgetUtilityAggregateValues for baseline:",
              baseLineSearch
            );
            dispatch(
              getWidgetUtilityAggregateValues({ search: baseLineSearch })
            );

            break;
        }
      }

      //if the user has requested targets
      if (showTargets) {
        //debugger

        if (useFilters) {
          localFilter = renderState.filter;
        }

        // Split the string on '|'
        const parts = localFilter.split("|");

        // Process each part and filter out [when]=all
        const filteredParts = parts.filter((part) => {
          // Check if the part does not end with '=all'
          return !part.endsWith("=all");
        });

        // Join the filtered parts back together with '|'

        const targetSearch = { ...search };

        if (nonAggregateFields) {
          // Add nonAggregateFields to search.descriptors
          targetSearch.fields = targetSearch.fields
            ? targetSearch.fields + "," + nonAggregateFields
            : nonAggregateFields;
        }

        targetSearch.filter = filteredParts.join("|");
        targetSearch.area = "target";
        targetSearch.currentTargetAt = lastDayOfPeriod;

        //debugger;

        switch (serviceType) {
          case "Kpi":
            console.log("Dispatching get targets for KPI:", targetSearch);
            dispatch(getWidgetCurrentTargets({ search: targetSearch }));

            break;
        }
      }

      search = {
        dashboard: dashboard,
        widget: widget,
        id: config.implementorId,
        serviceType: serviceType,
      };

      console.log("Dispatching getWidgetColumnValues:", search);
      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dashboard, widget, config, refreshPoint]);

  if (!widgetData?.rows || !config) {
    return <Box>Loading...</Box>;
  }

  const sortedData = [...widgetData?.rows].sort((a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];

    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    } else {
      return 0;
    }
  });

  //debugger;

  let groupedData = [];

  const groupBys = groupBy.split(",");

  //add in the nonAggregates
  let fullFieldList = valueField;

  if (nonAggregateFields) {
    fullFieldList = fullFieldList + "," + nonAggregateFields;
  }

  const fields = fullFieldList.split(",");

  if (groupBys.length > 1) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        //backgroundColor={theme.palette.background.alt}
        sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
        
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography
              variant="h4"
              sx={{ color: theme.palette.text.main}}
            >
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main}}>
            To many Group By fields
          </Typography>
        </Box>
      </Box>
    );
  }

  if (groupBys.length > 1 && fields.length > 1) {
    //this is an error and not supported
    return (
      <Box
        gridColumn={`${left} / span ${columns}`}
        gridRow={`${top} /span ${rows}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        //backgroundColor={theme.palette.background.alt}
        sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
        borderRadius="0.55rem"
      >
        <Box display="flex" flexDirection="column">
          <FlexBetween>
            <Typography
              variant="h4"
              sx={{ color: theme.palette.text.main }}
            >
              {renderTitle}
            </Typography>
          </FlexBetween>

          <Typography variant="h4" sx={{ color: theme.palette.text.main}}>
            You cant have multi group by fields as well as multi value fileds,
            pick one
          </Typography>
        </Box>
      </Box>
    );
  }

  console.log("Data befor field group:", sortedData);

  let gridColumns = [
    {
      field: "id",
      headerName: "",
      flex: 1,
      sortable: false,
    },
    {
      field: "groupByFieldName",
      headerName: "GroupBy Field Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "renderType",
      headerName: "Render Type",
      flex: 1,
      sortable: false,
    },
    {
      field: "targetType",
      headerName: "Target Type",
      flex: 1,
      sortable: false,
    },
  ];

  let columnsVisability = {
    groupByFieldName: false,
    renderType: false,
    targetType: false,
  };

  //add a column for the currency filed if its being used
  if (currencyField) {
    const name = columnsForRendering.find(
      (col) => col.field === currencyField
    )?.headerName;

    const currencyColumn = {
      field: currencyField,
      headerName: name,
      flex: 1,
      sortable: false,
    };

    columnsVisability[currencyField] = false;

    gridColumns.push(currencyColumn);
  }


  const trendColumn = {
    field: "trend",
    //headerName: "Trend",
    flex: 1,
    sortable: false,
    renderHeader: () => {
      const display = getBaseLineAsFirstDay(baseLinePeriod);
      return DataGridTwoLineHeader("Trend");
    },

    renderCell: ({ row }) => {
      return renderType("<NiceTrend>", row, null, null, gridColumns);
    },
  };

  if (showTrend) {
    gridColumns.push(trendColumn);
  }


  if (baseLinePeriod) {
    const baseLineColumn = {
      field: "baseLine",
      renderHeader: () => {
        const display = getBaseLineAsFirstDay(baseLinePeriod);
        return DataGridTwoLineHeader("Base Line", display);
      },

      sortable: false,
      flex: 1,
      renderCell: ({ row }) =>
        renderType(row.renderType, row["baseLine"], row[currencyField]),
    };

    gridColumns.push(baseLineColumn);
  }

  groupedData = fields.map((idField) => {
    const measureId = idField[0] === "-" ? idField.substring(1) : idField;

    //get the Header version of the column

    //lookup human name for field
    const columnMetaUIOriginal = columnsForRendering.find(
      (col) => col.field === measureId
    );

    // const name = columnsForRendering.find(
    //   (col) => col.field === measureId
    // )?.headerName;

    const newObject = { id: columnMetaUIOriginal?.headerName };

    sortedData.forEach((fact) => {
      const groupByFieldName = fact[groupBy]; // Use the 'groupBy' variable as the field name

      //debugger;

      //check if we have the groupBy in the columns array if not add it

      if (!gridColumns.some((column) => column.field === groupByFieldName)) {
        //debugger;

        const columnGroupByMeta = columnsForRendering.find(
          (col) => col.field === groupBy
        );

        let columnMetaUI = { ...columnMetaUIOriginal };

        //update the field to be the current groupBy else the rest fo the table logic will not be able to find it
        columnMetaUI.field = groupByFieldName;

        let r = "";
        let headerName = groupByFieldName;
        let currencyCode = "";
        let factCurrencyField = "";

        if (currencyField) {
          factCurrencyField = "descriptor_" + currencyField;

          currencyCode = fact[factCurrencyField];
        }

        if (columnMetaUI.renderCell !== "") {
          // check if renderCell exists
          //r = ({ row }) => renderColumn(columnMetaUI, row);
          r = ({ row }) =>
            renderType(
              row.renderType,
              row[groupByFieldName],
              row[currencyField]
            );
        }

        if (columnGroupByMeta?.renderCell !== "") {
          //convert the value for the groupBy into a nice version using the rendercell
          headerName = renderType(
            columnGroupByMeta?.renderCell,
            groupByFieldName,
            currencyCode
          );
        }

        const newCol = {
          field: groupByFieldName,
          //headerName: headerName,
          flex: 1,
          renderCell: r,
          sortable: false,
          renderHeader: () => {
            return DataGridTwoLineHeader("Actual", headerName);
          },
          trendColumn: true,
        };

        gridColumns.push(newCol);
      }

      newObject[groupByFieldName] = {}; // Create an object for each group by

      // Round and negate the values if necessary
      Object.keys(fact).forEach((key) => {
        //debugger;

        if (currencyField) {
          const fieldKey = "descriptor_" + currencyField;

          newObject[currencyField] = fact[fieldKey];
        }

        if (key == measureId) {
          const value =
            typeof fact[key] === "number"
              ? parseFloat(fact[key].toFixed(2))
              : fact[key];

          const negKey = "-" + measureId;
          newObject[groupByFieldName] = fields.includes(negKey)
            ? -value
            : value;

          newObject["groupByFieldName"] = measureId;

          const columnGroupByMeta = columnsForRendering.find(
            (col) => col.field === measureId
          );

          newObject["renderType"] = columnGroupByMeta?.renderCell;
          newObject["measureId"] = measureId;
          newObject["desiredTrendDirection"] = columnGroupByMeta?.desiredTrendDirection;
        }

        const measureAsNonAggregated = "descriptor_" + measureId;

        if (key === measureAsNonAggregated) {
          const value =
            typeof fact[key] === "number"
              ? parseFloat(fact[key].toFixed(2))
              : fact[key];

          const negKey = "-" + measureId;
          newObject[groupByFieldName] = fields.includes(negKey)
            ? -value
            : value;

          newObject["groupByFieldName"] = measureId;

          const columnGroupByMeta = columnsForRendering.find(
            (col) => col.field === measureId
          );

          newObject["renderType"] = columnGroupByMeta?.renderCell;
          newObject["measureId"] = measureId;
          newObject["desiredTrendDirection"] = columnGroupByMeta?.desiredTrendDirection;
        }
      });
    });

    return newObject;
  });

  if (baseLinePeriod && widgetBaseLineData?.length > 0) {
    //loop over the groupedData and add the data for the baseline column

    const withBaseLine = groupedData.map((record) => {
      if (!nonAggregateFields.includes(record.measureId)) {
        const value =
          typeof widgetBaseLineData[0][record.measureId] === "number"
            ? parseFloat(widgetBaseLineData[0][record.measureId].toFixed(2))
            : widgetBaseLineData[0][record.measureId];

        const negKey = "-" + record.measureId;
        record["baseLine"] = fields.includes(negKey) ? -value : value;

        return record;
      } else {
        const measureAsNonAggregated = "descriptor_" + record.measureId;

        const value =
          typeof widgetBaseLineData[0][measureAsNonAggregated] === "number"
            ? parseFloat(
                widgetBaseLineData[0][measureAsNonAggregated].toFixed(2)
              )
            : widgetBaseLineData[0][measureAsNonAggregated];

        const negKey = "-" + measureAsNonAggregated;
        record["baseLine"] = fields.includes(negKey) ? -value : value;

        return record;
      }
    });

    //debugger;

    groupedData = withBaseLine;
  }

  if (showTargets && widgetTargetData?.length > 0) {
    //loop over the groupedData and add the data for the target

    const withTarget = groupedData.map((record) => {
      //debugger;

      const target = widgetTargetData.find(
        (target) => target.measure === record?.measureId
      );

      if (target?.noTarget) {
        record["target"] = "None";
      } else {
        const value = parseFloat(target?.target.toFixed(2));

        const negKey = "-" + record?.measureId;
        record["target"] = fields.includes(negKey) ? -value : value;
        record["targetType"] = target?.type;
      }

      return record;
    });

    groupedData = withTarget;
  }

  //debugger

  //add the final columns



  const targetColumn = {
    field: "target",
    //headerName: "Target",
    flex: 1,
    sortable: false,
    renderHeader: () => {
      const display = getBaseLineAsFirstDay(baseLinePeriod);
      return DataGridTwoLineHeader("Target");
    },

    renderCell: ({ row }) => {
      return renderType(
        "<NiceTarget>",
        row.target,
        row[currencyField],
        row.targetType
      );
    },
  };



  if (showTargets) {
    gridColumns.push(targetColumn);
  }

  console.log("Grid Columns: ", gridColumns);

  console.log("Columns: ", columnsForRendering);
  console.log("Widget: ", widget);

  console.log("Data after field group:", groupedData);

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      p="1.25rem 1rem"
      flex="1 1 100%"
      //backgroundColor={theme.palette.background.alt}
      sx={{ border: '1px solid', borderColor:  theme.palette.grey[300]}}
      borderRadius="0.55rem"
    >
      <Box display="flex" flexDirection="column">
        <FlexBetween>
          <Typography variant="h4" sx={{ color: theme.palette.text.main }}>
            {renderTitle}
          </Typography>
          {icon}
        </FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main}}>
          {renderDescription}
        </Typography>
      </Box>

      {sortedData?.length > 0 && (
        <Box
          mt="1rem"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-row": {
              //backgroundColor: theme.palette.background.light,
            //backgroundColor: theme.palette.grey[10],
            backgroundColor: 'white',
            borderBottomWidth: "1px",
            borderBottomStyle:"solid",
            borderBottomColor: theme.palette.grey[300],
            },

            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.main,
              fontWeight: "bold",

            borderBottomWidth: "2px",
            borderBottomStyle:"solid",
            borderBottomColor: theme.palette.grey[300],

            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.text.main,
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
          display={"flex"}
        >
          <DataGrid
            //{...(groupBy ? { getRowId: groupBy } : {})}
            //onRowClick={rowClick}
            rows={groupedData}
            columns={gridColumns}
            disableColumnMenu
            // pageSize={+page_size}
            // page={dataRows?.page}
            // paginationMode="server"
            rowCount={groupedData?.length}
            // onPageChange={(newPage) => {
            //   setPage(newPage);
            // }}
            // onPageSizeChange={(newPageSize) => {
            //   setPageSize(newPageSize);
            // }}

            autoHeight={true}
            hideFooter={true}
            // components={{
            //   Toolbar: CustomToolbar,
            //   ColumnMenu: DataGridCustomColumnMenu,
            // }}

            headerHeight={50}
            //rowHeight={40}
            //getRowHeight={() => "auto"}
            //checkboxSelection
            //disableSelectionOnClick
            //keepNonExistentRowsSelected

            // sortingMode="server"
            // sortModel={columnSortModel}
            // onSortModelChange={handleSortModelChange}

            columnVisibilityModel={columnsVisability}
            // onColumnVisibilityModelChange={(newModel) =>
            //   setColumnVisibilityModel(newModel)
            // }
            // onSelectionModelChange={(newSelectionModel) => {
            //   selectionSetter(newSelectionModel);
            // }}
            selectionModel={selectionModel}
          />
        </Box>
      )}
    </Box>
  );
};

export default WidgetTable;
