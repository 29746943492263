import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import { addKpiTarget } from "slices/kpi";
import KeyVal from "components/global/KeyVal";
import DisplayFormikState from "components/global/FormikHelper";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const KpiAddTarget = ({
  closerFunc,
  kpi,
  refreshTableFunc,
  measures,
  dimensions,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const filteredDimensions = kpi?.dimensions.filter(
  //   (dimension) => dimension.field !== "when"
  // );

  const localMeasures = measures?.map((option) => option);

  localMeasures.unshift({ value: "SELECT", label: "Select Measure" });

  // Now, localMeasures contains all the measures with the "SELECT" option at the top

  const filteredDimensions = dimensions?.filter(
    (dimension) => dimension.field !== "when"
  );

  console.log("Dimensions to allow traget for: ", filteredDimensions);

  const [dimensionFilters, setDimensionFilters] = useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const [dimensionsUpdatingCount, setDimensionsUpdatingCount] = useState(0);

  const handelUpdateOnDimension = (newVal) => {
    // If newVal is true, increment dimensionsUpdatingCount by 1; otherwise, decrement by 1
    setDimensionsUpdatingCount((prevCount) =>
      newVal ? prevCount + 1 : prevCount - 1
    );
  };

  const handelUpdate = (field, newVal) => {
    // Check if the field exists in dimensionFilters
    const existingFilter = dimensionFilters.find(
      (filter) => filter.field === field
    );

    if (existingFilter) {
      // Update the value field of the existing filter
      const updatedFilters = dimensionFilters.map((filter) =>
        filter.field === field ? { ...filter, value: newVal } : filter
      );
      setDimensionFilters(updatedFilters);
    } else {
      // Add a new object to dimensionFilters
      setDimensionFilters((prevFilters) => [
        ...prevFilters,
        { field, value: newVal },
      ]);
    }
  };

  const types = [
    {
      value: "number",
      label: "Number",
    },
    {
      value: "<NicePercentage>",
      label: "Percentage",
    },
    {
      value: "<NiceCurrency>",
      label: "Currency",
    },
  ];

  const initialValues = {
    measure: localMeasures[0],
    target: 0,
    type: "number",
    currencyField: "",
  };

  const validationSchema = Yup.object().shape({
    measure: Yup.mixed()
      .test("is-not-select", "Select a valid measure", (value) => {
        // Check if measure is an object and its value field is not "SELECT"
        return typeof value === "object" && value.value !== "SELECT";
      })
      .required("Measure is required"),
    target: Yup.number().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    currencyField: Yup.string().when("type", {
      is: (type) => type == "<NiceCurrency>",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().nullable(), // No validation when type is not <NiceCurrency>
    }),
  });

  return (
    <Box m="20px">
      <Header title={`New Target for "${kpi.name}"`} />

      <Formik
        onSubmit={(values, actions) => {
          const row = { ...values };

          row.organization = kpi.organization;
          row.orchestrator = kpi.orchestrator;
          row.id = kpi.id;

          row.measure = row.measure.value

          if (row.type == "<NicePercentage>") {
            row.target = row.target / 100;
          }

          if (row.type == "number") {
            row.type = "";
          }

          let dimensionsToAdd = {};

          //loop over and addd any dimendion filters
          dimensionFilters.forEach((filter) => {
            const { field, value } = filter;

            dimensionsToAdd[field] = value;
          });

          row.dimensions = dimensionsToAdd;

          //debugger

          dispatch(clearMessage());

          dispatch(addKpiTarget({ row }))
            .unwrap()
            .then(() => {
              //debugger;

              refreshTableFunc();
              closerFunc();
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <Autocomplete
                fullWidth
                options={localMeasures}
                getOptionLabel={(option) => option.label}
                value={values.measure}
                onChange={(e, value) => {
                  setFieldValue("measure", value);
                }}

                onBlur={(e, value) => {
                  if(values.measure === null){
                    setFieldValue("measure", localMeasures[0]);
                  }
                  
                }}

                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                //onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="measure"
                    error={!!touched.measure && !!errors.measure}
                    helperText={touched.measure && errors.measure}
                    sx={{ gridColumn: "span 1" }}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue, {
                    insideWords: true,
                  });
                  const parts = parse(option.label, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Value"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.target}
                name="target"
                error={!!touched.target && !!errors.target}
                helperText={touched.target && errors.target}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                select
                fullWidth
                variant="outlined"
                type="text"
                label="Target Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 1" }}
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Currency Field"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.currencyField}
                name="currencyField"
                error={!!touched.currencyField && !!errors.currencyField}
                helperText={touched.currencyField && errors.currencyField}
                sx={{ gridColumn: "span 1" }}
                disabled={values.type != "<NiceCurrency>"}
              />

              {/* add the dimensions  */}

              <Box display="flex" flexDirection="column" gap=".6rem">
                {filteredDimensions.map((dimension) => {
                  // Use find to get the first matching filter
                  const currentFilter = dimensionFilters.find(
                    (filter) => filter.field === dimension.field
                  );

                  // Use optional chaining to handle cases where currentFilter is undefined
                  const currentValue = currentFilter?.value || "All";

                  return (
                    <KeyVal
                      key={dimension.field}
                      label={dimension.headerName}
                      value={currentValue}
                      fieldType={dimension.type}
                      field={dimension.field}
                      flexDirection="column"
                      canEdit={true}
                      uiRenderType={dimension.renderCell}
                      multiLine={null}
                      rows={null}
                      showTechnicalName={false}
                      updater={handelUpdate}
                      serviceImplemenator={dimension.serviceImplemenator}
                      serviceDimension={dimension.serviceDimension}
                      serviceType={dimension.serviceType}
                      lookUpLabel={dimension.lookUpLabel}
                      setIsUpdating={handelUpdateOnDimension}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="1rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    !dirty ||
                    dimensionsUpdatingCount > 0
                  }
                >
                  Add target
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default KpiAddTarget;
