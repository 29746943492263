import { CheckBox, LaunchOutlined } from "@mui/icons-material";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import EditableFieldString from "./EditableFieldString";
import { renderType } from "common/renderType";
import NiceBoolean from "./NiceBoolean";
import NiceMultiLine from "./NiceMultiLine";
import EditableFieldNumber from "./EditableFieldNumber";
import EditableFieldBool from "./EditableFieldBool";
import EditableFieldDate from "./EditableFieldDate";
import { processString } from "common/helpers";
import NiceLink from "./NiceLink";

//CorrectLable: make the lable have title case with spaces
const KeyValValue = ({
  value,
  link,
  tip,
  makeBlue,
  fieldType,
  field,
  updater,
  serviceImplemenator,
  serviceType,
  serviceDimension,
  serviceFilter,
  lookUpLabel,
  uiRenderType,
  canEdit,
  multiLine,
  rows,
  currencyCode,
  required,
  setIsUpdating,

  makeLink,
  makeLinkIcon,
  linkLabel,
  uiFocusLevel,
  rowData,
}) => {
  const navigate = useNavigate();

  const valueType = typeof value;

  let renderedValue = value;

  if (uiRenderType) {
    //check for a NiceActive, this will not render as a key vall

    if (uiRenderType === "<NiceActive>") {
      renderedValue = <NiceBoolean>{value}</NiceBoolean>;
    } else {
      renderedValue = renderType(uiRenderType, value, currencyCode);
    }
  }

  if (multiLine) {
    renderedValue = <NiceMultiLine>{value}</NiceMultiLine>;
  }

  
  console.log("Linklabel for field:", field, linkLabel);

  return (
    <Box>
      {makeLink ? (
        <NiceLink
          label={
            linkLabel
              ? linkLabel !== ""
                ? processString(linkLabel, rowData)
                : renderedValue
              : renderedValue
          }
          link={processString(link, rowData)}
          tip={processString(tip, rowData)}
          makeBlue={makeBlue}
          showFollowIcon={makeLinkIcon}
        />
      ) : (
        //this is a non link field
        <Box>
          {updater === undefined || !canEdit ? (
            <Tooltip title={tip}>
              <Box>{renderedValue}</Box>
            </Tooltip>
          ) : fieldType === "string" ? (
            <EditableFieldString
              field={field}
              serviceImplemenator={serviceImplemenator}
              serviceDimension={serviceDimension}
              serviceType={serviceType}
              serviceFilter={serviceFilter}
              lookUpLabel={lookUpLabel}
              tip={tip}
              rows={rows}
              fieldType={fieldType}
              initialValue={value}
              displayValue={renderedValue ? renderedValue : "Not Set"}
              currencyCode={currencyCode}
              onChange={(newValue) => {
                if (value !== newValue) {
                  updater(field, newValue);
                }
              }}
              uiRenderType={uiRenderType}
              setIsUpdating={setIsUpdating}
            />
          ) : fieldType === "number" ? (
            <EditableFieldNumber
              field={field}
              serviceImplemenator={serviceImplemenator}
              serviceDimension={serviceDimension}
              serviceType={serviceType}
              lookUpLabel={lookUpLabel}
              serviceFilter={serviceFilter}
              tip={tip}
              rows={rows}
              fieldType={fieldType}
              initialValue={value}
              displayValue={renderedValue}
              currencyCode={currencyCode}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                if (!isNaN(numericValue) && value !== numericValue) {
                  updater(field, numericValue);
                }
              }}
              uiRenderType={uiRenderType}
              setIsUpdating={setIsUpdating}
            />
          ) : fieldType === "boolean" ? (
            <EditableFieldBool
              field={field}
              tip={tip}
              initialValue={value}
              displayValue={renderedValue}
              onChange={(newValue) => {
                const boolNewValue = newValue === "TRUE" ? true : false;
                if (value !== boolNewValue) {
                  updater(field, boolNewValue);
                }
              }}
              setIsUpdating={setIsUpdating}
            />
          ) : fieldType === "date" ? (
            <EditableFieldDate
              field={field}
              tip={tip}
              initialValue={value}
              displayValue={renderedValue}
              renderType={uiRenderType}
              onChange={(newValue) => {
                if (value !== newValue) {
                  updater(field, newValue);
                }
              }}
              setIsUpdating={setIsUpdating}
            />
          ) : (
            fieldType
          )}
        </Box>
      )}
    </Box>
  );
};

export default KeyValValue;
