import { CheckOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { getNiceCurrency, getNicePercentage } from "common/helpers";

const NiceTarget = (props) => {
  let value = props.children;
  let type = props.type;

  let formattedValue = value;

  switch (type) {
    case "<NicePercentage>":

    formattedValue = getNicePercentage(value)
      break;

    default:
      //do nothing special
      break;
  }

  return <Box>{formattedValue}</Box>;
};

export default NiceTarget;
