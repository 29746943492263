import React from "react";
import { Typography, Stack, useTheme } from "@mui/material";
import { DynamicIcon } from "common/dynamicIcon";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyValLabel from "./KeyValLabel";
import KeyValValue from "./KeyValValue";


const ObjectDetailsGroup = ({
  groups,
  parentName,
  row,
  thisGroupName,
  rowUI,
  showHidden,
  showTechnical,
  hideId,
  parentColumns,
  updater,
}) => {
  const theme = useTheme();

  if (!groups || groups.length === 0) {
    return null; // Return null if groups is undefined or empty
  }

  if (parentName === "Screen") {
    //means this is the top level so set the group
    thisGroupName = groups.find((obj) => obj.parent === parentName).name;
    parentName = thisGroupName;
  }

  // Find the current group based on the parentName
  const thisGroup = groups.find((obj) => obj.name === thisGroupName);

  // If thisGroup is not found, return null or handle appropriately
  if (!thisGroup) {
    return null;
  }

  // Find child groups of the current group and sort them by column and sequence
  const childGroups = groups
    .filter((obj) => obj.parent === thisGroupName)
    .sort((a, b) => {
      // Sort by column first
      if (a.column !== b.column) {
        return a.column - b.column;
      }
      // If columns are equal, sort by sequence
      return a.row - b.row;
    });

  // Create an array to hold child groups for each column
  const columns = [];
  for (let i = 0; i < thisGroup.columns; i++) {
    columns.push([]);
  }

  // Populate the columns array with child groups
  childGroups.forEach((group) => {
    columns[group.column - 1].push(group);
  });

  // Render the child groups in each column
  const renderedColumns = columns.map((column, index) => (
    <Stack
      direction="column"
      justifyContent="space-between"
      flex="1 1 100%"
      //backgroundColor={theme.palette.background.alt}
      // borderRadius="0.55rem"
      alignItems="start"
      sx={{ maxWidth: `${100 / parentColumns}%` }}
    >
      {column.map((group) => (
        <ObjectDetailsGroup
          key={group.name}
          groups={groups}
          parentName={thisGroupName}
          row={row}
          thisGroupName={group.name}
          rowUI={rowUI}
          showHidden={showHidden}
          showTechnical={showTechnical}
          hideId={hideId}
          parentColumns={thisGroup.columns}
          updater={updater}
        />
      ))}
    </Stack>
  ));

  // Render the current group and its child groups in columns

  if (thisGroup.visible) {
    return (
      <Stack direction="column" alignItems="start" gap={".2rem"} width={"100%"}>
        <Stack direction="row" alignItems="start" gap={"0.5rem"} mb=".2rem">
          <DynamicIcon iconName={thisGroup.icon} />
          <Typography
            variant="h3"
            fontWeight={"bold"}
            sx={{ color: theme.palette.text.main}}
          >
            {thisGroup.name}
          </Typography>
        </Stack>

        {/* <Grid container rowSpacing={0.4} width={"100%"} key={thisGroup.name}>
          {rowUI?.columns
            .filter((item) => item.uiDetailHide !== true || showHidden)
            .filter((item) => item.field !== "id" || !hideId)
            .filter((item) => item.uiGroup === thisGroup.name)
            .sort((a, b) => (a.row > b.row ? 1 : -1))
            .map((item) => (


              <>
                <Grid xs={3} key={"label" + item.field} >
                  <KeyValLabel
                    label={item.headerName}
                    correctLabel={false}
                    field={item.field}
                    showTechnicalName={showTechnical}
                    labelBold={false}
                    
                  />
                </Grid>
                <Grid  key={"gap" + item.field} width={"15px"}></Grid>
                <Grid xs={8} key={"field" + item.field}>
                  <KeyValValue
                    value={
                      row[item.field] !== undefined
                        ? row[item.field]
                        : "Not Set"
                    }
                    tip={item.helperText}
                    fieldType={item.type}
                    field={item.field}
                    flexDirection="row"
                    canEdit={item.valueMode === "User" ? true : false}
                    uiRenderType={
                      row[item.field] !== undefined ? item.renderCell : ""
                    }
                    multiLine={item.multiLine}
                    rows={item.rows}
                    showTechnicalName={showTechnical}
                    currencyCode={row[item.currencyField]}
                    updater={updater}
                    serviceImplemenator={item.serviceImplemenator}
                    serviceDimension={item.serviceDimension}
                    serviceType={item.serviceType}
                    lookUpLabel={item.lookUpLabel}
                    makeBlue={item.makeLinkBlue}
                    link={item.linkAddress}
                    makeLink={item.makeLink}
                    makeLinkIcon={item.makeLinkIcon}
                    linkLabel={item.linkLabel}
                    uiFocusLevel={item.uiFocusLevel}
                    rowData={row}
                  />
                </Grid>
              </>
            ))}
        </Grid> */}

<Grid container rowSpacing={0.4} width={"100%"} key={thisGroup.name}>
  {rowUI?.columns
    .filter((item) => item.uiDetailHide !== true || showHidden)
    .filter((item) => item.field !== "id" || !hideId)
    .filter((item) => item.uiGroup === thisGroup.name)
    .sort((a, b) => (a.row > b.row ? 1 : -1))
    .map((item, index, array) => {
      // Find the max width of the labels within this group
      const maxLabelWidth = Math.max(...array.map((i) => i.headerName.length)) * 8; // Assuming 8px per character, you can adjust this accordingly
      
      return (
        <Grid container item xs={12} key={item.field}>
          <Grid 
            item 
            xs={12} 
            container 
            direction="row" 
            alignItems="flex-start" 
            wrap="nowrap"
            gap={"0.4rem"}
          >
            <Grid 
              item 
              style={{ 
                flexGrow: 0, 
                flexBasis: 'auto', 
                minWidth: `${maxLabelWidth}px`, // Set min-width based on the widest label
                maxWidth: `${maxLabelWidth}px`, // Ensure all labels have the same width
                
              }}
            >
              <KeyValLabel
                label={item.headerName}
                correctLabel={false}
                field={item.field}
                showTechnicalName={showTechnical}
                labelBold={false}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <KeyValValue
                value={
                  row[item.field] !== undefined ? row[item.field] : "Not Set"
                }
                tip={item.helperText}
                fieldType={item.type}
                field={item.field}
                flexDirection="row"
                canEdit={item.valueMode === "User" ? true : false}
                uiRenderType={
                  row[item.field] !== undefined ? item.renderCell : ""
                }
                multiLine={item.multiLine}
                rows={item.rows}
                showTechnicalName={showTechnical}
                currencyCode={row[item.currencyField]}
                updater={updater}
                serviceImplemenator={item.serviceImplemenator}
                serviceDimension={item.serviceDimension}
                serviceType={item.serviceType}
                lookUpLabel={item.lookUpLabel}
                makeBlue={item.makeLinkBlue}
                link={item.linkAddress}
                makeLink={item.makeLink}
                makeLinkIcon={item.makeLinkIcon}
                linkLabel={item.linkLabel}
                uiFocusLevel={item.uiFocusLevel}
                rowData={row}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    })}
</Grid>





        {/* Render child groups in columns */}
        <Stack
          direction={thisGroup.columns > 1 ? "row" : "column"}
          alignItems="start"
          mb={"3rem"}
          sx={{ maxWidth: `${100 / parentColumns}%` }}
        >
          {renderedColumns}
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack
        direction={thisGroup.columns > 1 ? "row" : "column"}
        alignItems="start"
        gap={"3rem"}
        sx={{ maxWidth: `${100 / parentColumns}%` }}
      >
        {renderedColumns}
      </Stack>
    );
  }
};

export default ObjectDetailsGroup;
