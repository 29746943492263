import Moment from 'react-moment';
import { Typography, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect  } from "react";
import { clearMessage, setMessage } from "../../slices/message";
import { getOrganization } from "../../slices/organization";


const NiceOrganization = (props) => {

  const dispatch = useDispatch();
  
  const organization = useSelector((state) => state.organizations.data.list?.find(org => org.id === props.children));

  useEffect(() => {
    if(!organization){
      //need to get the user to display 
      let id = props.children;

      dispatch(getOrganization({id}));
    };

}, [organization]);

return (
  <>
  {organization &&
  <Box display="flex" justifyContent="start" alignItems="baseline"> 
    {`${organization.name}`}
  </Box>
}
</>)
}

export default NiceOrganization;