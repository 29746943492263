import Moment from 'react-moment';

const NiceDateOnly = (props) => {

return (
    <Moment format="DD-MMM-YYYY">
      {props.children}
    </Moment>
  )
}

export default NiceDateOnly;