import { Box, useTheme } from "@mui/material";
import Header from "../../components/global/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { YardSharp } from "@mui/icons-material";

const Privacy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Privacy Policy" />

<Typography variant="h3">
    OpsBlox's Privacy Policy Statement
</Typography>

<Typography m="1rem">
    OpsBlox.io (the “Company”, “we”, or “us”) respects your privacy. This Privacy Policy Statement (“Privacy Policy”) describes 
    the ways we collect information from and about you, what we do with the information, and your privacy rights. 
    By accessing our website, or purchasing our products or services, you agree to this Privacy Policy in addition to any other 
    agreements we might have with you. In the event that such agreements contain terms that conflict with this Privacy Policy, 
    the terms of those agreements will prevail.
</Typography>

<Typography variant="h3">
    SCOPE
</Typography>

<Typography m="1rem">
    The Privacy Policy Statement applies to personal data processed by us in our business, including on our websites, mobile applications, 
    and other online or offline offerings (collectively, the “Services”).
</Typography>

<Typography variant="h3">
    1. OUR COLLECTION OF YOUR INFORMATION
</Typography>
<Typography m="1rem">
    Personal Data The information we collect may include your personal data, such as your name, contact information, 
    IP addresses, product and service selections and other data that may identify you.  We collect personal data about you at 
    several different points, including but not limited to the following:


    <ul>
        <li>when we correspond with you as a customer or prospective customer;when you visit our website or use the Platform;</li>
        <li>when you use our software or services;</li>
        <li>when you register as an end-user of our services and an account is created for you;</li>
        <li>when you enroll in our newsletter or loyalty program;</li>
        <li>when you decide to participate in a survey;</li>
        <li>when you contact us for help;</li>
        <li>when you attend our customer conferences or webinars;</li>
        <li>when the websites or mobile apps send us error reports or application analytics data; and</li>
        <li>when you provide content on our forums, blogs, or social media pages. Any content you provide on these channels will be
            considered “public” and is not subject to privacy protections.</li>
    </ul>

</Typography>

<Typography variant="h3">
    2. INFORMATION COLLECTED AUTOMATICALLY OR FROM OTHERS
</Typography>

<Typography m="1rem">
    Automatic Data Collection.  We may collect certain information automatically when you use the Services.  
    This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, 
    mobile advertising and other unique identifiers, details about your browser, operating system or device, location information, 
    Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and other 
    information about how you use the Services.  Information we collect may be associated with accounts and other devices. Our Services, such as 
    those related to location data management, may collect precise geolocation information in accordance with applicable law. In addition, we may 
    automatically collect data regarding your use of our Services, such as the types of content you interact with and the frequency and duration of 
    your activities.  Unless contrary to applicable law or contractual agreement, we may combine your information with information that other people 
    provide when they use our Services, including, when the option is available, information about you when they tag you.

    <ul>
        <li>
        Cookies, Pixel Tags/Web Beacons, Analytics Information, and Interest-Based Advertising technologies.  We, as well as third parties that provide
        content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) 
        to automatically collect information through the Services.  Technologies are essentially small data files placed on your computer, tablet, 
        mobile phone, or other devices that allow us and our partners to record certain pieces of information whenever you visit or interact with our 
        Services.</li>

        <li>
        Cookies.  Cookies are small text files placed in visitors’ computer browsers to store their preferences.  Most browsers allow you to block and 
        delete cookies.  However, if you do that, the Services may not work properly.</li>

        <li>
        Pixel Tags/Web Beacons.  A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about
        users’ engagement on that web page.  The use of a pixel allows us to record, for example, that a user has visited a particular web page or 
        clicked on a particular advertisement.</li>

    </ul>
</Typography>

<Typography variant="h3">
    3. LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA (EEA, UK AND SWITZERLAND ONLY)
</Typography>

<Typography m="1rem">
    With respect to personal data of individuals from the European Economic Area (“EEA”), 
    the United Kingdom (“UK”) or Switzerland, our legal basis for collecting and using the personal data will depend on the 
    personal data concerned and the specific context in which we collect it. Our Company will  generally collect personal data from you 
    or a third party only where: (a) we have your consent to do so, (b) where we need the personal data to perform a contract with you 
    (e.g. to deliver the services you  have requested), or (c) where the processing is in our or a third party’s legitimate interests 
    (and not overridden by your data protection interests or fundamental rights and freedoms).
</Typography>

<Typography variant="h3">
    4. OUR USE OF YOUR PERSONAL DATA
</Typography>
<Typography m="1rem">
Our Company may use information that we collect about you to: Fulfil our contract with you and provide you with our Services, such as:

<ul>
<li>delivering the products and services that you have requested;</li>
<li>managing your customer relationship and provide you with customer support;</li>
<li>communicating with you by email, postal mail, telephone or mobile devices about products or services that may be of interest to you either from 
us, or other third parties;</li>
<li>undertaking activities to verify or maintain the quality or safety of a service or device;</li>
<li>processing your financial information and other payment methods for products or Services purchased;</li>
<li>managing our business;</li>
<li>allowing you to register for events</li>

<Typography variant="h4" mt="1rem" mb="1rem">
    Analyze and improve our Services pursuant to our legitimate interest, such as:
</Typography>

<li>detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those 
responsible for that activity;</li>
<li>performing research and analysis about your use of, or interest in, our Services or content offered by others, and short-term, 
transient use, such as contextual customization of ads;</li>
<li>undertaking research for technological development and demonstration;</li>
<li>improving, upgrading or enhancing our Services or device or those of our Providers;</li>
<li>verifying your identity and preventing fraud;</li>
<li>debugging to identify and repair errors that impair existing intended functionality, and
enforcing our terms and conditions</li>

<Typography variant="h4" mt="1rem" mb="1rem">
    Provide you with additional content and Services, such as:
</Typography>

<li>developing and display content and advertising tailored to your interests on our websites or mobile applications;</li>
<li>auditing relating to interactions, transactions and other compliance activities;</li>
<li>verifying your eligibility and deliver prizes in connection with promotions, and
performing functions you consent to or that are otherwise described to you at the time of collection.</li>

</ul>

Use De-identified and Aggregated Information.  We may use personal data and other data about you to create de-identified and aggregated 
information, such as de-identified demographic information, de-identified location information, information about the computer or device 
from which you access our Services, or other analyses we create. Share Content with Friends or Colleagues.  Our Services may offer various
 tools and functionalities.  For example, we may allow you to provide information about your friends or colleagues through our referral services. 
  Our referral services may allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use 
  our Services. Process Information on Behalf of Our Customers (as processors).   Our customers may choose to use our Services to process 
  certain data of their own, which may contain personal data.  The data that we process through our Services is processed by us on behalf of our 
  customer, and our privacy practices will be governed by the contracts that we have in place with our customers, not this Privacy Policy. 
  If you have any questions or concerns about how such data is handled or would like to exercise your rights, you should contact the person or 
  entity (i.e., the data controller) who has contracted with us to use the Service to process this data.  Our customers control the personal 
  data in these cases and determine the security settings within the account, its access controls and credentials.  We will, however, provide 
  assistance to our customers to address any concerns you may have, in accordance with the terms of our contract with them.  For a list of our 
  sub-processors, contact us as described below.

</Typography>

<Typography variant="h3">
    5. OUR DISCLOSURE OF YOUR PERSONAL DATA TO THIRD PARTIES
</Typography>

<Typography m="1rem">

    <ul>
        <li>We may share your personal data with third parties only in the ways that are described in this Privacy Policy. 
        Below is a list of categories of personal data (from the California Consumer Privacy Act) we have collected and disclosed  about consumers 
        for a business purpose in the past 12 months:</li>

        <li>Category A: Identifiers;</li>
        <li>Category B: Personal Data categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e));</li>
        <li>Category D: Commercial information;</li>
        <li>Category F: Internet or other electronic network activity;</li>
        <li>Category G: Geolocation data;</li>
        <li>Category I: Professional or employment-related information.</li>
    </ul>
<Typography fontWeight="bold">
    We have not sold consumers’ personal information in the preceding 12 months.
</Typography>

    <ul>
        <li>Affiliates and Service Providers. we may provide your information to affiliated entities within our corporate group, as well as with vendors 
        who perform functions on our behalf; such parties provide hosting and maintenance services, virtual infrastructure, payment processing, analysis 
        and other services for us;</li>
        <li>Contractors. third party contractors may have access to our databases.  Usually these contractors sign a standard confidentiality agreement;</li>
        <li>Business Partners. we may share your data with any parent company, subsidiaries, joint ventures, other entities under a common control or 
        third-party acquirers. We expect these other entities will honor this Privacy Policy;</li>
        <li>Disclosure for Merger, Sale or Other Asset Transfer. we may allow a potential acquirer or merger partner to review our databases, 
        although we would restrict their use and disclosure of this data during the diligence phase;</li>
        <li>Disclosure to Protect Us or Others. as required by law enforcement, government officials, or other third parties pursuant to a subpoena, 
        court order, or other legal process or requirement applicable to our Company; or when we believe, in our sole discretion, that the disclosure
        of personal data is necessary to prevent physical harm or financial loss; to report suspected illegal activity or to investigate violations 
        of our agreements or Company policies; to enforce our terms and conditions, to protect the security or integrity of our products and services, 
        and</li>
        <li>Consent. when you give us permission to do so.</li>

    </ul>

    Please note that these third parties may be in other countries where the laws on processing personal data may be less stringent than in your 
    country. Potential for Other Users to Contact You To the extent that a given application supports personal messaging functionality between and 
    among end users, you may receive personal messages from other end users.  You can disable this functionality by using the unsubscribe and other 
    disabling instructions in the given application.

</Typography>

<Typography variant="h3">
    6. OUR SECURITY MEASURES TO PROTECT YOUR PERSONAL DATA
</Typography>
<Typography m="1rem">
    Our Company uses industry-standard technologies when transferring and receiving data exchanged between our Company and other 
    companies to help ensure its security. This site has security measures in place to help protect information under our control from the 
    risk of accidental or unlawful destruction or accidental loss, alteration or unauthorized disclosure or access. However, “perfect security” 
    does not exist on the Internet.  Also, if this website contains links to other sites, our Company is not responsible for the security practices
     or the content of such sites.
</Typography>

<Typography variant="h3">
    7. OUR USE OF AUTOMATIC COLLECTION TECHNOLOGIES
</Typography>

<Typography m="1rem">
    
    Automatic Collection Technologies. We, as well as third parties that provide content, advertising, or other functionality on the Services, 
    may use cookies, pixel tags, local storage, and other technologies to automatically collect information through the Services. 
    Our uses of these Technologies fall into the following general categories:
    <ul>
    <li>Operationally Necessary.  This includes Technologies that allow you access to our Services, applications, and tools that are required to 
    identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functionality;</li>
    <li>Performance Related.  We may use Technologies to assess the performance of our Services, including as part of our analytic practices to 
    help us understand how our visitors use the Services;</li>
    <li>Functionality Related.  We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services.  
    This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items 
    viewed;</li>
    <li>Advertising or Targeting Related.  We may use first party or third-party Technologies to deliver content, including ads relevant to your 
    interests, on our Services or on third party sites.</li>
    
    </ul>
    Website Links. We may create links to other websites. We will make a reasonable effort to link only to sites that meet similar standards 
    for maintaining each individual’s right to privacy. However, many other sites that are not associated with or authorized by our Company may 
    have links leading to our site. Our Company cannot control these links and we are not responsible for any content appearing on these sites. 
    Since this website does not control the privacy policies of third parties, you are subject to the privacy practices of that third party. We 
    encourage you to ask questions before you disclose any personal data to others. App Links. A given application may provide you with an 
    advertising link to other, third-party sites and applications.  Such third-party sites will have their own data collection practices and
     policies.  Please review the privacy policy for such sites and exercise caution in providing information that personally identifies you.  
     We have no responsibility, access, or control over the data collection practices and policies of such third-party sites and applications.  
     You will use such sites and applications at your own risk and outside of the scope of this Privacy Policy.  The provision of such 
     advertisements is done on an “As Is” basis, with no endorsements or representations. Cross-Device Tracking.  Your browsing activity may be 
     tracked across different websites and different devices or apps.  For example, we may attempt to match your browsing activity on your mobile 
     device with your browsing activity on your laptop.  To do this our technology partners may share data, such as your browsing patterns, 
     geo-location and device identifiers, and will match the information of the browser and devices that appear to be used by the same person.
      Notice Regarding Third Party Websites, Social Media Platforms and Software Development Kits. Our Company websites and mobile apps may use 
      third parties to present or serve the advertisements that you may see at its web pages and to conduct research about the advertisements and 
      web usage. This Privacy Policy does not cover any use of information that such third parties may have collected from you or the methods
     used by the third parties to collect that information. We do not endorse, screen or approve and are not responsible for the privacy practices 
     or content of such other websites or applications.  Visiting these other websites or applications is at your own risk. Our Services may 
     include publicly accessible blogs, forums, social media pages, and private messaging features.  By using such Services, you assume the risk 
     that the personal data provided by you may be viewed and used by third parties for any number of purposes.  In addition, social media buttons 
     such as Twitter and LinkedIn (that might include widgets such as the “share this” button or other interactive mini-programs) may be on our 
     site.  These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to 
     function properly.  These social media features are either hosted by a third party or hosted directly on our site.  Your interactions with 
     these features apart from your visit to our site are governed by the privacy policy of the company providing it. We may use third party APIs 
     and software development kits (“SDKs”) as part of the functionality of our Services.  APIs and SDKs may allow third parties including 
     analytics and advertising partners to collect your personal data for various purposes including to provide analytics services and content 
     that is more relevant to you.  For more information about our use of APIs and SDKs, please contact us as set forth below.

</Typography>


<Typography variant="h3">
    8. LIMITING USE, DISCLOSURE, RETENTION
</Typography>
<Typography m="1rem">
    Whenever applicable, our Company identifies the purposes for which the information is being collected before or at the time of collection. 
    The collection of your personal data will be limited to that which is needed for the purposes identified by our Company. Unless you consent, 
    or we are required by law, we will only use the personal data for the purposes for which it was collected. If our Company will be processing 
    your personal data for another purpose later on, our Company will seek your further legal permission or consent; except where the other 
    purpose is compatible with the original purpose. We will keep your personal data only as long as required to serve those purposes. We will 
    also retain and use your personal data for as long as necessary to comply with our legal obligations, resolve disputes, and enforce our 
    agreements.
</Typography>

<Typography variant="h3">
    9. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA
</Typography>
<Typography m="1rem">
    We are a global company. Information about you may be stored and processed in the European Economic Area, the United States or any other 
    country in which our Company or agents or contractors maintain facilities, and by accessing our websites and using our mobile apps,
     software and services, you consent to the transfer of your information outside of your country. Such countries may have laws which are 
     different, and potentially not as protective as the laws of your own country.
</Typography>

<Typography variant="h3">
    10. ACCURACY OF PERSONAL DATA
</Typography>
<Typography m="1rem">
    We do our best to ensure that the personal data we hold and use is accurate. We rely on the customers we do business with to disclose to us 
    all relevant information and to inform us of any changes.
</Typography>

<Typography variant="h3">
    11. YOUR ACCESS TO AND UPDATING OF YOUR PERSONAL DATA
</Typography>

<Typography m="1rem">
    Reasonable access to your personal data may be provided upon request made to our Company at the contact information provided below. 
    If access cannot be provided within that time frame, our Company will provide the requesting party a date when the information will be 
    rovided. If for some reason access is denied, we will provide an explanation as to why access has been denied. We may charge a reasonable 
    fee in advance for copying and sending the information requested. If you would like us to delete any personal data held about you, we will 
    do so on request unless we need to hold the information as part of the provision of products and services to you.
</Typography>

<Typography variant="h3">
    12. YOUR CHOICES
</Typography>
<Typography m="1rem">
    We offer those who provide personal data a means to choose how we use the information provided. Where you have consented to the processing 
    of your personal data, you may withdraw that consent at any time and prevent further processing by contacting us as described below.  
    Even if you opt out, we may still collect and use non- personal data regarding your activities on our Services and for other legal purposes 
    as described above. Marketing. You may manage your receipt of marketing and non- transactional communications by clicking on the “unsubscribe” 
    link located on the bottom of our marketing emails. Note that you will continue to receive transaction-related emails regarding products or 
    Services you have requested.  We may also send you certain non-promotional communications regarding us and our Services, and you will not 
    beable to opt out of those communications (e.g., communications regarding the Services or updates to our Terms or this Privacy Policy). 
    We process requests to be placed on do-not-mail, do-not-phone and do-not-contact lists as required by applicable law. Mobile Devices. 
    We may send you push notifications through our mobile application.  You may at any time opt- out from receiving these types of communications 
    by changing the settings on your mobile device.  We may also collect location-based information if you use our mobile applications.  
    You may opt-out of this collection by changing the settings on your mobile device. “Do Not Track.” Do Not Track (“DNT”) is a privacy 
    preference that users can set in certain web browsers.  Please note that we do not respond to or honor DNT signals or similar mechanisms 
    transmitted by web browsers. Cookies and Interest-Based Advertising. You may stop or restrict the placement of Technologies on your device 
    or remove them by adjusting your preferences as your browser or device permits.  The online advertising industry also provides websites from 
    which you may opt out of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory programs. 
     You can access these and learn more about targeted advertising and consumer choice and privacy, at 
     https://optout.networkadvertising.org/, https://www.youronlinechoices.eu/, https://youradchoices.ca/choices/, and 
     https://www.aboutads.info/choices/.  
     To separately make choices for mobile apps on a mobile device, you can download DAA’s AppChoices application from your device’s app store.  
     Alternatively, for some devices you may use your device’s platform controls in your settings to exercise choice. Please note you must separately
      opt out in each browser and on each device.  Advertisements on third party websites that contain the AdChoices link may have been directed 
      to you based on information collected by advertising partners over time and across websites.  These advertisements provide a mechanism to 
      opt out of the advertising partners’ use of this information for interest-based advertising purposes.
</Typography>

<Typography variant="h3">
    13. YOUR PRIVACY RIGHTS
</Typography>
<Typography m="1rem">
    Depending on where you reside, you may have the right to exercise additional rights available to you under applicable laws, including:

    <ul>
        <li>Right of erasure. You may have a broader right to erasure of personal data that we hold about you. For example, if it is no longer necessary in 
        relation to the purposes for which it was originally collected. Please note, however, that we may need to retain certain information for 
        record keeping purposes, to complete transactions or to comply with our legal obligations, among other things.</li>

        <li>Right to object to processing. You may have the right to request that we stop processing your personal data, including the right to opt in or 
        opt out of the sale of your Personal Data to third parties, or to stop sending you marketing communications.</li>

        <li>Right to restrict processing. You may have the right to request that we restrict processing of your personal data in certain circumstances. 
        For example, where you believe that the personal data we hold about you is inaccurate or unlawfully held.</li>

        <li>Right to data portability: In certain circumstances, you may have the right to be provided with your personal data in a structured, 
        machine readable and commonly used format and to request that we transfer the personal data to another data controller without hindrance.</li>

    </ul>

    If you would like to exercise any of the above rights, please contact our support team or contact our Data Protection Officer 
    (see our contact details in the “Contacting Us” Section below). We will consider your request in accordance with applicable laws. 
    To protect your privacy and security, we may take steps to verify your identity before complying with the request. You may also have the 
    right to make a GDPR complaint to the relevant Supervisory Authority. A list of Supervisory Authorities is available here: 
    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.  If you need further assistance regarding your rights, please 
    contact us using the contact information provided below and we will consider your request in accordance with applicable law. In some cases, 
    our ability to uphold these rights for you may depend upon our obligations to process personal data for security, safety, fraud prevention 
    reasons, compliance with regulatory or legal requirements, or because processing is necessary to deliver the services you have requested. Where 
    this is the case, we will inform you of specific details in response to your request.

</Typography>

<Typography variant="h3">
    14. ADDITIONAL CALIFORNIA PRIVACY RIGHTS (UNITED STATES ONLY)
</Typography>
<Typography m="1rem">
    If you are a California resident, the California Consumer Privacy Act may entitle you to certain rights under Section 13 in 
    addition to the disclosures about data collection, use and sharing contained herein. Also, California Civil Code Section 1798.83 
    permits you to request certain information regarding our disclosure of personal data to third parties for the third parties’ direct 
    marketing purposes. To make such a request, please contact us by sending an email to customersuccess@OpsBlox.io.com. Our site, products, 
    and services are not intended to appeal to minors. However, if you are a California resident under the age of 18, and a registered user 
    of our Site or Service, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or 
    information you have publicly posted. To make such a request, please send an email with a detailed description of the specific content or 
    information to customersuccess@OpsBlox.io.com. We will process your request in compliance with applicable law. Under California law, 
    California residents who have an established business relationship with us may opt-out of our disclosing personal data about them to third 
    parties for their marketing purposes.
</Typography>

<Typography variant="h3">
    15. NOTICE TO END USERS
</Typography>
<Typography m="1rem">

    Most of our services are intended for use by organizations. Where the services are made available to you through an organization 
    (e.g. your employer), that organization is the administrator of the services and is responsible for the accounts and/or service over 
    which it has control. Please direct your data privacy questions to your administrator, as your use of the services is subject to that o
    rganization’s policies. We are not responsible for the privacy or security practices of an administrator’s organization, which may be different 
    from this policy. Administrators may be able to:

    <ul>
        <li>help you exercise the rights described in “Your Privacy Rights” Section (if applicable);</li>
        <li>allow you to reset your account password;</li>
        <li>restrict, suspend or terminate your access to the services;</li>
        <li>access information in and about your account;</li>
        <li>access or retain information stored as part of your account;</li>
        <li>change your information, including profile information associated with your account; and</li>
        <li>allow you or restrict your ability to edit, restrict, modify or delete information.</li>
    </ul>

    Please contact your organization or refer to your administrator’s organizational policies for more information.

</Typography>

<Typography variant="h3">
    16. CHILDREN’S PRIVACY
</Typography>
<Typography m="1rem">

    Because of the nature of our business, our services are not designed to appeal to minors. We do not knowingly attempt to 
    solicit or receive any information from anyone under the age of 17 (or other age as required by local law). If you are a parent or 
    guardian and you are aware that your child has provided us with personal data, please contact us immediately. If we learn that we have 
    collected any personal data in violation of applicable law, we will promptly take steps to delete such information and terminate the child’s 
    account.

</Typography>


<Typography variant="h3">
    17. CHANGES TO OUR PRIVACY POLICY
</Typography>

<Typography m="1rem">
    Our Company may amend this Privacy Policy at any time by posting a new version. It is your responsibility to review this Privacy Policy periodically as your continued use of this website and our products and services represents your agreement with the then-current Privacy Policy.
</Typography>

<Typography variant="h3">
    18. CONTACTING US
</Typography>
<Typography m="1rem">
    OpsBlox.io<br/>

    Data Protection Officer<br/><br/>

    31 Grosvner Road<br/>
    Reading<br/>
    UK<br/><br/>
    E-mail: privacy@opsblox.io<br/>

</Typography> 

<Typography >
    Last Updated: 12/01/2023
</Typography>

</Box>
  );
};

export default Privacy;