import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { tokens } from "../../theme";
import NiceDate from "../../components/global/NiceDateOnly";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { getUser } from "../../slices/user";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useParams, useSearchParams } from "react-router-dom";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import PercentIcon from "@mui/icons-material/Percent";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import OnDeviceTrainingIcon from "@mui/icons-material/OnDeviceTraining";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import LightModeIcon from "@mui/icons-material/LightMode";
import { getOrganization, updateOrganization } from "../../slices/organization";
import { setMessage, clearMessage } from "../../slices/message";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Header from "../../components/global/Header";

import useInterval from "../../common/useInterval";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Organization = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const Organization = useSelector((state) =>
    state.organizations.data.list?.find((org) => org.id === id)
  );

  const [tabValue, setTabValue] = React.useState(1);

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!Organization) {
      //need to get the Organization form the API
      dispatch(getOrganization({ id }));
    }

    //if there are no users then we can go get it
    // if (!adminUser && Organization){
    //   let username = Organization?.administrator
    //   dispatch(getUser({username}));
    // }
  }, [Organization]);

  return (
    <Box m="1.5rem 2.5rem" height="75vh">
      <Header title={Organization?.name} subtitle={Organization?.id} />

      <Box
        display="flex"
        flexDirection="row"
        backgroundColor={theme.palette.background.alt}
        padding="15px"
        borderRadius="0.55rem"
        gap="2rem"
      >

        <Box
            display = "flex"
            flexDirection="column"
            padding="15px"
        >
            <Typography>Domain: {Organization?.domain}</Typography>
            <Typography>Status: {Organization?.status}</Typography>
            <Typography>Active: {Organization?.active}</Typography>
        </Box>

        <Box
            display = "flex"
            flexDirection="column"
            padding="15px"
        >
            <Typography>Created: <NiceDate>{Organization?.createdAt}</NiceDate></Typography>
            <Typography>Modified: <NiceDate>{Organization?.modifiedAt}</NiceDate></Typography>
        </Box>


      </Box>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Organization Tabs"
          >
            <Tab label="Global Orechestrators" {...a11yProps(0)} />
            <Tab label="Global Users" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>Orchestrators</TabPanel>

        <TabPanel value={tabValue} index={1}>users</TabPanel>
      </Box>
    </Box>
  );
};

export default Organization;
