import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetKpiAggregateValues,
  getWidgetUtilityAggregateValues,
} from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";
import moment from "moment";
import {
  getBillingPeriodForReportingPeriod,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
  processStringForDashboardFilters,
} from "../../../common/helpers";
import { useSearchParams } from "react-router-dom";

const StatBox = ({
  name,
  icon,
  top,
  left,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  //console.log("Passed config: ", config);

  const title = config?.title;
  const serviceType = config?.serviceType;
  const description = config?.description;
  const currencyField = config?.currencyField;
  const valueField = config?.valueField;

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const renderTitle = processStringForDashboardFilters(title, renderState);

  //calculate the when range based on the selected dimension
  const period = parsePeriod(config?.period, searchParams);

  const billingPeriod = getBillingPeriodForReportingPeriod(
    period
  );

  const dimension = parseDimension(config?.dimension, searchParams);

  const filter = getWhenFilterForReportingPeriod(period);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard && widgetData.widget === widget
    )
  );

  // console.log("Widget data raw ", widgetDataRaw)
  // console.log("Widget data ", widgetData)

  useEffect(() => {
    //debugger

    if (config) {
      //need to augment the filter, we have the filter from the periods now add what is set in the renderstate

      let localFilter = filter;

      if (widget.userFilters) {
        localFilter = localFilter + "|" + renderState.filter;
      }

      //debugger;

      let search = {
        dashboard: dashboard,
        widget: widget,

        function: config.function,
        id: config.implementorId,
        fields: valueField,
        dimension: dimension,
        descriptors: currencyField,
        billingPeriod: billingPeriod,
      };

      // Split the string on '|'
      const parts = localFilter?.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts?.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts?.join("|");

      //debugger;

      switch (serviceType) {
        case "Kpi":
          console.log("Dispatching getWidgetKpiAggregateValues:", search);
          dispatch(getWidgetKpiAggregateValues({ search }));

          break;

        case "Utility":
          console.log("Dispatching getWidgetUtilityAggregateValues:", search);
          dispatch(getWidgetUtilityAggregateValues({ search }));

          break;
      }

      search = {
        dashboard: dashboard,
        widget: widget,
        id: config.implementorId,
        serviceType: serviceType,
      };

      console.log("Dispatching getWidgetColumnValues:", search);
      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dashboard, widget, config, refreshPoint]);

  //debugger

  const value = widgetData?.rows[0]?.[valueField];
  const currency = widgetData?.rows[0]?.["descriptor_" + currencyField];

  // if (valueField === "when"){
  // debugger
  // }

  if (!widgetData?.rows || !config) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      gridColumn={`${left} / span ${columns}`}
      gridRow={`${top} /span ${rows}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      sx={{ border: "1px solid", borderColor: theme.palette.grey[300] }}
      borderRadius="0.55rem"
    >
      <FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.text.main }}>
          {renderTitle}
        </Typography>
        {icon}
      </FlexBetween>

      <Typography
        variant="h3"
        fontWeight="600"
        sx={{ color: theme.palette.text.main }}
      >
        {currencyField !== "" ? (
          <NiceCurrency currencyCode={currency}>{value}</NiceCurrency>
        ) : (
          { value }
        )}
      </Typography>
      <FlexBetween gap="1rem">
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default StatBox;
