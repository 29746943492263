import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import JobService from "../services/job.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false};

export const stopJob = createAsyncThunk("Job/top", async ({ id }, thunkAPI) => {
  try {
    const data = await JobService.stopJob(id);
    return { data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
    return thunkAPI.rejectWithValue();
  }
});

export const updateJob = createAsyncThunk(
  "Job/update",
  async ({ org }, thunkAPI) => {
    try {
      const data = await JobService.updateJob(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getJobs = createAsyncThunk(
  "Job/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await JobService.getJobs(search);
      return { jobs: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getJob = createAsyncThunk("Job/get", async ({ id }, thunkAPI) => {
  try {
    const data = await JobService.getJob(id);
    return { Job: data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
    return thunkAPI.rejectWithValue();
  }
});

export const getJobLogs = createAsyncThunk(
  "JobLog/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await JobService.getJobLogs(search);
      return { logs: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getJobFilterValues = createAsyncThunk(
  "Job/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await JobService.getJobFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getJobLogFilterValues = createAsyncThunk(
  "Job/get_log_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await JobService.getJobLogFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  data: list,
  loading: false,
  logs: list,
  filter_values: [],
};

const JobSlice = createSlice({
  name: "Jobs",
  initialState,
  extraReducers: {
    [getJobs.pending]: (state, action) => {
      state.loading = true;
    },
    [getJobs.fulfilled]: (state, action) => {
      state.data = action.payload.jobs;
      state.loading = false;
    },
    [getJobs.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getJobFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getJobFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getJobFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },

    [getJobLogFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getJobLogFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getJobLogFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },
    
    [getJobLogs.pending]: (state, action) => {
      state.loading = true;
    },
    [getJobLogs.fulfilled]: (state, action) => {
      state.logs = action.payload.logs;
      state.loading = false;
    },
    [getJobLogs.rejected]: (state, action) => {
      state.logs = list;
      state.loading = false;
    },

    [stopJob.pending]: (state, action) => {
      state.loading = true;
    },
    [stopJob.fulfilled]: (state, action) => {
      debugger;

      let jobs = state.data.list;
      let thisJob = jobs.find((job) => job.id === action.payload.data.id);

      thisJob.modifiedAt = action.payload.data.modifiedAt;
      thisJob.status = action.payload.data.status;
    },

    [updateJob.fulfilled]: (state, action) => {
      debugger;

      let organizations = state.data.list;
      let thisOrg = organizations.find(
        (org) => org.id === action.payload.data.id
      );

      thisOrg.name = action.payload.data.name;
      thisOrg.domain = action.payload.data.domain;
      thisOrg.active = action.payload.data.active;
    },

    [getJob.pending]: (state, action) => {
      state.loading = true;
    },
    [getJob.fulfilled]: (state, action) => {
      const newPayload = action.payload.Job;

      const existingIndex = state.data.list.findIndex(
        (payload) => payload.id === newPayload.id
      );

      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }

      state.loading = false;
    },
    [getJob.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = JobSlice;
export default reducer;
