import api from "./api";



const getSearchPrompts = (search) => {
  return api
    .get("/search/prompt", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getSearchSummary = (search) => {
  return api
    .get("/search/summary", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};


const getSearchPrompt = (id) => {
  return api.get("/search/prompt/" + id).then((response) => {
    return response.data;
  });
};


const createSearchPrompt = (step) => {
  return api.post("/search/prompt", {
    ...step,
  });
};




const getSearchPromptFilterValues = (search) => {

  return api.get("/search/prompt/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getSearchPromptUi = () => {
  return api.get("/search/prompt/ui")
  .then((response) => {
    return response.data;
  });
};


const updateSearchFeedback = (prompt) => {
  return api
    .patch("/search/prompt", {
      ...prompt,
    })
    .then((response) => {
      return response.data;
    });
};



const SearchService = {
  updateSearchFeedback,
  getSearchPrompts,

  getSearchPrompt,
  createSearchPrompt,

  getSearchPromptFilterValues,

  getSearchPromptUi,
  getSearchSummary,

};

export default SearchService;
