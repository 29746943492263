import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import ScheduleService from "../services/schedule.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0
};


//const users = {data: list, loading: false};


export const updateSchedule = createAsyncThunk(
  "Schedule/update",
  async ( {schedule},thunkAPI) => {
    try {
      const data = await ScheduleService.updateSchedule(schedule);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createSchedule = createAsyncThunk(
  "Schedule/create",
  async ({ schedule }, thunkAPI) => {
    try {
      debugger
      const data = await ScheduleService.createSchedule(schedule);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSchedules = createAsyncThunk(
  "Schedule/get_list",
  async ({search}, thunkAPI) => {
    try {

      const data = await ScheduleService.getSchedules(search);
      return { Schedules: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSchedule = createAsyncThunk(
  "Schedule/get",
  async ({id}, thunkAPI) => {
    try {
      const data = await ScheduleService.getSchedule(id);
      return { Schedules: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateScheduleActive = createAsyncThunk(
  "Schedule/update_active",
  async ({ schedule }, thunkAPI) => {
    try {
      const data = await ScheduleService.updateScheduleActive(schedule);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  "Schedule/delete",
  
  async ({ id }, thunkAPI) => {
    try {

      const data = await ScheduleService.deleteSchedule(id);
      return { Schedule : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {data: list, loading: false, }

const ScheduleSlice = createSlice({
  name: "Schedules",
  initialState,
  extraReducers: {

    [deleteSchedule.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteSchedule.fulfilled]: (state, action) => {

      const updatedList = state.data.list.filter((schedule) => schedule.id !== action.payload.Schedule.id);
      
      // Update the state with the filtered list
      state.data.list = updatedList;
      
      // Set loading to false
      state.loading = false;
    },
    
    [deleteSchedule.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getSchedules.pending]: (state, action) => {
      state.loading = true;
    },
    [getSchedules.fulfilled]: (state, action) => {
      state.data = action.payload.Schedules; 
      state.loading = false;
    },
    [getSchedules.rejected]: (state, action) => {
      state.data = list
      state.loading = false;
    },

    [createSchedule.pending]: (state, action) => {
      state.loading = true;
    },
    [createSchedule.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.data.data);
      state.data.total ++;
      state.loading = false;
    },
    [createSchedule.rejected]: (state, action) => {
      state.loading = false;
    },

    [getSchedule.pending]: (state, action) => {
      state.loading = true;
    },
    [getSchedule.fulfilled]: (state, action) => {


      state.data.list.push(action.payload.Schedules)
      state.data.total ++;

      state.loading = false;
    },
    [getSchedule.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateScheduleActive.fulfilled]: (state, action) => {
      let schedules = state.data.list;
      let thisSchedule = schedules.find(
        (schedule) => schedule.id === action.payload.data.id
      );
      thisSchedule.active = action.payload.data.active;
    },


  },
});


const { reducer } = ScheduleSlice;
export default reducer;