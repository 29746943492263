import { Box, Typography } from "@mui/material";

const DataGridTwoLineHeader = (line1, line2) => {

return (
    <Box className="MuiDataGrid-columnHeaderTitle css-1jbbcbn-MuiDataGrid-columnHeaderTitle" display={"flex"} flexDirection={"column"} height={"50"}>
      <Typography variant="inherit" component="span" fontWeight={"bold"} lineHeight={"25px"}>
        {line1}
      </Typography>
      <Typography  variant="inherit"  component="span" lineHeight={"25px"} >
        {line2}
      </Typography>
    </Box>
  );
}

export default DataGridTwoLineHeader