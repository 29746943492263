import api from "./api";

const updateCloudOrgStructureElement = (cloudOrgStructure) => {
  return api
    .patch("/cloudorgstructure", {
      ...cloudOrgStructure,
    })
    .then((response) => {
      return response.data;
    });
};


const getCloudOrgStructure = (search) => {
  return api
    .get("/cloudorgstructure", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getOpportunitiyLogs = (search) => {
  return api
    .get("/cloudorgstructure/log", {
      params: {
        ...search,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getCloudOrgStructureElement = (id) => {

  
  return api.get("/cloudorgstructure/" + id).then((response) => {
    return response.data;
  });
};

const getCloudOrgStructureByType = ({search}) => {

  return api.get("/cloudorgstructurebytype/" + search.resourceType + "/" + search.wantedResource).then((response) => {
    return response.data;
  });
};

const createCloudOrgStructureElement = (CloudOrgStructureElement) => {
  return api.post("/cloudorgstructure", {
    ...CloudOrgStructureElement,
  });
};

const getCloudOrgStructureFilterValues = (search) => {

  return api.get("/cloudorgstructure/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const runCloudOrgStructure = () => {
  return api.get("/cloudorgstructure/run")
  .then((response) => {
    return response.data;
  });
};


const getCloudOrgStructureUi = () => {
  return api.get("/cloudorgstructure/ui")
  .then((response) => {
    return response.data;
  });
};

const CloudOrgStructureService = {
  getCloudOrgStructureElement,
  updateCloudOrgStructureElement,
  getCloudOrgStructure,
  getOpportunitiyLogs,
  getCloudOrgStructureFilterValues,
  runCloudOrgStructure,
  createCloudOrgStructureElement,
  getCloudOrgStructureByType,
  getCloudOrgStructureUi,

};

export default CloudOrgStructureService;
