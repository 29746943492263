import { createSlice } from "@reduxjs/toolkit";

const persistConfig = (config) => {
  localStorage.setItem("tablePage", JSON.stringify(config));
};

const tablePageDefault = {
  leadObject: null,
  tab: null,
  filters: null
}

const config = JSON.parse(localStorage.getItem("tablePage")) || tablePageDefault;

const initialState = { config };

const tablePageSlice = createSlice({
  name: "tablePage",
  initialState,
  reducers: {
    setTablePageLeadObject: (state, action) => {
      state.config.leadObject = action.payload;
      persistConfig(state.config);
    },
    setTablePageTab: (state, action) => {
      state.config.tab = action.payload;
      persistConfig(state.config);
    },

    setTablePageFilters: (state, action) => {
      state.config.filters = action.payload;
      persistConfig(state.config);
    },

    setTablePageKpi: (state, action) => {
      state.config.kpi = action.payload;
      persistConfig(state.config);
    },

    setTablePageDimension: (state, action) => {
      state.config.dimension = action.payload;
      persistConfig(state.config);
    },

    setTablePageMasterIfField: (state, action) => {
      state.config.masterIdField = action.payload;
      persistConfig(state.config);
    },

    setTablePageMasterId: (state, action) => {
      state.config.masterId = action.payload;
      persistConfig(state.config);
    },

    setTablePageServiceType: (state, action) => {
      state.config.serviceType = action.payload;
      persistConfig(state.config);
    },

    setTablePageStateSetId: (state, action) => {
      state.config.stateSetId = action.payload;
      persistConfig(state.config);
    },
    setTablePageBillingPeriod: (state, action) => {
      state.config.BillingPeriod = action.payload;
      persistConfig(state.config);
    },
  },
});

const { reducer, actions } = tablePageSlice;

export const {
  setTablePageLeadObject,
  setTablePageTab,
  setTablePageDimension,
  setTablePageFilters,
  setTablePageKpi,
  setTablePageMasterId,
  setTablePageMasterIfField,
  setTablePageServiceType,
  setTablePageStateSetId,
  setTablePageBillingPeriod,
} = actions;
export default reducer;
