import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { tokens } from "../../theme";
import NiceDate from "../../components/global/NiceDateOnly";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { getBlox, updateBlox, getBloxData, getBloxUi } from "../../slices/blox";
import { setMessage, clearMessage } from "../../slices/message";

import Header from "../../components/global/Header";

import useInterval from "../../common/useInterval";
import PropTypes from "prop-types";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import FlexBetween from "components/global/FlexBetween";
import { RefreshOutlined, StopOutlined } from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import KeyVal from "components/global/KeyVal";
import NiceDuration from "components/global/NiceDuration";
import TablePage from "components/global/TablePage";
import NiceLink from "components/global/NiceLink";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import NiceOrganization from "components/global/NiceOrganization";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Blox = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const Blox = useSelector((state) =>
    state.blox.data.list?.find((org) => org.id === id)
  );

  const Blox_data = useSelector((state) => state.blox.blox_data);

  const Blox_UI = useSelector((state) => state.blox.bloxUi);

  //console.log("Blox UI: ", Blox_UI);

  const [tabValue, setTabValue] = React.useState(0);

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!Blox) {
      //need to get the Blox form the API
      dispatch(getBlox({ id }));
    }


      //need to get the Blox UI form the API
      dispatch(getBloxUi({ id }));

  }, [Blox]);

  let columns = [];

  if (Blox_UI?.columns) {
    columns = Blox_UI.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object
      if (updatedColumn.renderCell) {
        // check if renderCell exists
        updatedColumn.renderCell = ({ row }) => {
          //debugger;

          switch (column.renderCell) {
            case "<NiceOrchestrator>":
              return <NiceOrchestrator>{row[column.field]}</NiceOrchestrator>;
              case "<NiceOrganization>":
                return <NiceOrganization>{row[column.field]}</NiceOrganization>;
                case "<NiceDate>":
                  return <NiceDate>{row[column.field]}</NiceDate>;
            default:
              //this is an error so remove the render, will keep it safe
              return "";
          }
        };
      }
      return updatedColumn;
    });
  }

  console.log("updated: ", columns);

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Blox?.name} subtitle={Blox?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary">
            <RefreshOutlined />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<StopOutlined />}
          >
            Stop
          </Button>
        </FlexBetween>
      </FlexBetween>

      <DetailBlock>
        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>{Blox?.description}</Typography>
          <Typography>Blox Type: {Blox?.type}</Typography>
          <Typography>
            Last Run:{" "}
            {Blox?.active ? <NiceDate>{Blox?.lastRun}</NiceDate> : "n/a"}
          </Typography>
          <Typography>
            Identified Last Time:{" "}
            {Blox?.active ? Blox?.opertunitiesLastTime : "n/a"}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>
            Created: <NiceDate>{Blox?.createdAt}</NiceDate>
          </Typography>
          <Typography>
            Modified: <NiceDate>{Blox?.modifiedAt}</NiceDate>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>
            Active: {Blox?.active ? "Active" : "Not Running at Orchestrator"}
          </Typography>

          <KeyVal
            label="Job Status"
            value={Blox?.jobStatus}
            link={`/job/${Blox?.jobId}`}
            tip={`Open Job id:${Blox?.jobId}`}
            makeBlue
          />
        </Box>
      </DetailBlock>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Blox Tabs"
          >
            <Tab
              label={`Opertunities (${Blox_data.total})`}
              {...a11yProps(0)}
            />
            <Tab label="Configuration" {...a11yProps(1)} />
            <Tab label="Schedules" {...a11yProps(2)} />
            <Tab label="History" {...a11yProps(3)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          {Blox_data && Blox_UI ? (
            <TablePage
              masterId={id}
              dataRows={Blox_data}
              dataColumns={columns}
              dataGetter={getBloxData}
              defaultColumnsVisability={Blox_UI.columnsVisability}
              defaultSort={Blox_UI.sort}
            />
          ) : (
            <Typography>Loading</Typography>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          Config
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          Schedule
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          History
        </TabPanel>
      </Box>
    </PageBlock>
  );
};

export default Blox;
