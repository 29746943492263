import api from "./api";







const getBackups = (search) => {

  return api.get("/backup",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getBackup = (id) => {
  return api.get("/backup/"+id)
  .then((response) => {
    return response.data;
  });
};

const downloadBackup = (id) => {
  return api.get("/backup/download/"+id)
  .then((response) => {
    return response.data;
  });
};

const restoreBackup = (id) => {
  return api.get("/backup/restore/"+id)
  .then((response) => {
    return response.data;
  });
};

const getBackupUi = () => {
  return api.get("/backup/ui")
  .then((response) => {
    return response.data;
  });
};




const runBackup = () => {
  return api.get("/backup/run")
  .then((response) => {
    return response.data;
  });
};

const getBackupFilterValues = (search) => {

  return api.get("/backup/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getBackupAggregateValues = (search) => {

  return api.get("/backup/aggregatevalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const deleteBackup = (id) => {
  return api.delete("/backup/" + id).then((response) => {
    return response.data;
  });
};



const BackupSevice = {
  getBackup,
  getBackupUi,
  runBackup,
  getBackupFilterValues,
  getBackupAggregateValues,
  getBackups,
  deleteBackup,
  downloadBackup,
  restoreBackup,
};

export default BackupSevice;