import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import SequenceService from "../services/sequence.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};






export const listSequence = createAsyncThunk(
  "Sequence/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await SequenceService.listSequence(search);
      return { Sequence: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSequence = createAsyncThunk(
  "Sequence/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await SequenceService.getSequence(id);
      return { Sequence: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getSequenceFilterValues = createAsyncThunk(
  "Sequence/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await SequenceService.getSequenceFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getSequenceUi = createAsyncThunk(
  "Sequence/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await SequenceService.getSequenceUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateSequence = createAsyncThunk(
  "Sequence/update",
  async ({ sequence }, thunkAPI) => {
    try {
      const data = await SequenceService.updateSequence(sequence);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  loading: false,
  filter_values: [],
  Ui: [],
}

const SequenceSlice = createSlice({
  name: "Sequence",
  initialState,

  extraReducers: {

    [updateSequence.fulfilled]: (state, action) => {

      const updatedSequence = state.data.list.map((Sequence) => {
        if (Sequence.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return Sequence;
        }
      });
      state.data.list = updatedSequence;
    },

    

    [getSequenceUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getSequenceUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getSequenceUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getSequenceFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getSequenceFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getSequenceFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },





    [listSequence.pending]: (state, action) => {
      state.loading = true;
    },
    [listSequence.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.Sequence;
      state.loading = false;
    },
    [listSequence.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [getSequence.pending]: (state, action) => {
      state.loading = true;
    },
    [getSequence.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.Sequence;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getSequence.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});

const { reducer } = SequenceSlice;
export default reducer;
