const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const PageBlock = styled(Box) (({ theme }) => ({
  margin: ".7rem 1rem", 
  minHeight: "75vh",

}));

export default PageBlock;
