import {
  CloseOutlined,
  FlagOutlined,
  ForkLeftOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlaylistAddCheckOutlined,
  RepeatOnOutlined,
  RepeatOutlined,
  StopCircleOutlined,
  VerticalAlignBottomOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { closeMenu, addContributionSelectionNode, setSelectedNode } from "slices/workflowUI";
import { useDispatch } from "react-redux";

const WorkflowMenu = memo(({ data, isConnectable }) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const id = data.id;

  const addContribution = () =>{

    dispatch(addContributionSelectionNode(id))
    //set this new node as selected

    dispatch(setSelectedNode(id))

  }

  return (
    <Box
      id="findme"
      display="flex"
      flexDirection="column"
      sx={{ minWidth: "20rem", minHeight: "2.5rem" }}
    >
      <Typography variant="h4" mb=".3rem">
        What's Next?
      </Typography>

      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap=".7rem"
        pb=".7rem"
      >
        <Tooltip title="Use Contribution">
          <IconButton
            sx={{
              borderRadius: "2px", // Set the border radius to 0
              width: "50px", // Set the width and height to make it square
              height: "50px",
              border: "1px dashed", // Add the border property with a dotted style
              borderColor: theme.palette.primary.main,
            }}

            onClick={addContribution}
          >
            <VerticalAlignBottomOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Create conditional if">
          <IconButton
            sx={{
              borderRadius: "2px", // Set the border radius to 0
              width: "50px", // Set the width and height to make it square
              height: "50px",
              border: "1px dashed", // Add the border property with a dotted style
              borderColor: theme.palette.primary.main,
              transform: "rotate(180deg)",
            }}
          >
            <ForkLeftOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Repeat Step">
          <IconButton
            sx={{
              borderRadius: "2px", // Set the border radius to 0
              width: "50px", // Set the width and height to make it square
              height: "50px",
              border: "1px dashed", // Add the border property with a dotted style
              borderColor: theme.palette.primary.main,
            }}
          >
            <RepeatOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title="Stop">
          <IconButton
            sx={{
              borderRadius: "2px", // Set the border radius to 0
              width: "50px", // Set the width and height to make it square
              height: "50px",
              border: "1px dashed", // Add the border property with a dotted style
              borderColor: theme.palette.primary.main,
            }}
          >
            <StopCircleOutlined />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Tooltip title="Close Menu">
          <IconButton
            sx={{
              borderRadius: "30px", // Set the border radius to 0
              width: "30px", // Set the width and height to make it square
              height: "30px",
              border: "1px dotted", // Add the border property with a dotted style
              borderColor: theme.palette.primary.main,
            }}
            onClick={() => dispatch(closeMenu({ id }))}
          >
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Box>

      <Handle
        type="target"
        position={Position.Top}
        id="IN"
        isConnectable={isConnectable}
        style={{
          left: "1.7rem",
        }}
      />

      <Handle
        type="source"
        position={Position.Bottom}
        id="OUT"
        isConnectable={isConnectable}
        style={{
          left: "1.7rem",
        }}
      />
    </Box>
  );
});

export default WorkflowMenu;
