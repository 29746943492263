import { Box, useTheme, Link, Divider } from "@mui/material";
import Header from "../../components/global/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

import { Link as RouterLink } from "react-router-dom";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const About = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="1.5rem 2.5rem" height="75vh">
      <Header title="Saving money since 2023 all over the world" />

      <Box>
        <Typography variant="h4">Our Mission:</Typography>
        <Typography inline>
          Fundamentally remove cloud cost wastage through business empowerment
          and owned well governed and automated detect - remediate – prevent
          cycles
        </Typography>
      </Box>

      <Box display="flex" alignContent="flex-start" ml="3rem" >
        <Box width="50rem">
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h3">2008</Typography>
                  <Typography variant="h4">
                    OpsBlox.io founders start using Azure Services
                  </Typography>
                  <Typography>
                    In the early days befor OpsBlox.io was a thing public cloud
                    started to become a day to day reality for both founders,
                    over time the cost of running services became more and more
                    opaque
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              
              <TimelineContent>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h3">2010</Typography>
                  <Typography variant="h4">
                    Experance grows working with AWS
                  </Typography>
                  <Typography>
                    The issues with Azure cost managment, were ionly too common
                    and found to be equaly true and previlate in AWS
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineContent>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h3">2020</Typography>
                  <Typography variant="h4">FinOps becomes a thing</Typography>
                  <Typography>
                    The world startes to see the issues arround enterprise
                    operations relating to the cost managemnt of public cloud,
                    the term finops is coined
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
      </Box>


    </Box>
  );
};

export default About;
