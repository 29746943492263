import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import WorkflowContributionService from "../services/workflow_contribution.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

//const users = {data: list, loading: false};

export const updateWorkflowContribution = createAsyncThunk(
  "WorkflowContribution/update",
  async ({ org }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.updateWorkflowContribution(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateWorkflowContributionActive = createAsyncThunk(
  "WorkflowContribution/update_active",
  async ({ WorkflowContribution }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.updateWorkflowContributionActive(WorkflowContribution);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowContributions = createAsyncThunk(
  "WorkflowContribution/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.getWorkflowContributions(search);
      return { WorkflowContributions: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowContribution = createAsyncThunk(
  "WorkflowContribution/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.getWorkflowContribution(id);
      return { WorkflowContribution: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowContributionUi = createAsyncThunk(
  "WorkflowContribution/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.getWorkflowContributionUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWorkflowContributionData = createAsyncThunk(
  "WorkflowContribution/get_data_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.getWorkflowContributionData(search);
      return { WorkflowContribution_data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runWorkflowContribution = createAsyncThunk(
  "WorkflowContribution/run",
  async ({ id }, thunkAPI) => {
    try {
      const data = await WorkflowContributionService.runWorkflowContribution(id);
      thunkAPI.dispatch(
        setMessage({
          message: `WorkflowContribution started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process WorkflowContribution ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearWorkflowContributionUi = createAction("WorkflowContribution/clearWorkflowContributionUi");

const initialState = {
  data: list,
  loading: false,
  WorkflowContribution_data: list,
  WorkflowContributionUi: null,
  WorkflowContributionRunJob: null,
};

const WorkflowContributionSlice = createSlice({
  name: "WorkflowContributions",
  initialState,

  extraReducers: {
    [clearWorkflowContributionUi]: (state, action) => {
      state.WorkflowContributionUi = null;
      state.loading = false;
    },

    [getWorkflowContributionUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowContributionUi.fulfilled]: (state, action) => {
      state.WorkflowContributionUi = action.payload.ui;
      state.loading = false;
    },
    [getWorkflowContributionUi.rejected]: (state, action) => {
      state.WorkflowContributionUi = null;
      state.loading = false;
    },

    [runWorkflowContribution.pending]: (state, action) => {
      state.loading = true;
    },
    [runWorkflowContribution.fulfilled]: (state, action) => {
      state.WorkflowContributionRunJob = action.payload.job;
      state.loading = false;

      //update the rows that this job was created for 
      let WorkflowContributions = state.data.list;
      let thisWorkflowContribution = WorkflowContributions.find(
        (WorkflowContribution) => WorkflowContribution.id === action.payload.job.implementorId
      );

      thisWorkflowContribution.jobStatus = action.payload.job.status;
      thisWorkflowContribution.jobId = action.payload.job.id;

    },
    [runWorkflowContribution.rejected]: (state, action) => {
      state.WorkflowContributionRunJob = null;
      state.loading = false;
    },

    [getWorkflowContributionData.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowContributionData.fulfilled]: (state, action) => {
      //debugger;

      state.WorkflowContribution_data = action.payload.WorkflowContribution_data;
      state.loading = false;
    },
    [getWorkflowContributionData.rejected]: (state, action) => {
      state.WorkflowContribution_data = list;
      state.loading = false;
    },

    [getWorkflowContributions.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowContributions.fulfilled]: (state, action) => {
      state.data = action.payload.WorkflowContributions;
      state.loading = false;
    },
    [getWorkflowContributions.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [updateWorkflowContributionActive.fulfilled]: (state, action) => {
      let WorkflowContributions = state.data.list;
      let thisWorkflowContribution = WorkflowContributions.find(
        (WorkflowContribution) => WorkflowContribution.id === action.payload.data.id
      );
      thisWorkflowContribution.active = action.payload.data.active;
    },

    [updateWorkflowContribution.fulfilled]: (state, action) => {
      debugger;

      let organizations = state.data.list;
      let thisOrg = organizations.find(
        (org) => org.id === action.payload.data.id
      );

      thisOrg.name = action.payload.data.name;
      thisOrg.domain = action.payload.data.domain;
      thisOrg.active = action.payload.data.active;
    },

    [getWorkflowContribution.pending]: (state, action) => {
      state.loading = true;
    },
    [getWorkflowContribution.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.WorkflowContribution;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getWorkflowContribution.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = WorkflowContributionSlice;
export default reducer;
