import api from "./api";



const updateBlox = (org) => {
  return api.patch("/blox",
  {
    ...org
  })
  .then((response) => {
    return response.data;
  });
};

const getBloxData = (search) => {

  return api.get("/blox/data",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};


const getBloxs = (search) => {

  return api.get("/blox",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getBlox = (id) => {
  return api.get("/blox/"+id)
  .then((response) => {
    return response.data;
  });
};

const getBloxUi = (id) => {
  return api.get("/blox/ui/"+id)
  .then((response) => {
    return response.data;
  });
};


const BloxSevice = {
  getBloxs,
  updateBlox,
  getBlox,
  getBloxData,
  getBloxUi,
};

export default BloxSevice;