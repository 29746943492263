import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Chip,
  Link,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridPagination,
  GridCellModesModel,
  GridCellModes,
  GridToolbarExport,
  GridColumnsPanel,
  GridColumnsMenuItem,
} from "@mui/x-data-grid";

import {
  ArrowDropDown,
  Bolt,
  RefreshOutlined,
  RestartAltOutlined,
  StopCircleOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import NiceDuration from "components/global/NiceDuration";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "slices/job";
import { setMessage } from "slices/message";
import { setTablePageDimension, setTablePageLeadObject, setTablePageTab } from "slices/tablePage";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";

import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import KeyVal from "components/global/KeyVal";
import NiceLink from "components/global/NiceLink";
import DataGridCustomColumnMenu from "components/DataGridCustomColumnMenu";
import { now } from "moment";

const TablePage = ({
  dataGetter,
  updater,
  dataRows,
  dataColumns,
  defaultColumnsVisability,
  defaultSort,
  rowClick,
  masterId,
  masterIdField,
  stateSetId,
  initialFilter,
  refreshPoint,
  selectionModel,
  selectionSetter,
  rowId,
  initialDimension,
  initialBillingPeriod,
  kpi,
  serviceType,
  billingRelevant,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [refreshPointLocal, setRefreshPointLocal] = useState(refreshPoint);

  const [page, setPage] = React.useState(searchParams.get("page") || 0);

  const [page_size, setPageSize] = React.useState(
    searchParams.get("page_size") || 25
  );

  const [sort, setSortOptions] = useState(
    searchParams.get("sort") || defaultSort
  );

  const [filter, setFilter] = useState(
    searchParams.get("filter") === undefined
      ? ""
      : searchParams.get("filter") || (initialFilter ? initialFilter : "")
  );

  const [dimension, setDimension] = useState(
    searchParams.get("dimension") === undefined
      ? initialDimension
        ? initialDimension
        : ""
      : searchParams.get("dimension")
  );

  console.log("initial billing: " , initialBillingPeriod)

  const [billingPeriod, setBillingPeriod] = useState(
    searchParams.get("billingPeriod") === undefined
      ? initialBillingPeriod
        ? initialBillingPeriod
        : ""
      : searchParams.get("billingPeriod")
  );

  const dimensions = kpi?.dimensions;

  const currentTablePage = useSelector((state) => state.tablePage.config);

  const thisLeadObject = location.pathname;
  const thisTab = searchParams.get("tab");

  const [isFirstLoad, setIsFirstLoad] = useState(() => {
    if (
      currentTablePage?.leadObject === thisLeadObject &&
      currentTablePage?.tab === thisTab
    ) {
      return false;
    } else {
      return true;
    }
  });

  //debugger;

  useEffect(() => {
    dispatch(setTablePageLeadObject(location.pathname));
    dispatch(setTablePageTab(searchParams.get("tab")));
    
  }, []);

  useEffect(() => {
    console.log("First load: ", isFirstLoad);

    console.log("current table page ", currentTablePage);
    console.log("this location", thisLeadObject);

    const search = {};

    if (stateSetId !== undefined){
      search.stateSetId = stateSetId
    }

    if (masterId !== undefined) {
      search.id = masterId;
    }

    if (masterIdField !== undefined) {
      search.idField = masterIdField;
    }

    if (isFirstLoad) {
      //clear the search as its a new table page

      console.log("Clearing search params");

      let newFilters = initialFilter ? initialFilter : "";

      

      if (kpi !== undefined) {
        //debugger;

        if (newFilters !== "") {
          //add a filter seperated as there are some alreday
          newFilters += "|";
        }
        
        const dimensionKeys = Object.keys(kpi.dimensions);

        for (const key of dimensionKeys) {
          const dimension = dimensions[key];

          newFilters += "[" + dimension.field + "]=all|";

        }

        newFilters = newFilters.slice(0, -1);
      }

      //debugger;

      const params = {
        page: 0,
          page_size: 25,
          filter: newFilters,
          sort: defaultSort ? defaultSort : "",
          dimension: initialDimension ? initialDimension : "",
          billingPeriod: initialBillingPeriod ? initialBillingPeriod : "", 
      };
      
      // Add the "tab" field conditionally
      const tabValue = searchParams.get("tab");
      if (tabValue) {
        params.tab = tabValue;
      }

      setSearchParams(
        params,
        { replace: true }
      );



      setPage(0);
      setPageSize(25);
      setFilter(newFilters);
      setSortOptions(defaultSort ? defaultSort : "");
      setDimension(initialDimension ? initialDimension : "");

      setBillingPeriod(initialBillingPeriod ? initialBillingPeriod : "");

      search.page = 0;
      search.pageSize = 25;
      search.filter = newFilters;
      search.sort = defaultSort ? defaultSort : "";
      search.dimension = initialDimension ? initialDimension : "";
      search.billingPeriod = initialBillingPeriod ? initialBillingPeriod : "";
    } else {
      if (searchParams.get("page") != undefined) {
        search.page = searchParams.get("page");
      }

      if (searchParams.get("page_size") != undefined) {
        search.page_size = searchParams.get("page_size");
      }
      if (searchParams.get("filter") != undefined) {
        search.filter = searchParams.get("filter");
      }
      if (searchParams.get("sort") != undefined) {
        search.sort = searchParams.get("sort");
      }
      if (searchParams.get("dimension") != undefined) {
        search.dimension = searchParams.get("dimension");
      }
      if (searchParams.get("billingPeriod") != undefined) {
        search.billingPeriod = searchParams.get("billingPeriod");
      }
    }

    //final pass to remove the use of "ALL"

    if (search.filter) {
      // Split the string on '|'
      const parts = search.filter.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");
    }
    console.log(
      "Search Params:",
      searchParams.toString(),
      "Search model:",
      search
    );

    // if (isFirstLoad == false) {
    //   dispatch(dataGetter({ search }));
    // }

    dispatch(dataGetter({ search }));

    setIsFirstLoad(false);
  }, [searchParams, refreshPoint]);

  useEffect(() => {
    if (!isFirstLoad) {
      console.log("setting search");
      console.log("tab value:" , searchParams.get("tab"));
      //debugger;

      const params = {
        page: page,
        page_size: page_size,
        filter: filter,
        sort: sort,
        dimension: dimension,
        billingPeriod: billingPeriod
      };
      
      // Add the "tab" field conditionally
      const tabValue = searchParams.get("tab");
      if (tabValue) {
        params.tab = tabValue;
      }

      setSearchParams(
        params,
        isFirstLoad ? { replace: true } : {}
      );
    }
  }, [page, page_size, sort, filter, dimension, billingPeriod]);

  useEffect(() => {
    setRefreshPointLocal(now());
  }, [dataRows]);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      
      debugger

      const id = newRow.id;
      const isBlocked = newRow.is_blocked;

      //dispatch(updateSession({id,isBlocked}));

      return newRow;
    },
    [dataRows]
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    defaultColumnsVisability
  );

  //console.log("Visability Model Table Control: ", columnVisibilityModel);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    dispatch(setMessage("Error editing Row"));
  }, []);

  let columnSortModel = [];

  if (sort?.length > 0) {
    //debugger;

    sort.split(",").forEach((col) => {
      if (col[0] === "-") {
        columnSortModel.push({
          field: col.slice(1, col.length),
          sort: "desc",
        });
      } else {
        columnSortModel.push({
          field: col,
          sort: "asc",
        });
      }
    });
  }

  const handleSortModelChange = React.useCallback((sortModel) => {
    console.log("new sort model: ", sortModel);

    let newSort = "";

    sortModel.forEach((element) => {
      if (element.sort === "desc") {
        newSort = newSort + "-";
      }
      newSort = newSort + element.field;
    });

    setSortOptions(newSort);
  }, []);

  //console.log("sort model: ", columnSortModel);

  const CustomToolbar = () => (
    <DataGridCustomToolbar
      pageSize={page_size}
      pageSizeSetter={setPageSize}
      columns={dataColumns}
      columnsVisible={columnVisibilityModel}
      columnsVisibleSetter={setColumnVisibilityModel}
      filter={filter}
      filterSetter={setFilter}
      refreshPoint={refreshPointLocal}
      kpi={kpi}
      dimension={dimension}
      dimensionSetter={setDimension}
      masterId={masterId}
      masterIdField={masterIdField}
      serviceType={serviceType}
      stateSetId={stateSetId}
      billingPeriod={billingPeriod}
      billingPeriodSetter={setBillingPeriod}
      billingRelevant={billingRelevant}
    />
  );

  if (dataRows !== undefined && isFirstLoad === false) {
    return (
      <Box
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            backgroundColor: 'transparent',
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-row": {
            //backgroundColor: theme.palette.background.light,
            //backgroundColor: theme.palette.grey[10],
            backgroundColor: 'white',
            borderBottomWidth: "1px",
            borderBottomStyle:"solid",
            borderBottomColor: theme.palette.grey[300],
          },

          "& .MuiDataGrid-columnHeaders": {
            //backgroundColor: theme.palette.background.alt,
            color: theme.palette.grey[900],
            fontWeight: "bold",
            borderBottomWidth: "2px",
            borderBottomStyle:"solid",
            borderBottomColor: theme.palette.grey[300],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            //color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            //color: `${theme.palette.secondary[200]} !important`,
          },
        }}
        display={"flex"}
      >
        <DataGrid

          {...(rowId ? { getRowId: rowId } : {})}
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={rowClick}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          rows={dataRows?.list}
          columns={dataColumns}
          pageSize={+page_size}
          page={dataRows?.page}
          paginationMode="server"
          rowCount={dataRows?.total}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
          }}
          autoHeight={true}
          hideFooter={true}
          components={{
            Toolbar: CustomToolbar,
            ColumnMenu: DataGridCustomColumnMenu,
          }}
          headerHeight={45}
          rowHeight={45}
          getRowHeight={() => 'auto'}
          checkboxSelection
          disableSelectionOnClick
          keepNonExistentRowsSelected
          sortingMode="server"
          sortModel={columnSortModel}
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          onSelectionModelChange={(newSelectionModel) => {
            selectionSetter(newSelectionModel);
          }}
          selectionModel={selectionModel}
          footerRow={true} // Enable footer row
        />
      </Box>
    );
  } else {
    return <Typography>Loading</Typography>;
  }
};

export default TablePage;
