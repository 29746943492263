import api from "./api";




const updateJob = (job) => {
  return api.patch("/job",
  {
    ...job
  })
  .then((response) => {
    return response.data;
  });
};

const stopJob = (id) => {
  return api.get("/job/stop/"+id)
  .then((response) => {
    return response.data;
  });
};

const getJobs = (search) => {

  return api.get("/job",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getJob = (id) => {
  return api.get("/job/"+id)
  .then((response) => {
    return response.data;
  });
};

const getJobLogs = (search) => {

  return api.get("/job/log",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getJobFilterValues = (search) => {

  return api.get("/job/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getJobLogFilterValues = (search) => {

  return api.get("/job/og/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const JobSevice = {
  getJobs,
  updateJob,
  getJob,
  stopJob,
  getJobLogs,
  getJobFilterValues,
  getJobLogFilterValues,
};

export default JobSevice;