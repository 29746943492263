import React from "react";

import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

import Header from "components/global/Header";
import KeyValLabel from "components/global/KeyValLabel";
import KeyValValue from "components/global/KeyValValue";

const CataloguePageDetail = ({ closerFunc, page, catalogue }) => {
  const modelsList = page.savingsModels
    ?.split(",")
    .map((model) => model.trim());

  return (
    <Box m="20px">
      <Header title={"Page Details"} />

      <Box display={"flex"} flexDirection={"column"} gap={0.3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Catalogue"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={catalogue.name} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Catalogue Type"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={catalogue.type} />
          </Grid>
        </Grid>

        <Divider flexItem sx={{ my: 2 }} />

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Page Type"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={page.pageType} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Service Category"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={page.serviceCategory} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Service"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={page.service} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Saving Category"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={page.savingCategory} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Saving Group"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <KeyValValue value={page.savingGroup} />
          </Grid>
        </Grid>

        {/* <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <KeyValLabel label={"Saving Models"} labelBold={true} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box>
              {modelsList?.map((model, index) => (
                <Typography key={index} variant="body2">
                  {model}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid> */}
      </Box>

      <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
        <Stack direction="row" spacing={2} mb="2rem">
          <Button
            type="button"
            color="error"
            variant="contained"
            onClick={() => closerFunc()}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default CataloguePageDetail;
