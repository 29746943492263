import { Box, Typography } from "@mui/material";


const element = (big,little, bigVal, littleVal) => {

  return (
    <Box display="flex" flexDirection="row" gap=".2rem" alignItems="baseline">

        <Typography fontWeight="normal">{bigVal}{big}</Typography>
        <Typography sx={{ fontSize: 11 }}>({littleVal}{little})</Typography>
      </Box>
  )

}
const NiceSize = (props) => {

  let bytes = props.children

  let wholeBytes = Math.floor(bytes)

  // let remainderMillies = props.children % 1e9
  // let roundedMillis = Math.round(remainderMillies / 1000000);


  let wholeKb = Math.floor(bytes / 1024) ;
  let wholeMb = Math.round(wholeKb / 1024);
  let wholeGb = Math.round(wholeMb / 1024);
  let wholeTb = Math.round(wholeGb / 1024);

  if(wholeTb > 0){

    let gbLeft = Math.round(wholeGb % 1024)
    return(
      element("d","h" ,wholeTb, gbLeft )
    )
  }
  
  if(wholeGb > 0){

    let mbLeft = Math.round(wholeMb % 1024)
    return(
      element("gb","mb" ,wholeGb, mbLeft )
    )
  }

  if (wholeMb > 0){

    let kbLeft = Math.round(wholeKb % 1024)

    return(
      element("mb","kb" ,wholeMb,  kbLeft)
    )
  }

  if (wholeKb > 0){

    let bytesLeft = Math.round(bytes % 1024)

    return(
      element("kb","b" ,wholeKb,bytesLeft  )
    )
  }


return (
  element("kb","b" ,0, bytes )
  )
}

export default NiceSize;
