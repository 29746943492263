import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/global/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetColumnValues,
  getWidgetKpiAggregateValues,
  getWidgetUtilityAggregateValues,
} from "slices/dashboard";
import NiceCurrency from "components/global/NiceCurrency";

import { useSearchParams } from "react-router-dom";
import WidgetGague from "./WidgetGague";

import {
  getBillingPeriodForReportingPeriod,
  getWhenFilterForReportingPeriod,
  parseDimension,
  parsePeriod,
  processStringForDashboardFilters,
  niceFormatData,

} from "common/helpers";

const WidgetGagueKPITarget = ({
  name,
  icon,
  columns,
  rows,
  dashboard,
  widget,
  config,
  refreshPoint,
  top,
  left,
  useFilters,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const title = config?.title;

  const serviceType = config?.serviceType;
  const description = config?.description;
  const valueField = config?.valueField;

  const currency = config?.currencyField
  const valuesArePercentages = config?.valuesArePercentages
  const valueFormatter = config?.valueFormatter

  const renderState = useSelector((state) =>
    state.dashboards.renderState?.find((state) => state.dashboard === dashboard)
  );

  const renderTitle = processStringForDashboardFilters(title, renderState);

  //calculate the when range based on the selected dimension
  const period = parsePeriod(config?.period, searchParams);
  const dimension = parseDimension(config?.dimension, searchParams);

  //debugger;

  const billingPeriod = getBillingPeriodForReportingPeriod(period);
  const filter = getWhenFilterForReportingPeriod(period);

  const widgetData = useSelector((state) =>
    state.dashboards.widgetDataForRendering?.find(
      (widgetData) =>
        widgetData.dashboard === dashboard && widgetData.widget === widget
    )
  );

  // console.log("Widget data raw ", widgetDataRaw)
  //console.log("Widget data ", widgetData)

  useEffect(() => {
    //debugger

    if (config) {
      //need to augment the filter, we have the filter from the periods now add what is set in the renderstate

      let localFilter = filter;

      if (widget.userFilters) {
        localFilter = localFilter + "|" + renderState.filter;
      }

      let search = {
        dashboard: dashboard,
        widget: widget,

        function: config.function,
        id: config.implementorId,
        fields: valueField,
        dimension: dimension,
        billingPeriod: billingPeriod,
      };

      // Split the string on '|'
      const parts = localFilter.split("|");

      // Process each part and filter out [when]=all
      const filteredParts = parts.filter((part) => {
        // Check if the part does not end with '=all'
        return !part.endsWith("=all");
      });

      // Join the filtered parts back together with '|'
      search.filter = filteredParts.join("|");

      //debugger;

      switch (serviceType) {
        case "Kpi":
          console.log("Dispatching getWidgetKpiAggregateValues:", search);
          dispatch(getWidgetKpiAggregateValues({ search }));

          break;

        case "Utility":
          console.log("Dispatching getWidgetUtilityAggregateValues:", search);
          dispatch(getWidgetUtilityAggregateValues({ search }));

          break;

        default:
          console.log("Not Implemented");
      }

      search = {
        dashboard: dashboard,
        widget: widget,
        id: config.implementorId,
        serviceType: serviceType,
      };

      console.log("Dispatching getWidgetColumnValues:", search);
      dispatch(getWidgetColumnValues({ search }));
    }
  }, [dashboard, widget, config, refreshPoint]);

  //debugger

  let value = widgetData?.rows[0]?.[valueField];
  let valueText = value;


  if (valueFormatter){
    valueText = niceFormatData(valueFormatter,value,currency)
  }
  if (valuesArePercentages){
    value = value * 100;
  }

  if (!widgetData?.rows || !config) {
    return <Box>Loading...</Box>;
  }

//debugger

  return (
    <WidgetGague
      title={renderTitle}
      description={description}
      icon={icon}
      columns={columns}
      rows={rows}
      left={left}
      top={top}
      value={value}
      valueText={valueText}
      min={0}
      midStart={33}
      midEnd={66}
      max={100}
    />
  );
};

export default WidgetGagueKPITarget;
