import * as React from "react";
import Box from "@mui/material/Box";
import { useSpring, animated } from "@react-spring/web";
import SvgIcon from "@mui/material/SvgIcon";

import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  AddOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  DeleteOutlined,
  EditOutlined,
  GetAppOutlined,
} from "@mui/icons-material";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import FlexBetween from "../global/FlexBetween";
import StateSetFieldCreateEdit from "./StateSetFieldCreateEdit";
import { useState } from "react";
import { string } from "yup";
import { useDispatch } from "react-redux";
import { clearMessage } from "slices/message";
import { updateStateSetRowSchema } from "slices/state";
import UIGroupCreateEdit from "./UIGroupCreateEdit";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function EditSquare(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
    </SvgIcon>
  );
}

function ArrowCircle(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12s4.48,10,10,10C17.52,22,22,17.52,22,12z M4,12c0-4.42,3.58-8,8-8 c4.42,0,8,3.58,8,8s-3.58,8-8,8C7.58,20,4,16.42,4,12z M16,12l-4,4l-1.41-1.41L12.17,13H8v-2h4.17l-1.59-1.59L12,8L16,12z" />
    </SvgIcon>
  );
}

function Square(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M3,3v18h18V3H3z M19,19H5V5h14V19z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = React.forwardRef((props, ref) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const UiGroupDisplay = ({ groups, updater, configRef }) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeFields, setSelectedNodeFields] = useState(null);
  const [createEditMode, setcreateEditMode] = useState("NEW");

  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  //const configRef = React.useRef(null);

  const sortedGroups = Array.isArray(groups) ? [...groups] : [];

  console.log("Groups: ", groups);
  console.log("Sorted: ", sortedGroups);

  const handelDelete = (node) => {};

  const handelOpenAddGroup = (node) => {
    //debugger;

    setSelectedNodeFields(node);

    setSelectedNode(node);
    setcreateEditMode("NEW");

    setAnchorEl(configRef.current);
  };

  const handelOpenEditGroup = (node) => {
    //debugger;

    setSelectedNodeFields(node);
    setSelectedNode(node);
    setcreateEditMode("EDIT");

    setAnchorEl(configRef.current);
  };

  const handelCloseEditGroup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const nodeCount = sortedGroups ? sortedGroups.length : 0;

  //console.log("Nodes: " , nodeCount)

  console.log("Groups: ", sortedGroups);

  const renderTree = (groups, parent) => {
    return groups
      .filter((item) => item.parent === parent)
      .sort((a, b) =>
        a.column === b.column ? a.row - b.row : a.column - b.column
      )
      .map((group) => (
        <StyledTreeItem
          key={group.name}
          nodeId={group.name}
          label={
            <Box
              display="flex"
              alignContent="center"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={"bold"}> {group.name} </Typography>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <IconButton
                  size="small"
                  aria-label="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handelOpenEditGroup(group);
                  }}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="Add Below"
                  onClick={(e) => {
                    e.stopPropagation();
                    handelOpenAddGroup(group);
                  }}
                >
                  <AddOutlined fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handelDelete(group);
                  }}
                >
                  <DeleteOutlined fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          }
        >
          {group.name !== "Main" &&
            Object.entries(group).map(
              ([key, value]) =>
                key !== "name" && (
                  <StyledTreeItem
                    key={key}
                    nodeId={key}
                    label={`${key}: ${value}`}
                  />
                )
            )}

          {/* {Object.entries(group).map(
            ([key, value]) =>
              key !== "name" && (
                <StyledTreeItem
                  key={key}
                  nodeId={key}
                  label={`${key}: ${value}`}
                />
              )
          )} */}

          {renderTree(groups, group.name)}
        </StyledTreeItem>
      ));
  };

  return (
    <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: "600px" }}>
      <Typography variant="H3">Screen UI Groups</Typography>

      <TreeView
        aria-label="UI Groups"
        defaultExpanded={["Screen"]}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<Square />}
        sx={{ overflowX: "hidden" }}
      >
        <StyledTreeItem nodeId="Screen" label={<Typography fontWeight={"bold"}>Screen</Typography>}>
          {renderTree(sortedGroups, "Screen")}
        </StyledTreeItem>
      </TreeView>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseEditGroup}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <UIGroupCreateEdit
            closerFunc={handelCloseEditGroup}
            currentNode={selectedNode}
            mode={createEditMode}
            anchorNode={selectedNode}
            groupsUpdater={updater}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default UiGroupDisplay;
