import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import StateService from "../services/state.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};



export const getCustomerDefaults = createAsyncThunk(
  "CustomerDefaults/get_list",
  async ( thunkAPI) => {
    try {

      const data = await StateService.getStateDataId( "CUSTOMER_DEFAULTS" );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



const initialState = {
  defaults: null,
  loading: false,
}

const CustomerDefaultsSlice = createSlice({
  name: "CustomerDefaults",
  initialState,

  extraReducers: {

    [getCustomerDefaults.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerDefaults.fulfilled]: (state, action) => {
      //debugger;

      state.defaults = action.payload.data;
      state.loading = false;
    },
    [getCustomerDefaults.rejected]: (state, action) => {
      state.defaults = null;
      state.loading = false;
    },

  },
});

const { reducer } = CustomerDefaultsSlice;
export default reducer;
