import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate ,useLocation} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";


import { login } from "../../slices/auth";
import { clearMessage, setMessage } from "../../slices/message";


const Login = (props) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  // if (state &&
  //   console.log("From: " + state.from)
  // );

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!")
    .min(3, 'Must be min of 3 chars'),
    password: Yup.string().required("This field is required!")
    .min(6, 'Must be a min of 6 chars'),
  });

  const handleLogin = (formValue) => {
    const { username, password } = formValue;
    setLoading(true);
    //setMessage("go fo rit")
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate(state.from);
        //window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }


  return (

    <Box m="20px" height="80vh">
      <Header title="Login" subtitle="Enter User Name and Password" />

      <Formik
        onSubmit={handleLogin}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="primary" variant="contained" disabled={loading}>
                    Login
                </Button>
            </Box>

            

          </form>
        )}
      </Formik>
    </Box>

  );

//   return (
//     <div className="col-md-12 login-form">
//       <div className="card card-container">
//         <img
//           src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
//           alt="profile-img"
//           className="profile-img-card"
//         />
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           onSubmit={handleLogin}
//         >
//           <Form>
//             <div className="form-group">
//               <label htmlFor="username">Username</label>
//               <Field name="username" type="text" className="form-control" />
//               <ErrorMessage
//                 name="username"
//                 component="div"
//                 className="alert alert-danger"
//               />
//             </div>

//             <div className="form-group">
//               <label htmlFor="password">Password</label>
//               <Field name="password" type="password" className="form-control" />
//               <ErrorMessage
//                 name="password"
//                 component="div"
//                 className="alert alert-danger"
//               />
//             </div>

//             <div className="form-group">
//               <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
//                 {loading && (
//                   <span className="spinner-border spinner-border-sm"></span>
//                 )}
//                 <span>Login</span>
//               </button>
//             </div>
//           </Form>
//         </Formik>
//       </div>

//       {message && (
//         <div className="form-group">
//           <div className="alert alert-danger" role="alert">
//             {message}
//           </div>
//         </div>
//       )}
//     </div>
//   );
};

export default Login;