import { Box, Typography } from "@mui/material";

const element = (big, little, bigVal, littleVal) => {
  return (
    <Box display="flex" flexDirection="row" gap=".2rem" alignItems="baseline">
      <Typography fontWeight="normal">
        {bigVal}
        {big}
      </Typography>
      <Typography sx={{ fontSize: 11 }}>
        ({littleVal}
        {little})
      </Typography>
    </Box>
  );
};
const NiceDuration = (props) => {
  //duration will be golang time.duration which is nanoseconds

    let seconds = props.children / 1e9;
    let wholeSeconds = Math.floor(seconds);
    let remainderMillies = props.children % 1e9;
    let roundedMillis = Math.round(remainderMillies / 1000000);

    let wholeMinutes = Math.floor(seconds / 60);
    let wholedHours = Math.round(wholeMinutes / 60);
    let wholeDays = Math.round(wholedHours / 24);

    if (wholeDays > 0) {
      let hoursLeft = Math.round(wholedHours % 24);
      return element("d", "h", wholeDays, hoursLeft);
    }

    if (wholedHours > 0) {
      let minutesLeft = Math.round(wholeMinutes % 60);

      return element("h", "m", wholedHours, minutesLeft);
    }

    if (wholeMinutes > 0) {
      let secondsLeft = Math.round(seconds % 60);

      return element("m", "s", wholeMinutes, secondsLeft);
    }

    return element("s", "ms", wholeSeconds, roundedMillis);
  
};

export default NiceDuration;
