import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Search, Settings, SettingsOutlined } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  Divider,
  DialogTitle,
  DialogActions,
  Popover,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  Switch,
  List,
  Autocomplete,
  Chip,
  Stack,
  MenuItem,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridPagination,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import FlexBetween from "./global/FlexBetween";
import { Box } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { getCurrentBillingPeriod } from "common/billingPeriod";
import NiceBillingPeriod from "./global/NiceBillingPeriod";



const DataGridFilterBillingPeriod = ({ closer, saver, currentBillingPeriod, billingPeriods, props }) => {

  const selectRef = useRef(null); // Create a ref for the select element

  useEffect(() => {
    // Set focus on the select element when the component mounts
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, []);

  const [billingPeriod, setBillingPeriod] = React.useState(currentBillingPeriod);



  const setFilter = () => {
    //check if the filter is alreday in the list and amend or insert as needed
    saver(billingPeriod)
    closer();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="20rem"
      m="1rem"
      gap="1rem"
      sx={{bgcolor: 'background.paper'}}
    >
      <TextField
        select
        inputRef={selectRef} // Assign the ref to the select element
        label="Billling Period"
        variant="outlined"
        value={billingPeriod}
        onChange={(event) => {
          setBillingPeriod(event.target.value);
        }}
        size="small"
      >
        {billingPeriods.map((option) => (
          <MenuItem key={option} value={option}>
            <NiceBillingPeriod>{option}</NiceBillingPeriod>
          </MenuItem>
        ))}
      </TextField>

      <Box display="flex" gap="1rem" justifyContent="flex-end">
        <Button onClick={closer}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={setFilter}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default DataGridFilterBillingPeriod;
