
import { Box, Typography, Link, Tooltip } from "@mui/material";

//CorrectLable: make the lable have title case with spaces
const KeyValLabel = ({
  label,
  correctLabel,
  field,
  showTechnicalName,
  labelBold,
}) => {

  let newLabel = label;

  if (correctLabel) {
    let result = label.replace(/([a-z])([A-Z])/g, "$1 $2");
    newLabel = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return (
    <Box>
      <Typography fontWeight={labelBold ? "bold" : "normal"}>
        {newLabel}
      </Typography>
      <Typography variant="small">
        {showTechnicalName && <span>({field})</span>}
      </Typography>
    </Box>
  );
};

export default KeyValLabel;
