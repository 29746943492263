import Moment from "react-moment";
import moment from "moment-timezone";

const NiceDate = (props) => {
  //console.log("date: ", moment(props.children))

  const dateToDisplay = moment(props.children);


    return <Moment format="DD-MMM-YYYY HH:mm:ss">{dateToDisplay}</Moment>;
  
};

export default NiceDate;
