import { Box, useTheme , Button, TextField,Stack,Divider} from "@mui/material";
import Header from "../../../components/global/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../theme";
import { setMessage, clearMessage } from "../../../slices/message"
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { register,login } from "../../../slices/auth";
import { useDispatch, useSelector} from "react-redux";

const Register = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var initialValues = {
    username: "",
    password:"",
    password2:"",
    firstName: "",
    lastName: "", 
    email: "",
    note: "",
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");




  const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    username: yup.string().required("required").min(3, 'Must be a min of 3 chars'),
    password: yup.string().required("required").min(6, 'Must be a min of 6 chars'),
    password2: yup.string().required("required").oneOf([yup.ref('password'), null], 'Passwords must match'),
    firstName: yup.string().required("required").min(3, 'Must be a min of 3 chars'),
    lastName: yup.string().required("required").min(3, 'Must be a min of 3 chars'),
    email: yup.string().email("invalid email").required("required"),
  });


  return (
    <Box m="20px">
      <Header title="Register for opsblox" />
      
<Formik
        onSubmit={(values,actions) => {
        
        dispatch(clearMessage());

        
          let user = {
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            note: values.note,
            email: values.email,
          }

        dispatch(register({user}))
        .unwrap()
        .then(() => {
          actions.setSubmitting(false)

          const username = values.username;
          const password = values.password;

          //log the user in 
          dispatch(login({username, password} ))

          navigate("/user/" + values.username);
        })
        .catch(() => {
          actions.setSubmitting(false)
        });

    }}
        validationSchema={checkoutSchema}
        initialValues={initialValues}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          isSubmitting,
          isValid,
          dirty,
          
        }) => (
          <form onSubmit={handleSubmit} >

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                width: 500 ,
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 4" }}
                
              />

              

              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
                
              />

              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Repeat Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password2}
                name="password2"
                error={!!touched.password2 && !!errors.password2}
                helperText={touched.password2 && errors.password2}
                sx={{ gridColumn: "span 2" }}
                
              />

              <Divider sx={{ gridColumn: "span 4" }}/>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
                
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
                name="note"
                error={!!touched.note && !!errors.note}
                helperText={touched.note && errors.note}
                sx={{ gridColumn: "span 4" }}
              />

              


              <Box display="flex" justifyContent="end" mt="20px" sx={{ gridColumn: "span 4" }}>
              <Stack direction="row" spacing={2}>

                <Button 
                  type="submit" 
                  color="primary" 
                  variant="contained" 
                  disabled = {isSubmitting || !isValid || !dirty}
                >
                  Register
                </Button>            

            </Stack>
              
            </Box>

            </Box>
              
          </form>
        )}
      </Formik>

      
    </Box>
  );
};

export default Register;
