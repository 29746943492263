import axiosInstance from "./api";
import TokenService from "./token.service";
import { refreshToken,logout } from "../slices/auth";

const setup = (store) => {

    const { dispatch } = store;

//----------------Request----------------------------------------------------------------------------

    axiosInstance.interceptors.request.use(
        (config) => {

        //debugger;
        const token = TokenService.getLocalAccessToken();

        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token; 
        }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
  );


//----------------Response----------------------------------------------------------------------------

  
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        
        async (err) => {
            const originalConfig = err.config;

            //debugger;

            if (originalConfig.url !== "/refreshtoken" && err.response) {
                // Check if error is Unauthorized else pass on 
                if (err.response.status === 401 && err.response.data.message.includes("token has expired") && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post("/refreshtoken", {
                        refreshToken: TokenService.getLocalRefreshToken(),
                        });

                        //debugger;

                        const accessToken  = rs.data;

                        dispatch(refreshToken(accessToken));
                        TokenService.updateLocalAccessToken(accessToken);

                        return axiosInstance(originalConfig);

                    } catch (_error) {

                        //debugger;
                        dispatch(logout());
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );


};

export default setup;