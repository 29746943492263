import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import CloudOrgStructureService from "../services/cloudOrgStructure.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};

export const createCloudOrgStructureElement = createAsyncThunk(
  "CloudOrgStructureElement/create",
  async ({ CloudOrgStructureElement }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.createCloudOrgStructureElement(CloudOrgStructureElement);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const deleteCloudOrgStructureElement = createAsyncThunk(
  "CloudOrgStructureElement/delete",

  async ({ id }, thunkAPI) => {

    debugger;
    try {
      const data = await CloudOrgStructureService.deleteCloudOrgStructureElement(id);
      return { CloudOrgStructureElements : data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateCloudOrgStructureElement = createAsyncThunk(
  "CloudOrgStructureElement/update",
  async ({ CloudOrgStructureElement }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.updateCloudOrgStructureElement(CloudOrgStructureElement);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getCloudOrgStructure = createAsyncThunk(
  "CloudOrgStructureElement/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructure(search);
      return { elements: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCloudOrgStructureElement = createAsyncThunk(
  "CloudOrgStructureElement/get",

  async ({ id }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructureElement(id);
      return { CloudOrgStructureElement: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCloudOrgStructureByType = createAsyncThunk(
  "CloudOrgStructureElementByType/get",

  async ({ search }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructureByType({search});
      return { CloudOrgStructureElement: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCloudOrgStructureElementFilterValues = createAsyncThunk(
  "CloudOrgStructureElement/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructureFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCloudOrgStructureElementLogs = createAsyncThunk(
  "CloudOrgStructureElementLog/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructureElementLogs(search);
      return { logs: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runCloudOrgStructure = createAsyncThunk(
  "CloudOrgStructure/run",
  async (_,thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.runCloudOrgStructure();
      thunkAPI.dispatch(
        setMessage({
          message: `CloudOrgStructure started with job ${data.id}`,
          type: "success",
          longText: `Job ${data.id} created to process ${data.implementorId}`,
        })
      );

      return { job: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCloudOrgStructureUi = createAsyncThunk(
  "CloudOrgStructure/get_ui",
  async ({ }, thunkAPI) => {
    try {
      const data = await CloudOrgStructureService.getCloudOrgStructureUi();
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
  data: list,
  loading: false,
  filter_values: [],
  logs: list,
  RunJob: null,
  Ui: [],
}

const CloudOrgStructureSlice = createSlice({
  name: "CloudOrgStructure",
  initialState,

  extraReducers: {

    [getCloudOrgStructureUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getCloudOrgStructureUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getCloudOrgStructureUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [runCloudOrgStructure.pending]: (state, action) => {
      state.loading = true;
    },
    [runCloudOrgStructure.fulfilled]: (state, action) => {

      debugger
      state.RunJob = action.payload.job;
      state.loading = false;
    },
    [runCloudOrgStructure.rejected]: (state, action) => {
      state.RunJob = null;
      state.loading = false;
    },

    [getCloudOrgStructureElementFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getCloudOrgStructureElementFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getCloudOrgStructureElementFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },



    [createCloudOrgStructureElement.pending]: (state, action) => {
      state.loading = true;
    },
    [createCloudOrgStructureElement.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.data.data);
      state.data.total ++;
      state.loading = false;
    },
    [createCloudOrgStructureElement.rejected]: (state, action) => {
      state.loading = false;
    },



    [getCloudOrgStructure.pending]: (state, action) => {
      state.loading = true;
    },
    [getCloudOrgStructure.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.elements;
      state.loading = false;
    },
    [getCloudOrgStructure.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [deleteCloudOrgStructureElement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCloudOrgStructureElement.fulfilled]: (state, action) => {

      state.data = action.payload.CloudOrgStructureElements;
      state.loading = false;
    },
    [deleteCloudOrgStructureElement.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [updateCloudOrgStructureElement.fulfilled]: (state, action) => {

      const updatedCloudOrgStructureElement = state.data.list.map((CloudOrgStructureElement) => {
        if (CloudOrgStructureElement.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return CloudOrgStructureElement;
        }
      });
      state.data.list = updatedCloudOrgStructureElement;
    },

    [getCloudOrgStructureElement.pending]: (state, action) => {
      state.loading = true;
    },
    [getCloudOrgStructureElement.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.CloudOrgStructureElement;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getCloudOrgStructureElement.rejected]: (state, action) => {
      state.loading = false;
    },


    [getCloudOrgStructureByType.pending]: (state, action) => {
      state.loading = true;
    },
    [getCloudOrgStructureByType.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.CloudOrgStructureElement;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getCloudOrgStructureByType.rejected]: (state, action) => {
      state.loading = false;
    },


  },
});

const { reducer } = CloudOrgStructureSlice;
export default reducer;
