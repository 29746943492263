import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { CheckBox } from "@mui/icons-material";
import {
  addStateSetField,
  createStateSet,
  updateStateSet,
  updateStateSetField,
} from "slices/state";

const separateAndTitleCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

const StateSetCreateEdit = ({ closerFunc, stateSet, mode }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  const initialValues = {};

  if (mode === "EDIT") {
    initialValues.id = stateSet.id;
    initialValues.name = stateSet.name;
    initialValues.cloud = stateSet.cloud;
    initialValues.type = stateSet.type;
    initialValues.tableMode = stateSet.tableMode;
    initialValues.description = stateSet.description;
    initialValues.individuleValues = stateSet.individuleValues;
    initialValues.isHubLevel = stateSet.isHubLevel;
  } else {
    initialValues.id = "";
    initialValues.name = "";
    initialValues.cloud = "ALL";
    initialValues.type = "User";
    initialValues.tableMode = false;
    initialValues.description = "";
    initialValues.individuleValues = true;
    initialValues.isHubLevel = false;
  }

  const types = [
    {
      value: "User",
      label: "User",
    },
    {
      value: "System",
      label: "System",
    },
    {
      value: "Blox",
      label: "Blox Config",
    },
    {
      value: "Utility",
      label: "Utility Config",
    },
    {
      value: "Kpi",
      label: "Kpi Config",
    },
    {
      value: "Contribution",
      label: "Workflow Contribution",
    },
    {
      value: "OpsBlox",
      label: "OpsBlox Master Data",
    },
    {
      value: "Dashboard Widget",
      label: "Config for Dashboard Widget",
    },
  ];

  const clouds = [
    {
      value: "ALL",
      label: "All - Cross Cloud",
    },
    {
      value: "AZURE",
      label: "Azure",
    },
    {
      value: "AWS",
      label: "Amazon Web Services",
    },
    {
      value: "GCP",
      label: "Google Cloud Platform",
    },
  ];

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .required("This field is required!")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Must contain uppercase, lowercase, _, and numbers with no whitespace"
      ),

    name: Yup.string().required("This field is required"),
  });

  return (
    <Box m="20px">
      <Header
        title={mode === "NEW" ? `New State Set` : `Edit "${stateSet.name}"`}
      />

      <Formik
        onSubmit={(values, actions) => {
          const stateSet = { ...values };

          dispatch(clearMessage());

          if (mode === "NEW") {
            dispatch(createStateSet({ stateSet }))
              .unwrap()
              .then(() => {
                //debugger;

                dispatch(setMessage("Set Added", "success"));
                closerFunc();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          } else {
            dispatch(updateStateSet({ stateSet }))
              .unwrap()
              .then(() => {
                //debugger;

                dispatch(setMessage("State Set Updated", "success"));
                closerFunc();
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="State Set ID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                name="id"
                error={!!touched.id && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 1" }}
                disabled={mode === "EDIT"}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Name"
                onFocus={(e) => {
                  if (!values.name.trim()) {
                    // Copy the value from the field field
                    handleChange({
                      target: {
                        name: "name",
                        value: separateAndTitleCase(values.id),
                      },
                    });
                  }
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                multiline
                rows="5"
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 1" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.isHubLevel}
                    name="isHubLevel"
                    disabled={mode === "EDIT"}
                  />
                }
                label="State Set is Hub wide"
              />

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Cloud"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cloud}
                name="cloud"
                error={!!touched.cloud && !!errors.cloud}
                helperText={touched.cloud && errors.cloud}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 16 }}
                disabled={mode === "EDIT"}
              >
                {clouds.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 1" }}
                inputProps={{ min: 1, max: 16 }}
                disabled={mode === "EDIT"}
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.individuleValues}
                    name="individuleValues"
                    disabled={mode === "EDIT"}
                  />
                }
                label="State Set allows each value to be set one by one"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.tableMode}
                    name="tableMode"
                    disabled={mode === "EDIT"}
                  />
                }
                label="State Set containes table data"
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="neutral"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {mode === "NEW" ? "Add State Set" : "Save"}
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default StateSetCreateEdit;
