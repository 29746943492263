import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import OrchestratorService from "../services/orchestrator.service";


const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0
};


//const users = {data: list, loading: false};


export const updateOrchestrator = createAsyncThunk(
  "orchestrator/update",
  async ( {org},thunkAPI) => {
    try {
      const data = await OrchestratorService.updateOrchestrator(org);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getOrchestrators = createAsyncThunk(
  "orchestrator/get_list",
  async ({search}, thunkAPI) => {
    try {

      const data = await OrchestratorService.getOrchestrators(search);
      return { orchestrators: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOrchestrator = createAsyncThunk(
  "orchestrator/get",
  async ({id}, thunkAPI) => {
    try {
      const data = await OrchestratorService.getOrchestrator(id);
      return { orchestrator: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getThisOrchestrator = createAsyncThunk(
  "orchestratorThis/get",
  async (thunkAPI) => {
    try {
      const data = await OrchestratorService.getThisOrchestrator();
      return { orchestrator: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {data: list, loading: false, thisOrchestrator: {} }

const organizationSlice = createSlice({
  name: "orchestrators",
  initialState,
  extraReducers: {

    [getOrchestrators.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrchestrators.fulfilled]: (state, action) => {
      state.data = action.payload.orchestrators; 
      state.loading = false;
    },
    [getOrchestrators.rejected]: (state, action) => {
      state.data = list
      state.loading = false;
    },

    [updateOrchestrator.fulfilled]: (state, action) => {

      debugger
      
      let organizations = state.data.list;
      let thisOrg = organizations.find((org) => org.id === action.payload.data.id)

      thisOrg.name = action.payload.data.name
      thisOrg.domain = action.payload.data.domain
      thisOrg.active = action.payload.data.active

    },

    [getOrchestrator.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrchestrator.fulfilled]: (state, action) => {

      //debugger;
      const newPayload = action.payload.orchestrator;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }
      state.loading = false;

    },
    [getOrchestrator.rejected]: (state, action) => {
      state.loading = false;
    },

    [getThisOrchestrator.pending]: (state, action) => {
      state.loading = true;
    },
    [getThisOrchestrator.fulfilled]: (state, action) => {


      const newPayload = action.payload.orchestrator;

      state.thisOrchestrator = newPayload;
      state.loading = false;

    },
    [getThisOrchestrator.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});


const { reducer } = organizationSlice;
export default reducer;