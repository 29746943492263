import { Box, useTheme } from "@mui/material";
import Header from "../../../components/global/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import NiceDate from "../../../components/global/NiceDateOnly";
import { Margin } from "@mui/icons-material";
import ProfileAvatar from "../../../components/user/ProfileAvatar";
import { useParams } from "react-router-dom"

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //const { username } = useParams()

  const currentUser  = useSelector((state) => state.auth.user);

  const { isLoggedIn } = useSelector((state) => state.auth);


  return (
    <Box m="20px" width="80%" height="80vh">

      {isLoggedIn &&

      <Box>

        <Header title={currentUser.user.firstName + ' ' +  currentUser.user.lastName }/>

      <Box>

        <Box display="flex" justifyContent="left" alignItems="left" m="20px">
          
        <ProfileAvatar user = {currentUser.user} size="150"/>

        <Box textAlign="left" m="20px">

        
          <Box display="flex" justifyContent="space-left" alignItems="left" >

            <Typography variant="h5" >
              User Name:
            </Typography>

            <Typography variant="h6" pl="10px" >
              {currentUser.user.username}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-left" alignItems="left" >

            <Typography variant="h5" >
              User Since:
            </Typography>

            <Typography variant="h6" pl="10px" >
              <NiceDate>{currentUser.user.createdAt}</NiceDate>
            </Typography>
          </Box>
          

          <Typography variant="h5" >
            {currentUser.user.note}
          </Typography>

          <Typography variant="h5">
            {currentUser.user.email}
          </Typography>

        </Box>

       

        </Box>

        <Accordion 
          sx={{backgroundColor: theme.palette.background.alt}}
        >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography  color={theme.palette.secondary[200]} >
            Access Token
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{overflowWrap: "break-word"}}>
          {currentUser.accessToken}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        sx={{backgroundColor: theme.palette.background.alt}}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={theme.palette.secondary[200]}>
            Refresh Token
          </Typography>
          <Typography color={theme.palette.secondary[200]} pl="10px" variant="h6">
            Valid untill:
          </Typography>
          <Typography color={theme.palette.secondary[200]} pl="10px" variant="h6">
            <NiceDate>{currentUser.refreshTokenExpiresAt}</NiceDate>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{overflowWrap: "break-word"}}>
          {currentUser.refreshToken}
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Box>
    </Box>
}

      
    </Box>
  );
};

export default Profile;
