import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckOutlined,
  ClearOutlined,
  EditOutlined,
} from "@mui/icons-material";

const EditableFieldNumber = ({
  fieldType,
  field,
  initialValue,
  tip,
  rows,
  lookupCollection,
  lookupIdField,
  lookupLabel,
  displayValue,
  onChange = () => {},
  ...props
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);

  const handelEditMode = () => {
    setEditing(true);
  };

  const handelDisplayMode = () => {
    setEditing(false);
  };

  const handelSave = (e) => {
    onChange(value);
    handelDisplayMode();
  };

  const handelReset = (e) => {
    setValue(initialValue);
    handelDisplayMode();
  };

  if (isEditing) {
    if (lookupCollection != undefined && lookupCollection != "") {
      const options = [
        { label: "The Godfather", id: 1 },
        { label: "Pulp Fiction", id: 2 },
      ];

      return (
        <Autocomplete
          width="100%"
          size="small"
          id={`autocomplete-${field}`}
          options={options}
          //getOptionLabel={(option) => option.label}
          onChange={handleChange}
          onBlur={handelReset}
          includeInputInList
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              //value={network.find(option => option.value === values.role)?.label || ''}
            />
          )}
        />
      );
    } else {
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          <TextField
            width="100%"
            size="small"
            variant="standard"
            type={fieldType}
            value={value}
            onChange={handleChange}
            onBlur={() => {
              if (!buttonClicked) {
                handelReset();
              }
            }}
            autoFocus
            sx={{ flex: 1 }} 
          />
          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    }
  }

  return (
    <Tooltip title={tip} placement="bottom-start">
      <Stack gap=".2rem" direction="row" alignItems={"flex-start"}
        p={"2px"}
        onClick={handelEditMode}
        // sx={{
        //   borderRadius: 1,
        //   cursor: "pointer",
        //   border: "1px solid transparent", // Initially transparent border
        //   "&:hover": {
        //     border: "1px solid #000", // Change border color on hover
        //   },
        // }}

        sx={{
          cursor: "pointer",
        }}


      >
        {displayValue}

        <IconButton
              size="small"
              onClick={handelReset}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <EditOutlined fontSize="small" />
            </IconButton>

      </Stack>
    </Tooltip>
  );
};

export default EditableFieldNumber;
