import { Box } from "@mui/material";

const NiceMeasure = (props) => {

  return (

    <Box>
      {props.children}
    </Box>

  );
}

export default NiceMeasure;
