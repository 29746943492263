import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Chip,
  Link,
  Popover,
} from "@mui/material";
import {
  ArrowDropDown,
  Bolt,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  OpenInNewOutlined,
  RefreshOutlined,
  RestartAltOutlined,
  StopCircleOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import NiceDuration from "components/global/NiceDuration";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSchedule,
  getSchedules,
  updateScheduleActive,
} from "slices/schedule";
import { setMessage, clearMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";

import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import KeyVal from "components/global/KeyVal";
import NiceLink from "components/global/NiceLink";
import DataGridCustomColumnMenu from "components/DataGridCustomColumnMenu";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import NiceCron from "components/global/NiceCron";
import TableActionsButton from "components/global/TableActionsButton";
import ScheduleAdd from "components/scheduler/ScheduleCreate";
import ConfirmationDialog from "components/global/ConformationDialogue";

const Schedules = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.schedules.data);

  const [refreshPoint, setRefreshPoint] = useState(now());
  const [selectionModel, setSelectionModel] = React.useState([]);

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    dispatch(clearMessage());
    setAnchorEl(configRef.current);
  };

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("65vh");
  const [areaWidth, setAreaWidth] = useState("30vw");

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleActivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const schedule = { id: item, active: true };

      dispatch(updateScheduleActive({ schedule }));
    });
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const schedule = { id: item, active: false };

      dispatch(updateScheduleActive({ schedule }));
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [deleteScheduleMessage, setDeleteScheduleMessage] = useState(null);

  const handleDelete = () => {
    const selected = selectionModel.length;

    if (selected > 1) {
      let message = "Are you sure you want to delete schedules:";

      selectionModel.forEach((item) => {
        const selectedSchedule = rows?.list.find(
          (schedule) => schedule.id === item
        );
        message +=
          "\n" + (selectedSchedule?.name ? selectedSchedule.name : item);
      });

      setDeleteScheduleMessage(message);
      setOpenDialog(true);
    } else {
      const selectedSchedule = rows?.list.find(
        (backup) => backup.id === selectionModel[0]
      );
      setDeleteScheduleMessage(
        `Are you sure you want to delete schedule\n${
          selectedSchedule.name ? selectedSchedule.name : selectedSchedule.id
        }`
      );

      setOpenDialog(true);
    }
  };

  const handleDialogConfirm = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const id = item;
      dispatch(deleteSchedule({ id }));
    });
    setOpenDialog(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    navigate("/schedule/" + selectionModel[0]);
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },

    {
      text: "Activate",
      icon: <CheckOutlined />,
      callBack: handleActivate,
    },

    {
      text: "Deactivate",
      icon: <CloseOutlined />,
      callBack: handleDectivate,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: handleDelete,
    },
  ];

  const columnsVisability = {
    id: false,
    organization: false,
    orchestrator: false,
    modifiedAt: false,
  };

  const columns = [
    {
      field: "id",
      headerName: "Schedule Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <NiceLink
            label={id}
            link={`/schedule/${id}`}
            tip="Open Schedule"
            makeBlue
          />
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
      renderCell: ({ row: { organization } }) => {
        return <NiceOrganization>{organization}</NiceOrganization>;
      },
    },
    {
      field: "orchestrator",
      headerName: "Orchestrator",
      flex: 0.5,
      renderCell: ({ row: { orchestrator } }) => {
        return <NiceOrchestrator>{orchestrator}</NiceOrchestrator>;
      },
    },
    {
      field: "name",
      headerName: "Schedule",
      flex: 1,
      renderCell: ({ row: { id, name } }) => {
        return (
          <NiceLink
            label={name ? name : id}
            link={`/schedule/${id}`}
            tip="Open Schedule"
            makeBlue
          />
        );
      },
    },

    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "implementorId",
      headerName: "Runs",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 0.5,
      renderCell: ({ row: { active } }) => {
        return <Box>{active && <CheckOutlined />}</Box>;
      },
    },

    {
      field: "cron",
      headerName: "Schedule",
      flex: 1,
      renderCell: ({ row: { cron } }) => {
        return <NiceCron cron_string={cron} show_original />;
      },
    },

    {
      field: "validFrom",
      headerName: "Valid From",
      flex: 1,
      type: "date",
      renderCell: ({ row: { validFrom } }) => {
        return <NiceDate>{validFrom}</NiceDate>;
      },
    },
    {
      field: "validTo",
      headerName: "Valid To",
      flex: 1,
      type: "date",
      renderCell: ({ row: { validTo } }) => {
        return <NiceDate>{validTo}</NiceDate>;
      },
    },

    {
      field: "createdAt",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: ({ row: { createdAt } }) => {
        return <NiceDate>{createdAt}</NiceDate>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Update",
      flex: 1,
      type: "date",
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
  ];

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Schedules" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>
            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handelOpenAddEdit}
            >
              New Schedule
            </Button>
          </FlexBetween>
        </FlexBetween>
        <Box ref={configRef} />
        <TablePage
          dataRows={rows}
          dataColumns={columns}
          dataGetter={getSchedules}
          defaultColumnsVisability={columnsVisability}
          defaultSort={"-implementorId"}
          refreshPoint={refreshPoint}
          selectionModel={selectionModel}
          selectionSetter={setSelectionModel}
        />
      </PageBlock>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <ScheduleAdd
            closerFunc={handelCloseAddEdit}
            //cataloguePage={cataloguePage}
          />
        </Box>
      </Popover>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Deletion"
        content={deleteScheduleMessage}
        confirmeColour="error"
      />
    </>
  );
};

export default Schedules;
