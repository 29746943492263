import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  IconButton,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { clearWorkflowUi } from "slices/workflow";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  getWorkflow,
  updateWorkflow,
  runWorkflow,
  updateWorkflowActive,
} from "slices/workflow";

import { setMessage, clearMessage } from "slices/message";

import {
  getWorkflowSteps,
  updateWorkflowStepActive,
  runWorkflowStep,
} from "slices/worklow_step";

import {
  initUi,
  clearUi,
  setEdges,
  setNodes,
  setSelectedNode,
} from "slices/workflowUI";

import Header from "components/global/Header";

import PropTypes from "prop-types";
import KeyVal from "components/global/KeyVal";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import FlexBetween from "components/global/FlexBetween";
import {
  CheckOutlined,
  CloseOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";
import DetailBlock from "components/global/DetailBlock";
import { now } from "moment";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import FlowWorkflowStart from "components/flow/FlowWorkflowStart";
import WorkflowStop from "components/flow/WorkflowStop";
import TabPanel from "components/global/TabPanel";
import WorkflowStep from "components/flow/WorkflowStep";
import ButtonEdge from "components/flow/ButtonEdge";
import WorkflowMenu from "components/flow/WorkflowMenu";
import ContributionPicker from "components/flow/ContributionPicker";

const Workflow = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [refreshPoint, setRefreshPoint] = useState(now());

  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const Workflow = useSelector((state) =>
    state.workflows.data.list?.find((workflow) => workflow.id === id)
  );

  const Workflow_steps = useSelector((state) => state.workflowSteps);

  const [tabValue, setTabValue] = React.useState(0);

  //UI workflow stuff

  const uiDefaults = useSelector((state) => state.workflowUi.defaults);
  const nodes = useSelector((state) => state.workflowUi.nodes);
  const edges = useSelector((state) => state.workflowUi.edges);
  const selectedNode = useSelector((state) => state.workflowUi.selectedNode);
  const currentNode = nodes.find((node) => node.id == selectedNode);

  useEffect(() => {
    if (selectedNode != null) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [selectedNode]);

  const proOptionsReactFlow = { hideAttribution: true };

  const nodeTypes = useMemo(
    () => ({
      startNode: FlowWorkflowStart,
      stopNode: WorkflowStop,
      stepNode: WorkflowStep,
      menuNode: WorkflowMenu,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      buttonEdge: ButtonEdge,
    }),
    []
  );

  const handlePaneClick = () => {
    dispatch(setSelectedNode(null));
  };

  const loadWorkflowNodes = () => {
    console.log("initial load for:", { id });
    console.log("Steps: ", { Workflow_steps });

    //debugger;

    let initialEdges = [];

    let initialNodes = [
      {
        id: "1",
        position: { x: 5, y: uiDefaults.firstNodeY },
        data: { label: "Start" },
        type: "startNode",
      },
    ];

    if (Workflow_steps?.data.total > 0) {
      let lastNodeId = 1;
      let yPos = uiDefaults.firstNodeY + uiDefaults.nodeSpacing;

      Workflow_steps.data.list.forEach((step) => {
        let thisNodeId = step.sequence + 1;

        let newNode = {
          id: thisNodeId.toString(),
          position: { x: 5, y: yPos },
          data: {
            type: step.type,
            name: step.name,
            id: thisNodeId.toString(),
            stepId: step.id,
            description: step.description,
            note: step.note,
            implementor: step.implementor,
            implementorId: step.implementorId,
          },
          type: "stepNode",
        };

        initialNodes.push(newNode);

        //connect the last one to this one

        let eId = `e${lastNodeId}-${thisNodeId}`;

        initialEdges.push({
          id: eId,
          source: lastNodeId.toString(),
          target: thisNodeId.toString(),
          type: "buttonEdge",
        });

        lastNodeId = thisNodeId;
        yPos = yPos + uiDefaults.nodeSpacing;
      });

      initialNodes.push({
        id: "999",
        position: { x: 5, y: yPos },
        data: { label: "end" },
        type: "stopNode",
      });

      let eId = `e${lastNodeId}-999`;
      initialEdges.push({
        id: eId,
        source: lastNodeId.toString(),
        target: "999",
        type: "buttonEdge",
      });

      dispatch(setNodes(initialNodes));
      dispatch(setEdges(initialEdges));
    } else {
      dispatch(initUi());
    }
  };

  // console.log("Edges: ", edges);
  // console.log("Nodes: ", nodes);

  const configRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = () => {
    setAnchorEl(configRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setSelectedNode(null));
  };

  const open = Boolean(anchorEl);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (!Workflow) {
      //need to get the Workflow form the API
      dispatch(getWorkflow({ id }));
    }

    //debugger;

    const search = {};

    search.page = 0;
    search.page_size = 1000;
    search.filter = `[workflowId]=${id}`;
    search.sort = "sequence";

    dispatch(getWorkflowSteps({ search }));
  }, [Workflow, id]);

  useEffect(() => {
    dispatch(getWorkflow({ id }));
  }, [refreshPoint]);

  useEffect(() => {
    loadWorkflowNodes();

    //dispatch(initUi())
  }, [Workflow_steps]);

  const handleRun = () => {};

  const handleActivate = () => {
    dispatch(clearMessage());
    const workflow = { id: id, active: true };
    dispatch(updateWorkflowActive({ workflow }));
  };

  const handleDectivate = () => {
    dispatch(clearMessage());

    const workflow = { id: id, active: false };
    dispatch(updateWorkflowActive({ workflow }));
  };

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Workflow?.name} subtitle={Workflow?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          {!Workflow?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckOutlined />}
              onClick={handleActivate}
            >
              Activate
            </Button>
          )}
          {Workflow?.active && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloseOutlined />}
              onClick={handleDectivate}
            >
              Deactivate
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayArrowOutlined />}
            onClick={handleRun}
          >
            Run
          </Button>
        </FlexBetween>
      </FlexBetween>

      <DetailBlock>
        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>{Workflow?.description}</Typography>
          <Typography>
            Workflow Type: {Workflow?.isSystemType ? "System" : "User"}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <Typography>
            Created: <NiceDate>{Workflow?.createdAt}</NiceDate>
          </Typography>
          <Typography>
            Modified: <NiceDate>{Workflow?.modifiedAt}</NiceDate>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" padding="15px" gap=".5rem">
          <KeyVal
            label="Active"
            value={Workflow?.active ? "Yes" : "Not Running at Orchestrator"}
            tip="Enable the workflow if you want it to run"
          />
        </Box>
      </DetailBlock>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Workflow Tabs"
          >
            <Tab label="Steps" {...a11yProps(0)} />
            <Tab label="Execution History" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box height={areaHeight} display="flex" ref={configRef}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              //onNodesChange={onNodesChange}
              //onEdgesChange={onEdgesChange}
              proOptions={proOptionsReactFlow}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onPaneClick={handlePaneClick}
            >
              <Controls />
              <MiniMap />
              <Background variant="dots" gap={12} size={1} />
            </ReactFlow>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box height={areaHeight} width={areaWidth}>
                <ContributionPicker
                  workflow_id={id}
                  closer={handleClose}
                  node={currentNode}
                />
              </Box>
            </Popover>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          History
        </TabPanel>
      </Box>
    </PageBlock>
  );
};

export default Workflow;
